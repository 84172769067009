import { Button, Modal } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'umi';
import styles from '../style.less';
import AddOrUpdateApp from './components/add-or-update-app';
import AppListing from './components/app-listing';
import type { AppData } from './constants';

const AccountApps = () => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedApp, setSelectedApp] = useState<AppData | null>(null);
  const dispatch = useDispatch();
  const appSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch({ type: 'apps/fetchAllApps' });
  }, [dispatch]);

  const editAppHandler = (app: AppData) => {
    setModalVisible(true);
    setSelectedApp(app);
  };

  const modalCloseHandler = () => {
    setModalVisible(false);
    setSelectedApp(null);
  };

  return (
    <div className={styles['apps-section-container']} ref={appSectionRef}>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
        }}>
        <Title className={styles.title} level={4}>
          Apps
        </Title>
        <Button
          type="link"
          onClick={() => {
            setModalVisible(true);
          }}>
          Add
        </Button>
      </div>
      <AppListing onEditHandler={editAppHandler} />
      <Modal
        footer={false}
        destroyOnClose={true}
        title={selectedApp ? 'Update app' : 'Add app'}
        visible={isModalVisible}
        onCancel={modalCloseHandler}>
        <AddOrUpdateApp
          selectedApp={selectedApp}
          onSuccess={() => {
            modalCloseHandler();
            appSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
          }}
        />
      </Modal>
    </div>
  );
};

export default AccountApps;
