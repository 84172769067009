/* eslint-disable prefer-template */
import { DatePicker, Input } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';

import styles from './style.less';
import TimeDropDown from './TimeDropDown';

const defaultDisabledDate = (current: any) =>
  current && current > moment().add(1, 'day');

const validateDate = (dateStr: any) => {
  if (dateStr.length > 11) {
    return false;
  }
  if (dateStr.length === 0) {
    return true;
  }
  let regex =
    /([0-3]{1})([0-9]{1})([-]{1})([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})([ ]{1})([0-9]{1})([0-9]{1})([0-9]{1})([0-9]{1})/g;
  const strLen = dateStr.length;
  switch (strLen) {
    case 1:
      regex = /([0-3]{1})/g;
      break;
    case 2:
      regex = /([0-3]{1})([0-9]{1})/g;
      break;
    case 3:
      regex = /([0-3]{1})([0-9]{1})([-]{1})/g;
      break;
    case 4:
      regex = /([0-3]{1})([0-9]{1})([-]{1})([a-zA-Z]{1})/g;
      break;
    case 5:
      regex = /([0-3]{1})([0-9]{1})([-]{1})([a-zA-Z]{1})([a-zA-Z]{1})/g;
      break;
    case 6:
      regex =
        /([0-3]{1})([0-9]{1})([-]{1})([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})/g;
      break;
    case 7:
      regex =
        /([0-3]{1})([0-9]{1})([-]{1})([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})([-]{1})/g;
      break;
    case 8:
      regex =
        /([0-3]{1})([0-9]{1})([-]{1})([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})([-]{1})([0-9]{1})/g;
      break;
    case 9:
      regex =
        /([0-3]{1})([0-9]{1})([-]{1})([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})([-]{1})([0-9]{1})([0-9]{1})/g;
      break;
    case 10:
      regex =
        /([0-3]{1})([0-9]{1})([-]{1})([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})([-]{1})([0-9]{1})([0-9]{1})([0-9]{1})/g;
      break;
    case 11:
      regex =
        /([0-3]{1})([0-9]{1})([-]{1})([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})([-]{1})([0-9]{1})([0-9]{1})([0-9]{1})([0-9]{1})/g;
      break;
    default:
      regex = /([0-3]{1})/g;
      break;
  }
  const matchResult = dateStr.replaceAll(' ', '-').match(regex);
  return matchResult != null;
};

const datePlaceholder = (dateStr: any) => {
  if (!validateDate(dateStr)) return '';
  const strLen = dateStr.length;
  let returnStr = '';
  switch (strLen) {
    case 0:
      returnStr = 'dd mmm yyyy';
      break;
    case 1:
      returnStr = `${dateStr}d mmm yyyy`;
      break;
    case 2:
      returnStr = `${dateStr} mmm yyyy`;
      break;
    case 3:
      returnStr = `${dateStr}mmm yyyy`;
      break;
    case 4:
      returnStr = `${dateStr}mm yyyy`;
      break;
    case 5:
      returnStr = `${dateStr}m yyyy`;
      break;
    case 6:
      returnStr = `${dateStr} yyyy`;
      break;
    case 7:
      returnStr = `${dateStr}yyyy`;
      break;
    case 8:
      returnStr = `${dateStr}yyy`;
      break;
    case 9:
      returnStr = `${dateStr}yy`;
      break;
    case 10:
      returnStr = `${dateStr}y`;
      break;
    case 11:
      returnStr = '';
      break;
    default:
      returnStr = '';
      break;
  }
  return returnStr;
};

export const validateTime = (timeStr: any) => {
  if (timeStr.length > 8) {
    return false;
  }
  if (timeStr.length === 0) {
    return true;
  }
  let regex =
    /([0-2]{1})([0-9]{1})([:]{1})([0-5]{1})([0-9]{1})([:]{1})([0-5]{1})([0-9]{1})/g;
  const strLen = timeStr.length;
  switch (strLen) {
    case 1:
      regex = /([0-2]{1})/g;
      break;
    case 2:
      regex = /([0-2]{1})([0-9]{1})/g;
      break;
    case 3:
      regex = /([0-2]{1})([0-9]{1})([:]{1})/g;
      break;
    case 4:
      regex = /([0-2]{1})([0-9]{1})([:]{1})([0-5]{1})/g;
      break;
    case 5:
      regex = /([0-2]{1})([0-9]{1})([:]{1})([0-5]{1})([0-9]{1})/g;
      break;
    case 6:
      regex = /([0-2]{1})([0-9]{1})([:]{1})([0-5]{1})([0-9]{1})([:]{1})/g;
      break;
    case 7:
      regex =
        /([0-2]{1})([0-9]{1})([:]{1})([0-5]{1})([0-9]{1})([:]{1})([0-5]{1})/g;
      break;
    case 8:
      regex =
        /([0-2]{1})([0-9]{1})([:]{1})([0-5]{1})([0-9]{1})([:]{1})([0-5]{1})([0-9]{1})/g;
      break;
    default:
      regex = /([0-2]{1})/g;
  }
  const matchResult = timeStr.match(regex);
  return matchResult != null;
};

type MyProps = {
  title?: React.ReactElement | string;
  disabledDate?: any;
  styleMode?: string;
  showDate?: boolean;
  showTime?: boolean;
  timeOptionDisabling?: moment.Moment;
  timeOptionDisablingBefore?: boolean;
  value?: moment.Moment;
  onChange?: (...args: any[]) => any;
};

type MyState = {
  timeRange: moment.Moment;
  datePickerOpen: boolean;
  timeDropDownOpen: boolean;
  dateSearchValue: string;
  timeSearchValue: string;
  disablingValue: number;
};

class DatepickerDF extends Component<MyProps, MyState> {
  dateInputRef: any;
  timeInputRef: any;
  constructor(props: MyProps) {
    super(props);
    let i_tr = moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).add(
      -2,
      'days',
    );
    if (_.get(props, 'value', null)) {
      i_tr = _.get(props, 'value', i_tr);
    }
    this.state = {
      timeRange: i_tr,
      datePickerOpen: false,
      timeDropDownOpen: false,
      dateSearchValue: i_tr.format('DD MMM YYYY'),
      timeSearchValue: i_tr.format('HH:mm:ss'),
      disablingValue: -1,
    };
    this.dateInputRef = React.createRef();
    this.timeInputRef = React.createRef();
    // this.timeDropDownRef = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.timeOptionDisabling &&
      this.props.timeOptionDisabling instanceof moment
    ) {
      this.setDisablingValue();
    }
  }

  componentDidUpdate(prevProps: MyProps) {
    let dateSearchValue = this.props.value?.format('DD MMM YYYY');
    let timeSearchValue = this.props.value?.format('HH:mm:ss');

    if (
      prevProps.value !== this.props.value &&
      this.props.value instanceof moment &&
      (dateSearchValue !== this.state.dateSearchValue ||
        timeSearchValue !== this.state.timeSearchValue)
    ) {
      const timeRange = this.props.value;
      this.setState({ timeRange, dateSearchValue, timeSearchValue }, () =>
        this.updateTimeRange(),
      );
    }

    if (
      _.invoke(prevProps.timeOptionDisabling, 'unix') !==
        _.invoke(this.props.timeOptionDisabling, 'unix') &&
      this.props.timeOptionDisabling &&
      this.props.timeOptionDisabling instanceof moment
    ) {
      this.setDisablingValue();
    }
  }

  setDisablingValue() {
    const { timeOptionDisabling } = this.props;
    if (
      timeOptionDisabling &&
      timeOptionDisabling instanceof moment &&
      timeOptionDisabling.isSame(this.state.timeRange, 'day')
    ) {
      const ref_time =
        timeOptionDisabling.get('hour') * 3600 +
        timeOptionDisabling.get('minute') * 60 +
        timeOptionDisabling.get('second');

      this.setState({ disablingValue: ref_time });
    } else {
      this.setState({ disablingValue: -1 });
    }
  }

  timePlaceholder(timeStr: any) {
    if (!validateTime(timeStr)) return '';
    const strLen = timeStr.length;
    let returnStr = '';
    switch (strLen) {
      case 0:
        returnStr = '00:00:00';
        break;
      case 1:
        returnStr = `${timeStr}0:00:00`;
        break;
      case 2:
        returnStr = `${timeStr}:00:00`;
        break;
      case 3:
        returnStr = `${timeStr}00:00`;
        break;
      case 4:
        returnStr = `${timeStr}0:00`;
        break;
      case 5:
        returnStr = `${timeStr}:00`;
        break;
      case 6:
        returnStr = `${timeStr}00`;
        break;
      case 7:
        returnStr = `${timeStr}0`;
        break;
      case 8:
        returnStr = '';
        break;
      default:
        returnStr = '';
        break;
    }
    return returnStr;
  }

  updateTimeRange() {
    let { dateSearchValue, timeSearchValue } = this.state;
    let timeValue = timeSearchValue.split(':');
    // if a timezone has been set in the past, we need to keep using that,
    // otherwise the 'hours' being set up for the timeRange will be incorrect -
    // 03:00 instead of 10:00 etc.
    let oldTimeZone = this.state.timeRange.tz();
    let timeRange = moment.tz(dateSearchValue, 'DD MMM YYYY', oldTimeZone);

    timeRange
      .set('hour', parseInt(timeValue[0], 10))
      .set('minute', parseInt(timeValue[1], 10))
      .set('second', parseInt(timeValue[2], 10))
      .set('millisecond', 0);

    this.setState(
      {
        timeRange,
        dateSearchValue: timeRange.format('DD MMM YYYY'),
        timeSearchValue: timeRange.format('HH:mm:ss'),
      },
      () => {
        const tr = this.state.timeRange;
        if (this.props.onChange) {
          this.props.onChange(tr);
        }
        this.setDisablingValue();
      },
    );
  }

  render() {
    const { disablingValue, timeRange } = this.state;
    const { timeOptionDisablingBefore = true } = this.props;

    const {
      title,
      size,
      showDate = true,
      showTime = true,
      disabledDate = defaultDisabledDate,
      styleMode = 'light',
    } = this.props;

    const inputStyle: Record<string, any> = {};
    if (styleMode === 'light') {
      inputStyle.backgroundColor = '#FFF';
      inputStyle.border = '1px solid #d9d9d9';
    } else if (styleMode === 'grey') {
      inputStyle.backgroundColor = '#F8F9FA';
      inputStyle.border = '1px solid #DEE2E6';
    }

    let width = '100%';
    let offset = size === 'small' ? '60px' : '80px';
    if (showTime) {
      width = `calc( 100% - ${offset} )`;
    }

    return (
      <div className={styles['date-picker-ctn']}>
        <div className={styles['picker-title-input-ctn']}>
          <div className={`${styles['input-title']} df-datepicker-input-title`}>
            {title}
          </div>
          <div
            className={`${styles['input-items']} df-datepicker-input-item`}
            style={inputStyle}>
            {showDate ? (
              <div
                className={`${styles['date-input']} ${
                  size ? styles['date-input-' + size] : ''
                }`}
                // eslint-disable-next-line react/no-unknown-property
                ia={datePlaceholder(this.state.dateSearchValue)}
                style={{ width }}>
                <Input
                  id={`${this.props.id}-date`}
                  className={styles['date-input-box']}
                  ref={this.dateInputRef}
                  size={size}
                  // @ts-expect-error
                  bc={(() => {
                    const c = validateDate(this.state.dateSearchValue)
                      ? '#0091FF'
                      : '#FF9494';
                    const htmlDOM: HTMLHtmlElement | null =
                      window.document.querySelector(':root');
                    if (htmlDOM) {
                      htmlDOM.style.setProperty('--sdbc', c);
                    }
                    return c;
                  })()}
                  autoFocus={false}
                  value={this.state.dateSearchValue}
                  onFocus={(e) => {
                    e.target.select();
                    this.setState({ datePickerOpen: true });
                  }}
                  onBlur={() => {
                    this.setState({ datePickerOpen: false });
                  }}
                  onMouseUp={(e) => {
                    e.preventDefault();
                  }}
                  onChange={(e) => {
                    this.setState({ dateSearchValue: e.target.value }, () => {
                      if (
                        this.state.dateSearchValue.length === 11 &&
                        validateDate(this.state.dateSearchValue)
                      ) {
                        this.updateTimeRange();
                      }
                    });
                  }}
                  onPressEnter={(e) => {
                    const i_target = e.target;
                    if (i_target && i_target instanceof HTMLInputElement) {
                      this.setState(
                        {
                          dateSearchValue: i_target.value,
                          datePickerOpen: false,
                        },
                        () => {
                          if (
                            this.state.dateSearchValue.length === 11 &&
                            validateDate(this.state.dateSearchValue)
                          ) {
                            this.updateTimeRange();
                          }
                        },
                      );
                    }
                  }}
                />
                <DatePicker
                  className={styles['date-picker']}
                  size={size}
                  open={this.state.datePickerOpen}
                  suffixIcon=""
                  showToday={false}
                  placeholder={'dd mmm yyyy'}
                  allowClear={false}
                  disabledDate={disabledDate}
                  format={'dd mmm yyyy'}
                  value={timeRange}
                  onChange={(date) => {
                    if (date instanceof moment) {
                      this.setState(
                        {
                          dateSearchValue: date.format('DD MMM YYYY'),
                          datePickerOpen: false,
                        },
                        () => {
                          if (this.dateInputRef.current) {
                            this.dateInputRef.current.blur();
                          }
                          this.updateTimeRange();
                        },
                      );
                    }
                  }}
                />
              </div>
            ) : null}
            {showTime ? (
              <div
                className={`${styles['time-input']} ${
                  size ? styles['time-input-' + size] : ''
                }`}
                // eslint-disable-next-line react/no-unknown-property
                ib={this.timePlaceholder(this.state.timeSearchValue)}>
                <Input
                  id={`${this.props.id}-time`}
                  className={styles['time-input-box']}
                  ref={this.timeInputRef}
                  size={size}
                  // @ts-expect-error
                  bc={(() => {
                    const c = validateTime(this.state.timeSearchValue)
                      ? '#0091FF'
                      : '#FF9494';
                    const htmlDOM: HTMLHtmlElement | null =
                      window.document.querySelector(':root');
                    if (htmlDOM) {
                      htmlDOM.style.setProperty('--sbc', c);
                    }
                    return c;
                  })()}
                  value={this.state.timeSearchValue}
                  onFocus={(e) => {
                    e.target.select();
                    this.setState({ timeDropDownOpen: true });
                  }}
                  onBlur={() => {
                    setTimeout(
                      () => this.setState({ timeDropDownOpen: false }),
                      500,
                    );
                  }}
                  onMouseUp={(e) => {
                    e.preventDefault();
                  }}
                  onChange={(e) => {
                    this.setState({ timeSearchValue: e.target.value }, () => {
                      if (
                        this.state.timeSearchValue.length === 8 &&
                        validateTime(this.state.timeSearchValue)
                      ) {
                        this.updateTimeRange();
                      }
                    });
                  }}
                  onKeyDown={(e) => {
                    const placeholderValue = this.timePlaceholder(
                      this.state.timeSearchValue,
                    );
                    const newValue =
                      placeholderValue === '' ? '00:00:00' : placeholderValue;
                    if (
                      e.key === 'Tab' &&
                      this.state.timeSearchValue.length < 8
                    ) {
                      e.preventDefault();
                      this.setState({ timeSearchValue: newValue }, () => {
                        this.updateTimeRange();
                      });
                    }
                  }}
                  onPressEnter={(e) => {
                    const i_target = e.target;
                    if (i_target && i_target instanceof HTMLInputElement) {
                      this.setState(
                        {
                          timeSearchValue: i_target.value,
                          timeDropDownOpen: false,
                        },
                        () => {
                          if (
                            this.state.timeSearchValue.length === 8 &&
                            validateTime(this.state.timeSearchValue)
                          ) {
                            this.updateTimeRange();
                          }
                        },
                      );
                    }
                  }}
                />
                <TimeDropDown
                  timeDropDownOpen={this.state.timeDropDownOpen}
                  value={this.state.timeSearchValue}
                  disablingValue={disablingValue}
                  disableBefore={timeOptionDisablingBefore}
                  onChange={({ hr = 0, min = 0, sec = 0 }) => {
                    this.setState(
                      {
                        timeSearchValue: `${hr >= 10 ? hr : '0' + hr}:${
                          min >= 10 ? min : '0' + min
                        }:${sec >= 10 ? sec : '0' + sec}`,
                        timeDropDownOpen: false,
                      },
                      () => {
                        this.updateTimeRange();
                        if (this.timeInputRef.current) {
                          this.timeInputRef.current.blur();
                        }
                      },
                    );
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default DatepickerDF;
