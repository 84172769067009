import React from 'react';
import { connect, formatMessage } from 'umi';

import { Form } from '@ant-design/compatible';

import { Button, Input, Modal, Alert } from 'antd';

// @ts-expect-error
@connect(({ user, loading }) => ({ user, loading }))
class ChangePasswordForm extends React.Component {
  state = { confirmPwd: false };

  changePasswordSubmit = (e) => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (values.currentpassword.length >= 8 && !err) {
        dispatch({
          type: 'user/changePassword',
          payload: {
            currentpassword: values.currentpassword,
            password: values.password,
          },
        }).then((response) => {
          if (response.success) {
            this.toggleChangePasswordModal();
            form.resetFields();
          }
        });
      }
    });
  };

  handleConfirmPwd = (e) => {
    const { value } = e.target;
    this.setState({ confirmPwd: this.state.confirmPwd || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('The password and confirmation password do not match');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmPwd) {
      form.validateFields(['confirmpassword']);
    }
    callback();
  };

  toggleChangePasswordModal() {
    this.setState({ changePasswordModal: !this.state.changePasswordModal });
  }

  renderMessage = (content) => (
    <Alert
      style={{
        marginBottom: 24,
      }}
      message={content}
      type="error"
      showIcon
    />
  );

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <>
        <Button
          style={{ padding: 0 }}
          type="link"
          onClick={() => this.toggleChangePasswordModal()}>
          Change password
        </Button>
        <Modal
          title="Change password"
          visible={this.state.changePasswordModal}
          onOk={(e) => {
            this.changePasswordSubmit(e);
          }}
          onCancel={() => this.toggleChangePasswordModal()}
          footer={[
            <Button key="back" onClick={() => this.toggleChangePasswordModal()}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.props.loading.effects['user/changePassword']}
              onClick={(e) => {
                this.changePasswordSubmit(e);
              }}>
              Ok
            </Button>,
          ]}>
          <Form
            layout="vertical"
            colon={false}
            requiredMark={false}
            onSubmit={(e) => this.changePasswordSubmit(e)}>
            <Form.Item label="Current Password">
              {getFieldDecorator('currentpassword', {
                // validateTrigger: 'onBlur',
                rules: [
                  // { min: 8 },
                  {
                    required: true,
                    message: 'Please enter your current password',
                  },
                ],
              })(
                <Input
                  type="password"
                  placeholder="Current Password"
                  autoFocus
                />,
              )}
            </Form.Item>
            <Form.Item label="New Password">
              {getFieldDecorator('password', {
                rules: [
                  { min: 8 },
                  {
                    required: true,
                    message: 'Please enter your new password',
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password placeholder="New Password" />)}
            </Form.Item>
            <Form.Item label="Confirm Password" hasFeedback>
              {getFieldDecorator('confirmpassword', {
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your password',
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(
                <Input.Password
                  onBlur={this.handleConfirmPwd}
                  placeholder="Confirm Password"
                />,
              )}
            </Form.Item>
            {this.props.user.errors.changePassword &&
              this.renderMessage(
                formatMessage({
                  id: 'user-login.password.wrong',
                }),
              )}
          </Form>
        </Modal>
      </>
    );
  }
}

const NewProject = Form.create({ name: 'changePassword' })(ChangePasswordForm);
export default NewProject;
