import { Form } from '@ant-design/compatible';
import Icon from '@ant-design/icons';

import { ReactComponent as DfLogo } from '@/assets/logo.svg';
import { Alert, Button, Checkbox, Input } from 'antd';
import classNames from 'classnames';
import { Component } from 'react';
import { Link } from 'umi';
import styles from './style.less';

class SignupForm extends Component {
  static defaultProps = {
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const _new_values = { ...values };
        delete _new_values.confirm_password;
        this.props.onSubmit(err, _new_values);
      }
    });
  };

  termsChecked = () => {
    if (document.getElementById('checkbox').checked) {
      document.getElementById('create-button').disabled = false;
    } else document.getElementById('create-button').disabled = true;
  };

  renderMessage = (content) => (
    <Alert
      style={{
        marginBottom: 24,
      }}
      message={content}
      type="error"
      showIcon
    />
  );

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className={classNames(styles.container)}>
        <div className={styles['logo-container']}>
          <Icon component={DfLogo} className={styles.logo} />
        </div>
        <div className={classNames(styles.login)}>
          <div className={classNames(styles.header)}>
            Signup for Dragonfruit
          </div>
          <Form
            layout="vertical"
            onSubmit={this.handleSubmit}
            colon={false}
            requiredMark={false}
            className={styles.form}>
            <Form.Item className={styles['form-item-fname']} label="First Name">
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your first name',
                  },
                ],
              })(
                <Input
                  size="default"
                  autoFocus
                  className={styles.inputButton}
                />,
              )}
            </Form.Item>

            <Form.Item className={styles['form-item-lname']} label="Last Name">
              {getFieldDecorator('lastName', {
                rules: [
                  { required: true, message: 'Please enter your last name' },
                ],
              })(<Input size="default" className={styles.inputButton} />)}
            </Form.Item>
            <Form.Item
              className={styles['form-item-rest']}
              label="Company name"
              extra="Once your account is setup, you'll be able to invite others to the same company or organization account">
              {getFieldDecorator('customerName', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your organization name',
                  },
                ],
              })(<Input size="default" className={styles.inputButton} />)}
            </Form.Item>
            <Form.Item label="Email" className={styles['form-item-rest']}>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                ],
              })(<Input size="default" className={styles.inputButton} />)}
            </Form.Item>
            {this.props.error && this.renderMessage(this.props.error)}
            <Form.Item label="Password" className={styles['form-item-rest']}>
              {getFieldDecorator('password', {
                rules: [
                  { min: 8 },
                  { required: true, message: 'Please enter your password' },
                ],
              })(
                <Input
                  size="default"
                  type="password"
                  className={styles.inputButton}
                />,
              )}
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              className={styles['form-item-rest']}>
              {getFieldDecorator('confirm_password', {
                dependencies: ['password'],
                rules: [
                  { required: true, message: 'Please enter your password' },
                  {
                    validator: (rule, value, callback) => {
                      const { getFieldValue } = this.props.form;
                      if (value && value !== getFieldValue('password')) {
                        callback(
                          'The two passwords that you entered do not match!',
                        );
                      }
                      callback();
                    },
                  },
                ],
              })(
                <Input
                  size="default"
                  type="password"
                  className={styles.inputButton}
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Form.Item
                className={styles['form-item-rest']}
                name="agreement"
                rules={[
                  {
                    required: true,
                    message: 'Please accept the Terms of Service',
                  },
                ]}>
                <Checkbox
                  id="checkbox"
                  className={styles['agreement-check']}
                  style={{ fontSize: '10px' }}
                  onClick={this.termsChecked}>
                  I agree to the Dragonfruit AI{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://dragonfruit.ai/master-services-agreement/"
                    className={styles['new-user']}>
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://dragonfruit.ai/privacy-policy/"
                    className={styles['new-user']}>
                    Privacy Policy.
                  </a>
                </Checkbox>
              </Form.Item>
              <Button
                block
                id="create-button"
                loading={this.props.loading}
                size="default"
                className={styles.submit}
                type="primary"
                disabled
                htmlType="submit">
                Create Account
              </Button>
            </Form.Item>
          </Form>
          <div className={classNames(styles['footer-form'])}>
            Already have an account?&nbsp;
            <Link to="/login" className={styles['new-user']}>
              Log in.
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default Form.create({ name: 'login' })(SignupForm);
