import { getPageQuery } from '@/utils/utils';
import { Component } from 'react';
import { connect } from 'umi';
import SSOLoginForm from '../components/SSOLoginForm';
import styles from './style.less';

// @ts-expect-error
@connect(({ user, loading }) => ({
  user,
  loading,
}))
class SSOLoginPage extends Component {
  state = { response: {} };

  componentDidMount() {
    const params = getPageQuery();
    const { dispatch } = this.props;
    if (!params.account) {
      return;
    }
    dispatch({
      type: 'user/ssoLogin',
      payload: params,
    }).then((response) => {
      this.setState({ response });
      if (response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
        return null;
      }
    });
  }

  handleSubmit(err, values) {
    if (!err) {
      const { dispatch } = this.props;
      dispatch({
        type: 'user/ssoLogin',
        payload: values,
      }).then((response) => {
        this.setState({ response });
        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url;
          return null;
        }
      });
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <SSOLoginForm
          loading={this.props.loading.effects['user/ssoLogin']}
          onSubmit={(err, values) => this.handleSubmit(err, values)}
          user={this.props.user}
          response={this.state.response}
        />
      </div>
    );
  }
}

export default SSOLoginPage;
