import { dispatchWithFeedback } from '@/utils/utils';
import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, history } from 'umi';

// @ts-expect-error
@connect(({ loading }) => ({
  loadingLocation:
    loading.effects['locations/createLocation'] ||
    loading.effects['locations/updateLocation'],
}))
class CreateLocation extends React.Component {
  state = {
    showModal: false,
  };

  createLocationForm = React.createRef();

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  createOrUpdateLocation() {
    const { dispatch, location } = this.props;
    this.createLocationForm.current.validateFields().then(
      (values) => {
        if (location) {
          location.Name = values.name;
          dispatch({
            type: 'locations/updateLocation',
            locationID: location.ProjectID,
            payload: values,
          }).then(() => this.toggleModal());
        } else {
          dispatchWithFeedback(
            dispatch,
            'Location creation',
            {
              type: 'locations/createLocation',
              payload: values,
            },
            true,
          ).then((response) => {
            this.toggleModal();
            if (this.createLocationForm.current) {
              this.createLocationForm.current.resetFields();
            }
            history.push(`/locations/${response.ProjectID}`);
          });
        }
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const { children, loadingLocation, location } = this.props;
    return (
      <>
        <Modal
          width={400}
          title={location ? 'Update location' : 'Create location'}
          visible={this.state.showModal}
          onOk={(e) => this.createOrUpdateLocation(e)}
          confirmLoading={loadingLocation}
          onCancel={() => this.toggleModal()}>
          <Form
            ref={this.createLocationForm}
            layout="vertical"
            requiredMark={false}
            initialValues={{ name: _.get(location, 'Name') }}
            onFinish={(e) => this.createOrUpdateLocation(e)}>
            <Form.Item
              label="Location Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of the location',
                },
              ]}>
              <Input autoFocus />
            </Form.Item>
          </Form>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default CreateLocation;
