import LoadingSpinner from '@/components/LoadingSpinner';
import { dispatchWithFeedback } from '@/utils/utils';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Modal, Progress, Typography, Upload } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Component } from 'react';
import { connect } from 'umi';
import styles from './style.less';

// @ts-expect-error
@connect(({ investigations, loading }) => ({ investigations, loading }))
class UploadMedia extends Component {
  state = { visible: false };

  getUploadProps() {
    const { dispatch, investigation } = this.props;
    return {
      multiple: true,
      // accept: 'video/mp4,video/x-m4v,video/*,.mkv',
      action: (file) => this.getUploadUrl(file),
      showUploadList: false,
      customRequest: ({ action, file }) => {
        action.investigationID = investigation.InvestigationID;
        action.file = file;
        dispatch({
          type: 'investigations/uploadMedia',
          payload: action,
        }).then(({ error }) => {
          if (error) return;
          dispatch({
            type: 'investigations/markInvestigaitonMediaUploadComplete',
            investigation,
            payload: {
              upload_id: action.fields.key.split('/')[1],
              client_dir_name: 'WEB_UPLOAD',
              client_timezone: moment.tz.guess(),
              client_file_name: action.file.name,
              client_file_size: action.file.size,
              // fingerprint,
              s3_bucket: action.url.split('.')[0].split('//')[1],
              s3_filepath: action.fields.key,
              video_start_time: `${moment()
                // TODO: subtract 1 day should be removed, and replaced with `.tz('UTC')`
                //       this code is problematic because dates are stored in the backend
                //       as the local time of the client instead of being UTC which is then
                //       converted. Currently time will break if the client is in a different
                //       timezone than when the video was originally uploaded. The time should
                //       actually be obtained in the backend.
                .subtract(1, 'day')
                .format('YYYY-MM-DDTHH:mm:ss.000000')}Z`,
            },
          });
        });
      },
    };
  }

  getUploadUrl = (file) => {
    const { dispatch } = this.props;
    return dispatchWithFeedback(
      dispatch,
      'Getting upload URL',
      {
        type: 'investigations/getMediaUploadUrl',
        payload: {
          fileName: file.name,
        },
      },
      true,
    );
  };

  showUploadModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    const { investigation, investigations, loading, dispatch } = this.props;
    const mediaUploads = _.get(
      investigations,
      `mediaUploads[${investigation.InvestigationID}]`,
      [],
    );
    if (
      loading.effects['investigations/getMediaUploadUrl'] ||
      loading.effects['investigations/uploadMedia'] ||
      Object.keys(mediaUploads).reduce((p, c) => {
        if (_.get(mediaUploads[c], 'error')) {
          return p || false;
        }
        return p || _.get(mediaUploads[c], 'stats.progress') !== 100;
      }, false)
    ) {
      return;
    }
    dispatch({
      type: 'investigations/clearUploadMedia',
      payload: {
        investigation,
      },
    });
    this.setState({
      visible: false,
    });
  }

  render() {
    const {
      children,
      investigation,
      investigations,
      loading,
      subscriptionProfile,
    } = this.props;
    const mediaUploads = _.get(
      investigations,
      `mediaUploads[${investigation.InvestigationID}]`,
      [],
    );
    return (
      <>
        <Modal
          width={800}
          height={180}
          footer={null}
          title={
            <article>
              Upload media
              <div className={styles['modal-header-subtext']}>
                Upload media files using the drag-and-drop interface below.
              </div>
            </article>
          }
          visible={this.state.visible}
          onCancel={() => this.closeModal()}>
          {subscriptionProfile === 'blocked' ? (
            <div className={styles['trial-overage-container']}>
              <div className={styles['trial-overage-header']}>
                Trial upload limit reached
              </div>
              <div className={styles['trial-overage-body']}>
                <div>
                  We're sorry, but you've reached the trial upload limit.
                </div>
                <div>To continue, you'll have to set up payment.</div>
              </div>
              <div className={styles['trial-overage-footer']}>
                <Button
                  type="primary"
                  href="/account/billing"
                  style={{
                    width: '200px',
                  }}>
                  Set up Payment
                </Button>
              </div>
            </div>
          ) : (
            <>
              {Object.keys(mediaUploads).length ? (
                <div
                  style={{
                    height: '382px',
                    borderRadius: '4px',
                    border: '1px solid #c7c7c7',
                    overflowY: 'auto',
                  }}>
                  {Object.keys(mediaUploads).map((uploadKey) => (
                    <div
                      key={uploadKey}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '8px',
                        borderBottom: '1px solid #c7c7c7',
                      }}>
                      <span>{_.get(mediaUploads[uploadKey], 'file.name')}</span>
                      {_.get(mediaUploads[uploadKey], 'error') ? (
                        _.get(mediaUploads[uploadKey], 'error.message', 'Error')
                      ) : (
                        <Progress
                          type="circle"
                          percent={_.get(
                            mediaUploads[uploadKey],
                            'stats.progress',
                            0,
                          )}
                          width={25}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <Upload.Dragger
                  {...this.getUploadProps()}
                  style={{ background: 'white', borderWidth: '2px' }}>
                  <div className={styles['upload-container']}>
                    <div style={{ height: '50px' }}>
                      {loading.effects['investigations/getMediaUploadUrl'] ||
                      loading.effects['investigations/uploadMedia'] ? (
                        <LoadingSpinner fontSize="20px" />
                      ) : (
                        <CloudUploadOutlined style={{ fontSize: '45px' }} />
                      )}
                    </div>
                    <Typography.Text>
                      Drop files here or Click to select files
                    </Typography.Text>
                  </div>
                </Upload.Dragger>
              )}
              <div className={styles['modal-footer']}>
                <Button
                  loading={
                    loading.effects['investigations/getMediaUploadUrl'] ||
                    loading.effects['investigations/uploadMedia'] ||
                    loading.effects[
                      'investigations/markInvestigaitonMediaUploadComplete'
                    ] ||
                    Object.keys(mediaUploads).reduce((p, c) => {
                      if (_.get(mediaUploads[c], 'error')) {
                        return p || false;
                      }
                      return (
                        p || _.get(mediaUploads[c], 'stats.progress') !== 100
                      );
                    }, false)
                  }
                  type="primary"
                  onClick={() => this.closeModal()}>
                  Done
                </Button>
              </div>
            </>
          )}
        </Modal>
        <span onClick={() => this.showUploadModal()}>{children}</span>
      </>
    );
  }
}
export default UploadMedia;
