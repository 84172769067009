import { Button, Modal, Select, Steps, Tooltip, Typography } from 'antd';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import React, { Component } from 'react';
import { connect } from 'umi';
import ChannelThumbnailUpload from '../location-map/channel-thumbnail-upload';
import MapFloor from '../location-map/map-floor';
import MapPoints from '../location-map/map-points';
import MapTuning from '../location-map/map-tuning';
import UpdateMapChannels from '../location-map/update-map-channels';
import styles from './style.less';

const { Text, Paragraph } = Typography;

import { loadMap } from '@/pages/locations/location/location-map/utils';
import type { LocationMap, MapChannel } from '@/types/types';
import { QuestionCircleTwoTone, WarningFilled } from '@ant-design/icons';
import theme from '../../../../../config/theme';
import MapRegions from '../location-map/map-regions';
import { STEP_STATUS } from '../location-map/utils';
const { Step } = Steps;
const { Option } = Select;

PIXI.utils.skipHello();
// const isWebGLSupported = PIXI.utils.isWebGLSupported();

type ModalStep = {
  title: string;
  description: JSX.Element;
  helpText: JSX.Element;
  content: JSX.Element;
  errorText?: String;
};

const ShimmeringQuestionCircleTwoTone = (props) => {
  return (
    <div className={styles['shimmer-container']}>
      <QuestionCircleTwoTone {...props} twoToneColor="#aaaaaa" />
      <div className={styles['shimmer']}></div>
    </div>
  );
};

type State = {
  mappingStep?: number;
  showModal?: boolean;
  showThumbnailModal?: boolean;
  stepState: {
    status: STEP_STATUS;
    message: string;
  };
};

type Props = {
  locationMap: LocationMap;
  baseStationVersion: any;
  // injected by @connect
  dispatch: (action: any) => any;
  loading: any;
};

// @ts-expect-error
@connect(({ loading }) => ({ loading }), null, null, { forwardRef: true })
class ConfigureLocationMap extends Component<Props, State> {
  floorMapPixi: PIXI.Application;
  thumbnailPixi: PIXI.Application;
  configureStepRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    console.log('props.baseStationVersion', props.baseStationVersion);
    this.configureStepRef = React.createRef();

    this.state = {
      mappingStep: 0,
      showModal: false,
      showThumbnailModal: false,
      stepState: {
        status: STEP_STATUS.VALID,
        message: '',
      },
    };

    // resizing handled by DualViewportMap, the .resizeTo property is set to the parent div.
    this.floorMapPixi = new PIXI.Application({
      sharedLoader: true,
      antialias: true,
      backgroundAlpha: 0,
    });

    // resizing handled by DualViewportMap, the .resizeTo property is set to the parent div.
    this.thumbnailPixi = new PIXI.Application({
      sharedLoader: true,
      antialias: true,
      backgroundAlpha: 0,
    });
  }

  componentWillUnmount() {
    this.floorMapPixi.destroy();
    this.thumbnailPixi.destroy();
  }

  resetPixis() {
    if (this.thumbnailPixi.stage) {
      this.thumbnailPixi.stage.mask = null;
      this.thumbnailPixi.stage.removeChildren();
      this.thumbnailPixi.renderer.view.style.cursor = 'default';
    }
    if (this.floorMapPixi.stage) {
      this.floorMapPixi.stage.removeChildren();
    }
  }

  loadImages() {
    const { locationMap } = this.props;
    if (locationMap) {
      loadMap(locationMap);
    }
  }

  onStepChange(current: number) {
    this.resetPixis();
    this.setState({ mappingStep: current });
  }

  onChannelChange(channelID: number) {
    this.saveProgress().then(
      () => {
        this.resetPixis();
        this.setState({
          currentChannelID: channelID,
          mappingStep: 0,
          stepState: {
            status: STEP_STATUS.VALID,
            message: '',
          },
        });
      },
      () => {
        // ignore errors, the UI will show a message
        this.resetPixis();
        this.setState({
          currentChannelID: channelID,
          mappingStep: 0,
          stepState: {
            status: STEP_STATUS.VALID,
            message: '',
          },
        });
      },
    );
  }

  toggleModal = () => {
    const { showModal, showThumbnailModal } = this.state;
    const { locationMap } = this.props;
    const { currentChannelID } = this.state;
    const locationMapChannels = _.get(locationMap, 'Channels', []);
    const [currentMapChannel = locationMapChannels[0]] =
      locationMapChannels.filter(
        (mCh) => mCh.Channel.ChannelID === currentChannelID,
      );
    const thumbnailUrl = _.get(
      currentMapChannel,
      'Channel.LatestMedia[0].Thumbnail.SignedUrl',
      _.get(currentMapChannel, 'Channel.Thumbnail.SignedUrl', null),
    );
    if (thumbnailUrl === null) {
      this.setState({ showThumbnailModal: !showThumbnailModal });
    } else {
      if (!showModal) {
        this.loadImages();
      }
      this.setState({ showModal: !showModal });
    }
  };

  onThumbnailUpload() {
    this.setState({ showThumbnailModal: false }, () => {
      this.toggleModal();
    });
  }

  saveProgress() {
    const { errorMessage, payload, changed } =
      this.configureStepRef.current.getPayload();

    if (errorMessage !== undefined) {
      this.setState({
        stepState: {
          status: STEP_STATUS.ERROR,
          message: errorMessage,
        },
      });
      return Promise.reject();
    }

    let savePromise = Promise.resolve();
    if (changed === true) {
      savePromise = this.saveMapChannel(payload);
    }
    return savePromise;
  }

  next() {
    const { locationMap } = this.props;
    const channelIds = _.get(locationMap, 'Channels', []).map(
      (c) => c.Channel.ChannelID,
    );
    const { mappingStep, currentChannelID = channelIds[0] } = this.state;
    this.saveProgress().then(
      () => {
        this.resetPixis();
        if (mappingStep === 3) {
          const currentChannelIndex = channelIds.indexOf(currentChannelID);
          if (currentChannelIndex < channelIds.length - 1) {
            this.setState({
              mappingStep: 0,
              currentChannelID: channelIds[currentChannelIndex + 1],
            });
          } else {
            this.setState({
              mappingStep: 0,
              stepState: {
                status: STEP_STATUS.VALID,
                message: '',
              },
            });
            this.toggleModal();
          }
        } else {
          this.setState({
            mappingStep: mappingStep + 1,
            stepState: {
              status: STEP_STATUS.VALID,
              message: '',
            },
          });
        }
      },
      () => {
        // on error, the UI will be showing a message, don't do anything else
      },
    );
  }

  prev() {
    const { mappingStep } = this.state;
    this.saveProgress().then(
      () => {
        this.resetPixis();
        this.setState({
          mappingStep: Math.max(mappingStep - 1, 0),
          stepState: {
            status: STEP_STATUS.VALID,
            message: '',
          },
        });
      },
      () => {
        // on a failure, don't change state, error would be displayed.
      },
    );
  }

  saveMapChannel(payload: any) {
    const { locationMap } = this.props;
    const { currentChannelID } = this.state;

    const locationMapChannels = _.get(locationMap, 'Channels', []);
    const [currentMapChannel = locationMapChannels[0]] =
      locationMapChannels.filter(
        (mCh) => mCh.Channel.ChannelID === currentChannelID,
      );
    const existingConfig = _.get(currentMapChannel, 'Config');

    console.log('existingConfig', existingConfig);
    console.log('payload', payload);

    return this.props.dispatch({
      type: 'location_maps/updateChannelOnMapV2',
      locationID: _.get(locationMap, 'ProjectID'),
      locationMapID: _.get(locationMap, 'LocationMapID'),
      channelID: _.get(currentMapChannel, 'Channel.ChannelID'),
      payload: {
        // | src_pts
        // | dst_pts
        // | src_floor_poly
        ...existingConfig,
        ...payload,
      },
    });
  }

  render() {
    const { children, locationMap, loading, baseStationVersion } = this.props;
    const { showModal, mappingStep, currentChannelID, showThumbnailModal } =
      this.state;

    if (!locationMap) return <></>;
    const channels = _.get(locationMap, 'Channels', []).sort((a, b) =>
      a.Channel.Name.localeCompare(b.Channel.Name),
    );

    let currentMapChannel = channels.length > 0 ? channels[0] : null;
    if (currentChannelID) {
      [currentMapChannel] = channels.filter(
        (mCh: { Channel: { ChannelID: any } }) =>
          mCh.Channel.ChannelID === currentChannelID,
      );
    }

    if (!currentMapChannel) return <></>;

    const steps: ModalStep[] = [
      {
        title: 'Paint the floor',
        description: (
          <>
            In this step, we're trying to figure out where the 'ground' or
            'floor' is for this camera view.
          </>
        ),
        helpText: (
          <>
            <Paragraph>
              Don't paint vertical structures like walls or buildings.
            </Paragraph>
            <Paragraph>
              We only support a single contiguous floor area. If there are
              multiple distinct regions, or holes in the floor, these areas will
              be discarded.
            </Paragraph>
            <Paragraph>
              Hold <code>Shift</code> while dragging to deselect cells.
            </Paragraph>
          </>
        ),
        content: (
          <MapFloor
            baseStationVersion={baseStationVersion}
            thumbnailPixi={this.thumbnailPixi}
            ref={this.configureStepRef}
            mapChannel={currentMapChannel}
          />
        ),
      },
      {
        title: 'Drop the anchors',
        description: (
          <>
            In this step, we're trying to link corresponding landmarks on the
            camera to the map.
          </>
        ),
        helpText: (
          <>
            <Paragraph>
              The mapping will be incorrect if anchors are not placed on valid
              floor areas.
            </Paragraph>
            <Paragraph>
              Lines connected to anchors represent the mapped points from the
              other view. These lines should be as short as possible, as they
              indicate current mapping model accuracy and outliers.
            </Paragraph>
            <Paragraph>
              Hold <code>Shift</code> to disable anchor snapping.
            </Paragraph>
          </>
        ),
        content: (
          <MapPoints
            baseStationVersion={baseStationVersion}
            mapName={locationMap.Name}
            floorMapPixi={this.floorMapPixi}
            thumbnailPixi={this.thumbnailPixi}
            ref={this.configureStepRef}
            mapChannel={currentMapChannel}
            locationMap={locationMap}
            onChange={(status: STEP_STATUS, message: string) =>
              this.setState({
                stepState: {
                  status,
                  message,
                },
              })
            }
          />
        ),
      },
      {
        title: 'Refine the model',
        description: (
          <>
            {/*In this step, we visualise how well the mapping model has captured the anchors.*/}
            {/*Fine-tune the points or return to the previous step if needed.*/}
            In this step, we fine-tune the mapping model by fixing mapped grid
            points. If needed, return to the previous step.
          </>
        ),
        helpText: (
          <>
            <Paragraph>
              Drag grid points to their correct locations to create new anchor
              points.
            </Paragraph>
            <Paragraph>
              Toggle the visualisation direction with the sidebar between{' '}
              <code>Camera</code> {'->'} <code>Map</code> and <code>Map</code>{' '}
              {'->'} <code>Camera</code>.
            </Paragraph>
            <Paragraph>
              Outlier points are discovered by doing a round-trip transform with
              the model (eg. <code>Camera</code> {'->'} <code>Map</code> {'->'}{' '}
              <code>Camera</code>). If the model is not able to map a point back
              to its original location, it is considered an outlier and will be
              highlighted.
            </Paragraph>
            <Paragraph>
              Outliers should only be allowed for areas that are <b>not</b> on
              the floor. Otherwise, the anchor points should be adjusted via the
              previous step.
            </Paragraph>
          </>
        ),
        content: (
          <MapTuning
            baseStationVersion={baseStationVersion}
            mapName={locationMap.Name}
            ref={this.configureStepRef}
            floorMapPixi={this.floorMapPixi}
            thumbnailPixi={this.thumbnailPixi}
            saveMapChannel={(payload: any) => this.saveMapChannel(payload)}
            mapChannel={currentMapChannel}
            locationMap={locationMap}
          />
        ),
      },
      {
        title: 'Define Regions',
        description: (
          <>
            In this step, we can define regions on the map that can be used for
            searching or creating reports.
          </>
        ),
        helpText: (
          <>
            <Paragraph>
              Hold <code>Shift</code> to disable region and corner snapping.
            </Paragraph>
            <Paragraph>
              Hold <code>Alt</code> while dragging a corner to resize the entire
              region.
            </Paragraph>
          </>
        ),
        content: (
          <MapRegions
            baseStationVersion={baseStationVersion}
            locationMap={locationMap}
            ref={this.configureStepRef}
            floorMapPixi={this.floorMapPixi}
            saveMapChannel={(payload: any) => this.saveMapChannel(payload)}
          />
        ),
        isLast: true,
      },
    ];

    const step: ModalStep = steps[mappingStep];

    return (
      <>
        {/* configure modal */}
        <Modal
          style={{ top: 10 }}
          width={1200}
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                <div>Configure Map</div>
                <Text type="secondary" style={{ fontSize: '12px' }}>
                  {step.description}
                </Text>
              </div>
              <Tooltip
                title={
                  <div className={styles['modal-header-tooltip-body']}>
                    <Text style={{ fontSize: '12px' }}>{step.helpText}</Text>
                  </div>
                }
                color="white"
                placement="bottomRight"
                arrowPointAtCenter>
                <div className={styles['modal-header-info-icon']}>
                  <ShimmeringQuestionCircleTwoTone />
                </div>
              </Tooltip>
            </div>
          }
          visible={showModal}
          onCancel={() => this.toggleModal()}
          closable={false}
          maskClosable={false}
          keyboard={false}
          footer={null}>
          <div>
            <div style={{ height: 'calc(100vh - 300px)' }}>
              {showModal && step.content}
            </div>
            <br />
            <div className={styles['channel-switcher']}>
              <Select
                value={_.get(currentMapChannel, 'Channel.ChannelID')}
                style={{ width: '18%' }}
                onChange={(val) => this.onChannelChange(val)}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <UpdateMapChannels locationMap={locationMap} />
                  </div>
                )}>
                {channels.map((mCh: MapChannel) => (
                  <Option
                    key={mCh.Channel.ChannelID}
                    value={mCh.Channel.ChannelID}>
                    {(!mCh.Config || Object.keys(mCh.Config).length === 0) && (
                      <Tooltip title="Unmapped" placement="left">
                        <WarningFilled
                          style={{ color: theme['df-dark-yellow'] }}
                        />
                        &nbsp;
                      </Tooltip>
                    )}
                    {mCh.Channel.Name}
                  </Option>
                ))}
              </Select>
              <Steps
                style={{ width: '80%' }}
                progressDot
                current={mappingStep}
                // onChange={(step) => this.onStepChange(step)}
              >
                {steps.map((item, i) => (
                  <Step key={i} title={item.title} />
                ))}
              </Steps>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={() => {
                  this.setState({
                    mappingStep: 0,
                    stepState: {
                      status: STEP_STATUS.VALID,
                      message: '',
                    },
                  });
                  this.toggleModal();
                }}>
                Finish Later
              </Button>
              <div>
                {this.state.stepState.status === STEP_STATUS.ERROR && (
                  <Text type="danger">
                    {this.state.stepState.message}
                    &nbsp;&nbsp;
                  </Text>
                )}
                {!!loading.effects['location_maps/updateChannelOnMapV2'] && (
                  <Button
                    style={{ border: 'none', cursor: 'default' }}
                    type="ghost"
                    loading={
                      loading.effects['location_maps/updateChannelOnMapV2']
                    }>
                    &nbsp;
                  </Button>
                )}
                <Button
                  disabled={
                    loading.effects['location_maps/updateChannelOnMapV2'] ||
                    mappingStep === 0
                  }
                  onClick={() => this.prev()}>
                  Previous
                </Button>
                &nbsp;
                <Button
                  disabled={
                    loading.effects['location_maps/updateChannelOnMapV2'] ||
                    this.state.stepState.status !== STEP_STATUS.VALID
                  }
                  onClick={() => this.next()}>
                  {step.isLast ? 'Complete' : 'Next'}
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        {/* upload modal */}
        <Modal
          style={{ top: 10 }}
          width={1200}
          title={<div>Configure Map</div>}
          visible={showThumbnailModal}
          maskClosable={false}
          onCancel={() => this.setState({ showThumbnailModal: false })}
          keyboard={false}>
          <div>
            <div style={{ height: 'calc(100vh - 300px)' }}>
              <ChannelThumbnailUpload
                onThumbnailUpload={() => this.onThumbnailUpload()}
                mapChannel={currentMapChannel}
              />
            </div>
          </div>
        </Modal>

        {/* button that shows modal */}
        <span
          onClick={() => this.toggleModal()}
          className={styles['toggle-button']}>
          {children}
        </span>
      </>
    );
  }
}

export default ConfigureLocationMap;
