import React from 'react';
import _ from 'lodash';
import { connect, history } from 'umi';

import { Modal } from 'antd';

// @ts-expect-error
@connect(({ investigations, loading }) => ({ investigations, loading }))
class DeleteSummary extends React.Component {
  state = {
    showModal: false,
  };

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  openCannotDeleteSummary(data: any[]) {
    Modal.error({
      title: 'Delete Investigation Event',
      content: (
        <div>
          <div>
            Cannot delete this Summary. The Summary data is referenced in the
            following entities, remove/delete them before deleting the Summary:
          </div>
          <br />
          {data.map((item, item_index) => {
            return (
              <div key={item_index}>
                <span>{`${_.get(item, 'entity.type', '')}: `}</span>
                <span style={{ color: '#11119C' }}>{`${_.get(
                  item,
                  'entity.name',
                  '',
                )} > `}</span>
                <span style={{ color: '#11119C' }}>{`${_.get(
                  item,
                  'dependency.name',
                  '',
                )}`}</span>
              </div>
            );
          })}
        </div>
      ),
      closable: true,
      keyboard: false,
    });
  }

  checkDeleteSummary(e) {
    e.preventDefault();
    const { dispatch, investigation, event } = this.props;

    dispatch({
      type: 'investigation_events/fetchSummaryDependencies',
      investigationID: investigation.InvestigationID,
      eventID: event.InvestigationEventID,
    }).then(
      (data: any) => {
        if (_.get(data, 'length', 0) > 0) {
          this.openCannotDeleteSummary(data);
        } else {
          this.toggleModal();
        }
      },
      (err: any) =>
        console.log(
          `Error finding dependencies, InvestigationID:- ${investigation.InvestigationID}, InvestigationEventID:- ${event.InvestigationEventID}\t ERR:- ${err}`,
        ),
    );
  }

  deleteSummary() {
    const { dispatch, investigation, event } = this.props;

    dispatch({
      type: 'investigation_events/deleteSummary',
      investigationID: investigation.InvestigationID,
      eventID: event.InvestigationEventID,
    }).then(() => {
      this.toggleModal();
      history.push(
        `/investigations/${investigation.InvestigationID}/events/${event.InvestigationEventID}`,
      );
    });
  }

  render() {
    const { children, loading, event } = this.props;
    return (
      <>
        <Modal
          width={400}
          title="Delete Summary"
          visible={this.state.showModal}
          onOk={(e) => this.deleteSummary(e)}
          confirmLoading={loading.effects['investigation_events/deleteSummary']}
          onCancel={() => this.toggleModal()}>
          Are you sure you want to delete the summary{' '}
          <b>{event.Name} Summary</b> ?
        </Modal>
        <span onClick={(e) => this.checkDeleteSummary(e)}>{children}</span>
      </>
    );
  }
}
export default DeleteSummary;
