import BaseApp from './BaseApp';
import { connect } from 'umi';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tabs, Tag, Typography } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import PageHeader from '@/components/PageHeader2';
import {
  getCurrentCustomerID,
  dispatchWithFeedback,
  getFlexibleDateFormat,
} from '@/utils/utils';
import CustomerIDEditor from './components/insights-customer-id-editor';
import InfrastructureMetrics from './components/insights-infrastructure-metrics';

const { Title } = Typography;

const detailsRender = (job) => {
  return (
    <>
      <p>
        <b>Date Range</b>
      </p>
      <p>
        {getFlexibleDateFormat(job.config.date_range.start, true)} -{' '}
        {getFlexibleDateFormat(job.config.date_range.end, true)}
      </p>
      <p>
        <b>Entity Details</b>
      </p>
      <p>Type: {job.config.entity.type}</p>
      <p>ID: {job.config.entity.id}</p>
      <p>FilterID: {job.config.entity.filter_id}</p>
    </>
  );
};

@connect(({ loading }) => ({
  loadingDoAppOp: loading.effects['apps/doAppOp'],
}))
class InsightsDashboardApp extends BaseApp {
  constructor(props) {
    super(props);
    this.storageKey = `InsightDashboardApp`;
    this.state = {
      customerName: this.getCustomerName(getCurrentCustomerID()),
      customerID: getCurrentCustomerID(),
      isOverridden: false,
    };

    this.setOverrideCustomerID = this.setOverrideCustomerID.bind(this);
  }

  componentDidMount() {
    const localStorageConfig = localStorage.getItem(this.storageKey);
    this.getCustomerList();
    if (localStorageConfig) {
      const config = JSON.parse(localStorageConfig);
      this.setState({ ...config }, this.refresh);
    } else {
      this.refresh();
    }
  }

  refresh() {
    this.getInsightJobs();
    this.getQueueMetrics();
    this.getNodeMetrics();
  }

  getInsightJobs() {
    this.getCompletedInsightJobs(this.state.customerID);
    this.getPendingInsightJobs(this.state.customerID);
  }

  getCustomerList() {
    return dispatchWithFeedback(
      this.props.dispatch,
      'Getting customer list',
      {
        type: 'apps/doAppOp',
        appID: InsightsDashboardApp.appID,
        payload: {
          op: InsightsDashboardApp.OPS.getCustomerList.name,
        },
      },
      true,
    ).then((data) => {
      this.setState({
        ...data?.Data,
        customerName: this.getCustomerName(this.state.CustomerID),
      });
    });
  }

  getPendingInsightJobs(customerID) {
    return dispatchWithFeedback(
      this.props.dispatch,
      'Getting pending insight jobs',
      {
        type: 'apps/doAppOp',
        appID: InsightsDashboardApp.appID,
        payload: {
          op: InsightsDashboardApp.OPS.getPendingJobs.name,
          customer_id: customerID,
        },
      },
      true,
    ).then((data) => {
      this.setState({ ...data?.Data });
    });
  }

  getCompletedInsightJobs(customerID) {
    return dispatchWithFeedback(
      this.props.dispatch,
      'Getting completed insight jobs',
      {
        type: 'apps/doAppOp',
        appID: InsightsDashboardApp.appID,
        payload: {
          op: InsightsDashboardApp.OPS.getCompletedJobs.name,
          customer_id: customerID,
        },
      },
      true,
    ).then((data) => {
      this.setState({ ...data?.Data });
    });
  }

  getInsightJobFailParts(insightStateID) {
    this.setModal('failListModal', true);
    return dispatchWithFeedback(
      this.props.dispatch,
      'Getting failed insight job parts',
      {
        type: 'apps/doAppOp',
        appID: InsightsDashboardApp.appID,
        payload: {
          op: InsightsDashboardApp.OPS.getJobFailParts.name,
          insight_state_id: insightStateID,
        },
      },
      true,
    ).then((data) => {
      this.setState({
        ...data?.Data,
      });
    });
  }

  getQueueMetrics() {
    return dispatchWithFeedback(
      this.props.dispatch,
      'Getting queue metrics',
      {
        type: 'apps/doAppOp',
        appID: InsightsDashboardApp.appID,
        payload: {
          op: InsightsDashboardApp.OPS.getQueueMetrics.name,
        },
      },
      true,
    ).then((data) => {
      this.setState({ ...data?.Data });
    });
  }

  getNodeMetrics() {
    return dispatchWithFeedback(
      this.props.dispatch,
      'Getting node metrics',
      {
        type: 'apps/doAppOp',
        appID: InsightsDashboardApp.appID,
        payload: {
          op: InsightsDashboardApp.OPS.getNodeMetrics.name,
        },
      },
      true,
    ).then((data) => {
      this.setState({ ...data?.Data });
    });
  }

  cancelInsightJob(insightStateID) {
    return dispatchWithFeedback(
      this.props.dispatch,
      `Cancelling insight job id=${insightStateID}`,
      {
        type: 'apps/doAppOp',
        appID: InsightsDashboardApp.appID,
        payload: {
          op: InsightsDashboardApp.OPS.cancelJob.name,
          insight_state_id: insightStateID,
        },
      },
      false,
    ).then((response) => {
      // eslint-disable-next-line no-console
      console.log(response);
      this.getInsightJobs();
    });
  }

  getCustomerName(customerID) {
    let name = customerID;
    if (this.state?.all_customers) {
      const customer = this.state.all_customers.find(
        (c) => c.ID === customerID,
      );
      if (customer) {
        name = customer.Name;
      }
    }
    return name;
  }

  setOverrideCustomerID(customerID) {
    let config = {
      isOverridden: false,
      customerID: getCurrentCustomerID(),
      customerName: this.getCustomerName(getCurrentCustomerID()),
    };

    if (!customerID) {
      localStorage.removeItem(this.storageKey);
    } else {
      const isOverridden = customerID != getCurrentCustomerID();
      if (isOverridden) {
        config = {
          isOverridden: isOverridden,
          customerID: customerID,
          customerName: this.getCustomerName(customerID),
        };
        localStorage.setItem(this.storageKey, JSON.stringify(config));
      } else {
        localStorage.removeItem(this.storageKey);
      }
    }

    this.setState({ ...config }, this.getInsightJobs);
  }

  cancelButtonRender = (id) => {
    return (
      <>
        <Button type="primary" danger onClick={() => this.cancelInsightJob(id)}>
          Cancel
        </Button>
      </>
    );
  };

  getInsightJobPartsColumns() {
    const columns = [
      {
        title: 'Created At (UTC)',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (m) => getFlexibleDateFormat(m, true),
      },
      { title: 'ID', dataIndex: 'id', key: 'id' },
      { title: 'Status', dataIndex: 'status', key: 'status' },
      { title: 'Message', dataIndex: 'status_message', key: 'status_message' },
      // { title: "Last Updated At", dataIndex: 'last_updated_at', key: 'last_updated_at', render: (m) => getFlexibleDateFormat(m, true)},
    ];
    return columns;
  }
  setModal(key, value) {
    this.setState({ [key]: value });
  }

  onCancel = () => this.setModal('failListModal', false);

  failPartModal = () => {
    let title = 'No Insight State ID';
    if (this.state?.insight_parts_data?.insight_state_id) {
      title = `Insight ${this.state?.insight_parts_data?.insight_state_id}: FAIL parts`;
    }
    return (
      <>
        <Modal
          width={'70%'}
          visible={this.state.failListModal}
          title={title}
          onCancel={this.onCancel}
          destroyOnClose={true}
          footer={null}>
          <Table
            rowKey={(record) => record.id}
            dataSource={this.state?.insight_parts_data?.fail_parts}
            columns={this.getInsightJobPartsColumns()}
            pagination={false}
          />
        </Modal>
      </>
    );
  };

  partsRender = (partsObject) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (partsObject) {
      const parts = Object.keys(partsObject).map(function (key) {
        const upperKey = key.toUpperCase();
        let color;
        let icon;
        const tag = partsObject[key][0];
        if (upperKey === 'FAIL') {
          color = 'red';
          icon = <CloseCircleOutlined />;
          return (
            <Tag
              color={color}
              key={upperKey}
              icon={icon}
              onClick={() => {
                self.getInsightJobFailParts(partsObject[key][1]);
              }}>
              {upperKey}: {tag}
            </Tag>
          );
        } else if (upperKey === 'SUCCESS') {
          color = 'green';
          icon = <CheckCircleOutlined />;
        } else if (upperKey === 'DUPLICATE') {
          color = 'lime';
          icon = <CheckCircleOutlined />;
        } else if (upperKey === 'PROCESSING') {
          color = 'blue';
          icon = <SyncOutlined spin />;
        } else if (upperKey === 'QUEUED') {
          color = 'orange';
          icon = <ClockCircleOutlined />;
        } else if (upperKey === 'CANCELLED') {
          color = '';
          icon = <MinusCircleOutlined />;
        }
        return (
          <Tag color={color} key={upperKey} icon={icon}>
            {upperKey}: {tag}
          </Tag>
        );
      });
      return <>{parts}</>;
    }
  };

  getInsightJobColumns(allowCancelation = false) {
    const columns = [
      {
        title: 'Created At (UTC)',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (m) => getFlexibleDateFormat(m, true),
      },
      { title: 'ID', dataIndex: 'id', key: 'id' },
      { title: 'Status', dataIndex: 'status', key: 'status' },
      // { title: "Last Updated At", dataIndex: 'last_updated_at', key: 'last_updated_at', render: (m) => getFlexibleDateFormat(m, true)},
      {
        title: "Parts' Status Breakdown",
        dataIndex: 'part_states_count',
        render: this.partsRender,
      },
      Table.EXPAND_COLUMN,
    ];
    if (allowCancelation) {
      columns.push({
        title: 'Cancel Job',
        dataIndex: 'id',
        key: 'id',
        render: this.cancelButtonRender,
      });
    }
    return columns;
  }

  customerInsightJob(customerID, isOverridden) {
    return (
      <>
        <Title level={5}>{this.state.customerName}</Title>
        {this.failPartModal()}
        <Tabs
          style={{ marginRight: '0px' }}
          tabBarExtraContent={
            <div
              style={{
                display: 'flex',
                color: 'rgba(0,0,0,0.85)',
                fontSize: '11px',
                alignItems: 'baseline',
              }}>
              <div style={{ fontWeight: 500, marginRight: '8px' }}>
                {isOverridden ? (
                  <div style={{ color: 'red' }}>
                    Using override, Customer ID {customerID}
                  </div>
                ) : (
                  <div>Results for Customer ID {customerID}</div>
                )}
              </div>
              <CustomerIDEditor
                setCustomerID={this.setOverrideCustomerID}
                initialValues={customerID}
                allCustomers={this.state?.all_customers}
              />
            </div>
          }>
          <Tabs.TabPane
            tab="Pending"
            key="pending"
            style={{ overflow: 'scroll' }}>
            <Table
              rowKey={(record) => record.id}
              dataSource={_.cloneDeep(this.state.pending_jobs)}
              columns={this.getInsightJobColumns(true)}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => detailsRender(record),
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Completed"
            key="completed"
            style={{ overflow: 'scroll' }}>
            <Table
              rowKey={(record) => record.id}
              dataSource={_.cloneDeep(this.state.completed_jobs)}
              columns={this.getInsightJobColumns()}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => detailsRender(record),
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }

  render() {
    const { loadingDoAppOp } = this.props;
    const { customerID, isOverridden } = this.state;

    return (
      <>
        <PageHeader title="Insights Dashboard" />
        <Tabs
          style={{ marginRight: '16px' }}
          tabBarExtraContent={
            <div
              style={{
                display: 'flex',
                color: 'rgba(0,0,0,0.85)',
                fontSize: '11px',
                alignItems: 'baseline',
              }}>
              <div>
                {loadingDoAppOp && (
                  <LoadingOutlined
                    style={{ fontSize: 13, marginRight: '8px' }}
                    spin
                  />
                )}
              </div>
              <div style={{ marginLeft: '8px' }}>
                <Button
                  size="small"
                  // loading={loadingDoAppOp}
                  onClick={() => this.refresh()}>
                  Refresh
                </Button>
              </div>
            </div>
          }>
          <Tabs.TabPane
            tab="Customer Insight Jobs"
            key="customerInsightJobs"
            style={{ marginRight: '0px' }}>
            {this.customerInsightJob(customerID, isOverridden)}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Infrastructure Metrics"
            key="infrastructureMetrics">
            <InfrastructureMetrics
              queueMetrics={this.state.queue_metrics}
              nodeMetrics={this.state.node_metrics}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }

  static appID = 98;

  static OPS = {
    getPendingJobs: {
      name: 'get_pending_jobs',
    },
    getCompletedJobs: {
      name: 'get_completed_jobs',
    },
    cancelJob: {
      name: 'cancel_job',
    },
    getQueueMetrics: {
      name: 'get_queue_metrics',
    },
    getNodeMetrics: {
      name: 'get_node_metrics',
    },
    getJobFailParts: {
      name: 'get_job_fail_parts',
    },
    getCustomerList: {
      name: 'get_customer_list',
    },
  };
}

export default InsightsDashboardApp;
