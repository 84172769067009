import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'umi';
import { Modal, Form, Collapse, DatePicker, Input } from 'antd';
import { notificationOps, doNotificationOp } from '@/utils/notifications';

const { Panel } = Collapse;

// @ts-expect-error
@connect(({ user, loading, locations }) => ({ user, loading, locations }))
class NotificationsRunRule extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {}

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  runRule(e) {
    e.preventDefault();

    this.formRef.current.validateFields().then(
      (values) => {
        const { dispatch } = this.props;
        let now = moment().valueOf() / 1000;

        let anchorTimestamp = values.anchorOffset
          ? values.anchorOffset.valueOf() / 1000
          : now;

        return doNotificationOp(
          dispatch,
          notificationOps.runRule,
          {
            rule_id: this.props.rule.id,
            overrides: {
              run_at: parseInt(anchorTimestamp),
              run_frequency: values.run_frequency,
              rolling_window: values.rolling_window,
              throttle_window: values.throttle_window,
              event_time_buffer: values.event_time_buffer,
            },
          },
          { refreshAfter: false },
        ).then(() => {
          this.toggleModal();
        });
      },
      (err: any) => console.log('err', err),
    );
  }

  render() {
    const { children, rule, loading } = this.props;
    return (
      <>
        <Modal
          width={400}
          title={`Test Rule: ${rule.name}`}
          visible={this.state.showModal}
          onOk={(e) => this.runRule(e)}
          confirmLoading={loading.effects['apps/doAppOp']}
          onCancel={() => this.toggleModal()}>
          <div
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div>Test the rule by running it once.</div>
            <Form
              layout="vertical"
              colon={false}
              requiredMark={false}
              onSubmit={(e) => this.runRule(e)}
              ref={this.formRef}>
              <Collapse ghost={true}>
                <Panel header="Options">
                  <Form.Item
                    name="anchorOffset"
                    label={`Run the rule at this past date/time (in timezone ${moment.tz.guess()})`}>
                    <DatePicker
                      showTime
                      showNow={true}
                      showToday={true}
                      allowClear={true}
                    />
                  </Form.Item>
                  <Form.Item
                    name="rolling_window"
                    initialValue={_.get(
                      rule,
                      'delivery.threshold.rolling_window',
                    )}
                    label="Seconds of past data to consider when evaluating rule">
                    <Input type="number" style={{ width: '150px' }} />
                  </Form.Item>
                  <Form.Item
                    name="throttle_window"
                    initialValue={_.get(
                      rule,
                      'delivery.threshold.throttle_window',
                    )}
                    label="Seconds that need to pass before the rule sends notifications again">
                    <Input type="number" style={{ width: '150px' }} />
                  </Form.Item>
                  <Form.Item
                    name="event_time_buffer"
                    initialValue={_.get(
                      rule,
                      'delivery.threshold.event_time_buffer',
                    )}
                    label="Seconds of video to share before and after a rule triggers">
                    <Input type="number" style={{ width: '150px' }} />
                  </Form.Item>
                </Panel>
              </Collapse>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default NotificationsRunRule;
