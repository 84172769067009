import type { RadioChangeEvent } from 'antd';
import { Button, Drawer, Image, Input, Radio, Space } from 'antd';
import _ from 'lodash';
import React from 'react';

type MyProps = {
  visible?: boolean;
  record?: any;
  onDrawerClose?: () => void;
  handleDrawerSubmit?: any;
  loadingResults?: boolean;
};

type MyState = {
  licenseOption?: string;
  optionalValue?: string;
  truck_in?: boolean;
  optionalValueDisabled?: boolean;
};

class EditLicensePlate extends React.Component<MyProps, MyState> {
  static defaultProps = {
    visible: false,
    record: {},
  };

  constructor(props: MyProps) {
    super(props);
    this.state = {
      licenseOption: _.get(this.props.record, 'truck_number', ''),
      optionalValue: '',
      truck_in: _.get(this.props.record, 'truck_in'),
      optionalValueDisabled: true,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: MyProps) {
    const plate_readings = _.get(this.props.record, 'plate_readings', []);
    const newKey = _.get(this.props.record, 'object_id', -1);
    const oldKey = _.get(prevProps.record, 'object_id', -1);
    const truck_in = _.get(this.props.record, 'truck_in');
    if (plate_readings.length > 0 && newKey !== -1 && oldKey !== newKey) {
      const truck_number = _.get(this.props.record, 'truck_number', '');
      if (plate_readings.includes(truck_number)) {
        this.setState({
          licenseOption: truck_number,
          optionalValue: '',
          truck_in,
        });
      } else if (!plate_readings.includes(truck_number)) {
        this.setState({
          licenseOption: 'custom',
          optionalValue: truck_number,
          optionalValueDisabled: false,
          truck_in,
        });
      }
    }
  }

  onLicenseChange = (event: RadioChangeEvent) => {
    if (event.target.value == 'custom') {
      this.setState({ optionalValueDisabled: false });
    } else if (this.state.optionalValueDisabled == false) {
      this.setState({ optionalValueDisabled: true });
    }
    this.setState({ licenseOption: event.target.value });
  };

  onDirectionChange = (event: RadioChangeEvent) => {
    this.setState({ truck_in: event.target.value });
  };

  render() {
    return (
      <>
        {this.props.visible ? (
          <Drawer
            destroyOnClose
            title="Edit Truck"
            onClose={this.props.onDrawerClose}
            visible={this.props.visible}
            placement={'right'}
            width={window.innerWidth > 900 ? '30%' : '90%'}
            extra={
              <Space>
                <Button
                  type="primary"
                  loading={this.props.loadingResults}
                  onClick={(e) => {
                    e.preventDefault();

                    const plate_reading =
                      this.state.licenseOption === 'custom'
                        ? this.state.optionalValue
                        : this.state.licenseOption;

                    this.props.handleDrawerSubmit(
                      this.props.record.object_id,
                      plate_reading,
                      this.state.truck_in,
                    );
                  }}>
                  Submit
                </Button>
              </Space>
            }>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '16px',
                marginBottom: '16px',
                width: '100%',
              }}>
              <Image src={this.props.record.captured_image} width="100px" />
            </div>
            <Radio.Group
              onChange={this.onLicenseChange}
              value={this.state.licenseOption}>
              <Space direction="vertical">
                {this.props.record.plate_readings.map((entry: any) => {
                  return (
                    <Radio key={entry} value={entry}>
                      {entry}
                    </Radio>
                  );
                })}
                <Radio value={'custom'}>
                  <Input
                    value={this.state.optionalValue}
                    onChange={(e) =>
                      this.setState({ optionalValue: e.target.value })
                    }
                    disabled={this.state.optionalValueDisabled}
                  />
                </Radio>
              </Space>
            </Radio.Group>
            <div style={{ paddingTop: '10px' }}>
              <div>Direction</div>
              <Radio.Group
                style={{ paddingTop: '10px' }}
                onChange={this.onDirectionChange}
                value={this.state.truck_in}>
                <Radio.Button value={true}>IN</Radio.Button>
                <Radio.Button value={false}>OUT</Radio.Button>
              </Radio.Group>
            </div>
          </Drawer>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default EditLicensePlate;
