import DataList from '@/components/DataList';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import {
  DEFAULT_ROLE,
  getRoles,
  INTERNAL_ROLES,
  isRestrictedUser,
  ROLE_INFO,
  SELECTABLE_ROLES,
} from '@/utils/roles';
import { dispatchWithFeedback, isInternalUser } from '@/utils/utils';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Tooltip,
  TreeSelect,
  Typography,
} from 'antd';
import _ from 'lodash';
import { Component } from 'react';
import { connect } from 'umi';
import InviteForm from './components/invite-user';
import styles from './style.less';

const { Option } = Select;

type MyProps = {
  hideRoleSelection: boolean;
  hidePageHeader: boolean;
  alwayShowAddUserOption: boolean;
};
// @ts-expect-error
@connect(
  ({
    accounts,
    user,
    locations,
    investigations,
    views,
    insights,
    apps,
    loading,
  }) => ({
    accounts,
    currentUser: user.currentUser,
    locations,
    views,
    insights,
    investigations,
    apps,
    loading,
  }),
)
class UsersPermissions extends Component<MyProps, any> {
  state = {
    showRemoveUserModal: false,
    restrictions: {},
  };

  update() {
    const {
      currentUser,
      accounts,
      locations,
      views,
      insights,
      investigations,
      apps,
    } = this.props;

    const currentCustomerRoles = getRoles(currentUser);
    const isRestricted = isRestrictedUser(currentCustomerRoles);

    const allRestrictions = [
      [
        accounts.users.map((u) => u.User),
        'Users',
        'USER',
        'UserID',
        (u) => `${u.FirstName} ${u.LastName} (${u.Email})`,
      ],
      [locations.all, 'Locations', 'PROJECT', 'ProjectID'],
      [views.all, 'Views', 'VIEW', 'ViewID'],
      [insights.all, 'Insights', 'INSIGHT', 'InsightID'],
      [
        investigations.all,
        'Investigations',
        'INVESTIGATION',
        'InvestigationID',
      ],
      [apps.all, 'Apps', 'APP', 'AppID'],
    ].map((spec) => {
      const children = [];
      children.push({
        label: (
          <Tooltip title={`All current and future ${spec[1]}`}>All</Tooltip>
        ),
        value: `READ:${spec[2]}/*`,
        disableCheckbox: isRestricted,
      });

      if (spec[2] === 'INSIGHT') {
        children.push({
          label: <Tooltip title={`Only selected ${spec[1]}`}>Selected</Tooltip>,
          value: `Selections-${spec[1]}`,
          children: [
            ...spec[0].Insights.map((insight) => ({
              label: insight.Name,
              value: `READ:${spec[2]}/${insight.InsightID}`,
            })),
            ...spec[0].InsightGroups.map((insGrp) => {
              return {
                label: insGrp.Name,
                disableCheckbox: _.get(insGrp, 'Insights', []).length === 0,
                value: `READ:${'INSIGHT-GROUP'}/${insGrp.InsightGroupID}`,
                children: _.get(insGrp, 'Insights', []).map((insight) => ({
                  label: insight.Name,
                  value: `READ:${'INSIGHT'}/${insight.InsightID}`,
                })),
              };
            }),
          ],
        });
      } else {
        children.push({
          label: <Tooltip title={`Only selected ${spec[1]}`}>Selected</Tooltip>,
          value: `Selections-${spec[1]}`,
          disableCheckbox: spec[0].length === 0,
          children: spec[0].map((obj) => {
            return {
              label: obj.Name || spec[4](obj),
              value: `READ:${spec[2]}/${obj[spec[3]]}`,
            };
          }),
        });
      }

      return {
        label: spec[1],
        value: spec[1],
        checkable: false,
        selectable: false,
        children,
      };
    });
    this.setState({ allRestrictions, currentCustomerRoles });
  }

  componentDidMount() {
    const promises = [];
    promises.push(
      this.props.dispatch({
        type: 'accounts/fetchUsers',
      }),
    );
    promises.push(
      this.props.dispatch({
        type: 'accounts/fetchInvite',
      }),
    );
    promises.push(
      this.props.dispatch({
        type: 'locations/fetchLocations',
      }),
    );
    promises.push(
      this.props.dispatch({
        type: 'views/fetchViews',
      }),
    );
    promises.push(
      this.props.dispatch({
        type: 'insights/fetchAllInsights',
      }),
    );
    promises.push(
      this.props.dispatch({
        type: 'investigations/fetchInvestigations',
      }),
    );
    promises.push(
      this.props.dispatch({
        type: 'apps/fetchAllApps',
      }),
    );
    return Promise.all(promises).then(() => this.update());
  }

  handleChange(userID, value, usr) {
    if (value === 'delete') {
      this.showDeleteUserModal(usr);
    } else {
      this.changeUserRole(userID, [value]);
    }
  }

  handleCancel() {
    this.setState({ showRemoveUserModal: false });
  }

  showDeleteUserModal(usr) {
    this.setState({
      showRemoveUserModal: true,
      removeUserID: usr.User.UserID,
    });
  }

  changeUserRole(userID, roles) {
    // roles can't be empty in db
    if (!roles.length) {
      roles = [DEFAULT_ROLE];
    }
    console.log(userID, roles);
    return dispatchWithFeedback(
      this.props.dispatch,
      'Changing roles',
      {
        type: 'accounts/changeRole',
        payload: {
          userID,
          roles,
        },
      },
      true,
    ).then(() => {
      this.update();
    });
  }

  removeUser() {
    return this.removeUserWithID(this.state.removeUserID);
  }

  removeUserWithID(userID: any) {
    if (!userID) {
      return;
    }

    return dispatchWithFeedback(
      this.props.dispatch,
      'Removing user',
      {
        type: 'accounts/removeUser',
        payload: { userID },
      },
      true,
    ).then(() => {
      this.setState({ showRemoveUserModal: false, removeUserID: null });
      this.update();
      this.props.dispatch({ type: 'access_rules/fetchAccessRules' });
    });
  }

  withDrawInvite(inviteID: string) {
    if (!inviteID) {
      return;
    }

    return dispatchWithFeedback(
      this.props.dispatch,
      'Withdrawing invite',
      {
        type: 'accounts/withdrawInviteFromCustomerAccount',
        payload: { inviteID },
      },
      true,
    ).then(() => {
      this.update();
    });
  }

  updateRestrictions(userID, roles) {
    this.setState({
      restrictions: {
        ...this.state.restrictions,
        [userID]: roles,
      },
    });
  }

  saveRestrictions(userID) {
    if (!_.get(this.state.restrictions, userID)) {
      return null;
    }
    return this.changeUserRole(userID, this.state.restrictions[userID]).then(
      () => {
        const restrictions = { ...this.state.restrictions };
        delete restrictions[userID];
        this.setState({ restrictions });
      },
    );
  }

  isLoading() {
    const { loading } = this.props;
    return (
      loading.effects['accounts/fetchInvite'] ||
      loading.effects['accounts/fetchUsers'] ||
      loading.effects['accounts/removeUser'] ||
      loading.effects['accounts/changeRole'] ||
      loading.effects['locations/fetchLocations'] ||
      loading.effects['views/fetchViews'] ||
      loading.effects['insights/fetchAllInsights'] ||
      loading.effects['investigations/fetchInvestigations'] ||
      loading.effects['apps/fetchAllApps']
    );
  }

  getSectionHeader(showAddUser: boolean) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
        }}>
        <Typography.Title className={styles.title} level={5}>
          Users
        </Typography.Title>
        <InviteForm hideRoleSelection>
          {showAddUser ? <Button type="link">Add</Button> : null}
        </InviteForm>
      </div>
    );
  }

  render() {
    const { accounts, currentUser } = this.props;
    let showAddUser = this.props?.alwayShowAddUserOption;

    if (!this.state.allRestrictions) {
      return (
        <>
          {this.props?.hidePageHeader
            ? this.getSectionHeader(showAddUser)
            : null}
          <LoadingSpinner />
        </>
      );
    }

    let usersToDisplay = accounts.users.map((x) => ({
      name: `${x.User.FirstName} ${x.User.LastName}`,
      email: x.User.Email,
      role:
        _.get(_.intersection(x.Roles, Object.keys(ROLE_INFO)), '0') ||
        DEFAULT_ROLE,
      roles: x.Roles,
      entity: x,
    }));
    let invitesToDisplay = accounts.invites.map((x) => ({
      name: 'Pending Invitation',
      email: x.InvitedUserEmail,
      role: x.InvitedUserRole,
      isInvitee: true,
      entity: x,
    }));

    // Current user role
    const userIsAdmin =
      this.state.currentCustomerRoles.indexOf('ADMIN_USER') !== -1;
    if (userIsAdmin) {
      showAddUser = true;
    }

    let hasDragonfruitUsers = false;

    // if the user is not a DF user, filter out DF users listed on the account
    if (!isInternalUser(currentUser)) {
      usersToDisplay = usersToDisplay.filter(
        (u) => !_.endsWith(u.email, 'dragonfruit.ai'),
      );
      hasDragonfruitUsers = usersToDisplay.length !== accounts.users.length;
      invitesToDisplay = invitesToDisplay.filter(
        (invitee) => !_.endsWith(invitee.email, 'dragonfruit.ai'),
      );
    }

    const dataSource = usersToDisplay.concat(invitesToDisplay);
    const columns: any[] = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        defaultSortOrder: 'ascend',
        render: (name: any, record: any) => {
          const isMe = currentUser.Email === record.email;
          return isMe ? <b>{name} (me)</b> : name;
        },
        sorter: (a: any, b: any) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '20%',
        sorter: (a: any, b: any) =>
          a.email.toLowerCase() < b.email.toLowerCase() ? -1 : 1,
      },
    ];

    if (this.props?.hideRoleSelection) {
      columns.push({
        title: 'Actions',
        render: (record: any) => {
          return (
            <Popconfirm
              title={`Are you sure you want to ${
                record.isInvitee ? 'withdraw this invite' : 'remove this user'
              }?`}
              onConfirm={() =>
                record.isInvitee
                  ? this.withDrawInvite(record.entity.InviteUUID)
                  : this.removeUserWithID(record.entity.User.UserID)
              }
              okText="Yes"
              cancelText="No">
              <span className="df-link df-error-text">
                {record.isInvitee ? 'Withdraw Invite' : 'Remove user'}
              </span>
            </Popconfirm>
          );
        },
      });
    } else {
      columns.push({
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: '100%',
        sorter: (a: any, b: any) => (a.role < b.role ? -1 : 1),
        render: (role: any, record: any) => {
          const email = record.email;
          const isMe = currentUser.Email === email;

          if (!role || INTERNAL_ROLES.indexOf(role) !== -1) {
            return <span style={{ margin: '0 11px' }}>Internal User</span>;
          }
          const userID = record.entity?.User?.UserID;

          let treeData = this.state.allRestrictions;

          if (isMe) {
            treeData = _.cloneDeep(treeData);
            treeData.forEach((el) => {
              el.disableCheckbox = true;
              el.children?.forEach((c) => {
                c.disableCheckbox = true;
                c.children?.forEach((cc) => {
                  cc.disableCheckbox = true;
                });
              });
            });
          }

          return (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'baseline',
              }}>
              <div>
                {userIsAdmin ? (
                  <Select
                    className={styles['select-width']}
                    dropdownMatchSelectWidth={false}
                    value={role}
                    style={{ width: '200px', margin: '4px 8px 4px 0' }}
                    onChange={(value) => {
                      this.handleChange(userID, value, record.entity);
                    }}
                    optionLabelProp="label"
                    disabled={isMe || record.isInvitee || this.isLoading()}>
                    {SELECTABLE_ROLES.map((key) => {
                      const info = ROLE_INFO[key];
                      return (
                        <Option label={info.name} value={key} key={key}>
                          <div className={styles['option-data']}>
                            {info.name}
                          </div>
                          <div className={styles['option-content']}>
                            {info.description}
                          </div>
                        </Option>
                      );
                    })}
                    <Option
                      label="Delete"
                      value="delete"
                      style={{ color: 'rgb(254, 54, 54)' }}>
                      {/* <Icon type="delete" /> */}
                      Delete
                    </Option>
                  </Select>
                ) : (
                  <span style={{ margin: '0px 11px' }}>
                    {ROLE_INFO[role].name}
                  </span>
                )}
              </div>
              {role === 'RESTRICTED_USER' && !record.isInvitee && (
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <TreeSelect
                    maxTagCount={0}
                    maxTagPlaceholder={(values) => {
                      return !values
                        ? 'No Selection'
                        : `${values.length} Selection${
                            values.length === 1 ? '' : 's'
                          }`;
                    }}
                    treeCheckable={true}
                    defaultValue={record.roles.filter(
                      (x) => x !== 'RESTRICTED_USER',
                    )}
                    dropdownMatchSelectWidth={false}
                    style={{ width: '200px', margin: '4px 0' }}
                    treeData={treeData}
                    placeholder="Select..."
                    onChange={(value) => this.updateRestrictions(userID, value)}
                    disabled={this.isLoading()}
                    treeDefaultExpandedKeys={treeData.map((x) => x.value)}
                  />
                  {!isMe && _.get(this.state.restrictions, userID) && (
                    <Button
                      type="link"
                      disabled={this.isLoading()}
                      onClick={() => this.saveRestrictions(userID)}>
                      Save
                    </Button>
                  )}
                </div>
              )}
            </div>
          );
        },
      });
    }

    return (
      <>
        {this.props?.hidePageHeader ? (
          this.getSectionHeader(showAddUser)
        ) : (
          <PageHeader
            title={
              <>
                <span>Users</span>
                {this.isLoading() && (
                  <Spin
                    style={{ marginLeft: '16px' }}
                    indicator={
                      <LoadingOutlined style={{ fontSize: '16px' }} spin />
                    }
                  />
                )}
              </>
            }
            right={
              <InviteForm>
                {showAddUser ? (
                  <Button size="small" type="default">
                    Add Users
                  </Button>
                ) : null}
              </InviteForm>
            }
          />
        )}
        <div style={{ marginTop: 0 }}>
          <div>
            <DataList
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              rowClassName={(record) =>
                record.isInvitee ? styles.pending : ''
              }
            />

            {hasDragonfruitUsers && (
              <div className={styles['has-df-users']}>
                <i>Dragonfruit Support may have access to this account</i>
              </div>
            )}
          </div>
        </div>
        <Modal
          width={250}
          visible={this.state.showRemoveUserModal}
          title="Remove User?"
          footer={null}
          onCancel={() => this.handleCancel()}>
          Are you sure you want to delete this user?{'  '}
          <div className={styles['remove-user-modal']}>
            <Button onClick={() => this.handleCancel()}>Cancel</Button>
            &nbsp;
            <Button
              htmlType="submit"
              key="delete"
              type="primary"
              onClick={() => this.removeUser()}>
              Delete
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}
export default UsersPermissions;
