import ChannelSelect2 from '@/components/ChannelSelect2';
import DatepickerDF from '@/components/DatepickerDF';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import UploadVideoToChannel from '@/components/UploadVideoToChannel';
import styles from '@/pages/apps/app/style.less';
import { dispatchWithFeedback, getCurrentCustomerID } from '@/utils/utils';
import { Button, Form, Image, notification, Table, Tabs, Tag } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'umi';
import BaseApp from './BaseApp';
import EditLicensePlate from './components/edit-license-plate';

const { TabPane } = Tabs;

// @ts-expect-error
@connect(({ apps, channels, loading, user }) => ({
  apps,
  channels,
  loadingResults: loading.effects['apps/doAppOp'],
  loading: loading.effects['apps/fetchApp'],
  user,
}))
class LicenseScannerApp extends BaseApp {
  constructor(props) {
    super(props);
    const savedChannelID = localStorage.getItem('savedChannelID') || null;
    this.handleDrawerSubmit = this.handleDrawerSubmit.bind(this);
    this.state = {
      channelSelectValue: savedChannelID && +savedChannelID,
      visible: false,
      drawerRecord: {},
      data: [],
      page: 1,
      paginationSize: 10,
    };
  }

  componentDidMount() {
    const startTime = moment();

    this.setState({ startTime: startTime });
  }

  getPayload() {
    const customerID = getCurrentCustomerID();

    let startTime = this.state.startTime;
    const endTime = moment.utc(startTime).startOf('day').toISOString();

    const minutesOffset = startTime.utcOffset();
    startTime = moment
      .utc(startTime)
      .add(minutesOffset, 'minutes')
      .toISOString();

    return {
      queryID: '2',
      ESVideoEndTime: endTime,
      ESVideoStartTime: startTime,
      op: 'get_recently_added_data',
      Metadata: {
        CustomerID: customerID,
        ChannelID: [this.state.channelSelectValue],
      },
      p_number: 1,
      p_size: 5000,
      license_plate: '*',
    };
  }

  loadData() {
    this.props
      .dispatch({
        type: 'apps/fetchApp',
        appID: LicenseScannerApp.TRUCK_TURNAROUND_TIME_APP_ID,
        payload: this.getPayload(),
      })
      .then((response: { success: any; data: object }) => {
        if (response.success) {
          const responseData = _.get(response, 'data.Data', []).map(
            (entry: any, index: number) => {
              const rawTime = _.get(entry, 'timestamp', 0);
              return {
                key: _.get(entry, 'object_id', index),
                captured_time:
                  rawTime === '' ? null : moment.utc(rawTime * 1000),
                captured_image: _.get(entry, 'context_image', ''),
                truck_number: _.get(entry, 'plate_reading', ''),
                truck_in: _.get(entry, 'truck_in', ''),
                upload_id: _.get(entry, 'upload_id'),
                object_id: _.get(entry, 'object_id'),
                plate_readings: _.get(entry, 'all_plate_readings', []),
              };
            },
          );

          this.setState({ data: responseData });
        }
      });
  }

  handleDrawerSubmit(object_id: any, plate_reading: string, truck_in: boolean) {
    const payload = {
      op: 'update_license_plate_number',
      object_id: object_id,
      plate_reading: plate_reading,
      truck_in: truck_in,
    };

    return dispatchWithFeedback(
      this.props.dispatch,
      'Updating License Plate Number',
      {
        type: 'apps/doAppOp',
        appID: LicenseScannerApp.TRUCK_TURNAROUND_TIME_APP_ID,
        payload: payload,
      },
      true,
    )
      .then((_response) => {
        const responseData = this.state.data.map((entry: any) =>
          entry.object_id === object_id
            ? { ...entry, truck_number: plate_reading, truck_in: truck_in }
            : entry,
        );
        this.setState({ data: responseData });
      })
      .finally(() => {
        this.onDrawerClose();
      });
  }

  showSuccess() {
    notification.open({
      message: 'License Plate Video Uploaded',
      description:
        "If you've uploaded both an IN and OUT video of a given truck, you can now check out the Truck Turnaround Time app.",
      className: 'df-notification',
      style: { height: 'fit-content' },
      placement: 'bottomRight',
      duration: 15,
    });
  }

  onDrawerOpen = (record: any) => {
    this.setState({ drawerRecord: record, visible: true });
  };

  onDrawerClose = () => {
    this.setState({ visible: false });
  };

  onChannelSelectChange = (value: any, isOnVerify: boolean = false) => {
    const channelID = value.length > 0 ? value[0].toString() : null;
    this.setState({ channelSelectValue: channelID && +channelID }, () => {
      if (isOnVerify && channelID !== null) {
        this.loadData();
      }
    });

    if (channelID) {
      localStorage.setItem('savedChannelID', channelID);
    } else {
      localStorage.removeItem('savedChannelID');
    }
  };

  onTabClick = (key: string) => {
    if (key === 'verify') {
      this.loadData();
    }
  };

  onDateChange = (timeRange: any) => {
    const startTime = timeRange.endOf('day');
    this.setState({ startTime: startTime }, () => {
      this.loadData();
    });
  };

  render() {
    const { loading } = this.props;
    return (
      <>
        <PageHeader title="License Plate Scanner" />
        <Tabs destroyInactiveTabPane onTabClick={this.onTabClick}>
          <TabPane tab="Scan" key="scan">
            <div className={styles['ls-container']}>
              <Form
                className={styles['ls-form']}
                layout="vertical"
                requiredMark={false}
                size="large"
                initialValues={{
                  channelID: [this.state.channelSelectValue],
                }}>
                <Form.Item
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please select a camera channel.',
                    },
                  ]}
                  label={<div className={styles['ls-title']}>Camera</div>}
                  name="channelID">
                  <ChannelSelect2
                    licensesRequired={{ channel_licenses: ['LPR'] }}
                    onChange={this.onChannelSelectChange}
                  />
                </Form.Item>
              </Form>
              <div className={styles['ls-buttons']}>
                <UploadVideoToChannel
                  className={styles['ls-button-wrapper']}
                  channelID={this.state.channelSelectValue}
                  truckIn={true}
                  onComplete={() => this.showSuccess()}>
                  <Button
                    type="default"
                    className={styles['ls-button']}
                    disabled={!this.state.channelSelectValue}>
                    IN
                  </Button>
                </UploadVideoToChannel>
                <UploadVideoToChannel
                  className={styles['ls-button-wrapper']}
                  channelID={this.state.channelSelectValue}
                  truckIn={false}
                  onComplete={() => this.showSuccess()}>
                  <Button
                    type="default"
                    className={styles['ls-button']}
                    disabled={!this.state.channelSelectValue}>
                    OUT
                  </Button>
                </UploadVideoToChannel>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Verify" key="verify">
            <EditLicensePlate
              record={this.state.drawerRecord}
              visible={this.state.visible}
              onDrawerClose={this.onDrawerClose}
              handleDrawerSubmit={this.handleDrawerSubmit}
              loadingResults={this.props.loadingResults}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '16px',
                marginBottom: '16px',
              }}>
              <div style={{ marginRight: '16px' }}>
                <ChannelSelect2
                  value={[this.state.channelSelectValue]}
                  licensesRequired={{ channel_licenses: ['LPR'] }}
                  onChange={(value) => this.onChannelSelectChange(value, true)}
                />
              </div>
              <div>
                <DatepickerDF
                  showTime={false}
                  value={this.state.startTime}
                  onChange={this.onDateChange}
                />
              </div>
            </div>
            {loading === true ? (
              <LoadingSpinner />
            ) : (
              <>
                <Table
                  style={{ marginRight: '16px' }}
                  className={styles['table-container']}
                  dataSource={this.state.data}
                  size="small"
                  pagination={{
                    pageSizeOptions: ['10', '20', '25', '30'],
                    showSizeChanger: true,
                    locale: { items_per_page: '' },
                    position: ['bottomCenter'],
                    onChange: (current, pageSize) => {
                      this.setState({
                        page: current,
                        paginationSize: pageSize,
                      });
                    },
                  }}>
                  <Table.Column
                    dataIndex="key"
                    key="index"
                    title={<span className={styles.header}>ID</span>}
                    render={(text, record, index) => {
                      const { page, paginationSize } = this.state;
                      return (page - 1) * paginationSize + index + 1;
                    }}
                  />
                  <Table.Column
                    dataIndex="truck_number"
                    align="center"
                    key="truck_number"
                    title={<span className={styles.header}>License Plate</span>}
                    render={(text) => {
                      const first = text.slice(0, 4),
                        second = text.slice(4, text.length);
                      return `${first} ${second}`;
                    }}
                  />
                  <Table.Column
                    dataIndex="captured_image"
                    key="captured_image"
                    align="center"
                    title={
                      <span className={styles.header}>Captured Image</span>
                    }
                    render={(text, record) => {
                      return (
                        <span>
                          <Image
                            src={record.captured_image}
                            width="100px"
                            style={{ maxHeight: '100px', objectFit: 'contain' }}
                          />
                        </span>
                      );
                    }}
                  />
                  <Table.Column
                    dataIndex="captured_time"
                    key="captured_time"
                    align="center"
                    title={<span className={styles.header}>Capture Time</span>}
                    render={(text, record) => {
                      if (record.timestamp === 0) {
                        return 'NA';
                      }
                      return moment.isMoment(text)
                        ? text.format('DD-MM-YYYY HH:mm:ss')
                        : '-';
                    }}
                  />
                  <Table.Column
                    dataIndex="truck_in"
                    key="truck_in"
                    align="center"
                    title={<span className={styles.header}>Direction</span>}
                    render={(text, record) => {
                      return <Tag>{record.truck_in ? 'IN' : 'OUT'}</Tag>;
                    }}
                  />
                  <Table.Column
                    dataIndex="action"
                    key="action"
                    title={<span className={styles.header}>Action</span>}
                    render={(text, record) => {
                      return (
                        <Button
                          type={'primary'}
                          onClick={() => this.onDrawerOpen(record)}>
                          Edit
                        </Button>
                      );
                    }}
                  />
                </Table>
              </>
            )}
          </TabPane>
        </Tabs>
      </>
    );
  }

  static APP_ID = 40;
  static TRUCK_TURNAROUND_TIME_APP_ID = 51;

  static OPS = {};
}

export default LicenseScannerApp;
