import TimelinePlayer from '@/components/TimelinePlayer';
import {
  convertSearchClipsIntoSearchEvents,
  getTimeInEventFormatMsecs,
} from '@/utils/utils';
import { Button, notification } from 'antd';
import { NotificationInstance } from 'antd/lib/notification';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Component } from 'react';
import { history } from 'umi';

const getTimeFromUTC = (data, key, timezone) => {
  return getTimeInEventFormatMsecs(parseInt(_.get(data, key)) * 1000, timezone);
};

class BaseApp extends Component {
  static getEditRuleComponent = null;

  static getPublicTimelinePlayer = (appID, data) => {
    const media = _.get(data, 'appData.media');

    if (!media) {
      return <div>No data found</div>;
    }

    let channelsInfo = _.get(data, 'appData.channels_info');
    if (_.isEmpty(channelsInfo)) {
      channelsInfo = [
        {
          channel_id: parseInt(_.get(data, 'appData.channel_id', '0')),
          timezone: _.get(data, 'appData.timezone', 'UTC'),
        },
      ];
    }

    let tlTimezone;

    let events = channelsInfo.map((channelInfo) => {
      let channelID = +channelInfo.channel_id;
      let timezone = channelInfo.timezone;

      if (tlTimezone === undefined) {
        tlTimezone = timezone;
      } else if (timezone && tlTimezone !== timezone) {
        tlTimezone = moment.tz.guess();
      }

      // EventStart|End are ES times: local time written in UTC
      let eventStart;
      let eventEnd;
      let anchorTime;

      if (_.get(data, 'appData.start_utc')) {
        // if we have UTC times, they don't need to be adjusted for timezone,
        // but they still need to look at the timezone to convert to ES-style
        // UTC timestamp - i.e. local time written in msec format
        eventStart = getTimeFromUTC(data, 'appData.start_utc', timezone);
        eventEnd = getTimeFromUTC(data, 'appData.end_utc', timezone);

        let anchor_utc = _.get(data, 'appData.anchor_utc');
        if (anchor_utc) {
          anchorTime = getTimeFromUTC(data, 'appData.anchor_utc', timezone);
        }
      } else {
        // event start/end are local times written in UTC, and so are start_time/end_time
        // passed in here. we can use them straight up, do _not_ use the channel timezone
        // here!
        eventStart = moment
          .tz(_.get(data, 'appData.start_time'), 'UTC')
          .valueOf();
        eventEnd = moment.tz(_.get(data, 'appData.end_time'), 'UTC').valueOf();

        let anchor_time = _.get(data, 'appData.anchor_time');
        if (anchor_time) {
          anchorTime = moment.tz(anchor_time, 'UTC').valueOf();
        }
      }

      return {
        EventStart: eventStart,
        EventEnd: eventEnd,
        AnchorTime: anchorTime,
        ChannelID: channelID,
        Timezone: timezone,
        Media: media.filter((m) => m.ChannelID === channelID),
      };
    });

    const searchResults = _.get(data, 'appData.search_results');
    if (typeof searchResults === 'string') {
      let results = JSON.parse(searchResults);
      let searchEvents = convertSearchClipsIntoSearchEvents(
        results.clips,
        null,
        null,
        tlTimezone,
      );
      events = [...events, ...searchEvents];
    }

    return (
      <div style={{ height: '100%', width: '100%' }}>
        <TimelinePlayer
          eventsOnly={true}
          showShare={false}
          timezone={tlTimezone}
          events={events}
        />
      </div>
    );
  };

  static getPublicDestinationComponent = () => <div></div>;

  static sendAppNotification = (
    {},
    notificationWithContext: NotificationInstance,
    appInfo,
    _wsMessage,
  ) => {
    let key = Math.random().toString();
    notificationWithContext.open({
      key,
      message: `${appInfo.Name} Updated`,
      duration: 0,
      description: (
        <>
          <div style={{ cursor: 'pointer' }}>
            <Button style={{ paddingLeft: 0 }} type="link">
              View App
            </Button>
          </div>
        </>
      ),
      onClick: () => {
        history.push(`/apps/${appInfo.AppID}`);
        notification.close(key);
      },
    });
  };
}

export default BaseApp;
