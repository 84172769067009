import TimelinePlayer from '@/components/TimelinePlayer';
import { ArrowLeftOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useMemo } from 'react';
import { useNavigate, useSelector } from 'umi';
import styles from './styles.less';
import { getRecentChannelsTileName } from './utils';
import { useVMSPlusContext } from './VMSPlusContext';
import { useRecentChannelsTileMarkingFavoriteActions } from './VMSPlusMutations';
import type { RecentChannelsTileFragment$data } from './__generated__/RecentChannelsTileFragment.graphql';

interface SceneTimelineProps {
  recentTilesConnectionData: {
    id: string;
    key: string;
  };
  timelinePlayerRef: React.MutableRefObject<undefined>;
  selectedTileData?: RecentChannelsTileFragment$data;
  isTileViewingMode?: boolean;
}

const SceneTimeline = ({
  recentTilesConnectionData,
  timelinePlayerRef,
  selectedTileData,
  isTileViewingMode,
}: SceneTimelineProps) => {
  const navigate = useNavigate();
  const {
    appId,
    isSidebarHidden,
    toggleSidebarVisibility,
    selectedChannels,
    setSelectedChannels,
    setSelectedTileRef,
  } = useVMSPlusContext();
  const { ch } = useSelector((state) => ({
    // @ts-expect-error
    ch: state.locations.ch,
  }));

  const { markAsFavorite, unMarkAsFavorite } =
    useRecentChannelsTileMarkingFavoriteActions({
      appId,
      recentTilesConnectionData,
      recentTile: selectedTileData as RecentChannelsTileFragment$data,
    });

  const tileName = useMemo(() => {
    if (isTileViewingMode) {
      return (
        selectedTileData?.name ??
        getRecentChannelsTileName(selectedTileData?.channelIDs as string[], ch)
      );
    }

    return getRecentChannelsTileName(selectedChannels as string[], ch);
  }, [
    isTileViewingMode,
    selectedChannels,
    ch,
    selectedTileData?.name,
    selectedTileData?.channelIDs,
  ]);

  const chIDs = selectedChannels?.map((n) => parseInt(n));

  const handleBackClick = () => {
    setSelectedTileRef(undefined);
    setSelectedChannels([]);
    navigate('/apps/107', { replace: true });
  };

  const handleUnStarClick = () => {
    unMarkAsFavorite();
  };

  const handleStarClick = () => {
    markAsFavorite();
  };

  const handleHideSidebarClick = () => {
    toggleSidebarVisibility();
  };

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles['live-header']}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={handleBackClick}
              title="Go back"
            />
            <Typography.Text style={{ flex: 1 }}>{tileName}</Typography.Text>
            {isTileViewingMode && (
              <>
                {selectedTileData?.isFavorite ? (
                  <StarFilled
                    onClick={handleUnStarClick}
                    style={{ color: '#ff9301' }}
                  />
                ) : (
                  <StarOutlined onClick={handleStarClick} />
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles['right-buttons']}>
          <Button size="small" onClick={handleHideSidebarClick}>
            {isSidebarHidden ? 'Show camera list' : 'Hide camera list'}
          </Button>
        </div>
      </div>
      <div
        style={{
          padding: '16px 16px 16px 0',
          height: 'calc(100% - 42px)',
          width: '100%',
        }}>
        <TimelinePlayer
          // @ts-expect-error
          innerRef={timelinePlayerRef}
          autoPlay={true}
          channelIDs={chIDs}
          showShare={true}
          showLive={true}
          showLink={false}
        />
      </div>
    </>
  );
};

export { SceneTimeline };
