import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, history } from 'umi';

// @ts-expect-error
@connect(({ views, loading }) => ({ views, loading }))
class CreateView extends React.Component {
  state = {
    showModal: false,
  };

  createViewForm = React.createRef();

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  createOrUpdateView() {
    const { dispatch, view } = this.props;
    this.createViewForm.current.validateFields().then(
      (values) => {
        if (view) {
          view.Name = values.name;
          dispatch({
            type: 'views/updateView',
            viewID: view.ViewID,
            payload: values,
          }).then(() => this.toggleModal());
        } else {
          dispatch({
            type: 'views/createView',
            payload: {...values, visible: true},
          }).then((response) => {
            this.toggleModal();
            if (this.createViewForm.current) {
              this.createViewForm.current.resetFields();
            }
            history.push(`/views/${response.data.ViewID}`);
          });
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  render() {
    const { children, loading, view } = this.props;
    return (
      <>
        <Modal
          width={400}
          title={view ? 'Rename View' : 'New View'}
          visible={this.state.showModal}
          onOk={(e) => this.createOrUpdateView(e)}
          confirmLoading={
            loading.effects['views/createView'] ||
            loading.effects['views/updateView']
          }
          onCancel={() => this.toggleModal()}>
          <Form
            ref={this.createViewForm}
            layout="vertical"
            requiredMark={false}
            initialValues={{ name: _.get(view, 'Name') }}
            onFinish={(e) => this.createOrUpdateView(e)}>
            <Form.Item
              label="View Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of the view',
                },
              ]}>
              <Input autoFocus />
            </Form.Item>
          </Form>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default CreateView;
