import ImageStripPreview from '@/components/InfoSidebar/SidebarComponents/ImageStripPreview';
import PlayerModal from '@/components/PlayerModal';
import TimelinePlayer from '@/components/TimelinePlayer';
import CreateEvent from '@/pages/investigations/components/create-event';
import {
  ClipData,
  displayTZ,
  getFlexibleDateFormat,
  interpretClipData,
  linkTo,
} from '@/utils/utils';
import { Button, Carousel, Divider, Image, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';
import styles from './style.less';

const ColorOption = (props: any) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}>
    <span
      className={styles['color-circle']}
      style={{
        background: props.color_val,
        border:
          props.color_val === 'white' ? '1px solid rgba(0,0,0,0.48)' : 'none',
      }}></span>
    <span
      className={styles['info-title']}
      style={{ padding: '0px 5px', textTransform: 'capitalize' }}>
      {props.color_val}
    </span>
  </div>
);

function InfoBlock(props: any) {
  const { divideText, title, children, bodyStyle } = props;

  return (
    <div className={styles['info-block-ctn']} key="object_type">
      {divideText && (
        <Divider plain="true" orientation="left" orientationMargin="0">
          {divideText}
        </Divider>
      )}

      {title && (
        <div className={styles['info-block-header']}>
          <span className={styles['info-title']}>{title}</span>
        </div>
      )}

      <div
        className={styles['info-block-body']}
        style={{ width: '100%', ...bodyStyle }}>
        {children}
      </div>
    </div>
  );
}

function InfoBlockColors(props: any) {
  const { title, colours } = props;

  return (
    <InfoBlock title={title}>
      {colours.map((c, i) => (
        <ColorOption color_val={c} key={i} />
      ))}
    </InfoBlock>
  );
}

function ThumbnailCarousel(props: any) {
  const { thumbnails } = props;

  return (
    <Carousel className={styles['primary-carousal']}>
      {thumbnails.map((t, i) => (
        <div className={styles['primary-thumbnail-image-ctn']} key={i}>
          <Image className={styles['thumbnail-img']} src={t} />
        </div>
      ))}
    </Carousel>
  );
}

/* ====================================================================== */
/* Info Side Panel                                                        */
/* ====================================================================== */

type InfoSidebarState = ClipData & {
  showInfoSidebar: boolean;
  playerModalProps?: {};
  // allow null for now
  clip: null | any;
};

function makeDefaultState(): InfoSidebarState {
  return {
    showInfoSidebar: true,
    // ClipData
    info_title: '',
    thumbnails: [],
    primary_thumbnails: [],
    secondary_thumbnails: [],
    specialImages: [],
    preview: null,
    location: null,
    channel_group: null,
    channel: null,
    location_obj: null,
    channel_group_obj: null,
    channel_obj: null,
    from: null,
    to: null,
    elapsed_time: null,
    object_type: null,
    person_details: null,
    vehicle_details: null,
    clip: null,
  };
}

type InfoSidebarProps = {
  clip: any;
  loc: any;
  ch_grp: any;
  ch: any;
  searchResults: any;
  getExtraActions?: () => React.ReactNode;
  objectSearch?: (clip: {}) => void;
  // extra, which are react builtins?
  showContextInline?: any;
  onClose?: any;
  event?: any;
  // locations?: any;
  // channel_menu?: any;
  info_title?: string;
  closable?: boolean;
  showAddToInvestigation?: boolean;
};

// @ts-expect-error
@connect(
  ({ locations }) => ({
    loc: locations.loc,
    ch_grp: locations.ch_grp,
    ch: locations.ch,
  }),
  null,
  null,
  { forwardRef: true },
)
class InfoSidebar extends React.Component<InfoSidebarProps, InfoSidebarState> {
  createEventRef: any;
  playerModalRef: React.RefObject<PlayerModal>;

  constructor(props) {
    super(props);
    this.state = makeDefaultState();
    this.createEventRef = React.createRef();
    this.playerModalRef = React.createRef<PlayerModal>();
  }

  componentDidMount() {
    if (_.get(this.props, 'clip', null)) {
      this.initInfoSidebar();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.clip, this.props.clip) ||
      !_.isEqual(prevProps.loc, this.props.loc) ||
      !_.isEqual(prevProps.ch_grp, this.props.ch_grp) ||
      !_.isEqual(prevProps.ch, this.props.ch)
    ) {
      this.setState(makeDefaultState(), () => this.initInfoSidebar());
    }
  }

  initInfoSidebar() {
    const clip_obj = _.get(this.props, 'clip', null);
    const channel = _.get(
      this.props,
      `ch.byId[${+_.get(clip_obj, 'ChannelID', 0)}]`,
      null,
    );
    const { loc, ch_grp, ch } = this.props;
    const clip_info = interpretClipData(
      clip_obj,
      { loc, ch_grp, ch },
      _.get(channel, 'Timezone'),
      this.props.searchResults,
    );
    this.setState(clip_info);
  }

  toggleInfoSidebar(showInfoSidebar: any) {
    this.setState({ showInfoSidebar });
    // let container know that the thumbnail layer was closed
    if (!showInfoSidebar && this.props.onClose) {
      this.props.onClose();
    }
  }

  openPlayerModal(playerModalProps: Record<string, any>) {
    this.setState({ playerModalProps }, () => {
      if (this.playerModalRef.current) {
        this.playerModalRef.current.toggleModal();
      }
    });
  }

  render() {
    const {
      info_title,
      event,
      clip,
      showContextInline,
      closable,
      searchResults,
      showAddToInvestigation = true,
    } = this.props;

    const title = info_title || 'Details';

    const from = _.get(this.state, 'from');
    const fromText = from && getFlexibleDateFormat(from, true);
    const to = _.get(this.state, 'to');
    const toText = to && getFlexibleDateFormat(to, true);
    const timezone = _.get(this.state, 'timezone');

    const startTime = from && from.valueOf() / 1000;
    const endTime = to && to.valueOf() / 1000;

    const isWide = showContextInline;

    const wrapClassName = [
      styles['lp-thumbnail-modal-ctn'],
      isWide ? styles['wide'] : '',
      closable ? '' : styles['not-closable'],
    ].join(' ');

    const width = (isWide ? 450 : 300) - 16;

    const alert_info: Record<string, any> = {
      is_alert: false,
      alert_type: null,
      alert_start_time: null,
    };
    if ('alert_type' in clip) {
      const alery_start_time = _.get(
        searchResults,
        [0, 'ESVideoStartTime'],
        null,
      );
      alert_info['is_alert'] = true;
      alert_info['alert_type'] = _.get(clip, ['alert_type'], null);
      alert_info['alert_start_time'] = alery_start_time
        ? alery_start_time / 1000
        : null;
    }

    let playerStartTime = startTime;
    if (
      alert_info['is_alert'] &&
      ['app_alerts'].indexOf(alert_info['alert_type']) != -1 &&
      alert_info['alert_start_time']
    ) {
      playerStartTime = alert_info['alert_start_time'];
    }

    // object information
    const locID = this.state.location_obj?.ID;
    const chGrpID = this.state.channel_group_obj?.ID;
    const chID = this.state.channel_obj?.ID;

    const channelGroup = _.get(this.state, 'channel_group', '');
    const channel = _.get(this.state, 'channel', '');

    const events = this.state.events;

    const thumbnailsPrimary = _.get(this.state, 'primary_thumbnails', []);
    const thumbnailsSecondary = _.get(this.state, 'secondary_thumbnails', []);
    const preview = null && _.get(this.state, 'preview', null);
    const previewThumbnail = _.get(this.state, 'preview.VideoThumbnail', null);

    const location = _.get(this.state, 'location', null);
    const elapsed = _.get(this.state, 'elapsed_time', 0);
    const elapsedStr = _.get(this.state, 'elapsed_time_str', 'N/A');

    const objectType = _.get(this.state, 'object_type', null);
    const objectTypeStr = _.get(this.state, 'object_type_str', 'N/A');

    const colorsTop = _.get(this.state, 'person_details.top_colors', []);
    const colorsBottom = _.get(this.state, 'person_details.bottom_colors', []);
    const colorsVehicle = _.get(
      this.state,
      'vehicle_details.vehicle_colors',
      [],
    );

    const labelConfidence = this.state.label_confidence;
    const labelConfidenceStr = _.isNumber(labelConfidence)
      ? (labelConfidence * 100).toFixed(1) + '%'
      : 'N/A';

    const metadata = _.get(this.state, 'metadata', []);

    return (
      <>
        <Modal
          title={title || ''}
          className={styles['lp-thumbnail-mask']}
          wrapClassName={wrapClassName}
          width={width}
          maskStyle={{ width: '0px', height: '0px' }}
          footer={null}
          keyboard={closable}
          visible={this.state.showInfoSidebar}
          closable={closable}
          onOk={() => this.toggleInfoSidebar(false)}
          onCancel={() => this.toggleInfoSidebar(false)}
          destroyOnClose={true}>
          <div className={styles['thumbnail-details-ctn']}>
            <div>
              {clip && chID && from && (
                <div className={styles['info-block-ctn']}>
                  {showContextInline && (
                    <div style={{ paddingBottom: 4 }}>
                      {/* 16:9 ratio magic: https://stackoverflow.com/questions/1495407/maintain-the-aspect-ratio-of-a-div-with-css */}
                      <div
                        style={{
                          width: '100%',
                          paddingBottom: '56.25%',
                          position: 'relative',
                        }}>
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                          }}>
                          <TimelinePlayer
                            showLive={true}
                            startTime={startTime}
                            endTime={endTime}
                            showHideControls={true}
                            channelIDs={[chID]}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <Button
                    size="small"
                    block
                    onClick={() => {
                      this.openPlayerModal({
                        events,
                        startTime: playerStartTime,
                        endTime,
                        channelIDs: [chID],
                      });
                    }}>
                    {showContextInline ? 'View Fullscreen' : 'View in Context'}
                  </Button>
                </div>
              )}

              {clip && this.props.objectSearch && (
                <div className={styles['info-block-ctn']}>
                  <Button
                    size="small"
                    block
                    onClick={() => {
                      this.props.objectSearch?.(clip);
                    }}>
                    Find More Like This
                  </Button>
                </div>
              )}

              {showAddToInvestigation && startTime && (
                <div className={styles['info-block-ctn']}>
                  <Button
                    size="small"
                    block
                    onClick={() => {
                      this.createEventRef.current.toggleInvestigationEventModal();
                    }}>
                    Add To Investigation
                  </Button>
                  <CreateEvent
                    style={{ display: 'none' }}
                    clip={clip}
                    event={event}
                    ref={this.createEventRef}
                  />
                </div>
              )}
            </div>

            {_.get(thumbnailsPrimary, 'length') ? (
              <InfoBlock divideText="Thumbnail">
                <ThumbnailCarousel thumbnails={thumbnailsPrimary} />
              </InfoBlock>
            ) : (
              ''
            )}

            {thumbnailsSecondary.map((_slide_obj, i) => (
              <InfoBlock title={_slide_obj.label} key={i}>
                <ThumbnailCarousel thumbnails={_slide_obj.list} />
              </InfoBlock>
            ))}

            {preview && (
              <InfoBlock
                divideText={'Preview'}
                bodyStyle={{ background: 'black' }}>
                <ImageStripPreview
                  style={{ margin: 'auto' }}
                  thumbnailStrip={preview}
                  thumbnail={previewThumbnail}
                />
              </InfoBlock>
            )}

            {/* location */}
            {location && (
              <InfoBlock divideText={'Camera'}>
                <span className={styles['info']} style={{ color: '#2D00F7' }}>
                  {linkTo('LOCATION', { locID }, this.state.location)}
                </span>
                <span>{' > '}</span>
                {channelGroup && (
                  <span>
                    <span
                      className={styles['info']}
                      style={{ color: '#2D00F7', whiteSpace: 'nowrap' }}>
                      {linkTo(
                        'CHANNEL-GROUP',
                        { locID, chGrpID },
                        channelGroup,
                      )}
                    </span>
                    <span>{' > '}</span>
                  </span>
                )}
                {channel && (
                  <span>
                    <span
                      className={styles['info']}
                      style={{ color: '#2D00F7', whiteSpace: 'nowrap' }}>
                      {linkTo('CHANNEL', { locID, chID }, channel)}
                    </span>
                  </span>
                )}
              </InfoBlock>
            )}

            {/* timezone */}
            {from && (
              <InfoBlock divideText={'Time'}>
                <span className={styles['info']} style={{ color: '#495057' }}>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {fromText}{' '}
                    <span className={styles.subinfo}>
                      {displayTZ(timezone)}
                    </span>
                  </span>
                  {to && !!elapsed && (
                    <>
                      <span>{' - '}</span>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span
                          className={styles['info']}
                          style={{ color: '#495057' }}>
                          {toText}{' '}
                          <span className={styles.subinfo}>
                            {displayTZ(timezone)}
                          </span>
                        </span>
                      </span>
                    </>
                  )}
                  {!elapsed ? (
                    <span />
                  ) : (
                    <span
                      className={styles['info']}
                      style={{ color: '#495057' }}>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {' ('}
                        {elapsedStr}
                        {')'}
                      </span>
                    </span>
                  )}
                </span>
              </InfoBlock>
            )}

            {objectType && (
              <InfoBlock divideText={'Object Information'} title={'Type'}>
                <span className={styles['info']}>{objectTypeStr}</span>
              </InfoBlock>
            )}

            {colorsTop.length > 0 && (
              <InfoBlockColors title={'Top Color'} colours={colorsTop} />
            )}
            {colorsBottom.length > 0 && (
              <InfoBlockColors title={'Bottom Color'} colours={colorsBottom} />
            )}
            {colorsVehicle.length > 0 && (
              <InfoBlockColors
                title={'Vehicle Color'}
                colours={colorsVehicle}
              />
            )}

            {labelConfidence && (
              <InfoBlock title={'Confidence'}>
                <span className={styles['info']}>{labelConfidenceStr}</span>
              </InfoBlock>
            )}

            {metadata.map((obj, i) => (
              <InfoBlock divideText={obj.name} key={i}>
                {obj.getInfoBody(obj.value)}
              </InfoBlock>
            ))}

            {this.props.getExtraActions && this.props.getExtraActions()}
          </div>
        </Modal>

        {this.state.playerModalProps && (
          <PlayerModal
            autoPlay={true}
            ref={this.playerModalRef}
            showLive={false}
            fitEventsOnAxis={false}
            {...this.state.playerModalProps}
          />
        )}
      </>
    );
  }
}

export default InfoSidebar;
