import { RedoOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Tooltip } from 'antd';
import { useState } from 'react';

const CreateCode = ({ code = undefined, children, onFinish }) => {
  const [isModelOpen, toggleModal] = useState(false);
  const [form] = Form.useForm();

  const generateCode = () => {
    return Math.random().toString(36).slice(2, 7).toUpperCase();
  };
  const handleCancel = () => {
    toggleModal((val) => !val);
    form.resetFields();
  };
  return (
    <>
      <Modal
        visible={isModelOpen}
        title={code ? 'Edit Code' : 'Create Code'}
        onCancel={handleCancel}
        destroyOnClose
        width={360}
        footer={null}>
        <Form
          form={form}
          onFinish={(value) => {
            if (!code) {
              value.id = +new Date();
            } else {
              value.id = code.id;
            }
            onFinish(value);
            toggleModal((val) => !val);
            form.resetFields();
          }}
          initialValues={
            code
              ? code
              : {
                  enableCode: generateCode(),
                  disableCode: generateCode(),
                  timeout: 10,
                }
          }
          requiredMark={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter a name',
              },
            ]}>
            <Input></Input>
          </Form.Item>
          <Form.Item label="Enable Code">
            <Form.Item name="enableCode" noStyle>
              <Input style={{ width: 'calc(100% - 40px)' }} />
            </Form.Item>
            <Tooltip
              placement="right"
              title={'Generate Random Code'}
              mouseEnterDelay={0.7}>
              <Button
                type="default"
                style={{ height: '32px', marginLeft: '8px' }}
                icon={<RedoOutlined />}
                onClick={() =>
                  form.setFieldsValue({ enableCode: generateCode() })
                }
              />
            </Tooltip>
          </Form.Item>

          <Form.Item label="Disable Code">
            <Form.Item name="disableCode" noStyle>
              <Input style={{ width: 'calc(100% - 40px)' }} />
            </Form.Item>
            <Tooltip
              placement="right"
              title={'Generate Random Code'}
              mouseEnterDelay={0.7}>
              <Button
                type="default"
                style={{ height: '32px', marginLeft: '8px' }}
                icon={<RedoOutlined />}
                onClick={() =>
                  form.setFieldsValue({ disableCode: generateCode() })
                }
              />
            </Tooltip>
          </Form.Item>
          <Form.Item
            name="timeout"
            label="Timeout"
            rules={[
              {
                type: 'integer',
                min: 0, // Set the minimum allowed value to 0
                message: 'This must be a non-negative integer',
              },
            ]}>
            <InputNumber addonAfter="Mins" />
          </Form.Item>
          {/* <Form.Item name="enable" label="Enable" valuePropName="checked">
            <Checkbox></Checkbox>
          </Form.Item> */}
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={handleCancel}>Cancel</Button>
            &nbsp;
            <Button type="primary" htmlType="submit">
              Done
            </Button>
          </div>
        </Form>
      </Modal>
      <span onClick={() => toggleModal((val) => !val)}>{children}</span>
    </>
  );
};

export default CreateCode;
