import React from 'react';
import { connect } from 'umi';
import { Modal, Button, Popconfirm } from 'antd';
import AnnotationsPlayer from '@/components/AnnotationsPlayer';

// @ts-expect-error
@connect()
class RedactEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.appID = AnnotationsPlayer.APP_ID;
    this.playerRef = React.createRef();
  }

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  render() {
    const { children } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <Modal
          width={'90%'}
          height={'90%'}
          style={{ top: '5%' }}
          title={'Redaction Studio'}
          onCancel={() => this.toggleModal()}
          visible={showModal}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Popconfirm
                title="Are you sure you want to remove all changes?"
                style={{ color: 'red' }}
                onConfirm={(_e) =>
                  this.playerRef.current &&
                  this.playerRef.current.deleteOverrides()
                }
                onCancel={(e) => e.preventDefault()}
                okText="Yes"
                cancelText="No">
                <Button key="delete" danger>
                  Reset
                </Button>
              </Popconfirm>
              <Button key="close" onClick={() => this.toggleModal()}>
                Close
              </Button>
            </div>
          }>
          <div>
            <AnnotationsPlayer
              ref={this.playerRef}
              appID={this.appID}
              eventID={this.props.eventID}
              startTime={this.props.startTime}
              endTime={this.props.endTime}
            />
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default RedactEvent;
