/* eslint-disable consistent-return */
import React from 'react';
import _ from 'lodash';
import { Modal, Button, Form, Input, Select } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import styles from './style.less';
import { camera_json } from '@/utils/camera-details';
import { DiscoveredDeviceNode } from '@/types/location';

import type { FormInstance } from 'antd';

const { Option } = Select;

type Props = any;
type State = any;

class DiscoverManually extends React.Component<Props, State> {
  discoverManuallyFormRef: React.RefObject<FormInstance>;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.discoverManuallyFormRef = React.createRef();
    this.modelInfo = null;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      _.get(prevState, 'visible', null) !== _.get(this.state, 'visible', null)
    ) {
      if (
        this.discoverManuallyFormRef.current &&
        _.get(this.state, 'visible', null)
      ) {
        this.discoverManuallyFormRef.current.resetFields();
      }
    }
  }

  openModal() {
    this.setState({ visible: true });
  }

  closeModal() {
    this.setState({ visible: false });
  }

  onDiscoverClick() {
    if (!this.discoverManuallyFormRef.current) {
      return 0;
    }

    this.discoverManuallyFormRef.current.validateFields().then(
      (_values) => {
        let form_fields = {};
        if (this.discoverManuallyFormRef.current) {
          form_fields = this.discoverManuallyFormRef.current.getFieldsValue();
        }
        let model = this.modelInfo
          ? this.modelInfo.name
          : form_fields.camera_model;
        const channelServerCred = {
          Name: `${form_fields.camera_make} ${model}`,
          ChannelDetails: {
            Username: form_fields.username,
            Password: form_fields.password,
            RTSPPort: form_fields.port_number,
            HTTPPort: '80',
            ServerURL: form_fields.server_url,
            IPAddress: form_fields.ip_address,
            Manufacturer: form_fields.camera_make,
            Model: model,
          },
          MonitorStatus: 'stream',
        };

        this.props.addChannelToChannelList([channelServerCred]);
        this.closeModal();
      },
      (err: any) => console.log('err', err),
    );
  }

  updateFields() {
    let form = this.discoverManuallyFormRef.current;
    if (!form) {
      return;
    }
    let info = form.getFieldsValue();
    console.log(info);
    let model = _.get(info, 'camera_model');
    if (!model) {
      return;
    }
    this.modelInfo = DiscoveredDeviceNode.getModelInfo(model);
    if (this.modelInfo) {
      form.setFieldsValue({
        username: _.get(this.modelInfo, 'defaultUsername'),
        password: _.get(this.modelInfo, 'defaultPassword'),
        server_url: _.get(this.modelInfo, 'serverUrl'),
      });
    }
  }

  render() {
    const { visible } = this.state;
    let form_fields = {};
    if (this.discoverManuallyFormRef.current) {
      form_fields = this.discoverManuallyFormRef.current.getFieldsValue();
    }
    const show_config =
      _.get(form_fields, 'camera_make', null) &&
      _.get(form_fields, 'camera_model', null);
    const _make_list = Object.keys(camera_json);
    const _all_models = _.get(camera_json, [form_fields.camera_make], []);
    const _model_list = Object.keys(_all_models);
    return (
      <Modal
        wrapClassName={styles['discover-manually-modal']}
        visible={visible}
        title=""
        footer={null}
        width={450}
        onCancel={() => {
          this.discoverManuallyFormRef.current?.resetFields();
          this.closeModal();
        }}
        destroyOnClose={true}>
        <div className={styles['discover-manually-action-pannel']}>
          <h2>Discover Manually</h2>
          <p style={{ fontSize: '15px', color: '#6C757D' }}>
            Choose the make and model of your camera below. If you don’t see it
            displayed, select New Camera to enter RTSP info.
          </p>
          <br />
          <div className={styles['discover-manually-form-ctn']}>
            <Form
              ref={this.discoverManuallyFormRef}
              layout="vertical"
              requiredMark={false}
              onValuesChange={(changedValues, allValues) => {
                this.setState({ form_fields: allValues });
              }}>
              <Form.Item
                name="camera_make"
                label={
                  <span className={styles['form-item-label']}>
                    Manufacturer
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: 'Please select a value.',
                  },
                ]}>
                <Select
                  className={styles['form-select']}
                  showSearch
                  placeholder="Choose..."
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {['Unknown', ..._make_list].map((k, idx) => {
                    return (
                      <Option key={`make-${idx}`} value={k}>
                        {k}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="camera_model"
                label={
                  <span className={styles['form-item-label']}>
                    Camera Model
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: 'Please select a value.',
                  },
                ]}>
                <Select
                  className={styles['form-select']}
                  showSearch
                  placeholder="Choose..."
                  optionFilterProp="children"
                  onChange={() => this.updateFields()}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {['Unknown', ..._model_list].map((k, idx) => {
                    let modelName = _.get(
                      _all_models,
                      `['${k}'].name`,
                      'Unknown',
                    );
                    return (
                      <Option key={`model-${idx}`} value={k}>
                        {modelName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {show_config ? (
                <>
                  <Form.Item
                    name="username"
                    label={
                      <span className={styles['form-item-label']}>
                        Username
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}>
                    <Input className={styles['form-input']} />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={
                      <span className={styles['form-item-label']}>
                        Password
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}>
                    <Input className={styles['form-input']} />
                  </Form.Item>
                  <Form.Item
                    name="ip_address"
                    label={
                      <span className={styles['form-item-label']}>
                        IP Address
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}>
                    <Input className={styles['form-input']} />
                  </Form.Item>
                  <Form.Item
                    name="port_number"
                    initialValue="554"
                    label={
                      <span className={styles['form-item-label']}>
                        Port Number
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}>
                    <Input className={styles['form-input']} />
                  </Form.Item>
                  <Form.Item
                    name="server_url"
                    label={
                      <span className={styles['form-item-label']}>
                        Server URL
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}>
                    <Input className={styles['form-input']} />
                  </Form.Item>
                </>
              ) : null}
              <Button
                className={styles['form-btn']}
                style={{ margin: '10px 0' }}
                size="middle"
                type="primary"
                onClick={() => {
                  this.onDiscoverClick();
                }}>
                Discover
              </Button>
              <Button
                className={styles['form-btn']}
                size="middle"
                type="link"
                onClick={() => {
                  this.closeModal();
                  this.props.openBulkDiscoverCamerasModal();
                }}>
                Bulk Discover Cameras <ArrowRightOutlined />
              </Button>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
}
export default DiscoverManually;
