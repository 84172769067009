/* eslint-disable prefer-promise-reject-errors */
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { DELIVERY_STATUS } from '@/types/location.ts';
import { dispatchWithFeedback } from '@/utils/utils';
import {
  Form,
  Modal,
  Button,
  Input,
  InputNumber,
  Select,
  Popconfirm,
  notification,
  Radio,
} from 'antd';

import InternalMonitoringApp from '@/pages/apps/app/InternalMonitoringApp';

// @ts-expect-error
@connect(({ user, apps, loading }) => ({
  user,
  apps,
  loading,
}))
class InternalEditBaseStations extends Component {
  constructor(props) {
    super(props);
    this.addBaseStationForm = React.createRef();

    this.state = {
      showModal: false,
    };
  }

  updateBaseStationID() {
    this.setState({
      BaseStationID: _.get(this.props, 'baseStation.BaseStationID'),
    });
  }

  componentDidMount() {
    this.updateBaseStationID();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.baseStation !== this.props.baseStation) {
      this.updateBaseStationID();
    }
  }

  obliterate(e) {
    e.preventDefault();

    let { baseStation } = this.props;
    if (!_.get(baseStation, 'CustomerID') || !_.get(baseStation, 'ProjectID')) {
      return notification.open({
        message: 'Obliteration requires a customer ID and project ID',
        className: 'df-notification',
        placement: 'bottomRight',
      });
    }

    return dispatchWithFeedback(
      this.props.dispatch,
      'Base Station obliteration',
      {
        type: 'apps/doAppOp',
        appID: InternalMonitoringApp.appID,
        payload: {
          op: InternalMonitoringApp.OPS.obliterate.name,
          params: {
            customer_id: _.get(baseStation, 'CustomerID'),
            project_id: _.get(baseStation, 'ProjectID'),
            base_station_id: _.get(baseStation, 'BaseStationID'),
          },
        },
      },
    )
      .then(() => {
        return this.props.updater();
      })
      .then(() => {
        this.toggleModal();
      });
  }

  handleEditBaseStation(e) {
    e.preventDefault();

    this.addBaseStationForm.current.validateFields().then(
      (values) => {
        // we need to merge because we don't want to clobber existing
        // statusDetails object. there still is a server-side race condition though...
        let baseStation = _.merge(this.props.baseStation, values);

        if (!this.props.duplicate) {
          baseStation.BaseStationID = this.state.BaseStationID;
        }

        console.log(baseStation);

        return dispatchWithFeedback(
          this.props.dispatch,
          'Base Station update',
          {
            type: 'apps/doAppOp',
            appID: InternalMonitoringApp.appID,
            payload: {
              op: InternalMonitoringApp.OPS.editBaseStation.name,
              params: { base_station: baseStation },
            },
          },
        )
          .then(() => {
            return this.props.updater();
          })
          .then(() => {
            this.toggleModal();
          });
      },
      (err: any) => console.log('err', err),
    );
  }

  toggleModal() {
    let { showModal } = this.state;
    if (this.addBaseStationForm.current) {
      this.addBaseStationForm.current.resetFields();
    }
    this.setState({ showModal: !showModal });
  }

  getValue(type) {
    return (
      this.addBaseStationForm.current &&
      this.addBaseStationForm.current.getFieldValue(type)
    );
  }

  renderSearchBaseStations(initialValues) {
    let currentLocationType =
      this.addBaseStationForm.current?.getFieldValue('locationType');

    return (
      <Form
        layout="vertical"
        ref={this.addBaseStationForm}
        requiredMark={false}
        initialValues={initialValues}
        onSubmit={(e) => this.handleEditBaseStation(e)}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '33%', paddingRight: '32px' }}>
            <Form.Item
              label="Serial Number"
              name="SerialNumber"
              rules={[
                {
                  required: true,
                  message: 'Please enter a serial number for the Base Station',
                },
              ]}>
              <Input placeholder="Serial Number" autoFocus />
            </Form.Item>
            <Form.Item label="Wifi MAC" name="WifiMAC">
              <Input />
            </Form.Item>
            <Form.Item label="Ethernet MAC" name="EthernetMAC">
              <Input />
            </Form.Item>
            <Form.Item label="Bluetooth MAC" name="BluetoothMAC">
              <Input />
            </Form.Item>
          </div>
          <div style={{ width: '33%', paddingRight: '32px' }}>
            <Form.Item
              label="Customer ID"
              name="CustomerID"
              extra="Remove existing value to deallocate">
              <Input />
            </Form.Item>
            <Form.Item label="Location" name="locationType">
              <Radio.Group
                onChange={(e) => {
                  let locationType = e.target.value;
                  if (locationType === 'new') {
                    this.addBaseStationForm.current?.setFieldsValue({
                      ProjectID: -1,
                    });
                  } else {
                    this.addBaseStationForm.current?.setFieldsValue({
                      ProjectID: initialValues.ProjectID,
                    });
                  }
                  this.forceUpdate();
                }}>
                <Radio value="existing">Use Existing Location</Radio>
                <Form.Item
                  name="ProjectID"
                  style={{ margin: '8px 0 8px 32px' }}>
                  <Input
                    disabled={currentLocationType !== 'existing'}
                    placeholder="Location ID"
                  />
                </Form.Item>

                <Radio value="new">Create New Location</Radio>
                <Form.Item
                  style={{ margin: '8px 0 8px 32px' }}
                  label="Location Name"
                  name={['MiscInfo', 'new_location_name']}>
                  <Input
                    disabled={currentLocationType !== 'new'}
                    placeholder="Name (optional)"
                  />
                </Form.Item>
                <Form.Item
                  label="Geo Location"
                  style={{ margin: '8px 0 8px 32px' }}>
                  <div style={{ display: 'flex' }}>
                    <Form.Item
                      name={['MiscInfo', 'geo_location', 'latitude']}
                      style={{ marginRight: '16px' }}>
                      <InputNumber
                        disabled={currentLocationType !== 'new'}
                        placeholder="latitude"
                      />
                    </Form.Item>
                    <Form.Item name={['MiscInfo', 'geo_location', 'longitude']}>
                      <InputNumber
                        disabled={currentLocationType !== 'new'}
                        placeholder="longitude"
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={{ width: '33%' }}>
            <Form.Item
              label="Delivery Status"
              name={['StatusDetails', 'shipment_details', 'delivery_status']}>
              <Select
                options={Object.values(DELIVERY_STATUS).map((v) => ({
                  key: v,
                  value: v,
                }))}></Select>
            </Form.Item>
            <Form.Item
              label="Tracking Number"
              name={['StatusDetails', 'shipment_details', 'tracking_number']}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Tracking URL"
              name={['StatusDetails', 'shipment_details', 'tracking_url']}>
              <Input />
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  }

  render() {
    const { children, baseStation, duplicate } = this.props;
    const { showModal } = this.state;

    let initialValues = _.clone(baseStation);

    let title = 'Add Base Station';
    if (duplicate) {
      title = 'Duplicate';
      initialValues.name = `[Duplicate] ${initialValues.name}`;
    } else if (baseStation && baseStation.BaseStationID) {
      title = 'Edit Base Station';
      initialValues.BaseStationID = baseStation.BaseStationID;
    }

    if (
      !_.get(initialValues, 'locationType') &&
      _.get(initialValues, 'ProjectID')
    ) {
      initialValues.locationType = 'existing';
    }

    return (
      <>
        <Modal
          centered
          forceRender
          width="900px"
          style={{ height: '100%', top: '5%' }}
          title={title}
          visible={showModal}
          onCancel={() => this.toggleModal()}
          footer={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '5px',
              }}>
              <Popconfirm
                title="Have you received Amit's permission?"
                style={{ color: 'red' }}
                onConfirm={(e) => this.obliterate(e)}
                onCancel={(e) => e.preventDefault()}
                okText="Yes"
                cancelText="No">
                <Button key="delete" danger>
                  Obliterate
                </Button>
              </Popconfirm>
              <div>
                <Button key="cancel" onClick={() => this.toggleModal()}>
                  Cancel
                </Button>
                <Button
                  key="addbaseStation"
                  loading={
                    this.props.loading.effects['apps/doAppOp'] ||
                    this.props.loading.effects['apps/fetchApp']
                  }
                  onClick={(e) => this.handleEditBaseStation(e)}
                  type="primary">
                  Save
                </Button>
              </div>
            </div>
          }>
          <div>{this.renderSearchBaseStations(initialValues)}</div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default InternalEditBaseStations;
