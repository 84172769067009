import { Alert, Button, Form, Input } from 'antd';
import classNames from 'classnames';
import { Component } from 'react';
import { formatMessage, Link } from 'umi';
import styles from './style.less';

class Login extends Component {
  static defaultProps = {
    className: '',
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleSubmit = (values) => {
    this.props.onSubmit('', values);
  };

  renderMessage = (content) => (
    <Alert
      style={{
        marginBottom: 24,
      }}
      message={content}
      type="error"
      showIcon
    />
  );

  render() {
    return (
      <div className={classNames(styles.container)}>
        {/* <div className={styles['logo-container']}>
          <Icon component={DfLogo} className={styles.logo} />
        </div> */}
        <div className={classNames(styles.login)}>
          <div className={classNames(styles.header)}>
            Sign in to Dragonfruit
          </div>
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            colon={false}
            validateTrigger="onBlur"
            requiredMark={false}
            className={styles.form}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  pattern: /^[^\s]+@[^\s]+\.[^\s]+$/,
                  message: 'Please enter a valid email',
                },
              ]}>
              <Input size="default" autoFocus className={styles.inputButton} />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter your password' },
              ]}>
              <Input
                size="default"
                type="password"
                className={styles.inputButton}
              />
            </Form.Item>
            {this.props.user.errors.login &&
              this.renderMessage(
                formatMessage({
                  id: 'user-login.login.message-invalid-credentials',
                }),
              )}
            <Form.Item>
              <Button
                block
                loading={this.props.loading}
                size="default"
                className={styles.submit}
                type="primary"
                htmlType="submit">
                Log in
              </Button>
            </Form.Item>
          </Form>
          <div className={classNames(styles['footer-form'])}>
            <div className={classNames(styles['footer-new-user'])}>
              New to Dragonfruit?&nbsp;
              <Link to="/signup" className={styles['new-user']}>
                Create an account.
              </Link>
            </div>
            <div className={classNames(styles['footer-forgot-password'])}>
              <Link to="/login/forgot_password" className={styles['new-user']}>
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
