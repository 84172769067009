import { ReactComponent as InfoIcon } from '@/assets/info.svg';
import { dispatchWithFeedback } from '@/utils/utils';
import Icon from '@ant-design/icons';
import { Button, Input, notification, Tooltip } from 'antd';
import classnames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';
import styles from './style.less';

// @ts-expect-error
@connect(({ locations, loading, search }) => ({
  locations,
  loading,
  search,
}))
class RemoteServerCredentials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      edit: this.props.channelGroup.ChannelGroupID === 0,
      channelGroup: this.props.channelGroup,
      channelGroupID: this.props.channelGroup.ChannelGroupID,
      serverName: this.props.channelGroup.Name,
      username: this.props.channelGroup.Username,
      password: this.props.channelGroup.Password,
      ipAddress: this.props.channelGroup.IPAddress,
      port: this.props.channelGroup.Port,
      channelStatus: this.props.channelGroup.FuncStatus,
      channelStatusMsg: this.props.channelGroup.FuncStatusMsg,
      lastStatusUpdateTime: this.getStatusChangedAtFormattedTime(
        this.props.channelGroup.FuncStatusChangedAt,
      ),

      savedInMemory: {
        name: this.props.channelGroup.Name,
        username: this.props.channelGroup.Username,
        password: this.props.channelGroup.Password,
        ip_address: this.props.channelGroup.IPAddress,
        port: this.props.channelGroup.Port,
      },
      showFuncStatusVerboseMsg: false,
    };
  }

  getStatusChangedAtFormattedTime = (changedAt) =>
    moment(changedAt).local().format('MMM DD hh:mm A');

  setStorageServer = () => {
    if (this.state.serverName === '') {
      notification.open({
        message: 'Please enter a Storage server name',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }

    this.setState({
      savingStorageServer: true,
    });

    let storageServerCred = {};
    storageServerCred.name = this.state.serverName;
    storageServerCred.username = this.state.username;
    storageServerCred.password = this.state.password;
    storageServerCred.ip_address = this.state.ipAddress;
    storageServerCred.port = this.state.port;

    if (this.state.channelGroupID === 0) {
      dispatchWithFeedback(this.props.dispatch, 'Adding storage server', {
        type: 'locations/addStorageServer',
        locationID: this.props.locationID,
        payload: storageServerCred,
      }).then((data) => {
        this.setState({
          channelGroupID: data.ChannelGroupID,
          edit: false,
          savedInMemory: storageServerCred,
        });
        this.props.savedNewChannelGroup(data);
      });
    } else {
      dispatchWithFeedback(this.props.dispatch, 'Updating storage server', {
        type: 'locations/updateStorageServer',
        locationID: this.props.locationID,
        channelGroupID: this.state.channelGroupID,
        payload: storageServerCred,
      }).then((data) => {
        this.setState({
          channelGroupID: data.ChannelGroupID,
          edit: false,
          savedInMemory: storageServerCred,
        });
      });
    }
    this.props.setRefreshChannelGroups(true);
  };

  toggleShowVersboseStatusMsg = () => {
    this.setState({
      showFuncStatusVerboseMsg: !this.state.showFuncStatusVerboseMsg,
    });
  };

  enableEdit(value) {
    if (!value) {
      if (this.state.channelGroupID > 0) {
        this.setState({
          serverName: this.state.savedInMemory.name,
          username: this.state.savedInMemory.username,
          password: this.state.savedInMemory.password,
          ipAddress: this.state.savedInMemory.ip_address,
          port: this.state.savedInMemory.port,
        });
      } else {
        this.props.removeNewServer();
      }
    }

    this.setState({
      edit: value,
    });

    this.props.setRefreshChannelGroups(!value);
  }

  updateValue(e) {
    switch (e.target.id) {
      case 'input-serverName':
        this.setState({ serverName: e.target.value });
        break;
      case 'input-username':
        this.setState({ username: e.target.value });
        break;
      case 'input-password':
        this.setState({ password: e.target.value });
        break;
      case 'input-ipAddress':
        this.setState({ ipAddress: e.target.value });
        break;
      case 'input-port':
        this.setState({ port: e.target.value });
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div>
        <div>
          <label className={styles.label}>Storage Server Name</label>
          <Input
            id="input-serverName"
            className={styles['input-spacing']}
            placeholder="Storage Server Name"
            onChange={(value) => this.updateValue(value)}
            value={this.state.serverName}
            disabled={!this.state.edit}
            size="small"
          />
        </div>
        <div className={styles.row}>
          <div className={styles['row-element-left']}>
            <label className={styles.label}>Username</label>
            <Input
              id="input-username"
              className={styles['input-spacing']}
              placeholder="Username"
              onChange={(value) => this.updateValue(value)}
              value={this.state.username}
              disabled={!this.state.edit}
              size="small"
            />
          </div>
          <div className={styles['row-element-right']}>
            <label className={styles.label}>Password</label>
            <Input.Password
              id="input-password"
              className={styles['input-spacing']}
              placeholder="••••••••"
              onChange={(value) => this.updateValue(value)}
              disabled={!this.state.edit}
              size="small"
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles['row-element-left']}>
            <label className={styles.label}>IP Address</label>
            <Input
              id="input-ipAddress"
              className={styles['input-spacing']}
              placeholder="xxx.xxx.xxx.xxx"
              onChange={(value) => this.updateValue(value)}
              value={this.state.ipAddress}
              disabled={!this.state.edit}
              size="small"
            />
          </div>
          <div className={styles['row-element-right']}>
            <label className={styles.label}>Port</label>
            <Input
              id="input-port"
              className={styles['input-spacing']}
              placeholder="80"
              onChange={(value) => this.updateValue(value)}
              value={this.state.port}
              disabled={!this.state.edit}
              size="small"
            />
          </div>
        </div>
        <div>
          <div className={styles.row}>
            <div className={styles['row-element-left']}>
              {this.state.ChannelGroupID === 0 || this.state.edit ? (
                <div className={styles.row}>
                  <div className={styles['button-horizontal-spacing']}>
                    <Button
                      className={styles['button-font-small']}
                      type="primary"
                      size="small"
                      onClick={() => this.setStorageServer()}>
                      Save
                    </Button>
                  </div>
                  <Button
                    className={styles['button-font-small']}
                    size="small"
                    onClick={() => this.enableEdit(false)}>
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button
                  className={styles['button-font-small']}
                  type="primary"
                  size="small"
                  onClick={() => this.enableEdit(true)}>
                  Edit
                </Button>
              )}
            </div>
            {this.state.ChannelGroupID !== 0 &&
            this.state.channelStatus !== null ? (
              <div className={styles['row-element-right-right-alinged']}>
                <div
                  className={classnames(
                    styles.status,
                    this.state.channelStatus === 0
                      ? styles['status-connected']
                      : styles['status-disconnected'],
                    styles.clickable,
                  )}
                  onClick={() => this.toggleShowVersboseStatusMsg()}>
                  {this.state.channelStatus === 0 ? 'Connected' : 'Failed'}
                  &nbsp;
                  <Tooltip
                    arrowPointAtCenter
                    placement="top"
                    title="Click to see details">
                    <Icon component={InfoIcon} />
                  </Tooltip>
                </div>
                <span className={styles['last-update-time']}>
                  Last updated {this.state.lastStatusUpdateTime}
                </span>
              </div>
            ) : (
              <div
                className={classnames(
                  styles['last-update-time'],
                  styles['row-element-right-right-alinged'],
                )}>
                No connection updates available{' '}
              </div>
            )}
          </div>
          <div className={styles.row}>
            <div
              className={classnames(
                styles.status,
                this.state.channelStatus === 0
                  ? styles['status-connected']
                  : styles['status-disconnected'],
              )}>
              {this.state.showFuncStatusVerboseMsg ? (
                <span className={styles['last-update-time']}>
                  Last message:{' '}
                </span>
              ) : null}
              {this.state.showFuncStatusVerboseMsg
                ? this.state.channelStatusMsg === ''
                  ? 'No status update received from client'
                  : this.state.channelStatusMsg
                : ' '}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RemoteServerCredentials;
