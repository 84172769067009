/* eslint-disable prefer-template */

import { Select } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';

import { validateTime } from '@/components/DatepickerDF';
import styles from './style.less';

const getTimeOptionValue = ({ hr = 0, min = 0, sec = 0 }): number => {
  return 60 * 60 * hr + 60 * min + sec;
};

const getTimeValue = ({ hr = 0, min = 0, sec = 0 }) => {
  return `${hr >= 10 ? hr : '0' + hr}:${min >= 10 ? min : '0' + min}:${
    sec >= 10 ? sec : '0' + sec
  }`;
};

const getTimeOptionTitle = ({
  hr = 0,
  min = 0,
  sec = 0,
  disabled = false,
}): React.ReactElement => {
  return (
    <span>
      {`${hr >= 10 ? hr : '0' + hr}:${min >= 10 ? min : '0' + min}`}
      <span style={{ color: `${disabled ? '' : '#8E8E95'}` }}>{`:${
        sec >= 10 ? sec : '0' + sec
      }`}</span>
    </span>
  );
};

type TIME_OPTION = {
  hr: number;
  min: number;
  sec: number;
  disabled: boolean;
  value: number;
  title: React.ReactElement;
};

type MyState = {
  time_options: Record<number, TIME_OPTION>;
  seleted_option: number;
};
type MyProps = {
  timeDropDownOpen: boolean;
  value: string;
  disablingValue: number;
  disableBefore?: boolean;
  onChange: (_val: TIME_OPTION) => void;
};

class TimeDropDown extends Component<MyProps, MyState> {
  static defaultProps = {
    timeDropDownOpen: false,
    value: '00:00:00',
    disablingValue: -1,
    disableBefore: true,
    onChange: () => {},
  };

  constructor(props: MyProps) {
    super(props);
    this.state = {
      time_options: {},
      seleted_option: 0,
    };
  }

  componentDidMount() {
    this.setTimeOptions();
    this.setSelectedOption();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (_.get(prevProps, 'value', null) !== _.get(this.props, 'value', null)) {
      this.setSelectedOption();
    }

    if (prevProps.disablingValue !== this.props.disablingValue) {
      this.setTimeOptions();
    }
  }

  setSelectedOption() {
    const _value = _.get(this.props, 'value', null);
    const _seleted_option = _.get(
      this.state.time_options,
      [this.state.seleted_option],
      { hr: 0, min: 0, sec: 0 },
    );
    if (
      _value &&
      getTimeValue(_seleted_option) !== _value &&
      _.get(_value, 'length', 0) === 8 &&
      validateTime(_value)
    ) {
      const opt_arr = _value.split(':').map((t: any) => parseInt(t));
      const _option_value = getTimeOptionValue({
        hr: opt_arr[0],
        min: opt_arr[1],
        sec: opt_arr[2],
      });
      this.setState({ seleted_option: _option_value });
    }
  }

  setTimeOptions() {
    const { disablingValue, disableBefore } = this.props;
    const time_options: Record<number, TIME_OPTION> = {};

    for (let i = 0; i < 24; i += 1) {
      for (let j = 0; j < 59; j += 10) {
        const opt: TIME_OPTION = {
          hr: i,
          min: j,
          sec: 0,
          disabled: false,
          value: 0,
          title: <span>00:00:00</span>,
        };
        opt.value = getTimeOptionValue(opt);
        opt.disabled = disableBefore
          ? opt.value <= disablingValue
          : opt.value > disablingValue;
        opt.title = getTimeOptionTitle(opt);
        time_options[opt.value] = opt;
      }
    }

    this.setState({ time_options });
  }

  render() {
    const { time_options, seleted_option } = this.state;
    const { timeDropDownOpen } = this.props;
    return (
      <Select
        className={styles['time-dropdown-box']}
        // popupClassName={styles['time-dropdown-overlay']}
        showArrow={false}
        open={timeDropDownOpen}
        value={seleted_option}
        onChange={(val: number) => {
          this.setState({ seleted_option: val }, () => {
            this.props.onChange(time_options[val]);
          });
        }}>
        {Object.keys(time_options).map((key, idx) => {
          const opt = _.get(time_options, [key], null);
          if (opt === null) return null;
          return (
            <Select.Option
              key={`time-option-${idx}`}
              className={styles['time-option']}
              disabled={_.get(opt, 'disabled', false)}
              value={_.get(opt, 'value', 0)}>
              <div>{_.get(opt, 'title', '')}</div>
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}

export default TimeDropDown;
