/**
 * @generated SignedSource<<6531e91cd28a7b4ad0cf805f9e2ec051>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, FragmentRefs } from 'relay-runtime';
export type ScenesDataPaginationQuery$variables = {
  app_id?: number | null | undefined;
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  customer_id?: number | null | undefined;
  id: string;
  only_favorites?: boolean | null | undefined;
};
export type ScenesDataPaginationQuery$data = {
  readonly node:
    | {
        readonly ' $fragmentSpreads': FragmentRefs<'ScenesDataFragment'>;
      }
    | null
    | undefined;
};
export type ScenesDataPaginationQuery = {
  response: ScenesDataPaginationQuery$data;
  variables: ScenesDataPaginationQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'app_id',
    },
    v1 = {
      defaultValue: 10,
      kind: 'LocalArgument',
      name: 'count',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'cursor',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'customer_id',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v5 = {
      defaultValue: false,
      kind: 'LocalArgument',
      name: 'only_favorites',
    },
    v6 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v9 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'cursor',
      },
      {
        kind: 'Variable',
        name: 'appId',
        variableName: 'app_id',
      },
      {
        kind: 'Variable',
        name: 'customerId',
        variableName: 'customer_id',
      },
      {
        fields: [
          {
            kind: 'Variable',
            name: 'onlyFavorites',
            variableName: 'only_favorites',
          },
        ],
        kind: 'ObjectValue',
        name: 'filters',
      },
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'count',
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'ScenesDataPaginationQuery',
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              args: [
                {
                  kind: 'Variable',
                  name: 'app_id',
                  variableName: 'app_id',
                },
                {
                  kind: 'Variable',
                  name: 'count',
                  variableName: 'count',
                },
                {
                  kind: 'Variable',
                  name: 'cursor',
                  variableName: 'cursor',
                },
                {
                  kind: 'Variable',
                  name: 'customer_id',
                  variableName: 'customer_id',
                },
                {
                  kind: 'Variable',
                  name: 'only_favorites',
                  variableName: 'only_favorites',
                },
              ],
              kind: 'FragmentSpread',
              name: 'ScenesDataFragment',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v5 /*: any*/,
        v4 /*: any*/,
      ],
      kind: 'Operation',
      name: 'ScenesDataPaginationQuery',
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            v7 /*: any*/,
            v8 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: v9 /*: any*/,
                  concreteType: 'VMSPlusRecentChannelsConnection',
                  kind: 'LinkedField',
                  name: 'scenes',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'VMSPlusRecentChannelsEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'VMSPlusRecentChannels',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v8 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'name',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'isFavorite',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'channelIDs',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'timestamp',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'anchorTimeStamp',
                              storageKey: null,
                            },
                            v7 /*: any*/,
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'cursor',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'PageInfo',
                      kind: 'LinkedField',
                      name: 'pageInfo',
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'startCursor',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'endCursor',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'hasNextPage',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'hasPreviousPage',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: v9 /*: any*/,
                  filters: ['filters', 'appId', 'customerId'],
                  handle: 'connection',
                  key: 'VMSPlusConfig_scenes',
                  kind: 'LinkedHandle',
                  name: 'scenes',
                },
              ],
              type: 'VMSPlusScenesData',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '2e8d07db3ade75e6e55df5dccbd0a1f2',
      id: null,
      metadata: {},
      name: 'ScenesDataPaginationQuery',
      operationKind: 'query',
      text: 'query ScenesDataPaginationQuery(\n  $app_id: Int\n  $count: Int = 10\n  $cursor: String\n  $customer_id: Int\n  $only_favorites: Boolean = false\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ScenesDataFragment_279g7K\n    id\n  }\n}\n\nfragment RecentChannelsTileFragment on VMSPlusRecentChannels {\n  id\n  name\n  isFavorite\n  channelIDs\n  timestamp\n  anchorTimeStamp\n}\n\nfragment ScenesDataFragment_279g7K on VMSPlusScenesData {\n  scenes(first: $count, after: $cursor, filters: {onlyFavorites: $only_favorites}, appId: $app_id, customerId: $customer_id) {\n    edges {\n      node {\n        id\n        ...RecentChannelsTileFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n',
    },
  };
})();

(node as any).hash = '1a4d0c6d597a5bde6ca61eca01be30ea';

export default node;
