import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import Icon from '@ant-design/icons';
import { Checkbox, Form, Input, Modal } from 'antd';

import { ReactComponent as AttachementIcon } from '@/assets/attachmentprimary.svg';
import DateRangeText from '@/components/DateRangeText';
import Image from '@/components/Image';
import styles from './style.less';

// @ts-expect-error
@connect(({ loading, investigation_events }) => ({
  investigation_events,
  loadingReport:
    loading.effects['investigations/createReport'] ||
    loading.effects['investigations/updateReport'],
}))
class CreateReport extends React.Component {
  state = {
    showModal: false,
    reportItems: { eventID: [], attachmentID: [], summaryID: [] },
  };

  createReportFrom = React.createRef();

  toggleReportModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  addToReport(_e, item, type) {
    let { reportItems } = this.state;
    switch (type) {
      case 'EVENT':
        if (reportItems.eventID.includes(item)) {
          _.remove(reportItems.eventID, (val) => val === item);
        } else {
          reportItems.eventID.push(item);
        }
        break;

      case 'SUMMARY':
        if (reportItems.summaryID.includes(item)) {
          _.remove(reportItems.summaryID, (val) => val === item);
        } else {
          reportItems.summaryID.push(item);
        }
        break;

      case 'ATTACHMENT':
        if (reportItems.attachmentID.includes(item)) {
          _.remove(reportItems.attachmentID, (val) => val === item);
        } else {
          reportItems.attachmentID.push(item);
        }
        break;
      default:
        type = null;
    }
    this.setState({ reportItems });
  }

  createOrUpdateInvestigationReport() {
    const { dispatch, investigation, report } = this.props;
    this.createReportFrom.current.validateFields().then(
      (values) => {
        values.reportItems = this.state.reportItems;
        if (report) {
          dispatch({
            type: 'investigations/updateReport',
            investigationID: investigation.InvestigationID,
            reportID: report.InvestigationReportID,
            payload: values,
          }).then(() => this.toggleReportModal());
        } else {
          dispatch({
            type: 'investigations/createReport',
            investigation,
            investigationID: investigation.InvestigationID,
            payload: values,
          }).then((_response) => {
            this.toggleReportModal();
            if (this.createReportFrom.current) {
              this.createReportFrom.current.resetFields();
            }
          });
        }
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const {
      children,
      loadingReport,
      report,
      investigation,
      investigation_events,
      onlyItems = false,
      noItems = false,
    } = this.props;
    return (
      <>
        <Modal
          width={800}
          height={800}
          title={report ? 'Update Report' : 'New Report'}
          wrapClassName="df-update-create-modal"
          visible={this.state.showModal}
          onOk={(e) => this.createOrUpdateInvestigationReport(e)}
          confirmLoading={loadingReport}
          onCancel={() => this.toggleReportModal()}>
          <Form
            ref={this.createReportFrom}
            layout="vertical"
            requiredMark={false}
            initialValues={{ name: _.get(report, 'Name') }}
            onFinish={(e) => this.createOrUpdateInvestigationReport(e)}>
            {!onlyItems && (
              <Form.Item
                label="Report Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the name of the report',
                  },
                ]}>
                <Input autoFocus placeholder="Report Title" />
              </Form.Item>
            )}
            {!onlyItems && (
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: false,
                    message: 'Please enter the description of the report',
                  },
                ]}>
                <Input.TextArea rows={4} placeholder="Report Description" />
              </Form.Item>
            )}
            {!noItems && (
              <div style={{ overflow: 'auto' }}>
                <Form.Item name="reportItems">
                  <>
                    {_.get(investigation_events, 'all.events', []).map(
                      (event, i) => (
                        <div
                          key={i}
                          className={classnames(
                            styles['event-tile-container'],
                            this.state.reportItems.eventID.includes(
                              event.InvestigationEventID,
                            )
                              ? styles.active
                              : '',
                          )}
                          onClick={(e) =>
                            this.addToReport(
                              e,
                              event.InvestigationEventID,
                              'EVENT',
                            )
                          }>
                          <div className={styles['event-thumbnail']}>
                            <div className={styles['event-time']}>
                              <DateRangeText
                                start={event.EventStart}
                                end={event.EventEnd}
                              />
                            </div>
                            <Image
                              src={
                                _.get(event, 'Media[0].Thumbnail.SignedUrl') ||
                                _.get(
                                  event,
                                  'LatestMedia[0].Thumbnail.SignedUrl',
                                )
                              }
                              height={130}
                              width={230}
                            />
                            <Checkbox
                              value={event.InvestigationEventID}
                              checked={this.state.reportItems.eventID.includes(
                                event.InvestigationEventID,
                              )}
                              className={styles['report-checkbox-position']}
                            />
                          </div>
                          <div className={styles['event-tile-footer']}>
                            <div
                              className={styles['event-header-text']}
                              title={`${_.get(
                                event,
                                'Channel.Project.Name',
                              )} > ${_.get(
                                event,
                                'Channel.ChannelGroup.Name',
                              )}`}>
                              {`${_.get(
                                event,
                                'Channel.Project.Name',
                              )} > ${_.get(
                                event,
                                'Channel.ChannelGroup.Name',
                              )}`}
                            </div>
                            <div
                              className={styles['event-name-text']}
                              title={event.Name}>
                              {event.Name}
                            </div>
                          </div>
                        </div>
                      ),
                    )}
                    {_.get(investigation_events, 'all.events', []).map(
                      (event, i) => {
                        switch (event.SummaryIndexStatus) {
                          case 'SUCCESS': {
                            const sr_filter_null_arr = _.get(
                              event,
                              'SummarizationRequests',
                              [],
                            ).filter((e) => _.get(e, 'Filters', {}) == null);
                            return _.get(sr_filter_null_arr, 'length', 0) > 0 &&
                              _.get(
                                sr_filter_null_arr,
                                '[0].SummarizationRequestID',
                                null,
                              ) ? (
                              <div
                                key={i}
                                className={classnames(
                                  styles['event-tile-container'],
                                  this.state.reportItems.summaryID.includes(
                                    event.InvestigationEventID,
                                  )
                                    ? styles.active
                                    : '',
                                )}
                                onClick={(e) =>
                                  this.addToReport(
                                    e,
                                    _.get(
                                      sr_filter_null_arr,
                                      '[0].SummarizationRequestID',
                                      null,
                                    ),
                                    'SUMMARY',
                                  )
                                }>
                                <div className={styles['event-thumbnail']}>
                                  <div className={styles['event-time']}>
                                    <DateRangeText
                                      start={event.EventStart}
                                      end={event.EventEnd}
                                    />
                                  </div>
                                  <Image
                                    src={
                                      _.get(
                                        event,
                                        'Media[0].Thumbnail.SignedUrl',
                                      ) ||
                                      _.get(
                                        event,
                                        'LatestMedia[0].Thumbnail.SignedUrl',
                                      )
                                    }
                                    height={130}
                                    width={230}
                                  />
                                  <Checkbox
                                    value={_.get(
                                      sr_filter_null_arr,
                                      '[0].SummarizationRequestID',
                                      null,
                                    )}
                                    checked={this.state.reportItems.summaryID.includes(
                                      _.get(
                                        sr_filter_null_arr,
                                        '[0].SummarizationRequestID',
                                        null,
                                      ),
                                    )}
                                    className={
                                      styles['report-checkbox-position']
                                    }
                                  />
                                </div>
                                <div className={styles['event-tile-footer']}>
                                  <div
                                    className={styles['event-header-text']}
                                    title={`${_.get(
                                      event,
                                      'Channel.Project.Name',
                                    )} > ${_.get(
                                      event,
                                      'Channel.ChannelGroup.Name',
                                    )}`}>
                                    {`${_.get(
                                      event,
                                      'Channel.Project.Name',
                                    )} > ${_.get(
                                      event,
                                      'Channel.ChannelGroup.Name',
                                    )}`}
                                  </div>
                                  <div
                                    className={styles['event-name-text']}
                                    title={event.SummaryName}>
                                    {event.SummaryName}
                                  </div>
                                </div>
                              </div>
                            ) : null;
                          }
                          default:
                            return null;
                        }
                      },
                    )}
                    {_.get(investigation, 'InvestigationAttachments', []).map(
                      (attachment, j) => (
                        <div
                          key={j}
                          className={classnames(
                            styles['view-card'],
                            this.state.reportItems.attachmentID.includes(
                              attachment.InvestigationAttachmentID,
                            )
                              ? styles.active
                              : '',
                          )}
                          onClick={(e) =>
                            this.addToReport(
                              e,
                              attachment.InvestigationAttachmentID,
                              'ATTACHMENT',
                            )
                          }>
                          <div className={styles['view-card-title']}>
                            <Icon
                              className={styles['view-card-title-icon']}
                              component={AttachementIcon}
                            />{' '}
                            <Checkbox
                              value={attachment.InvestigationAttachmentID}
                              checked={this.state.reportItems.attachmentID.includes(
                                attachment.InvestigationAttachmentID,
                              )}
                              className={styles['report-checkbox-position']}
                            />
                          </div>
                          <div className={styles['view-card-body']} />
                          <div className={styles['view-card-body']}>
                            {attachment.Name}
                          </div>{' '}
                        </div>
                      ),
                    )}
                  </>
                </Form.Item>
              </div>
            )}
          </Form>
        </Modal>
        <span onClick={() => this.toggleReportModal()}>{children}</span>
      </>
    );
  }
}
export default CreateReport;
