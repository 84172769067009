import LoadingSpinner from '@/components/LoadingSpinner';
import _ from 'lodash';
import { Suspense, useCallback, useEffect, useRef } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { ScenesData } from './ScenesData';
import { SceneTimeline } from './SceneTimeline';
import { useVMSPlusContext } from './VMSPlusContext';
import { useRecentChannelsTileAddActions } from './VMSPlusMutations';
import type { RecentChannelsTileFragment$data } from './__generated__/RecentChannelsTileFragment.graphql';
import type { ScenesDataFragment$key } from './__generated__/ScenesDataFragment.graphql';
import type { ScenesQuery as ScenesQueryType } from './__generated__/ScenesQuery.graphql';

const ScenesQuery = graphql`
  query ScenesQuery($app_id: Int!, $customer_id: Int!) {
    vmsPlusConfig(appId: $app_id, customerId: $customer_id) {
      scenesData {
        __id
        ...ScenesDataFragment
          @arguments(
            app_id: $app_id
            customer_id: $customer_id
            only_favorites: false
          )
      }
    }
  }
`;

const ScenesContent = () => {
  const {
    appId,
    customerId,
    selectedChannels,
    newChannelsSelected,
    isTileViewingMode,
    isTimelineViewingMode,
    selectedTileData,
  } = useVMSPlusContext();
  const timelinePlayerRef = useRef();

  const queryData = useLazyLoadQuery<ScenesQueryType>(ScenesQuery, {
    app_id: appId,
    customer_id: customerId,
  });

  const recentTilesConnectionData = {
    id: queryData.vmsPlusConfig?.scenesData?.__id as string,
    key: 'VMSPlusConfig_scenes',
  };

  const { addChannelsTile } = useRecentChannelsTileAddActions({
    appId,
    recentTilesConnectionData,
  });

  const addChannelsTileDebounced = useCallback(
    _.debounce(addChannelsTile, 15000), // A 15 seconds of debounce is used to avoid adding multiple clusters in a short period of time
    [],
  );

  const getAnchorTimestamp = () => {
    // @ts-expect-error
    let timestamp = timelinePlayerRef.current?.state.currentPlayTime;
    if (timestamp) {
      // db doesn't like floats...
      timestamp = Math.floor(timestamp) + '';
    }

    return timestamp;
  };

  useEffect(() => {
    if (!isTimelineViewingMode) {
      addChannelsTileDebounced.cancel();
    }

    if (!!selectedChannels?.length && newChannelsSelected) {
      addChannelsTileDebounced(selectedChannels, getAnchorTimestamp());
    }
  }, [
    newChannelsSelected,
    selectedChannels,
    addChannelsTileDebounced,
    isTimelineViewingMode,
  ]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        marginRight: 16,
      }}>
      {isTimelineViewingMode ? (
        <SceneTimeline
          recentTilesConnectionData={recentTilesConnectionData}
          timelinePlayerRef={timelinePlayerRef}
          selectedTileData={selectedTileData as RecentChannelsTileFragment$data}
          isTileViewingMode={isTileViewingMode}
        />
      ) : (
        <ScenesData
          customerID={customerId}
          scenesDataKey={
            queryData.vmsPlusConfig?.scenesData as ScenesDataFragment$key
          }
          scenesDataConnectionKey={
            queryData.vmsPlusConfig?.scenesData?.__id as string
          }
        />
      )}
    </div>
  );
};

const Scenes = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ScenesContent />
    </Suspense>
  );
};

export { Scenes };
