import { ARCHIVE_REASONS } from '@/pages/apps/app/AlarmApp/constants';
import { Button, Form, Input, Modal, Select } from 'antd';
import { ReactElement, useState } from 'react';
import { useSelector } from 'umi';

type Props = {
  onArchive?: (reason: string) => any;
  onUnarchive?: () => any;
  children: ReactElement | ReactElement[];
  is_archived: boolean;
};
const ArchiveIncident: React.FC<Props> = (props: Props) => {
  const { onArchive, onUnarchive, children, is_archived = false } = props;
  const [reason, setReason] = useState(ARCHIVE_REASONS[0]);
  const [customReason, setCustomReason] = useState('');
  const isLoading = useSelector((state) => {
    const loadingEffects = state.loading.effects;
    return loadingEffects['burglar_alarm/archiveIncident'];
  });

  const handleReasonChange = (value: string) => {
    setReason(value);
    setCustomReason('');
  };

  const showCommentBox = reason.indexOf('Other') > -1;

  const content = is_archived ? (
    'Are you sure you want to unarchive this incident?'
  ) : (
    <Form layout="vertical">
      <Form.Item label="Reason for Archiving">
        <Select value={reason} onChange={handleReasonChange}>
          {ARCHIVE_REASONS.map((val) => {
            return <Select.Option value={val}>{val}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      {showCommentBox ? (
        <Form.Item
          label="Please Specify"
          rules={[
            {
              required: true,
              message: 'Please enter a reason',
            },
          ]}>
          <Input
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
          />
        </Form.Item>
      ) : null}
    </Form>
  );

  const [showModal, setShowModal] = useState(false);
  function toggleOpenState() {
    setShowModal(!showModal);
  }

  return (
    <>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={
          <Button
            type="primary"
            disabled={showCommentBox && !customReason}
            loading={isLoading}
            onClick={() => {
              if (is_archived && onUnarchive) {
                onUnarchive();
              } else if (!is_archived && onArchive) {
                onArchive(reason + (customReason ? ',' + customReason : ''));
              }
              toggleOpenState();
            }}>
            {is_archived ? 'Unarchive' : 'Archive'}
          </Button>
        }
        title={`${is_archived ? 'Unarchive' : 'Archive'} Incident`}>
        {content}
      </Modal>
      <span onClick={toggleOpenState}>{children}</span>
    </>
  );
};
export default ArchiveIncident;
