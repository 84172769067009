import { getCurrentCustomerID } from '@/utils/utils';
import { ConnectionHandler, graphql, useMutation } from 'react-relay';
import { getCurrentTimestamp } from './utils';
import type { RecentChannelsTileFragment$data } from './__generated__/RecentChannelsTileFragment.graphql';
import type { VMSPlusMutations_recentChannelsTileConnectionAddd_Mutation } from './__generated__/VMSPlusMutations_recentChannelsTileConnectionAddd_Mutation.graphql';
import type { VMSPlusMutations_recentChannelsTileConnectionRemove_Mutation } from './__generated__/VMSPlusMutations_recentChannelsTileConnectionRemove_Mutation.graphql';
import type { VMSPlusMutations_recentChannelsTileRemove_Mutation } from './__generated__/VMSPlusMutations_recentChannelsTileRemove_Mutation.graphql';
import type { VMSPlusMutations_recentChannelsTileUpdate_Mutation } from './__generated__/VMSPlusMutations_recentChannelsTileUpdate_Mutation.graphql';

export const RecentChannelsTileConnectionAddMutation = graphql`
  mutation VMSPlusMutations_recentChannelsTileConnectionAddd_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $channel_ids: [String!]!
    $timestamp: String!
    $name: String
    $isFavorite: Boolean
    $anchorTimeStamp: String
    $connections: [ID!]!
  ) {
    createOrUpdateVmsPlusRecentChannels(
      input: {
        appId: $app_id
        customerId: $customer_id
        timestamp: $timestamp
        channelIDs: $channel_ids
        name: $name
        isFavorite: $isFavorite
        anchorTimeStamp: $anchorTimeStamp
      }
    ) {
      recentEdge @appendEdge(connections: $connections) {
        node {
          id
          name
          channelIDs
          timestamp
          isFavorite
          anchorTimeStamp
        }
      }
    }
  }
`;

export const RecentChannelsTileConnectionRemoveMutation = graphql`
  mutation VMSPlusMutations_recentChannelsTileConnectionRemove_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $channel_ids: [String!]!
    $timestamp: String!
    $name: String
    $isFavorite: Boolean
    $anchorTimeStamp: String
    $connections: [ID!]!
  ) {
    createOrUpdateVmsPlusRecentChannels(
      input: {
        appId: $app_id
        customerId: $customer_id
        timestamp: $timestamp
        channelIDs: $channel_ids
        name: $name
        isFavorite: $isFavorite
        anchorTimeStamp: $anchorTimeStamp
      }
    ) {
      recentEdge {
        node {
          id @deleteEdge(connections: $connections)
          name
          channelIDs
          timestamp
          isFavorite
          anchorTimeStamp
        }
      }
    }
  }
`;

export const RecentChannelsTileUpdateMutation = graphql`
  mutation VMSPlusMutations_recentChannelsTileUpdate_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $channel_ids: [String!]!
    $timestamp: String!
    $name: String
    $isFavorite: Boolean
    $anchorTimeStamp: String
  ) {
    createOrUpdateVmsPlusRecentChannels(
      input: {
        appId: $app_id
        customerId: $customer_id
        channelIDs: $channel_ids
        timestamp: $timestamp
        name: $name
        isFavorite: $isFavorite
        anchorTimeStamp: $anchorTimeStamp
      }
    ) {
      recentEdge {
        node {
          id
          name
          channelIDs
          timestamp
          isFavorite
          anchorTimeStamp
        }
      }
    }
  }
`;

export const RecentChannelsTilesRemoveMutation = graphql`
  mutation VMSPlusMutations_recentChannelsTileRemove_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $id: String!
    $connections: [ID!]!
  ) {
    deleteVmsPlusRecentChannels(
      input: { appId: $app_id, customerId: $customer_id, id: $id }
    ) {
      deleted {
        id @deleteEdge(connections: $connections)
      }
    }
  }
`;

export const useRecentChannelsTileMarkingFavoriteActions = ({
  recentTile,
  recentTilesConnectionData,
  appId,
}: {
  recentTile: RecentChannelsTileFragment$data;
  recentTilesConnectionData: {
    id: string;
    key: string;
  };
  appId: number;
}) => {
  const customerId = getCurrentCustomerID();
  const [commitConnectionAddRecentChannelsTileMutation] =
    useMutation<VMSPlusMutations_recentChannelsTileConnectionAddd_Mutation>(
      RecentChannelsTileConnectionAddMutation,
    );

  const [commitConnectionRemoveRecentChannelsTileMutation] =
    useMutation<VMSPlusMutations_recentChannelsTileConnectionRemove_Mutation>(
      RecentChannelsTileConnectionRemoveMutation,
    );

  const markAsFavorite = () => {
    const connectionId = ConnectionHandler.getConnectionID(
      recentTilesConnectionData.id,
      recentTilesConnectionData.key,
      { appId, customerId, filters: { onlyFavorites: true } },
    );

    commitConnectionAddRecentChannelsTileMutation({
      variables: {
        app_id: appId,
        customer_id: customerId,
        connections: [connectionId],
        channel_ids: recentTile.channelIDs as string[],
        name: recentTile.name,
        anchorTimeStamp: recentTile.anchorTimeStamp,
        timestamp: getCurrentTimestamp() + '',
        isFavorite: true,
      },
      optimisticResponse: {
        createOrUpdateVmsPlusRecentChannels: {
          recentEdge: {
            node: {
              id: recentTile.id,
              channelIDs: recentTile.channelIDs as string[],
              name: recentTile.name,
              anchorTimeStamp: recentTile.anchorTimeStamp,
              timestamp: getCurrentTimestamp() + '',
              isFavorite: true,
            },
          },
        },
      },
    });
  };

  const unMarkAsFavorite = () => {
    const connectionId = ConnectionHandler.getConnectionID(
      recentTilesConnectionData.id,
      recentTilesConnectionData.key,
      { appId, customerId, filters: { onlyFavorites: true } },
    );

    commitConnectionRemoveRecentChannelsTileMutation({
      variables: {
        app_id: appId,
        customer_id: customerId,
        connections: [connectionId],
        channel_ids: recentTile.channelIDs as string[],
        name: recentTile.name,
        anchorTimeStamp: recentTile.anchorTimeStamp,
        timestamp: getCurrentTimestamp() + '',
        isFavorite: false,
      },
      optimisticResponse: {
        createOrUpdateVmsPlusRecentChannels: {
          recentEdge: {
            node: {
              id: recentTile.id,
              channelIDs: recentTile.channelIDs as string[],
              name: recentTile.name,
              anchorTimeStamp: recentTile.anchorTimeStamp,
              timestamp: getCurrentTimestamp() + '',
              isFavorite: false,
            },
          },
        },
      },
    });
  };

  return {
    markAsFavorite,
    unMarkAsFavorite,
  };
};

export const useRecentChannelsTileAddActions = ({
  recentTilesConnectionData,
  appId,
}: {
  recentTilesConnectionData: {
    id: string;
    key: string;
  };
  appId: number;
}) => {
  const customerId = getCurrentCustomerID();
  const [commitConnectionAddRecentChannelsTileMutation] =
    useMutation<VMSPlusMutations_recentChannelsTileConnectionAddd_Mutation>(
      RecentChannelsTileConnectionAddMutation,
    );
  const addChannelsTile = (channelIds: string[], anchorTimeStamp: string) => {
    const connectionId = ConnectionHandler.getConnectionID(
      recentTilesConnectionData.id,
      recentTilesConnectionData.key,
      { appId, customerId, filters: { onlyFavorites: false } },
    );

    commitConnectionAddRecentChannelsTileMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        channel_ids: channelIds,
        timestamp: getCurrentTimestamp() + '',
        anchorTimeStamp,
        connections: [connectionId],
      },
      optimisticResponse: {
        createOrUpdateVmsPlusRecentChannels: {
          recentEdge: {
            node: {
              id: channelIds.join('-'),
              channelIDs: channelIds,
              timestamp: getCurrentTimestamp() + '',
              anchorTimeStamp,
              isFavorite: false,
              name: null,
            },
          },
        },
      },
    });
  };

  return { addChannelsTile };
};

export const useRecentChannelsTileRemoveActions = ({
  recentTile,
  recentTilesConnectionData,
  appId,
}: {
  recentTile: RecentChannelsTileFragment$data;
  recentTilesConnectionData: {
    id: string;
    key: string;
  };
  appId: number;
}) => {
  const customerId = getCurrentCustomerID();
  const [commitRemoveRecentChannelsTileMutation] =
    useMutation<VMSPlusMutations_recentChannelsTileRemove_Mutation>(
      RecentChannelsTilesRemoveMutation,
    );

  const removeChannelsTile = () => {
    const connectionOnlyFavoritesTiles = ConnectionHandler.getConnectionID(
      recentTilesConnectionData.id,
      recentTilesConnectionData.key,
      { appId, customerId, filters: { onlyFavorites: true } },
    );

    const connectionAllTiles = ConnectionHandler.getConnectionID(
      recentTilesConnectionData.id,
      recentTilesConnectionData.key,
      { appId, customerId, filters: { onlyFavorites: false } },
    );

    commitRemoveRecentChannelsTileMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: recentTile.id,
        connections: [connectionOnlyFavoritesTiles, connectionAllTiles],
      },
      optimisticResponse: {
        deleteVmsPlusRecentChannels: {
          deleted: {
            id: recentTile.id,
          },
        },
      },
    });
  };

  return { removeChannelsTile };
};

export const useRecentChannelsTileUpdateActions = ({
  recentTile,
  appId,
}: {
  recentTile: RecentChannelsTileFragment$data;
  appId: number;
}) => {
  const [commitUpdateRecentChannelsTileMutation] =
    useMutation<VMSPlusMutations_recentChannelsTileUpdate_Mutation>(
      RecentChannelsTileUpdateMutation,
    );

  const updateTileName = (name: string) => {
    commitUpdateRecentChannelsTileMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        channel_ids: recentTile.channelIDs as string[],
        name,
        anchorTimeStamp: recentTile.anchorTimeStamp,
        timestamp: getCurrentTimestamp() + '',
        isFavorite: recentTile.isFavorite,
      },
      optimisticResponse: {
        createOrUpdateVmsPlusRecentChannels: {
          recentEdge: {
            node: {
              id: recentTile.id,
              channelIDs: recentTile.channelIDs as string[],
              name,
              anchorTimeStamp: recentTile.anchorTimeStamp,
              timestamp: getCurrentTimestamp() + '',
              isFavorite: recentTile.isFavorite,
            },
          },
        },
      },
    });
  };

  return { updateTileName };
};
