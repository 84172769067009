import { loadMap } from '@/pages/locations/location/location-map/utils';
import MapCoverage from '@/pages/locations/location/view-coverage/mapcoverage';
import type { LocationMap } from '@/types/types';
import { Modal } from 'antd';
import * as PIXI from 'pixi.js';
import { Component } from 'react';
import styles from './style.less';

PIXI.utils.skipHello();

type State = {
  showModal: boolean;
};

type Props = {
  locationMap: LocationMap;
  locationID: any;
  // injected by @connect
  dispatch: (action: any) => any;
  loading: any;
};

class ViewMapCoverage extends Component<Props, State> {
  floorMapPixi: PIXI.Application;
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
    };

    // resizing handled by DualViewportMap, the .resizeTo property is set to the parent div.
    this.floorMapPixi = new PIXI.Application({
      sharedLoader: true,
      antialias: true,
      backgroundAlpha: 0,
    });
  }

  componentWillUnmount() {
    this.floorMapPixi.destroy();
  }

  loadImages() {
    const { locationMap } = this.props;
    if (locationMap) {
      loadMap(locationMap);
    }
  }

  toggleModal = () => {
    const { showModal } = this.state;
    if (!showModal) {
      this.loadImages();
    }
    this.setState({ showModal: !showModal });
  };

  render() {
    const { showModal } = this.state;
    const { locationMap, children } = this.props;
    const mapName = locationMap ? locationMap.Name : '';

    return (
      <>
        {/* coverage modal */}
        <Modal
          style={{ top: 10 }}
          width={1200}
          title={
            <div>
              <div>Map Coverage</div>
              <div className={styles['modal-header-help-text']}>{mapName}</div>
            </div>
          }
          visible={showModal}
          onCancel={() => this.toggleModal()}
          closable={true}
          maskClosable={true}
          keyboard={false}
          footer={null}>
          <div style={{ height: 'calc(100vh - 300px)' }}>
            <MapCoverage
              locationID={this.props.locationID}
              locationMap={this.props.locationMap}
              floorMapPixi={this.floorMapPixi}
            />
          </div>
        </Modal>

        {/* button that shows modal */}
        <span
          onClick={() => this.toggleModal()}
          className={styles['toggle-button']}>
          {children}
        </span>
      </>
    );
  }
}

export default ViewMapCoverage;
