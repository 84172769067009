import { dispatchWithFeedback } from '@/utils/utils';
import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, history } from 'umi';

// @ts-expect-error
@connect(({ loading }) => ({
  loadingInsightGroup:
    loading.effects['insights/createInsightGroup'] ||
    loading.effects['insights/updateInsightGroup'],
}))
class CreateInsightGroup extends React.Component {
  state = {
    showModal: false,
  };

  createInsightGroupForm = React.createRef();

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  createOrUpdateLocation() {
    const { dispatch, insightGroup, parentInsightGroupID } = this.props;
    this.createInsightGroupForm.current.validateFields().then(
      (values) => {
        if (insightGroup) {
          insightGroup.Name = values.name;
          dispatch({
            type: 'insights/updateInsightGroup',
            insightGroupID: insightGroup.InsightGroupID,
            payload: values,
          }).then(() => this.toggleModal());
        } else {
          if (parentInsightGroupID !== undefined) {
            values.parent_id = parentInsightGroupID;
          }
          dispatchWithFeedback(
            dispatch,
            'insight_group creation',
            {
              type: 'insights/createInsightGroup',
              payload: values,
            },
            true,
          ).then((res) => {
            this.toggleModal();
            if (this.createInsightGroupForm.current) {
              this.createInsightGroupForm.current.resetFields();
            }
            const insightGroupID = _.get(res, 'InsightGroupID', null);
            if (insightGroupID) {
              history.push(`/insight-groups/${insightGroupID}`);
            }
          });
        }
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const { children, loadingInsightGroup, insightGroup } = this.props;
    return (
      <>
        <Modal
          width={400}
          title={insightGroup ? 'Update Insight Group' : 'Create Insight Group'}
          visible={this.state.showModal}
          onOk={(e) => this.createOrUpdateLocation(e)}
          confirmLoading={loadingInsightGroup}
          onCancel={() => this.toggleModal()}>
          <Form
            ref={this.createInsightGroupForm}
            layout="vertical"
            requiredMark={false}
            initialValues={{ name: _.get(insightGroup, 'Name') }}
            onFinish={(e) => this.createOrUpdateLocation(e)}>
            <Form.Item
              label="Insight Group Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of the insight group',
                },
              ]}>
              <Input autoFocus />
            </Form.Item>
          </Form>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default CreateInsightGroup;
