import React, { Component } from 'react';
import { connect } from 'umi';
import { Modal, Button } from 'antd';

import Settings from '@/components/Settings';
import ArchiveReportTab from './components/archive-report-tab';
import NameDescriptionTab from './components/name-description-tab';

// @ts-expect-error
@connect(({ locations, search, investigations }) => ({
  locations,
  search,
  investigations,
}))
class ReportSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({ showModal: this.props.showModal });
    }
  }

  handleCancel = () => {
    this.setState({ showModal: this.props.showModal });
    this.props.onModalClosed();
  };

  showReportSettingsModal() {
    this.setState({
      showModal: true,
    });
  }

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  render() {
    const { children } = this.props;
    let { investigationID, reportID } = this.props.match.params;
    investigationID = +investigationID;
    reportID = +reportID;
    const { investigations } = this.props;
    const report = investigations.reportsByID[reportID];

    const reportSetting = [
      {
        name: 'Name & Description',
        content: (
          <NameDescriptionTab
            investigationID={investigationID}
            report={report}
          />
        ),
      },
      {
        name: 'Archive Report',
        content: (
          <ArchiveReportTab investigationID={investigationID} report={report} />
        ),
      },
    ];

    return (
      <>
        <Modal
          title="Report Settings"
          visible={this.state.showModal}
          onCancel={this.handleCancel}
          width={800}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Close
            </Button>,
          ]}>
          <Settings settingsConfig={reportSetting} />
        </Modal>
        <span onClick={() => this.showReportSettingsModal()}>{children}</span>
      </>
    );
  }
}
export default ReportSettings;
