import React, { Component } from 'react';
import { connect } from 'umi';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import styles from './style.less';

// @ts-expect-error
@connect(({ user, loading }) => ({
  user,
  loading,
}))
class ForgotPasswordPage extends Component {
  state = { response: {} };

  handleSubmit(err, values) {
    if (!err) {
      const { dispatch } = this.props;
      dispatch({
        type: 'user/forgotPassword',
        payload: values,
      }).then((response) => this.setState({ response }));
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <ForgotPasswordForm
          loading={this.props.loading.effects['user/forgotPassword']}
          onSubmit={(err, values) => this.handleSubmit(err, values)}
          user={this.props.user}
          response={this.state.response}
        />
      </div>
    );
  }
}

export default ForgotPasswordPage;
