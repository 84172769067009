import React, { Component } from 'react';
import { Modal, Popconfirm, Button, Form, Input } from 'antd';

const modalKey = 'configModal';

class JSONConfigLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.configForm = React.createRef();
  }

  setModal(key, value) {
    this.setState({ [key]: value });
  }

  onOk = () => {
    this.configForm.current.validateFields().then(
      (values) => {
        try {
          const config = JSON.parse(values.config);
          this.props.setConfig(config);
        } catch (e) {
          window.alert(`invalid config: ${e}`);
        }
        this.setModal(modalKey, false);
      },
      (err: any) => console.log('err', err),
    );
  };

  onCancel = () => this.setModal(modalKey, false);

  onReset = () => {
    this.props.setConfig(null);
    this.setModal(modalKey, false);
  };

  render() {
    const { loadingFetchApp } = this.props;
    return (
      <>
        <Modal
          width={450}
          visible={this.state[modalKey]}
          title="Config"
          onCancel={this.onCancel}
          destroyOnClose={true}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Popconfirm
                title="Are you sure you want to remove all changes?"
                style={{ color: 'red' }}
                onConfirm={this.onReset}
                onCancel={(e) => e.preventDefault()}
                okText="Yes"
                cancelText="No">
                <Button key="delete" loading={loadingFetchApp} danger>
                  Reset
                </Button>
              </Popconfirm>
              <div>
                <Button key="cancel" onClick={this.onCancel}>
                  Cancel
                </Button>
                <Button
                  key="save"
                  loading={loadingFetchApp}
                  onClick={this.onOk}>
                  Save
                </Button>
              </div>
            </div>
          }>
          <Form ref={this.configForm} initialValues={this.props.initialValues}>
            <Form.Item name="config">
              <Input.TextArea rows={20} />
            </Form.Item>
          </Form>
        </Modal>
        <Button
          size="small"
          loading={loadingFetchApp}
          onClick={() => this.setModal(modalKey, true)}>
          Configure
        </Button>
      </>
    );
  }
}

export default JSONConfigLoader;
