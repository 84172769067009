/* eslint-disable no-nested-ternary */
import { ReactComponent as EditIcon } from '@/assets/edit.svg';
import { ReactComponent as LocationIcon } from '@/assets/location-black.svg';
import { ReactComponent as LocationMapIcon } from '@/assets/map.svg';
import LocationSettings from '@/pages/locations/location/location-settings';
import { naturalSortKey } from '@/utils/natural-sort';
import Icon, { CloseOutlined } from '@ant-design/icons';
import { Checkbox, Col, Divider, Form, Radio, Row, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import FacetContainer from '../facet-container';
import styles from './style.less';

const INITIAL_VALUE = {
  regions: [
    {
      region: 'all',
    },
  ],
};

class RegionFacet extends React.PureComponent {
  constructor(props) {
    super(props);

    const facet = _.get(props, 'facet', {});
    this.formRef = React.createRef({});
    this.state = {
      editing: true,
      facet,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.facet !== this.props.facet) {
      const { facet } = this.props;
      this.setState({ facet });
      this.formRef.current.setFieldsValue(this.props.facet);
    }
  }

  toggleEdit() {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  }

  onFormValueChange() {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        this.setState({ facet: values, errors: null });
        this.props.onFacetChange(values);
      })
      .catch((err) => {
        this.setState({ errors: err });
      });
  }

  getMap(fieldIndex) {
    return _.get(this.props.location_maps, 'all', []).find(
      (map) =>
        map.LocationMapID ===
        _.get(this.state.facet, `regions[${fieldIndex}].locationMapID`),
    );
  }

  getMapRegions(fieldIndex) {
    return naturalSortKey(
      _.get(this.getMap(fieldIndex), 'MapRegions', []),
      'Name',
    );
  }

  render() {
    const { loc, location_maps } = this.props;
    const { editing, facet, errors } = this.state;
    const facet_value = { ...INITIAL_VALUE, ...facet };
    const radioStyle = {
      display: 'block',
      height: '30px',
    };

    return (
      <FacetContainer
        title="Regions"
        editing={editing}
        disabled={_.get(this.props, 'disableFacet', false)}
        toggleEdit={() => this.toggleEdit()}>
        {editing === true && (
          <Form
            style={{ padding: '10px' }}
            initialValues={facet_value}
            name="region_facet_form"
            ref={this.formRef}
            onValuesChange={(changedValues, allValues) => {
              this.setState({ facet: allValues });
              setTimeout(() => {
                this.onFormValueChange();
              }, 100);
            }}
            autoComplete="off">
            <Form.List name="regions">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, fieldIndex) => {
                      return (
                        <div key={fieldIndex}>
                          {fieldIndex > 0 && (
                            <div
                              style={{
                                textAlign: 'center',
                                width: '100%',
                                margin: '8px 0',
                                fontSize: '11px',
                                color: '#8E8E95',
                              }}>
                              <Divider plain="true" style={{ fontSize: 12 }}>
                                <i>and</i>
                              </Divider>
                            </div>
                          )}
                          <div style={{ display: 'flex' }}>
                            <div style={{ width: '90%' }}>
                              <Form.Item
                                noStyle
                                name={[field.name, 'locationID']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please choose a location',
                                  },
                                ]}>
                                <Select
                                  style={{ marginBottom: '10px' }}
                                  placeholder="Select Location"
                                  onChange={() => {
                                    const regions =
                                      this.formRef.current.getFieldValue(
                                        'regions',
                                      );
                                    regions[fieldIndex].locationMapID = '';
                                    regions[fieldIndex].mapRegionIDs = [];
                                    this.formRef.current.setFieldsValue(
                                      regions,
                                    );
                                  }}>
                                  {_.get(loc, 'allIds', []).map(
                                    (l_id: number) => {
                                      const location = _.get(
                                        loc,
                                        `byId[${l_id}]`,
                                        null,
                                      );
                                      if (!location) return null;
                                      return (
                                        <Select.Option
                                          key={location.ID}
                                          value={location.ID}>
                                          <Icon component={LocationIcon} />
                                          &nbsp;
                                          {location.Name}
                                        </Select.Option>
                                      );
                                    },
                                  )}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                noStyle
                                name={[field.name, 'locationMapID']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please choose a map',
                                  },
                                ]}>
                                <Select
                                  style={{ marginBottom: '10px' }}
                                  placeholder="Select Map"
                                  onChange={() => {
                                    const regions =
                                      this.formRef.current.getFieldValue(
                                        'regions',
                                      );
                                    regions[fieldIndex].region = 'all';
                                    regions[fieldIndex].mapRegionIDs = [];
                                    this.formRef.current.setFieldsValue(
                                      regions,
                                    );
                                  }}>
                                  {_.get(location_maps, 'all', [])
                                    .filter(
                                      (map) =>
                                        map.ProjectID ===
                                        _.get(
                                          facet,
                                          `regions[${fieldIndex}].locationID`,
                                        ),
                                    )
                                    .map((locationMap) => (
                                      <Select.Option
                                        key={locationMap.LocationMapID}
                                        value={locationMap.LocationMapID}>
                                        <div className={styles['location-map']}>
                                          <Icon component={LocationMapIcon} />
                                          &nbsp;
                                          {locationMap.Name}
                                        </div>
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div style={{ margin: '5px 5px 5px 10px' }}>
                              <LocationSettings
                                startTabName="Maps"
                                locationID={_.get(
                                  this.state.facet,
                                  `regions[${fieldIndex}].locationID`,
                                )}>
                                <Icon
                                  style={{
                                    color: '#0045F7',
                                    cursor: 'pointer',
                                    float: 'right',
                                  }}
                                  component={EditIcon}
                                />
                              </LocationSettings>
                              {fieldIndex !== 0 && (
                                <div
                                  style={{ color: 'red', cursor: 'pointer' }}
                                  onClick={() => remove(fieldIndex)}>
                                  <CloseOutlined style={{ fontSize: '14px' }} />
                                </div>
                              )}
                            </div>
                          </div>
                          <Form.Item
                            noStyle
                            name={[field.name, 'region']}
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a region',
                              },
                            ]}>
                            <Radio.Group
                              style={{
                                fontSize: '12px',
                                width: '100%',
                              }}
                              onChange={() => {
                                const regions =
                                  this.formRef.current.getFieldValue('regions');
                                regions[fieldIndex].mapRegionIDs = [];
                                this.formRef.current.setFieldsValue(regions);
                              }}>
                              <Radio style={radioStyle} value={'all'}>
                                All
                              </Radio>
                              <Radio style={radioStyle} value={'custom'}>
                                By Region
                              </Radio>
                              {_.get(facet, `regions[${fieldIndex}].region`) ===
                              'custom' ? (
                                this.getMapRegions(fieldIndex).length > 0 ? (
                                  <div style={{ paddingLeft: '20px' }}>
                                    <Form.Item
                                      noStyle
                                      name={[field.name, 'mapRegionIDs']}
                                      rules={[
                                        {
                                          required: false,
                                          message: 'Please choose a region',
                                        },
                                        ({ getFieldValue }) => ({
                                          validator: (rule, value) => {
                                            const values =
                                              value ||
                                              getFieldValue(['mapRegionIDs']);
                                            // eslint-disable-next-line no-restricted-globals
                                            if (values.length === 0) {
                                              return Promise.reject(
                                                new Error(
                                                  'At least one region must be selected',
                                                ),
                                              );
                                            }
                                            return Promise.resolve();
                                          },
                                        }),
                                      ]}>
                                      <Checkbox.Group
                                        style={{
                                          width: '100%',
                                        }}>
                                        <Row>
                                          {this.getMapRegions(fieldIndex).map(
                                            (mapRegion) => (
                                              <Col
                                                key={mapRegion.MapRegionID}
                                                span={12}
                                                style={{ padding: '5px' }}>
                                                <Checkbox
                                                  checked={
                                                    mapRegion.MapRegionID
                                                  }
                                                  value={mapRegion.MapRegionID}>
                                                  {mapRegion.Name}
                                                </Checkbox>
                                              </Col>
                                            ),
                                          )}
                                        </Row>
                                      </Checkbox.Group>
                                    </Form.Item>
                                    {errors ? (
                                      <div
                                        style={{
                                          color: 'red',
                                          paddingTop: '2px',
                                        }}>
                                        {errors.errorFields[0].errors}
                                      </div>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div style={{ marginLeft: '25px' }}>
                                    No regions have been defined for this map.
                                  </div>
                                )
                              ) : null}
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      );
                    })}
                    <Divider
                      plain="true"
                      style={{ fontSize: 12, marginBottom: 0 }}>
                      <span onClick={() => add()} className="df-link">
                        + Another Map
                      </span>
                    </Divider>
                  </>
                );
              }}
            </Form.List>
          </Form>
        )}
      </FacetContainer>
    );
  }
}

export default RegionFacet;
