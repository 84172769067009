import React, { Component } from 'react';
import _ from 'lodash';
import { Input, Modal, Form } from 'antd';
import { connect } from 'umi';

// @ts-expect-error
@connect(({ investigations, loading }) => ({
  investigations,
  loading,
}))
class UpdateSummary extends Component {
  state = { showModal: false };

  updateSummary = React.createRef();

  handleSave = (e) => {
    const { dispatch, event, investigation } = this.props;
    e.preventDefault();
    this.updateSummary.current.validateFields().then(
      (values) => {
        dispatch({
          type: 'investigation_events/updateInvestigationEventSummaryName',
          investigationID: investigation.InvestigationID,
          eventID: event.InvestigationEventID,
          payload: values,
        }).then(() => {
          this.setState({
            showModal: false,
          });
        });
      },
      (err) => console.log('err', err),
    );
  };

  toggleupdateSummaryModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  render() {
    const { children, event } = this.props;

    return (
      <>
        <Modal
          title="Rename Summary"
          visible={this.state.showModal}
          onOk={(e) => this.handleSave(e)}
          confirmLoading={
            this.props.loading.effects[
              'investigation_events/updateInvestigationEventSummaryName'
            ]
          }
          onCancel={() => this.toggleupdateSummaryModal()}>
          <Form
            ref={this.updateSummary}
            layout="vertical"
            initialValues={{ summaryName: _.get(event, 'SummaryName') }}
            onSubmit={(e) => this.handleSave(e)}>
            <Form.Item
              label="Summary Name "
              name="summaryName"
              rules={[
                {
                  message: 'Please enter the name of summary',
                },
              ]}>
              <Input autoFocus />
            </Form.Item>
          </Form>
        </Modal>
        <span onClick={() => this.toggleupdateSummaryModal()}>{children}</span>
      </>
    );
  }
}
export default UpdateSummary;
