import ChannelSelect2 from '@/components/ChannelSelect2';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tabs,
} from 'antd';
import { connect } from 'dva';
import _ from 'lodash';
import React from 'react';

import { ChannelGroupNode, ChannelNode, LocationNode } from '@/types/location';
import { getConfigMap } from '@/utils/licenses';
import {
  dispatchWithFeedback,
  getCurrentCustomerID,
  isInternalUser,
} from '@/utils/utils';
import styles from './style.less';

const { Column } = Table;
const { Option } = Select;

// @ts-expect-error
@connect(({ accounts, locations, user, loading }) => ({
  accounts,
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  currentUser: user.currentUser,
  loadingFetchCustomerConfigs: loading.effects['accounts/fetchCustomerConfigs'],
  loadingAddConfigProfile: loading.effects['accounts/addConfigProfile'],
  loadingRemoveConfigProfile: loading.effects['accounts/removeConfigProfile'],
}))
class Licenses extends React.PureComponent {
  constructor(props) {
    super(props);
    this.licensesForm = React.createRef();
    this.removeProfile = this.removeProfile.bind(this);
    this.addConfigProfile = this.addConfigProfile.bind(this);
    this.addConfigForm = React.createRef();

    this.state = {
      licenses: {},
      skus: {},
      configMap: {},
    };
  }

  fetchCustomerConfig() {
    this.props
      .dispatch({
        type: 'accounts/fetchCustomerConfigs',
      })
      .then((response) => {
        if (response.success) {
          const customerConfigs = response.data || [];
          this.setState({ customerConfigs });
        }
      });
  }

  refreshProfileData() {
    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching license info',
      {
        type: 'accounts/fetchLicenseInfo',
      },
      true,
    ).then((data) => {
      this.licensesForm.current?.setFieldsValue(data.licenses);

      const configMap = getConfigMap(data.skus);

      this.setState({
        licenses: data.licenses,
        skus: data.skus,
        configMap,
      });
    });
  }

  componentDidMount() {
    this.refreshProfileData();
    this.props.dispatch({
      type: 'locations/fetchLocations',
    });
  }

  editProfile = (profile_details) => {
    this.addConfigForm.current?.setFieldsValue(profile_details);
  };

  removeProfile = (profile_details, e) => {
    const { dispatch } = this.props;
    e.preventDefault();
    dispatch({
      type: 'accounts/removeConfigProfile',
      payload: profile_details,
    }).then((response) => {
      if (response.success) {
        this.fetchCustomerConfig();
      }
    });
  };

  async opConfigProfile(type, msgPrefix, profile) {
    await dispatchWithFeedback(this.props.dispatch, msgPrefix, {
      type: `accounts/${type}ConfigProfile`,
      payload: profile,
    });
  }

  addConfigProfile = (e) => {
    e.preventDefault();
    this.addConfigForm.current
      .validateFields()
      .then((val) => this.opConfigProfile('add', 'Saving config', val))
      // on failure and scucess, always refresh
      .then(() => this.fetchCustomerConfig())
      .catch((err) => console.log('err', err));
  };

  async submitLicenseChanges() {
    const customerID = getCurrentCustomerID();
    await this.opConfigProfile('add', 'Saving licenses', {
      entity_type: 'Customer',
      entity_id: customerID,
      profile_type: 'licenses',
      profile_name: JSON.stringify(this.state.proposedLicenses),
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const config of _.get(this.state, 'proposedConfigs.added', [])) {
      // eslint-disable-next-line no-await-in-loop
      await this.opConfigProfile(
        'add',
        `Saving ${config.profile_type} for ${config.entity_id}`,
        config,
      );
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const config of _.get(this.state, 'proposedConfigs.deleted', [])) {
      // eslint-disable-next-line no-await-in-loop
      await this.opConfigProfile(
        'remove',
        `Removing ${config.profile_type} for ${config.entity_id}`,
        config,
      );
    }

    await this.refreshProfileData();
    await this.fetchCustomerConfig();

    this.setState({ showModal: false });
  }

  showAndConfirmLicenses(licenses, configs) {
    this.setState({
      proposedLicenses: licenses,
      proposedConfigs: configs,
      showModal: true,
    });
  }

  getConfigFromSpec(tag, op, specs, entity_type, entity_id) {
    return (specs || []).map((spec) => ({
      key: `${tag}-${spec.type}e-${entity_id}`,
      entity_type,
      entity_id,
      profile_type: spec.type,
      profile_name: spec.name,
      tag,
      op,
    }));
  }

  renderConfigsTab() {
    const revMap = {
      Channel: {},
      Project: {},
    };
    const { loc, ch_grp, ch } = this.props;
    Object.entries(this.state.licenses || {}).forEach(([tag, val]) => {
      const config = _.get(this.state.configMap, tag, {});
      _.get(val, 'entities', []).forEach((entity) => {
        if (!config.entityType) {
          return;
        }
        const list = revMap[config.entityType][entity] || [];
        list.push({ tag, ...config });
        revMap[config.entityType][entity] = list;
      });
    });

    const getNodeSpec = (
      node: LocationNode | ChannelGroupNode | ChannelNode,
    ) => {
      const spec: Record<string, any> = {
        name: node.Name,
        key: node.ID,
        tags: _.get(
          revMap,
          `${
            node instanceof LocationNode
              ? 'Project'
              : node instanceof ChannelGroupNode
              ? 'Group'
              : node instanceof ChannelNode
              ? 'Channel'
              : ''
          }[${node.ID}]`,
          [],
        ),
        type:
          node instanceof LocationNode
            ? 'Location'
            : node instanceof ChannelGroupNode
            ? 'Group'
            : node instanceof ChannelNode
            ? 'Channel'
            : '',
      };
      if (node instanceof LocationNode || node instanceof ChannelGroupNode) {
        spec['children'] = [
          ...(node.Channels || [])
            .map((id: number) => ch.byId[+id])
            .filter((n) => n)
            .map(getNodeSpec),
          ...(node.ChannelGroups || [])
            .map((id: number) => ch_grp.byId[+id])
            .filter((n) => n)
            .map(getNodeSpec),
        ];
      }
      return spec;
    };

    const dataSource = loc.allIds
      .map((id: number) => loc.byId[+id])
      .filter((n) => n)
      .map(getNodeSpec);

    return (
      <div>
        <Table
          className={styles['shubham-table-container']}
          dataSource={dataSource}
          size="small"
          pagination={false}>
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="" dataIndex="type" key="type" />
          <Column
            title="Licenses"
            dataIndex="tags"
            key="tags"
            width="50%"
            render={(list) =>
              (list || []).map((info) => (
                <div key={info.tag}>
                  {info.name}{' '}
                  <span style={{ color: 'lightgrey' }}>{info.tag}</span>
                </div>
              ))
            }
          />
        </Table>
      </div>
    );
  }

  renderLicensesTab() {
    const handleSave = (e) => {
      if (e) {
        e.preventDefault();
      }
      this.licensesForm.current.validateFields().then(
        (values) => {
          // the way the antd tables are defined, any rows that were
          // not opened won't show up in the keys. we can use this to
          // figure out what the deltas are to the licenses we had
          // to begin with.

          const licenses = _.cloneDeep(this.state.licenses);
          let proposedLicenses = {};
          const proposedConfigs = {
            added: [],
            deleted: [],
          };

          // we'll remove old license entries we've already processed
          Object.entries(values).forEach(([tag, val]) => {
            // if 'licenses' is not set, the editable area was never opened
            if (!_.get(val, 'licenses')) {
              return;
            }

            const tagInfo = _.get(this.state.configMap, tag);

            const oldLicenseEntities = _.get(licenses, `${tag}.entities`, []);
            proposedLicenses[tag] = {
              licenses: parseInt(val.licenses || '0'),
              entities: [],
            };

            // add new configs as needed
            (val.entities || []).forEach((entity) => {
              proposedLicenses[tag].entities.push(entity);
              proposedConfigs.added.push(
                ...this.getConfigFromSpec(
                  tag,
                  'add',
                  _.get(tagInfo, 'configs.enabled'),
                  tagInfo.entityType,
                  entity,
                ),
              );
              // we've processed this already
              _.pull(oldLicenseEntities, entity);
            });

            if (oldLicenseEntities.length) {
              // we had some license info previously for this tag
              // for the old licenses still here, we need to remove
              (oldLicenseEntities || []).forEach((entity) => {
                proposedConfigs.deleted.push(
                  ...this.getConfigFromSpec(
                    tag,
                    'remove',
                    _.get(tagInfo, 'configs.disabled', []),
                    tagInfo.entityType,
                    entity,
                  ),
                );
              });
              delete licenses[tag];
            }
          });

          // for each license in the old configs, that weren't modfied
          // in any way, we need to add them back in
          proposedLicenses = {
            ...licenses,
            ...proposedLicenses,
          };

          this.showAndConfirmLicenses(proposedLicenses, proposedConfigs);
        },
        () => {
          // ignore error, form will be showing the errors
        },
      );
    };

    return (
      <Form
        ref={this.licensesForm}
        requiredMark={false}
        initialValues={this.state.licenses}
        onSubmit={(e) => handleSave(e)}>
        {this.renderLicensables(
          'Suites',
          this.state.licenses,
          this.state.skus.suites,
        )}
        {this.renderLicensables(
          'Modules',
          this.state.licenses,
          this.state.skus.modules,
        )}
        <Form.Item>
          <Button onClick={(e) => handleSave(e)}>Save</Button>
        </Form.Item>
      </Form>
    );
  }

  renderLicensables(title, licenses, list) {
    if (!list) {
      return <div />;
    }
    const dataSource = list.map((elem) => {
      const children = elem.license_modes.map((mode) => ({
        ...mode,
        entities: _.get(licenses, `[${mode.tag}].entities`, []),
        licenses: _.get(licenses, `[${mode.tag}].licenses`, 0),
      }));
      return {
        ...elem,
        unlicensable: true,
        licenses: _.reduce(children, (sum, x) => sum + x.licenses, 0),
        children,
      };
    });

    const select_locs = this.props.loc.allIds
      .map((id: number) => this.props.loc.byId[+id])
      .filter((n) => n)
      .map((node) => ({
        label: node.Name,
        key: node.ID,
        value: node.ID,
      }));

    const maxLicenseRuleValidation =
      (record, type) =>
      ({ getFieldValue }) => ({
        validator(r, value) {
          const maxLicenses = getFieldValue([record.tag, 'licenses']) || 0;
          if (!value || value.length <= maxLicenses) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(`${type} exceed available licenses`));
        },
      });

    return (
      <div style={{ margin: '0 0 15px' }}>
        <div className={styles['user-heading']}>{title}</div>
        <div className={styles['user-content']}>
          <Table
            className={styles['table-container']}
            dataSource={dataSource}
            indentSize={0}
            size="small"
            rowKey="tag"
            pagination={false}>
            <Column
              title="Name"
              dataIndex="tag"
              key="tag"
              width="30%"
              render={(text, record) => {
                return (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      {record.name}{' '}
                      <span style={{ color: 'lightgrey' }}>{record.tag}</span>
                    </div>
                    <div style={{ color: 'grey' }}>{record.description}</div>
                  </div>
                );
              }}
            />
            <Column
              width="75px"
              align="center"
              title="Licenses"
              dataIndex="licenses"
              key="licenses"
              render={(text, record) => {
                if (record.unlicensable) {
                  return text || '-';
                }
                return (
                  <Form.Item
                    style={{ width: '75px', marginBottom: 0 }}
                    name={[record.tag, 'licenses']}>
                    <Input type="number" />
                  </Form.Item>
                );
              }}
            />
            <Column
              title="Selection"
              dataIndex="entities"
              key="entities"
              width="60%"
              render={(text, record) => {
                switch (record.entity) {
                  case 'Channel':
                    return (
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        label="Channels"
                        dependencies={[[record.tag, 'licenses']]}
                        rules={[maxLicenseRuleValidation(record, 'Channels')]}
                        name={[record.tag, 'entities']}>
                        <ChannelSelect2
                          value={this.state.channelSelectValue}
                          selecttype="treeselect"
                          multiple
                          onChange={(value) => {
                            this.setState({ channelSelectValue: value });
                          }}
                        />
                      </Form.Item>
                    );
                  case 'Project':
                    return (
                      <Form.Item
                        name={[record.tag, 'entities']}
                        style={{ marginBottom: 0 }}
                        dependencies={[[record.tag, 'licenses']]}
                        rules={[maxLicenseRuleValidation(record, 'Locations')]}
                        label="Locations">
                        <Select
                          showArrow
                          options={select_locs}
                          mode="multiple"
                        />
                      </Form.Item>
                    );
                  default:
                    return '';
                }
              }}
            />
          </Table>
        </div>
      </div>
    );
  }

  renderAdvancedTab() {
    return (
      <>
        <div className={styles['user-heading']}>Add Configuration</div>
        <div className={styles['user-content']}>
          <Form
            className={styles['add-config-row']}
            ref={this.addConfigForm}
            onSubmit={(e) => this.addConfigProfile(e)}>
            <Form.Item
              key="entity_type"
              name="entity_type"
              style={{ width: '250px' }}
              rules={[
                {
                  required: true,
                  message: 'Field required.',
                },
              ]}
              initialValue="Customer">
              <Select className={styles['select-width']}>
                <Option value="Customer">Customer</Option>
                <Option value="Project">Project</Option>
                <Option value="ChannelGroup">Channel Group</Option>
                <Option value="Channel">Channel</Option>
              </Select>
            </Form.Item>
            <Form.Item
              key="entity_id"
              name="entity_id"
              style={{ width: '250px' }}
              rules={[
                {
                  required: true,
                  message: 'Field required.',
                },
              ]}>
              <Input placeholder="Entity ID" />
            </Form.Item>
            <Form.Item
              key="profile_type"
              name="profile_type"
              style={{ width: '250px' }}
              rules={[
                {
                  required: true,
                  message: 'Field required.',
                },
              ]}>
              <Input placeholder="Profile Type" />
            </Form.Item>
            <Form.Item
              key="profile_name"
              name="profile_name"
              style={{ width: '250px' }}
              rules={[
                {
                  required: true,
                  message: 'Field required.',
                },
              ]}>
              <Input placeholder="Profile Name" />
            </Form.Item>
            <Form.Item key="submit_btn">
              <Button
                loading={this.props.loadingAddConfigProfile}
                type="default"
                onClick={(e) => {
                  this.addConfigProfile(e);
                }}>
                Add
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className={styles['user-heading']}>Active Configuration</div>
        <div className={styles['user-content']}>
          <Table
            className={styles['table-container']}
            dataSource={this.state.customerConfigs}
            rowKey={(rec) =>
              `${rec.entity_type}-${rec.entity_id}-${rec.profile_type}`
            }
            size="small"
            pagination={false}>
            <Column
              title={
                <span className={styles['header-style']}>ENTITY_TYPE</span>
              }
              dataIndex="entity_type"
              key="entity_type"
            />
            <Column
              title={<span className={styles['header-style']}>ENTITY_ID</span>}
              dataIndex="entity_id"
              key="entity_id"
            />
            <Column
              title={
                <span className={styles['header-style']}>PROFILE_TYPE</span>
              }
              dataIndex="profile_type"
              key="profile_type"
            />
            <Column
              title={
                <span className={styles['header-style']}>PROFILE_NAME</span>
              }
              dataIndex="profile_name"
              key="profile_name"
            />
            <Column
              title={<span className={styles['header-style']}>ACTION</span>}
              dataIndex=""
              key="x"
              render={(text, record) => (
                <>
                  <span>
                    <span
                      className="df-link"
                      onClick={() => this.editProfile(record)}>
                      Edit
                    </span>
                  </span>
                  &nbsp;|&nbsp;
                  <span>
                    <Popconfirm
                      title="Are you sure to remove this profile?"
                      style={{ color: 'red' }}
                      onConfirm={(e) => this.removeProfile(record, e)}
                      onCancel={(e) => e.preventDefault()}
                      okText="Yes"
                      cancelText="No">
                      <span className="df-link">Delete</span>
                    </Popconfirm>
                  </span>
                </>
              )}
            />
          </Table>
        </div>
      </>
    );
  }

  renderLicenseConfirmationModal() {
    const licenses = Object.entries(this.state.proposedLicenses || {}).map(
      ([tag, entry]) => ({ ...entry, key: tag }),
    );
    const licenseColumns = [
      { title: 'Part', dataIndex: 'key', key: 'key' },
      { title: 'Licenses', dataIndex: 'licenses', key: 'licenses' },
      {
        title: 'Entities',
        dataIndex: 'entities',
        key: 'entitites',
        render: (el) => el.join(', '),
      },
    ];

    const configs = [
      ..._.get(this.state, 'proposedConfigs.added', []),
      ..._.get(this.state, 'proposedConfigs.deleted', []),
    ];

    const configsColumns = [
      { title: 'Part', dataIndex: 'tag', key: 'tag' },
      { title: 'Op', dataIndex: 'op', key: 'op' },
      { title: 'Key', dataIndex: 'profile_type', key: 'type' },
      { title: 'Value', dataIndex: 'profile_name', key: 'name' },
    ];

    return (
      <Modal
        centered
        title="Confirm Changes"
        visible={this.state.showModal}
        onCancel={() => this.setState({ showModal: false })}
        confirmLoading={
          this.props.loadingAddConfigProfile ||
          this.props.loadingRemoveConfigProfile
        }
        onOk={() => this.submitLicenseChanges()}
        destroyOnClose={true}>
        <div>
          <h4>Licenses</h4>
          <Table
            size="small"
            dataSource={licenses}
            columns={licenseColumns}
            pagination={{ size: 'small', hideOnSinglePage: true }}
          />
          <h4 style={{ marginTop: '10px' }}>Configs</h4>
          <Table
            size="small"
            dataSource={configs}
            columns={configsColumns}
            pagination={{ size: 'small', hideOnSinglePage: true }}
          />
        </div>
      </Modal>
    );
  }

  render() {
    const { currentUser } = this.props;
    return (
      <>
        <PageHeader title="Licenses" />
        <Tabs
          style={{ paddingRight: '16px' }}
          onTabClick={(key) => {
            if (key === 'advanced') {
              this.fetchCustomerConfig();
            }
          }}>
          <Tabs.TabPane tab="Active" key="config">
            {this.renderConfigsTab()}
          </Tabs.TabPane>
          {isInternalUser(currentUser) && (
            <Tabs.TabPane tab="Manage" key="licenses">
              {this.renderLicensesTab()}
            </Tabs.TabPane>
          )}
          {isInternalUser(currentUser) && (
            <Tabs.TabPane tab="Advanced" key="advanced">
              {this.props.loadingFetchCustomerConfigs ? (
                <LoadingSpinner />
              ) : (
                <>{this.renderAdvancedTab()}</>
              )}
            </Tabs.TabPane>
          )}
        </Tabs>
        {this.renderLicenseConfirmationModal()}
      </>
    );
  }
}

export default Licenses;
