/**
 * @generated SignedSource<<fac88383103f2d0be3121fc693e807cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, FragmentRefs } from 'relay-runtime';
export type ScenesQuery$variables = {
  app_id: number;
  customer_id: number;
};
export type ScenesQuery$data = {
  readonly vmsPlusConfig:
    | {
        readonly scenesData:
          | {
              readonly __id: string;
              readonly ' $fragmentSpreads': FragmentRefs<'ScenesDataFragment'>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};
export type ScenesQuery = {
  response: ScenesQuery$data;
  variables: ScenesQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'app_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customer_id',
      },
    ],
    v1 = {
      kind: 'Variable',
      name: 'appId',
      variableName: 'app_id',
    },
    v2 = {
      kind: 'Variable',
      name: 'customerId',
      variableName: 'customer_id',
    },
    v3 = [v1 /*: any*/, v2 /*: any*/],
    v4 = {
      kind: 'ClientExtension',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: '__id',
          storageKey: null,
        },
      ],
    },
    v5 = [
      v1 /*: any*/,
      v2 /*: any*/,
      {
        fields: [
          {
            kind: 'Literal',
            name: 'onlyFavorites',
            value: false,
          },
        ],
        kind: 'ObjectValue',
        name: 'filters',
      },
      {
        kind: 'Literal',
        name: 'first',
        value: 10,
      },
    ],
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ScenesQuery',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'VMSPlusConfig',
          kind: 'LinkedField',
          name: 'vmsPlusConfig',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'VMSPlusScenesData',
              kind: 'LinkedField',
              name: 'scenesData',
              plural: false,
              selections: [
                {
                  args: [
                    {
                      kind: 'Variable',
                      name: 'app_id',
                      variableName: 'app_id',
                    },
                    {
                      kind: 'Variable',
                      name: 'customer_id',
                      variableName: 'customer_id',
                    },
                    {
                      kind: 'Literal',
                      name: 'only_favorites',
                      value: false,
                    },
                  ],
                  kind: 'FragmentSpread',
                  name: 'ScenesDataFragment',
                },
                v4 /*: any*/,
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ScenesQuery',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'VMSPlusConfig',
          kind: 'LinkedField',
          name: 'vmsPlusConfig',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'VMSPlusScenesData',
              kind: 'LinkedField',
              name: 'scenesData',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: v5 /*: any*/,
                  concreteType: 'VMSPlusRecentChannelsConnection',
                  kind: 'LinkedField',
                  name: 'scenes',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'VMSPlusRecentChannelsEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'VMSPlusRecentChannels',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v6 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'name',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'isFavorite',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'channelIDs',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'timestamp',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'anchorTimeStamp',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: '__typename',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'cursor',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'PageInfo',
                      kind: 'LinkedField',
                      name: 'pageInfo',
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'startCursor',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'endCursor',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'hasNextPage',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'hasPreviousPage',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: v5 /*: any*/,
                  filters: ['filters', 'appId', 'customerId'],
                  handle: 'connection',
                  key: 'VMSPlusConfig_scenes',
                  kind: 'LinkedHandle',
                  name: 'scenes',
                },
                v6 /*: any*/,
                v4 /*: any*/,
              ],
              storageKey: null,
            },
            v6 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'b99bbfd0aa9fbf1b46bc4d08bb3a508f',
      id: null,
      metadata: {},
      name: 'ScenesQuery',
      operationKind: 'query',
      text: 'query ScenesQuery(\n  $app_id: Int!\n  $customer_id: Int!\n) {\n  vmsPlusConfig(appId: $app_id, customerId: $customer_id) {\n    scenesData {\n      ...ScenesDataFragment_3as2Y\n      id\n    }\n    id\n  }\n}\n\nfragment RecentChannelsTileFragment on VMSPlusRecentChannels {\n  id\n  name\n  isFavorite\n  channelIDs\n  timestamp\n  anchorTimeStamp\n}\n\nfragment ScenesDataFragment_3as2Y on VMSPlusScenesData {\n  scenes(first: 10, filters: {onlyFavorites: false}, appId: $app_id, customerId: $customer_id) {\n    edges {\n      node {\n        id\n        ...RecentChannelsTileFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n',
    },
  };
})();

(node as any).hash = 'fcafe05008fd7a456ff47605c83cdbec';

export default node;
