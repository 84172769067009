import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'umi';

// @ts-expect-error
@connect(({ investigations, loading }) => ({
  investigations,
  loading,
}))
class UpdateAttachment extends Component {
  state = { showModal: false };

  updateAttachement = React.createRef();

  handleSave = (_e) => {
    const { dispatch, attachment, investigation } = this.props;
    this.updateAttachement.current.validateFields().then(
      (values) => {
        attachment.Name = values.name;
        dispatch({
          type: 'investigations/updateInvestigationAttachment',
          investigationID: investigation.InvestigationID,
          attachmentID: attachment.InvestigationAttachmentID,
          payload: values,
        }).then(() => {
          this.setState({
            showModal: false,
          });
        });
      },
      (err) => console.log('err', err),
    );
  };

  toggleUpdateAttachmentModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  render() {
    const { children, attachment } = this.props;

    return (
      <>
        <Modal
          title="Rename Attachment"
          visible={this.state.showModal}
          onOk={this.handleSave}
          confirmLoading={
            this.props.loading.effects[
              'investigations/updateInvestigationAttachment'
            ]
          }
          onCancel={() => this.toggleUpdateAttachmentModal()}>
          <Form
            ref={this.updateAttachement}
            layout="vertical"
            initialValues={{ name: _.get(attachment, 'Name') }}
            onFinish={this.handleSave}>
            <Form.Item
              label="Attachment Name "
              name="name"
              rules={[
                {
                  message: 'Please enter the name of attachment',
                },
              ]}>
              <Input autoFocus />
            </Form.Item>
          </Form>
        </Modal>
        <span onClick={() => this.toggleUpdateAttachmentModal()}>
          {children}
        </span>
      </>
    );
  }
}
export default UpdateAttachment;
