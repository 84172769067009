import { Button, Checkbox, Collapse, Form, notification, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, history } from 'umi';
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import InsightSelect from '@/components/InsightSelect';
import Facets from '@/pages/insights/insight/components/facets';
import {
  DEFAULT_FACET_CONFIG,
  findPathOfNode,
  INSIGHT_NODE_TYPE,
  processInsightAll,
} from '@/utils/insight';

import type { FormInstance } from 'antd';

type MyProps = {
  ins_grp?: Record<number, any>;
  ins?: Record<string, any>;
  head_node?: Record<any, any>;
  processInsightReplicator?: (_payload: any, cb: (_res) => void) => void;
  dispatch?: (_any: any) => Promise<any>;
};

type MyState = {
  config: Record<string, any> | null;
};

// @ts-expect-error
@connect(({ insights }) => {
  const { ins_grp, ins, head_node } = processInsightAll(
    insights.all,
    insights.byID,
  );
  return {
    ins_grp,
    ins,
    head_node,
  };
})
class InsightReplicator extends React.Component<MyProps, MyState> {
  static FacetsOrder = [
    {
      facet_key: 'search_filter_facet',
      name: 'Search Filter',
    },
    {
      facet_key: 'metrics_facet',
      name: 'Metrics',
    },
    {
      facet_key: 'date_facet',
      name: 'Date',
    },
    {
      facet_key: 'time_facet',
      name: 'Time',
    },
    {
      facet_key: 'days_of_week_facet',
      name: 'Day of Week',
    },
    {
      facet_key: 'group_by_facet',
      name: 'Group By',
    },
  ];

  formRef: React.RefObject<FormInstance>;

  constructor(props: MyProps) {
    super(props);
    this.state = {
      config: null,
    };
    this.formRef = React.createRef<FormInstance>();
  }

  createFacetConfigFromFieldValues(
    facet_to_apply: string[],
    template_insight_id: string | null = null,
    template_insight_group_id: number | null = null,
  ) {
    const { config } = this.state;
    const facet_config: Record<string, any> = template_insight_group_id
      ? DEFAULT_FACET_CONFIG
      : {};

    // For template insight, the facet_config has configs users want to be same as template insight
    // For template insight group, the facet_config has configs users does not
    // want to be same as template insight, these configs are taken from DEFAULT_FACET_CONFIG
    Object.entries(facet_to_apply).forEach(([facet_key, facet_checked]) => {
      if (facet_checked) {
        if (template_insight_id) {
          const facet_data =
            _.get(config, [facet_key], null) ||
            _.get(DEFAULT_FACET_CONFIG, [facet_key], null);
          if (facet_data) {
            facet_config[facet_key] = facet_data;
          }
        }
        if (template_insight_group_id) {
          delete facet_config[facet_key];
        }
      }
    });

    return facet_config;
  }

  displayReplicatedInsights({
    ins_grp = [],
    ins = [],
    template_insight_group_id = null,
    existing_insight_groups = [],
  }: {
    ins_grp: number[];
    ins: string[];
    template_insight_group_id: number | null;
    existing_insight_groups: number[];
  }) {
    const display_all = template_insight_group_id ? false : true;

    const ins_to_display = display_all
      ? ins
      : ins.filter((inss) => !_.get(inss, 'InsightGroupID', null));
    const ins_grp_to_display = display_all
      ? ins_grp
      : ins_grp.filter((ins_grpp) => {
          return (
            (Array.isArray(existing_insight_groups) &&
              existing_insight_groups.includes(
                _.get(ins_grpp, 'InsightGroupID', null),
              )) ||
            !_.get(ins_grpp, 'ParentID', null)
          );
        });

    if (ins_grp_to_display.length + ins_to_display.length <= 0) return;
    this.formRef.current?.resetFields();

    notification.success({
      key: `insight-replicator-success`,
      message: 'Replicated Insights/InsightGroups',
      description: (
        <div
          style={{
            maxHeight: '600px',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          {ins_grp_to_display && ins_grp_to_display.length > 0 && (
            <strong>Insight Group</strong>
          )}
          {ins_grp_to_display &&
            ins_grp_to_display.length > 0 &&
            ins_grp_to_display.map((ins_grpp) => {
              const ins_grp_id = _.get(ins_grpp, 'InsightGroupID', null);
              if (ins_grp_id) {
                const path = findPathOfNode(
                  this.props.ins_grp || {},
                  this.props.ins || {},
                  INSIGHT_NODE_TYPE.INSIGHT_GROUP,
                  ins_grp_id,
                );
                const ins_grp_label = path
                  .map((path_entry) =>
                    _.get(path_entry, ['node', 'Name'], null),
                  )
                  .filter((v) => v)
                  .join(' > ');

                return (
                  <div
                    key={`ins_grp_${ins_grp_id}`}
                    style={{
                      width: '100%',
                      paddingLeft: '6px',
                      marginBottom: '4px',
                    }}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/insight-groups/${ins_grp_id}`);
                      }}>
                      {ins_grp_label}
                    </a>
                  </div>
                );
              }
              return null;
            })}
          {ins_to_display && ins_to_display.length > 0 && (
            <strong>Insight</strong>
          )}
          {ins_to_display &&
            ins_to_display.length > 0 &&
            ins_to_display.map((inss) => {
              const ins_id = _.get(inss, 'InsightID', null);
              if (ins_id) {
                const path = findPathOfNode(
                  this.props.ins_grp || {},
                  this.props.ins || {},
                  INSIGHT_NODE_TYPE.INSIGHT,
                  ins_id,
                );
                const ins_label = path
                  .map((path_entry) =>
                    _.get(path_entry, ['node', 'Name'], null),
                  )
                  .filter((v) => v)
                  .join(' > ');

                return (
                  <div
                    key={`ins_${ins_id}`}
                    style={{
                      width: '100%',
                      paddingLeft: '6px',
                      marginBottom: '4px',
                    }}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/insights/${ins_id}`);
                      }}>
                      {ins_label}
                    </a>
                  </div>
                );
              }
              return null;
            })}
        </div>
      ),
      duration: 0,
    });
  }

  rollOutInsightConfig() {
    const { processInsightReplicator } = this.props;
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        const template = _.get(values, ['template', 0], null);
        const [template_insight_id, template_insight_group_id] =
          InsightReplicator.getTemplateDetails(template);
        const facet_to_apply = _.get(values, ['facet_to_apply'], {});
        const facet_config = this.createFacetConfigFromFieldValues(
          facet_to_apply,
          template_insight_id,
          template_insight_group_id,
        );
        const existing_insights = _.get(values, ['existing_insights'], []);
        const existing_insight_groups: number[] = _.flatten(
          _.get(values, ['existing_insight_groups'], []),
        );

        const payload = {
          template_insight_id,
          template_insight_group_id,
          facet_config,
          existing_insights,
          existing_insight_groups,
        };

        if (processInsightReplicator) {
          processInsightReplicator(payload, (res) => {
            const ins_grp = _.get(res, 'InsightGroups', null);
            const ins = _.get(res, 'Insights', null);
            if (ins && ins_grp) {
              this.displayReplicatedInsights({
                ins_grp: ins_grp || [],
                ins: ins || [],
                template_insight_group_id,
                existing_insight_groups,
              });
            }
          });
        }
      })
      .catch((err) => console.log('err', err));
  }

  static getTemplateDetails = (template = null) => {
    // return [template_insight_id, template_insight_group_id]
    const template_detail: [string | null, number | null] = [null, null];
    if (template) {
      if (parseInt(template) == template) {
        template_detail[1] = +template;
      } else {
        template_detail[0] = _.toString(template);
      }
    }
    return template_detail;
  };

  render() {
    const { ins_grp, ins } = this.props;
    return (
      <Form
        ref={this.formRef}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          padding: '10px',
        }}>
        <strong style={{ marginTop: '10px' }}>
          Select Template Insight or Insight Group
        </strong>
        <Form.Item
          style={{
            width: '100%',
            maxWidth: '500px',
            marginTop: '4px',
          }}
          name={'template'}
          rules={[
            {
              required: true,
              message: 'Template is required',
            },
          ]}
          initialValue={[]}>
          <InsightSelect
            placeholder={<strong>Select Insight or Insight Group</strong>}
            selectStrategy="ALL"
          />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            const template_changed = !_.isEqual(
              _.get(prevValues, 'template', null),
              _.get(currentValues, 'template', null),
            );
            if (template_changed) {
              const template = _.get(currentValues, 'template[0]', null);
              const [insightID, insightGroupID] =
                InsightReplicator.getTemplateDetails(template);
              const insight = _.get(this.props.ins, `${insightID}`, null);
              const insight_group = _.get(
                this.props.ins_grp,
                `${insightGroupID}`,
                null,
              );
              if (insight) {
                const insightConfig = _.get(insight, 'Config', {});
                this.setState({ config: insightConfig });
              } else if (insight_group) {
                this.setState({ config: {} });
              } else {
                this.setState({ config: null });
              }
            }
            return template_changed;
          }}>
          {({ getFieldValue }) => {
            const { config } = this.state;
            const template = _.get(getFieldValue('template'), [0], null);
            const [template_insight_id, template_insight_group_id] =
              InsightReplicator.getTemplateDetails(template);
            if (config) {
              return (
                <>
                  {template_insight_id ? (
                    <Collapse
                      style={{ width: '100%', marginTop: '10px' }}
                      destroyInactivePanel={true}
                      defaultActiveKey="template_config">
                      <Collapse.Panel
                        key="template_config"
                        header={<strong>Configure Facets</strong>}>
                        <div style={{ width: '100%' }}>
                          <Facets
                            key={`insight-facets-${template_insight_id}`}
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItem: 'flex-start',
                              flexWrap: 'wrap',
                            }}
                            showChannelFacet={false}
                            showRegionFacet={false}
                            showCalculateButton={false}
                            insightConfig={config}
                            onConfigChange={(
                              new_config: Record<string, any>,
                            ) => {
                              this.setState({ config: new_config });
                            }}
                          />
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  ) : null}

                  <strong style={{ marginTop: '20px' }}>
                    Select Facets to Copy{' '}
                  </strong>
                  <div
                    style={{
                      width: '100%',
                      maxWidth: '500px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                    }}>
                    {InsightReplicator.FacetsOrder.map((facet_item) => {
                      return (
                        <Form.Item
                          key={`facet_to_apply-${facet_item.facet_key}`}
                          style={{
                            width: 'calc(100% / 3)',
                            padding: '2px 4px 2px 4px',
                            margin: 0,
                          }}
                          name={['facet_to_apply', facet_item.facet_key]}
                          valuePropName="checked"
                          initialValue={true}>
                          <Checkbox>{facet_item.name}</Checkbox>
                        </Form.Item>
                      );
                    })}
                  </div>

                  {template_insight_id ? (
                    <>
                      <strong style={{ marginTop: '20px' }}>
                        Copy these Selected Facets to the following existing
                        Insights.
                      </strong>
                      <Form.Item
                        style={{
                          width: '100%',
                          maxWidth: '500px',
                          marginTop: '4px',
                        }}
                        name={['existing_insights']}
                        initialValue={[]}>
                        <InsightSelect
                          multiple
                          placeholder={<strong>Select Insight</strong>}
                        />
                      </Form.Item>
                    </>
                  ) : null}

                  <strong style={{ marginTop: '20px' }}>
                    Create new Insights in these Insight-Groups using the
                    facets.
                  </strong>
                  <Form.Item
                    style={{
                      width: '100%',
                      maxWidth: '500px',
                      marginTop: '4px',
                    }}
                    name={['existing_insight_groups']}
                    initialValue={[]}>
                    {/* <InsightSelect
                      multiple
                      placeholder={<strong>Select Insight Group</strong>}
                      selecttype='search-select'
                      selectStrategy='INSIGHT_GROUP'
                    /> */}
                    <Select
                      showSearch
                      placeholder="Select Insight Groups"
                      mode="multiple"
                      showArrow
                      filterOption={(input, option) => {
                        const insGrp_label: any = _.get(
                          option,
                          'children',
                          null,
                        );
                        if (typeof insGrp_label == 'string') {
                          return insGrp_label.includes(input.toLowerCase());
                        }
                        return false;
                      }}>
                      {Object.keys(ins_grp || {}).map((ins_grp_id) => {
                        const path = findPathOfNode(
                          ins_grp || {},
                          ins || {},
                          INSIGHT_NODE_TYPE.INSIGHT_GROUP,
                          ins_grp_id,
                        );
                        let decendent_of_template_group = false;
                        const ins_grp_label = path
                          .map((path_entry) => {
                            if (
                              template_insight_group_id &&
                              _.get(path_entry, ['node_type'], null) ==
                                INSIGHT_NODE_TYPE.INSIGHT_GROUP &&
                              _.isEqual(
                                _.get(
                                  path_entry,
                                  ['node', 'InsightGroupID'],
                                  null,
                                ),
                                template_insight_group_id,
                              )
                            ) {
                              decendent_of_template_group = true;
                            }
                            return _.get(path_entry, ['node', 'Name'], null);
                          })
                          .filter((v) => v)
                          .join(' > ');

                        if (decendent_of_template_group) return null;
                        return (
                          <Select.Option
                            key={`ins-grp-option-${+ins_grp_id}`}
                            value={+ins_grp_id}>
                            {ins_grp_label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Button
                    type="primary"
                    style={{ maxWidth: '200px' }}
                    onClick={() => {
                      this.rollOutInsightConfig();
                    }}>
                    Apply
                  </Button>
                </>
              );
            }

            return <></>;
          }}
        </Form.Item>
      </Form>
    );
  }
}
export default InsightReplicator;
