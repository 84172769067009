import Icon, {
  CheckOutlined,
  CloseOutlined,
  EditFilled,
  EyeFilled,
  EyeInvisibleFilled,
  MoreOutlined,
} from '@ant-design/icons';
import { Affix, Button, Collapse, Form, Modal, Tooltip } from 'antd';
import deepdash from 'deepdash';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';

import { ReactComponent as SpatialFilterSvg } from '@/assets/box-indicator.svg';
import LoadingSpinner from '@/components/LoadingSpinner';
import RangePickerDF from '@/components/RangePickerDF';
import ChannelSearchMenu from '@/components/SearchForm2/ChannelSearchMenu';
import FormItemDisabler from '@/components/SearchForm2/FormItemDisabler/FormItemDisabler';
import PersonColorSelect from '@/components/SearchForm2/PersonColorSelect';
import SpatialFilters from '@/components/SearchForm2/SpatialFilters';
import VehicleColorSelect from '@/components/SearchForm2/VehicleColorSelect';
import { filterModulesInfo } from '@/utils/filterModules';
import { entityHasLicenseOfType } from '@/utils/licenses';
import {
  ChannelGroupMenuNode,
  ChannelMenuNode,
  LocationMenuNode,
  _checkboxOnClick_,
  _getChannelMenu_,
} from '@/utils/searchFilterMenuNode';
import {
  getMainContentWidth,
  getPath,
  getSearchResultsHeight,
  interpretClipData,
  setChannelSearchFormScrollPosition,
} from '@/utils/utils';
import styles from './style.less';
const __ = deepdash(_);
const disabledDate = (current: any) =>
  current && current > moment().endOf('day');

type CameraSearchFormState = any;
type CameraSearchFormProps = any;

// @ts-expect-error
@connect(
  ({ locations, accounts, search2, investigation_events, loading }) => ({
    allLocations: locations.all,
    installationsByID: locations.installationsByID,
    loc: locations.loc,
    ch_grp: locations.ch_grp,
    ch: locations.ch,
    accounts,
    search2,
    investigation_events,
    fetchLocationLoading: loading.effects['locations/fetchLocation'],
  }),
  null,
  null,
  { forwardRef: true },
)
class CameraSearchForm extends React.Component<
  CameraSearchFormProps,
  CameraSearchFormState
> {
  cameraSearchFormContainerRef: any;
  cameraSearchFormRef: any;
  personColorSelectRef: any;
  vehicleColorSelectRef: any;
  selectRefs: any;

  constructor(props: CameraSearchFormProps) {
    super(props);
    this.state = {
      dateRange: [],
      channelIDs: [],
      person_colors: {
        top_colors: [],
        bottom_colors: [],
      },
      vehicle_colors: [],
      lineFilters: {},
      regionFilters: {},
      pathFilters: {},

      search_object: null,
      object_id: null,

      editableChannelSearchMenu: !(_.get(props, 'channelID', null) !== null),
      editPersonColorState: false,
      editVehicleColorState: false,

      editStates: {},
      unavailable: {},

      openPanels: ['Basics'],
    };

    this.cameraSearchFormContainerRef = React.createRef();
    this.cameraSearchFormRef = React.createRef();
    this.personColorSelectRef = React.createRef();
    this.vehicleColorSelectRef = React.createRef();
    this.selectRefs = {};

    Object.entries(filterModulesInfo).forEach(([mKey, info]) => {
      this.state[mKey] = info.stateDefault;
      this.state.editStates[mKey] = false;
      this.selectRefs[mKey] = React.createRef();
    });
  }

  componentDidMount() {
    this.resetFilters();
    this.updateSearchForm().then(() => {
      if (this.props.summaryObj) {
        this.onSummarySubmit();
      }
    });
  }

  componentWillUnmount() {
    this.resetFilters();
  }

  componentDidUpdate(prevProps: CameraSearchFormProps) {
    const {
      accounts,
      locationID,
      channelGroupID,
      channelID,
      eventID,
      investigationID,
    } = this.props;
    if (
      locationID !== prevProps.locationID ||
      channelGroupID !== prevProps.channelGroupID ||
      channelID !== prevProps.channelID ||
      eventID !== prevProps.eventID ||
      investigationID !== prevProps.investigationID ||
      !_.isEqual(accounts.licenses, prevProps.accounts.licenses)
    ) {
      const resetToInitialState = _.get(
        this.props.createSearchFilterRef,
        'current.resetToInitialState',
        () => {},
      );
      resetToInitialState();
      this.updateSearchForm();
      return;
    }
    if (!_.isEqual(prevProps.search2, this.props.search2)) {
      this.updateSearchForm().then(() => {
        if (
          this.props.summaryObj &&
          prevProps.search2.appliedFilterName !==
            this.props.search2.appliedFilterName
        ) {
          this.onSummarySubmit();
        }
      });
      return;
    }
    if (
      (locationID &&
        !_.isEqual(
          _.get(this.props, `loc.byId[${locationID}]`, null),
          _.get(prevProps, `loc.byId[${locationID}]`, null),
        )) ||
      (channelGroupID &&
        !_.isEqual(
          _.get(this.props, `ch_grp.byId[${channelGroupID}]`, null),
          _.get(prevProps, `ch_grp.byId[${channelGroupID}]`, null),
        )) ||
      (channelID &&
        !_.isEqual(
          _.get(this.props, `ch.byId[${channelID}]`, null),
          _.get(prevProps, `ch.byId[${channelID}]`, null),
        )) ||
      (investigationID &&
        !_.isEqual(
          _.get(this.props, `investigations.byId[${investigationID}]`, null),
          _.get(prevProps, `investigations.byId[${investigationID}]`, null),
        )) ||
      (eventID &&
        !_.isEqual(
          _.get(this.props, `investigation_events.byID[${eventID}]`, null),
          _.get(prevProps, `investigation_events.byID[${eventID}]`, null),
        ))
    ) {
      this.resetFilters();
      this.updateSearchForm();
      return;
    }
  }

  getAffixTarget() {
    if (this.props.doNotAffix) return null;
    return document.getElementById('page-container');
  }

  setDateRange(dateRange) {
    const filters = { dateRange };
    this.setState(filters);
    this.props.dispatch({
      type: 'search2/changeSearchFilter',
      payload: {
        ...filters,
      },
    });
    if (this.cameraSearchFormRef.current) {
      this.cameraSearchFormRef.current.setFieldsValue({
        dateRange,
      });
    }
  }

  isDateRangeDifferent(one, other) {
    if (!one && !other) {
      return false;
    }

    return (
      (!one && other) ||
      (one && !other) ||
      (one.length === 2 && other.length !== 2) ||
      (one.length !== 2 && other.length === 2) ||
      !one[0].isSame(other[0]) ||
      !one[1].isSame(other[1])
    );
  }

  updateSearchForm() {
    const { search2, dispatch } = this.props;

    const panels = new Set();
    panels.add('Basics');

    const filter = {
      channelIDs: _.get(search2, 'channelIDs', []),
      locationID: _.get(search2, 'locationID', []),
      investigationIDs: _.get(search2, 'investigationIDs', []),
      person_colors: {
        top_colors: _.get(search2, 'person_colors.top_colors', []),
        bottom_colors: _.get(search2, 'person_colors.bottom_colors', []),
      },
      vehicle_colors: _.get(search2, 'vehicle_colors', []),
      lineFilters: _.get(search2, 'lineFilters', {}),
      regionFilters: _.get(search2, 'regionFilters', {}),
      pathFilters: _.get(search2, 'pathFilters', {}),
    };

    Object.entries(filterModulesInfo).forEach(([mKey, info]) => {
      filter[mKey] = _.get(search2, mKey, info.stateDefault);
      if (!_.isEqual(filter[mKey], info.stateDefault)) {
        const group = _.find(this.props.showFilters, (g) =>
          _.includes(g.list, mKey),
        );
        if (group) {
          panels.add(group.label);
        }
      }
    });

    const eventID = _.get(this.props, 'eventID', null);
    if (eventID !== null) {
      const investigation_event = _.get(
        this.props,
        `investigation_events.byID[${eventID}]`,
        null,
      );
      filter.dateRange = [
        moment(investigation_event.EventStart),
        moment(investigation_event.EventEnd),
      ];
    } else {
      if (_.isEmpty(search2.dateRange)) {
        if (eventID == null && !this.props.hideDate) {
          filter.dateRange = [
            moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).add(
              -1,
              'days',
            ),
            moment({ hour: 23, minute: 59, seconds: 59, milliseconds: 0 }).add(
              -1,
              'days',
            ),
          ];
        }
      }
    }

    // if we have an investigation
    const _investigationID = _.get(this.props, 'investigationID', null);
    if (_investigationID !== null) {
      const _eventID = _.get(this.props, 'eventID', null);
      if (_eventID === null && _investigationID !== null) {
        filter.investigationIDs = [_investigationID];
      }
    }

    // if we have location
    const _locationID = _.get(this.props, 'locationID', null);
    if (_locationID !== null) {
      filter.locationID = [_locationID];
    }

    // we might get channels from all sorts of places!
    const allChannelIDs = new Set();

    // if we have specific channels
    filter.channelIDs.forEach((ch) => allChannelIDs.add(parseInt(ch)));

    // if we are in a specific channel
    const _channelID = _.get(this.props, 'channelID', null);
    if (_channelID !== null) {
      allChannelIDs.add(parseInt(_channelID));
    }

    // if we are in a specific channel group
    const _chGroupID = _.get(this.props, 'channelGroupID', null);
    if (_chGroupID !== null) {
      const _chIDs = {};
      __.eachDeep(
        _.get(this.props, 'allLocations', {}),
        (val, key, parent) => {
          if (key === 'ChannelID' && parent.ChannelGroupID === _chGroupID) {
            _chIDs[val] = true;
          }
        },
        { checkCircular: true },
      );
      Object.keys(_chIDs).forEach((ch) => allChannelIDs.add(ch));
    }

    // if we have spatial filters
    ['lineFilters', 'regionFilters', 'pathFilters'].forEach((type) => {
      Object.keys(_.get(filter, type, {})).forEach((ch) =>
        allChannelIDs.add(parseInt(ch)),
      );
    });

    // combine them all up!
    filter.channelIDs = Array.from(allChannelIDs);

    this.setState({ openPanels: Array.from(panels) });

    return Promise.resolve()
      .then(() => {
        // update the model if we made changes
        let hasChanges = false;
        Object.entries(filter).forEach(([key, value]) => {
          // daterange is special
          if (key === 'dateRange') {
            if (this.isDateRangeDifferent(value, search2[key])) {
              hasChanges = true;
            }
            return;
          }

          if (!_.isEqual(value, search2[key])) {
            hasChanges = true;
          }
        });

        if (hasChanges) {
          dispatch({
            type: 'search2/changeSearchFilter',
            payload: {
              ...filter,
            },
          });
        }
      })
      .then(() => {
        const _fieldsValues = {
          ...filter,
          channelIDs: filter.channelIDs.map((_id) => `CH-${_id}`),
        };
        this.setState(_fieldsValues, () => {
          const channelmenu = this.getChannelsObjFromChannelMenu(
            filter.channelIDs,
          );
          filter.channelIDs.forEach((_k: any) => {
            const _path = getPath(channelmenu, 'ChannelID', parseInt(_k));
            if (_.get(_path, 'length', 0) > 0) {
              const _channelnode = _.get(
                channelmenu,
                _path.slice(0, _path.length - 1),
                null,
              );
              _channelnode.spatialFilters = {
                lineFilters: _.get(filter, `lineFilters[${_k}]`, []),
                regionFilters: _.get(filter, `regionFilters[${_k}]`, []),
                pathFilters: _.get(filter, `pathFilters[${_k}]`, []),
              };
            }
          });
          this.setState({ channelsObj: channelmenu });
          if (this.cameraSearchFormRef.current && _fieldsValues.dateRange) {
            this.cameraSearchFormRef.current.setFieldsValue({
              dateRange: _fieldsValues.dateRange,
            });
          }
        });
        this.setFormItemVisibility();
      });
  }

  getChannelsObjFromChannelMenu(channelIDs) {
    const _channelMenu = _getChannelMenu_({
      locations: {
        loc: _.get(this.props, 'loc', {}),
        ch_grp: _.get(this.props, 'ch_grp', {}),
        ch: _.get(this.props, 'ch', {}),
      },
      locationID: _.get(this.props, 'locationID', null),
      channelGroupID: _.get(this.props, 'channelGroupID', null),
      channelID: _.get(this.props, 'channelID', null),
    });

    const channelChecked = (channelIDs || []).map((ch) => `CH-${ch}`);
    channelChecked.forEach((k: any) => {
      _checkboxOnClick_(k, _channelMenu);
    });

    return _channelMenu;
  }

  setFormItemVisibility() {
    const { locationID, channelID, accounts, assumeLicenses } = this.props;

    const unavailable = {};
    Object.entries(filterModulesInfo).forEach(([mKey, info]) => {
      if (!info.licenseTag) {
        return;
      }
      if (assumeLicenses) {
        return;
      }
      const hasLicense = entityHasLicenseOfType(
        accounts,
        info.licenseTag,
        +channelID,
        +locationID,
      );
      unavailable[mKey] = !hasLicense;
    });
    this.setState({ unavailable });
  }

  // Modals
  openAddCameraModal() {
    const installation = this.props.installationsByID[this.props.locationID];
    const channelSearchMenuRef = React.createRef();

    const modalRef = Modal.confirm({
      title: (
        <>
          <h4 style={{ fontSize: '16px', fontWeight: '600' }}>Add Cameras</h4>
          <p style={{ fontSize: '12px', fontWeight: '300' }}>
            Select locations, camera groups, and cameras below. Add directional
            filters by clicking the plus icon on the right.
          </p>
        </>
      ),
      icon: null,
      content: (
        <ChannelSearchMenu
          baseStationVersion={installation?.AppVersion}
          ref={channelSearchMenuRef}
          channelMenu={_.get(this.state, 'channelsObj', null)}
          locations={_.get(this.props, 'allLocations', [])}
          loc={_.get(this.props, 'loc', {})}
          ch_grp={_.get(this.props, 'ch_grp', {})}
          ch={_.get(this.props, 'ch', {})}
          locationID={_.get(this.props, 'locationID', null)}
          channelGroupID={_.get(this.props, 'channelGroupID', null)}
          channelID={_.get(this.props, 'channelID', null)}
          defaultChannelCheckedValue={_.get(this.state, 'channelIDs', [])}
          defaultLineFilters={_.get(this.state, 'lineFilters', {})}
          defaultRegionFilters={_.get(this.state, 'regionFilters', {})}
          defaultPathFilters={_.get(this.state, 'pathFilters', {})}
          updateModal={(_updatedProps: any) => {
            modalRef.update(_updatedProps);
          }}
          dispatch={this.props.dispatch}
        />
      ),
      closable: true,
      className: styles['add-camera-modal'],
      okText: 'Done',
      keyboard: false,
      onOk: () => {
        const _node = _.get(
          channelSearchMenuRef.current,
          'state.editableSpatialFilterNode',
          null,
        );

        if (_node) {
          channelSearchMenuRef.current.editableSpatialFilterNodeOnDone(
            _node,
            () => {
              this.addCameraModalOnOk(channelSearchMenuRef);
            },
          );
        } else {
          this.addCameraModalOnOk(channelSearchMenuRef);
        }
        modalRef.destroy();
      },
      onCancel: () => {
        modalRef.destroy();
      },
    });

    return {
      contentRef: channelSearchMenuRef,
      modalRef,
    };
  }

  openSpatialFilterModal(node: any) {
    const installation = this.props.installationsByID[this.props.locationID];
    const spatialFilterRef = React.createRef();

    const showModal = () => {
      let stateNode = this.state.spatialFilterNode;
      const modalRef = Modal.confirm({
        title: (
          <>
            <h4
              style={{
                fontSize: '16px',
                fontWeight: '600',
              }}>{`${stateNode.Name} Spatial Filters`}</h4>
            <p style={{ fontSize: '12px', fontWeight: '300' }}>
              Add spatial filters to the selected camera below.
            </p>
          </>
        ),
        icon: null,
        content: (
          <SpatialFilters
            baseStationVersion={installation?.AppVersion}
            ref={spatialFilterRef}
            channelNode={stateNode}
          />
        ),
        closable: true,
        className: `${styles['spatial-filter-modal']} df-spatial-filter-modal`,
        okText: 'Done',
        keyboard: false,
        onOk: () => {
          this.spatialFilterOnOk(spatialFilterRef, stateNode);
          modalRef.destroy();
        },
        onCancel: () => {
          modalRef.destroy();
        },
      });
    };

    let ch;
    if (_.get(node, 'Media[0].Thumbnail') === undefined) {
      ch = _.get(this.props, `ch.byId[${+node?.ChannelID}]`, null);
      if (ch) {
        node.Media = _.get(ch, 'LatestMedia', []) || _.get(ch, 'Media', []);
      }

      // INVESTIGTION PAGE
      const eventID = _.get(this.props, 'eventID', null);
      if (!node.Media && eventID) {
        const investigation_event = _.get(
          this.props,
          `investigation_events.byID[${eventID}]`,
          null,
        );
        if (investigation_event && investigation_event.Media) {
          node.Media = _.get(investigation_event, 'Media');
        }
      }
    }
    if (_.get(node, 'Media[0].Thumbnail')) {
      this.setState({ spatialFilterNode: node }, showModal);
    } else {
      this.props
        .dispatch({
          type: 'locations/fetchLocation',
          payload: { locationID: node.ProjectID },
        })
        .then(() => {
          ch = _.get(this.props, `ch.byId[${+node?.ChannelID}]`, null);
          if (ch) {
            node.Media = _.get(ch, 'LatestMedia', []) || _.get(ch, 'Media', []);
          }
          this.setState({ spatialFilterNode: node }, showModal);
        });
    }
  }

  // filter selected option
  createChannelOption(node, optionArr) {
    if (node instanceof LocationMenuNode) {
      if (!(node.menuNode.checked || node.menuNode.indeterminate)) return null;
      const _option = (
        <div
          className={styles['channel-location-option']}
          key={node.menuNode.key}>
          <div className={styles['option-location-name']}>{node.Name}</div>
        </div>
      );
      optionArr.push(_option);
      node.menuNode.childrenNodes.forEach((_node) =>
        this.createChannelOption(_node, optionArr),
      );
      return _option;
    }
    if (node instanceof ChannelGroupMenuNode) {
      if (!(node.menuNode.checked || node.menuNode.indeterminate)) return null;
      const _option = (
        <div
          className={styles['channel-channelgroup-option']}
          key={node.menuNode.key}>
          <div className={styles['option-channelgroup-name']}>{node.Name}</div>
        </div>
      );
      optionArr.push(_option);
      node.menuNode.childrenNodes.forEach((_node) =>
        this.createChannelOption(_node, optionArr),
      );
      return _option;
    }
    if (node instanceof ChannelMenuNode) {
      if (!(node.menuNode.checked || node.menuNode.indeterminate)) return null;
      const _noOfSpatialFilters = _.sumBy(
        Object.keys(node.spatialFilters),
        (key) => _.get(node.spatialFilters, [key, 'length'], 0),
      );

      const spatialFilterButtonRef = React.createRef();
      const _option = (
        <div
          className={styles['channel-channel-option']}
          key={node.menuNode.key}>
          <div className={styles['option-channel-name']}>{node.Name}</div>
          {false && (
            <Tooltip
              placement="bottomRight"
              title="Options"
              overlayClassName={styles['df-tool-tip-overlay-class-2']}>
              <div className={styles['option-channel-morebtn']}>
                <MoreOutlined />
              </div>
            </Tooltip>
          )}
          {false && (
            <div
              className={
                styles['option-channel-spatialfilter-hide-show-btnCtn']
              }
              onClick={(e) => {
                const current_target = e.currentTarget;
                const hide_sf_btn = current_target.querySelector(
                  `.${styles['spatialfilter-hide-btn']}`,
                );
                const show_sf_btn = current_target.querySelector(
                  `.${styles['spatialfilter-show-btn']}`,
                );

                if (hide_sf_btn.style.display === 'block') {
                  hide_sf_btn.style.display = 'none';
                  show_sf_btn.style.display = 'block';
                } else {
                  hide_sf_btn.style.display = 'block';
                  show_sf_btn.style.display = 'none';
                }
              }}>
              <Tooltip
                placement="bottomRight"
                title="Hide Spatial Filters"
                overlayClassName={styles['df-tool-tip-overlay-class-2']}>
                <div className={styles['spatialfilter-hide-btn']}>
                  <EyeFilled />
                </div>
              </Tooltip>
              <Tooltip
                placement="bottomRight"
                title="Show Spatial Filters"
                overlayClassName={styles['df-tool-tip-overlay-class-2']}>
                <div className={styles['spatialfilter-show-btn']}>
                  <EyeInvisibleFilled />
                </div>
              </Tooltip>
            </div>
          )}
          <Tooltip
            placement="bottomRight"
            title="Edit Spatial Filters"
            overlayClassName={styles['df-tool-tip-overlay-class-2']}>
            <div style={{ display: 'flex' }}>
              {this.props.fetchLocationLoading && (
                <LoadingSpinner fontSize={14} />
              )}
              <div
                className={styles['option-channel-spatialfilterbtn']}
                ref={spatialFilterButtonRef}
                onClick={() => {
                  this.openSpatialFilterModal(node);
                }}>
                <Icon component={SpatialFilterSvg} />
                <span className={styles.noOfSpatialFilterApplied}>
                  {_noOfSpatialFilters}
                </span>
              </div>
            </div>
          </Tooltip>
        </div>
      );
      optionArr.push(_option);
      return _option;
    }
    return null;
  }

  getChannelSelectedOptions() {
    const optionArr: any = [];
    const channelsObj = _.get(this.state, 'channelsObj', null);

    if (!channelsObj) return null;
    if (Array.isArray(channelsObj)) {
      channelsObj.forEach((_channelObj) => {
        this.createChannelOption(_channelObj, optionArr);
      });
    } else {
      this.createChannelOption(channelsObj, optionArr);
    }

    return optionArr;
  }

  addCameraModalOnOk(channelSearchMenuRef: any) {
    const channelMenu = _.get(
      channelSearchMenuRef.current,
      'state.channelMenu',
      [],
    );
    const _channelChecked = _.get(
      channelSearchMenuRef.current,
      'state.channelChecked',
      {},
    );
    const channels = Object.keys(_channelChecked).filter(
      (k) => _channelChecked[k],
    );
    const lineFilters = _.get(
      channelSearchMenuRef.current,
      'state.lineFilters',
      {},
    );
    const regionFilters = _.get(
      channelSearchMenuRef.current,
      'state.regionFilters',
      {},
    );
    const pathFilters = _.get(
      channelSearchMenuRef.current,
      'state.pathFilters',
      {},
    );
    const filter = {
      channelIDs: channels,
      channelsObj: _.cloneDeep(channelMenu),
      lineFilters: _.cloneDeep(lineFilters),
      regionFilters: _.cloneDeep(regionFilters),
      pathFilters: _.cloneDeep(pathFilters),
    };
    this.cameraSearchFormRef.current.setFieldsValue(filter);
    this.setState(filter, () => setChannelSearchFormScrollPosition());

    this.props.dispatch({
      type: 'search2/changeSearchFilter',
      payload: {
        ...filter,
        channelIDs: channels.map((ch) => ch.replace('CH-', '')),
      },
    });
  }

  spatialFilterOnOk(spatialFilterRef: any, node: any) {
    const lineFilters = _.get(
      spatialFilterRef.current,
      'state.lineFilters',
      [],
    );
    const regionFilters = _.get(
      spatialFilterRef.current,
      'state.regionFilters',
      [],
    );
    const pathFilters = _.get(
      spatialFilterRef.current,
      'state.pathFilters',
      [],
    );
    node.spatialFilters = {
      lineFilters: _.cloneDeep(lineFilters),
      regionFilters: _.cloneDeep(regionFilters),
      pathFilters: _.cloneDeep(pathFilters),
    };
    const filter = {
      lineFilters: { ...this.state.lineFilters },
      regionFilters: { ...this.state.regionFilters },
      pathFilters: { ...this.state.pathFilters },
    };
    if (lineFilters.length > 0) {
      filter.lineFilters[node.ChannelID] = _.cloneDeep(lineFilters);
    } else {
      if (_.has(filter.lineFilters, [node.ChannelID]))
        delete filter.lineFilters[node.ChannelID];
    }

    if (regionFilters.length > 0) {
      filter.regionFilters[node.ChannelID] = _.cloneDeep(regionFilters);
    } else {
      if (_.has(filter.regionFilters, [node.ChannelID]))
        delete filter.regionFilters[node.ChannelID];
    }

    if (pathFilters.length > 0) {
      filter.pathFilters[node.ChannelID] = _.cloneDeep(pathFilters);
    } else {
      if (_.has(filter.pathFilters, [node.ChannelID]))
        delete filter.pathFilters[node.ChannelID];
    }

    this.setState(filter);

    this.props.dispatch({
      type: 'search2/changeSearchFilter',
      payload: { ...filter },
    });
  }

  personColorFilterOnOk() {
    if (this.personColorSelectRef.current) {
      const top_colors = _.get(
        this.personColorSelectRef.current,
        'state.person_top_color',
        [],
      );
      const bottom_colors = _.get(
        this.personColorSelectRef.current,
        'state.person_bottom_color',
        [],
      );
      const filter = {
        person_colors: { top_colors, bottom_colors },
      };
      this.cameraSearchFormRef.current.setFieldsValue(filter);
      this.setState({ ...filter, editPersonColorState: false });
      this.props.dispatch({
        type: 'search2/changeSearchFilter',
        payload: { ...filter },
      });
    } else {
      this.setState({ editPersonColorState: false });
    }
  }

  vehicleColorFilterOnOk() {
    if (this.vehicleColorSelectRef.current) {
      const vehicle_colors = _.get(
        this.vehicleColorSelectRef.current,
        'state.vehicle_color',
        [],
      );
      const filter = { vehicle_colors };
      this.cameraSearchFormRef.current.setFieldsValue(filter);
      this.setState({ ...filter, editVehicleColorState: false });
      this.props.dispatch({
        type: 'search2/changeSearchFilter',
        payload: { ...filter },
      });
    } else {
      this.setState({ editVehicleColorState: false });
    }
  }

  setFilterEditState(module: any, value: any, callback: any) {
    const { editStates } = this.state;
    editStates[module] = value;
    this.setState({ editStates }, callback);
  }

  filterModuleOnOk(module: any) {
    if (this.selectRefs[module].current) {
      const setting = _.get(this.selectRefs[module].current, `state.${module}`);
      const filter = {};
      filter[module] = setting;

      this.cameraSearchFormRef.current.setFieldsValue(filter);

      this.setFilterEditState(module, false);
      this.setState({ ...filter });
      this.props.dispatch({
        type: 'search2/changeSearchFilter',
        payload: { ...filter },
      });
    } else {
      this.setFilterEditState(module, false);
    }
  }

  handleObjectSearch(clip: any) {
    const { loc, ch_grp, ch } = this.props;
    const info = interpretClipData(clip, { loc, ch, ch_grp });
    const filters = {};
    if (_.get(info, 'vehicle_details')) {
      if (_.get(info.vehicle_details, 'vehicle_colors')) {
        filters.vehicle_colors = info.vehicle_details.vehicle_colors;
      }
    }
    if (_.get(info, 'person_details')) {
      filters.person_colors = {
        top_colors: info.person_details.top_colors,
        bottom_colors: info.person_details.bottom_colors,
      };
    }
    if (_.get(info, 'object_type')) {
      filters.search_object = info['object_type'];
    }
    const clip_info = _.get(info, 'clip', {});
    if (clip_info && _.get(clip_info, 'ObjectID')) {
      filters.object_id = _.get(clip_info, 'ObjectID');
    }
    this.setState(filters);
    this.props.dispatch({
      type: 'search2/changeSearchFilter',
      payload: {
        ...filters,
      },
    });
    this.onSearchSubmit();
  }

  submitAllSearchFormFilter() {
    const { editPersonColorState, editVehicleColorState } = this.state;
    if (editPersonColorState) {
      this.personColorFilterOnOk();
    }
    if (editVehicleColorState) {
      this.vehicleColorFilterOnOk();
    }

    Object.keys(filterModulesInfo).forEach((mKey) => {
      if (this.state.editStates[mKey]) {
        this.filterModuleOnOk(mKey);
      }
    });
  }

  onSearchSubmit(is_reset_embed_payload: boolean = false) {
    this.submitAllSearchFormFilter();

    const resultHeight = getSearchResultsHeight();
    const resultWidth = getMainContentWidth();
    if (is_reset_embed_payload) {
      this.resetEmbeddingsSearchInfo();
    }

    this.props
      .dispatch({
        type: 'search2/changeSearchFilter',
        payload: {
          resultHeight,
          resultWidth,
          p_number: 1,
          p_size: Math.floor(resultHeight / 100) * Math.floor(resultWidth / 80),
          showSearchResults: true,
          showObjectSearchResult: false,
        },
      })
      .then(() => {
        this.props
          .dispatch({
            type: 'search2/search',
          })
          .then(
            () => {
              document.getElementById('loc-ch_grp-ch-title').scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            },
            (err) => console.log(err),
          );
      });
  }

  onSummarySubmit() {
    this.submitAllSearchFormFilter();
    if (!this.props.summaryObj) return;
    this.props.summaryObj.setState({
      showOriginalVideo: false,
    });
    const { eventID, investigationID } = this.props;
    const { investigation_events, dispatch } = this.props;

    const event = investigation_events.byID[eventID];
    if (event.SummaryIndexStatus === 'SUCCESS') {
      dispatch({
        type: 'investigation_events/getSummaryVideo',
        investigationID,
        eventID,
      }).then((res: any) => {
        if (res.success) {
          this.props.summaryObj.setSummaryReq(res.data.SummarizationRequestID);
        }
      });
    }
  }

  // Check Filter Have Value
  checkFilterSet(_obj: any) {
    const filter_name = _.get(_obj, 'filter_name', null);
    if (filter_name) {
      if (filter_name === 'person_colors') {
        if (
          _.get(this.state, 'person_colors.top_colors.length', 0) +
            _.get(this.state, 'person_colors.bottom_colors.length', 0) >
          0
        ) {
          return true;
        }
      } else if (filter_name === 'vehicle_colors') {
        if (_.get(this.state, 'vehicle_colors.length', 0) > 0) {
          return true;
        }
      } else {
        if (filter_name in filterModulesInfo && filter_name in this.state) {
          return filterModulesInfo[filter_name].isFilterSet(
            _.get(this.state, [filter_name], null),
          );
        }
      }
    }
    return false;
  }

  // Reset filters
  resetFilters() {
    const filter_locationID: any[] = [];
    const props_locationID = _.get(this.props, 'locationID', null);
    if (Array.isArray(props_locationID) && props_locationID.length > 0) {
      filter_locationID.push(...props_locationID);
    } else if (props_locationID) {
      filter_locationID.push(props_locationID);
    }

    const filter_channelIDs: any[] = [];
    const props_channelID = _.get(this.props, 'channelID', null);
    if (Array.isArray(props_channelID) && props_channelID.length > 0) {
      filter_channelIDs.push(...props_channelID);
    } else if (props_channelID) {
      filter_channelIDs.push(props_channelID);
    }

    const filter_investigationIDs: any[] = [];
    const props_investigationID = _.get(this.props, 'investigationID', null);
    if (
      Array.isArray(props_investigationID) &&
      props_investigationID.length > 0
    ) {
      filter_investigationIDs.push(...props_investigationID);
    } else if (props_investigationID) {
      filter_investigationIDs.push(props_investigationID);
    }

    const payload = {
      locationID: filter_locationID,
      channelIDs: filter_channelIDs,
      investigationIDs: [],
      person_colors: {
        top_colors: [],
        bottom_colors: [],
      },
      vehicle_colors: [],
      search_object: null,
      object_id: null,
      lineFilters: {},
      regionFilters: {},
      pathFilters: {},

      showSearchResults: false,
      showObjectSearchResult: false,
      searchResults: [],
      objectSearchResults: [],
    };

    Object.entries(filterModulesInfo).forEach(([mKey, info]) => {
      payload[mKey] = info.stateDefault;
    });

    const resetToInitialState = _.get(
      this.props.createSearchFilterRef,
      'current.resetToInitialState',
      () => {},
    );
    resetToInitialState();
    return this.props.dispatch({
      type: 'search2/changeSearchFilter',
      payload,
    });
  }

  // Reset filter by name
  resetFilterByFilterName(_obj: any) {
    const payload = {};

    const filter_name = _.get(_obj, 'filter_name', null);
    if (filter_name) {
      if (filter_name === 'person_colors') {
        payload.person_colors = {
          top_colors: [],
          bottom_colors: [],
        };
      } else if (filter_name === 'vehicle_colors') {
        payload.vehicle_colors = [];
      } else {
        if (filter_name in filterModulesInfo) {
          payload[filter_name] = filterModulesInfo[filter_name].stateDefault;
        }
      }
    }

    this.props.dispatch({
      type: 'search2/changeSearchFilter',
      payload,
    });
  }

  resetEmbeddingsSearchInfo() {
    const payload = {
      search_object: null,
      object_id: null,
    };
    this.props.dispatch({
      type: 'search2/changeSearchFilter',
      payload,
    });
  }

  renderFilterModuleInfo(mKey) {
    const info = filterModulesInfo[mKey];
    return (
      <Form.Item key={mKey} name={mKey}>
        <div>
          <FormItemDisabler
            disabler={_.get(this.state.unavailable, mKey, false)}
            channelID={+this.props.channelID}
            tag={info.licenseTag}
            licenseMode={info.licenseMode}
            refresh={() => {
              this.forceUpdate();
            }}
          />
          <div className={styles['filter-select']}>
            <Affix
              offsetTop={59}
              target={() => this.getAffixTarget()}
              style={{
                zIndex: '15',
                borderRadius: '8px',
                overflow: this.state.editStates[mKey] ? 'initial' : 'hidden',
              }}>
              <div className={styles['filter-header-ctn']}>
                <div
                  className={styles['filter-title']}
                  onClick={() => {
                    if (!this.state.editStates[mKey]) {
                      this.setFilterEditState(mKey, true, () =>
                        setChannelSearchFormScrollPosition(),
                      );
                    }
                  }}>
                  {info.displayName}
                </div>
                {!this.state.editStates[mKey] ? (
                  <>
                    {this.checkFilterSet({ filter_name: mKey }) ? (
                      <div
                        className={styles['filter-reset']}
                        onClick={() => {
                          this.resetFilterByFilterName({
                            filter_name: mKey,
                          });
                        }}>
                        <Tooltip
                          placement="bottomRight"
                          title="Reset Filter"
                          overlayClassName={
                            styles['df-tool-tip-overlay-class']
                          }>
                          <CloseOutlined />
                        </Tooltip>
                      </div>
                    ) : null}
                    <div
                      className={styles['filter-edit']}
                      onClick={() => {
                        this.setFilterEditState(mKey, true, () =>
                          setChannelSearchFormScrollPosition(),
                        );
                      }}>
                      <Tooltip
                        placement="bottomRight"
                        arrowPointAtCenter={true}
                        title={info.displayTooltip}>
                        <EditFilled />
                      </Tooltip>
                    </div>
                  </>
                ) : null}
                {this.state.editStates[mKey] ? (
                  <div className={styles['filter-done-cancel-ctn']}>
                    <div
                      onClick={() => {
                        this.filterModuleOnOk(mKey);
                      }}>
                      <Tooltip
                        placement="bottomRight"
                        title="Save"
                        overlayClassName={styles['df-tool-tip-overlay-class']}>
                        <CheckOutlined style={{ color: '#0045F7' }} />
                      </Tooltip>
                    </div>
                  </div>
                ) : null}
              </div>
            </Affix>
            <div className={styles['filter-options-selected-ctn']}>
              {info.getElement(
                info,
                this.selectRefs[mKey],
                this.state[mKey],
                this.state.editStates[mKey],
              )}
            </div>
          </div>
        </div>
      </Form.Item>
    );
  }

  render() {
    const {
      hideDate = false,
      hideChannel = false,
      hidePerson = false,
      hideVehicle = false,
      hideSubmit = false,
      showFilters = [],
      summaryObj,
    } = this.props;

    const {
      channelIDs = [],
      editableChannelSearchMenu = true,
      editPersonColorState = false,
      editVehicleColorState = false,
    } = this.state;

    return (
      <Form
        id="channel-search-form-id"
        className={styles['channel-search-form-class']}
        ref={this.cameraSearchFormRef}
        layout="vertical"
        requiredMark={false}>
        {!hideDate && (
          <Form.Item
            name="dateRange"
            rules={[
              {
                type: 'array',
                required: true,
                message: 'Please select time!',
              },
            ]}>
            <RangePickerDF
              style={{ width: '100%' }}
              layout="vertical"
              styleMode="grey"
              disabledDate={disabledDate}
              getPopupContainer={() => document.getElementById('search-form')}
              onChange={(dateRange: any) => this.setDateRange(dateRange)}
            />
          </Form.Item>
        )}
        {!hideChannel && (
          <Form.Item
            name="channelIDs"
            rules={[
              {
                type: 'array',
                required: true,
                message: 'Please select Channel!',
              },
            ]}>
            <div className={styles['channel-select']}>
              <Affix
                offsetTop={59}
                target={() => this.getAffixTarget()}
                style={{
                  zIndex: '15',
                  borderRadius: '8px',
                  overflow: editableChannelSearchMenu ? 'initial' : 'hidden',
                }}>
                <div className={styles['channel-header-ctn']}>
                  <div
                    className={styles['channel-title']}
                    onClick={() => {
                      if (editableChannelSearchMenu) {
                        this.openAddCameraModal();
                      }
                    }}>
                    Cameras
                  </div>
                  {editableChannelSearchMenu ? (
                    <div
                      className={styles['channel-edit']}
                      onClick={() => {
                        this.openAddCameraModal();
                      }}>
                      <Tooltip
                        placement="bottomRight"
                        title="Add Cameras"
                        overlayClassName={styles['df-tool-tip-overlay-class']}>
                        <EditFilled />
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              </Affix>
              <div className={styles['channel-options-selected-ctn']}>
                {channelIDs.length > 0
                  ? this.getChannelSelectedOptions()
                  : null}
              </div>
            </div>
          </Form.Item>
        )}
        <Collapse
          style={{ width: '100%' }}
          ghost={true}
          activeKey={this.state.openPanels}
          onChange={(value) => this.setState({ openPanels: value })}
          expandIconPosition="end">
          <Collapse.Panel
            key="Basics"
            forceRender={true}
            header={<div className={styles['collapse-header']}>Basics</div>}>
            {!hidePerson && (
              <Form.Item name="person_colors">
                <div className={styles['object-select']}>
                  <Affix
                    offsetTop={59}
                    target={() => this.getAffixTarget()}
                    style={{
                      zIndex: '15',
                      borderRadius: '8px',
                      overflow: editPersonColorState ? 'initial' : 'hidden',
                    }}>
                    <div className={styles['object-header-ctn']}>
                      <div
                        className={styles['object-title']}
                        onClick={() => {
                          if (!editPersonColorState) {
                            this.setState({ editPersonColorState: true }, () =>
                              setChannelSearchFormScrollPosition(),
                            );
                          }
                        }}>
                        People
                      </div>
                      {!editPersonColorState ? (
                        <>
                          {this.checkFilterSet({
                            filter_name: 'person_colors',
                          }) ? (
                            <div
                              className={styles['object-reset']}
                              onClick={() => {
                                this.resetFilterByFilterName({
                                  filter_name: 'person_colors',
                                });
                              }}>
                              <Tooltip
                                placement="bottomRight"
                                title="Reset Filter"
                                overlayClassName={
                                  styles['df-tool-tip-overlay-class']
                                }>
                                <CloseOutlined />
                              </Tooltip>
                            </div>
                          ) : null}
                          <div
                            className={styles['object-edit']}
                            onClick={() => {
                              this.setState(
                                { editPersonColorState: true },
                                () => setChannelSearchFormScrollPosition(),
                              );
                            }}>
                            <Tooltip
                              placement="bottomRight"
                              title="Select Person Color."
                              overlayClassName={
                                styles['df-tool-tip-overlay-class']
                              }>
                              <EditFilled />
                            </Tooltip>
                          </div>
                        </>
                      ) : null}
                      {editPersonColorState ? (
                        <div className={styles['object-done-cancel-ctn']}>
                          <div
                            onClick={() => {
                              this.personColorFilterOnOk();
                            }}>
                            <Tooltip
                              placement="bottomRight"
                              title="Save"
                              overlayClassName={
                                styles['df-tool-tip-overlay-class']
                              }>
                              <CheckOutlined style={{ color: '#0045F7' }} />
                            </Tooltip>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Affix>
                  <div className={styles['object-options-selected-ctn']}>
                    <PersonColorSelect
                      ref={this.personColorSelectRef}
                      defaultValue={this.state.person_colors}
                      editState={editPersonColorState}
                    />
                  </div>
                </div>
              </Form.Item>
            )}
            {!hideVehicle && (
              <Form.Item name="vehicle_colors">
                <div className={styles['object-select']}>
                  <Affix
                    offsetTop={59}
                    target={() => this.getAffixTarget()}
                    style={{
                      zIndex: '15',
                      borderRadius: '8px',
                      overflow: editVehicleColorState ? 'initial' : 'hidden',
                    }}>
                    <div className={styles['object-header-ctn']}>
                      <div
                        className={styles['object-title']}
                        onClick={() => {
                          if (!editVehicleColorState) {
                            this.setState({ editVehicleColorState: true }, () =>
                              setChannelSearchFormScrollPosition(),
                            );
                          }
                        }}>
                        Vehicles
                      </div>
                      {!editVehicleColorState ? (
                        <>
                          {this.checkFilterSet({
                            filter_name: 'vehicle_colors',
                          }) ? (
                            <div
                              className={styles['object-reset']}
                              onClick={() => {
                                this.resetFilterByFilterName({
                                  filter_name: 'vehicle_colors',
                                });
                              }}>
                              <Tooltip
                                placement="bottomRight"
                                title="Reset Filter"
                                overlayClassName={
                                  styles['df-tool-tip-overlay-class']
                                }>
                                <CloseOutlined />
                              </Tooltip>
                            </div>
                          ) : null}
                          <div
                            className={styles['object-edit']}
                            onClick={() => {
                              this.setState(
                                { editVehicleColorState: true },
                                () => setChannelSearchFormScrollPosition(),
                              );
                            }}>
                            <Tooltip
                              placement="bottomRight"
                              title="Select Vehicle Color."
                              overlayClassName={
                                styles['df-tool-tip-overlay-class']
                              }>
                              <EditFilled />
                            </Tooltip>
                          </div>
                        </>
                      ) : null}
                      {editVehicleColorState ? (
                        <div className={styles['object-done-cancel-ctn']}>
                          <div
                            onClick={() => {
                              this.vehicleColorFilterOnOk();
                            }}>
                            <Tooltip
                              placement="bottomRight"
                              title="Save"
                              overlayClassName={
                                styles['df-tool-tip-overlay-class']
                              }>
                              <CheckOutlined style={{ color: '#0045F7' }} />
                            </Tooltip>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Affix>
                  <div className={styles['object-options-selected-ctn']}>
                    <VehicleColorSelect
                      ref={this.vehicleColorSelectRef}
                      defaultValue={this.state.vehicle_colors}
                      editState={editVehicleColorState}
                    />
                  </div>
                </div>
              </Form.Item>
            )}
          </Collapse.Panel>
          {showFilters.map((groups: any) => {
            return (
              <Collapse.Panel
                key={groups.label}
                forceRender={true}
                header={
                  <div className={styles['collapse-header']}>
                    {groups.label}
                  </div>
                }>
                {groups.list.map((mKey) => this.renderFilterModuleInfo(mKey))}
              </Collapse.Panel>
            );
          })}
        </Collapse>
        {!hideSubmit && (
          <Form.Item>
            <div className={styles['submit-btn-ctn']}>
              {summaryObj ? (
                <div className={styles['summary-apply-btn-ctn']}>
                  <Button
                    block
                    size="small"
                    type="primary"
                    onClick={() => {
                      this.onSummarySubmit();
                    }}>
                    Apply
                  </Button>
                  <Button
                    block
                    size="small"
                    type="text"
                    style={{
                      color: '#6C757D',
                      marginTop: '4px',
                    }}
                    onClick={() => {
                      this.resetFilters().then(() => {
                        this.onSummarySubmit();
                      });
                    }}>
                    Reset All Filters
                  </Button>
                </div>
              ) : (
                <div className={styles['search-btn-ctn']}>
                  <Button
                    block
                    size="small"
                    type="primary"
                    onClick={() => {
                      this.onSearchSubmit(true);
                    }}>
                    Search
                  </Button>
                  <Button
                    block
                    size="small"
                    type="text"
                    style={{
                      color: '#6C757D',
                      marginTop: '4px',
                    }}
                    onClick={() => {
                      this.resetFilters();
                    }}>
                    Reset All Filters
                  </Button>
                </div>
              )}
            </div>
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default CameraSearchForm;
