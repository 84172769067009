import React, { Component } from 'react';
import { connect } from 'umi';
import LoginForm from './components/LoginForm';
import styles from './style.less';

// @ts-expect-error
@connect(({ user, loading }) => ({
  user,
  loading,
}))
class LoginPage extends Component {
  state = {};

  handleSubmit(err, values) {
    if (!err) {
      const { dispatch } = this.props;
      dispatch({
        type: 'user/login',
        payload: values,
      });
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.main}>
          <LoginForm
            loading={this.props.loading.effects['user/login']}
            onSubmit={(err, values) => this.handleSubmit(err, values)}
            user={this.props.user}
          />
        </div>
      </div>
    );
  }
}

export default LoginPage;
