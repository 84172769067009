import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import ViewLayout from '@/layouts/PhysicalSecurityLayout/ViewLayout/ViewLayout';
import View from '@/pages/views/view/index';
import { dispatchWithFeedback, isEmail } from '@/utils/utils';
import {
  ReportFrequency,
  ReportFrequencyLabels,
  VIEW_MODES,
} from '../constants';
import { getViewPlaceholders } from '../utils';
import ReportSections from './sections';

const { Option } = Select;

type Props = {
  reportId?: number;
  onClose: Function;
};

const CreateUpdateReport: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { onClose } = props;
  const isLoading = useSelector((state) => {
    const loadingEffects = state.loading.effects;
    return (
      loadingEffects['retail_insights/createReport'] ||
      loadingEffects['retail_insights/updateReport']
    );
  });

  const [reportId, setReportID] = useState(props.reportId);
  const report = useSelector(
    (state) => state.retail_insights.reports.byId[reportId],
  );
  const [error, setError] = useState(null);
  const [openEditLayout, setOpenEditLayout] = useState(false);
  const mounted = useRef(false);
  const closeModal = useRef(false);
  const addButtonRef = useRef();
  const viewPlaceHolders = useMemo(() => {
    return report && getViewPlaceholders(report);
  }, [report]);
  const frequency = Form.useWatch('frequency', form);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const onBeforeAdd = () => {
    if (!reportId) {
      form.validateFields().catch((e) => {
        if (e?.errorFields.length) {
          setError(
            'Please fix the highlighted error before adding report sections',
          );
        }
      });
      form.submit();
    }
  };

  const onFinish = (formData: any) => {
    setError(null);
    const payload = {
      name: formData.name,
      frequency: formData.frequency,
      emails: formData.emails?.split(',').map((email) => email.trim()) || [],
      enabled: true,
    };

    if (reportId) {
      payload.report_id = reportId;
    }
    return dispatchWithFeedback(
      dispatch,
      `${reportId ? 'Updating' : 'Creating'} Report`,
      {
        type: `retail_insights/${reportId ? 'updateReport' : 'createReport'}`,
        payload,
      },
      !closeModal.current,
    ).then((res) => {
      if (mounted.current && !reportId) {
        setReportID(res?.Data?.report.ID);
      }
      if (closeModal.current) {
        onClose();
      } else if (addButtonRef.current) {
        addButtonRef.current.click();
      }
    });
  };

  const onDone = () => {
    closeModal.current = true;
    form.submit();
  };

  const onSectionsReorder = (sectionsOrder) => {
    dispatchWithFeedback(
      dispatch,
      'Updating Sections Order',
      {
        type: 'retail_insights/updateReport',
        payload: {
          batch_update_sections: sectionsOrder,
          report_id: report.id,
        },
      },
      false,
    );
  };

  const title = `Configuring Report`;
  let initialValues = {};

  if (report) {
    initialValues = {
      name: report.name,
      frequency: report.frequency,
      emails: report.emails.join(','),
    };
  }

  return (
    <div style={{ width: '50%' }}>
      <div style={{ margin: '16px 0px', fontSize: '16px' }}>{title}</div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={initialValues}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please enter a name for the report',
            },
          ]}>
          <Input placeholder="Enter a name" />
        </Form.Item>
        <Form.Item
          name="frequency"
          label="Frequency"
          rules={[
            {
              required: true,
              message: 'Please select a frequency',
            },
          ]}>
          <Select placeholder="Select a frequency">
            {Object.values(ReportFrequency).map((freq) => (
              <Option key={freq} value={freq}>
                {ReportFrequencyLabels[freq]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="emails"
          label="Emails"
          rules={[
            () => ({
              validator(_, value) {
                const emailArray = value
                  ? value.split(',').map((email) => email.trim())
                  : [];
                if (!emailArray.every(isEmail)) {
                  return Promise.reject(
                    new Error('One or more emails are invalid'),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}>
          <Input placeholder="Enter emails separated by commas" />
        </Form.Item>
        {error && (
          <div className="df-error-text" style={{ margin: '16px 0px' }}>
            {error}
          </div>
        )}
        <ReportSections
          report={report}
          frequency={frequency}
          onBeforeAdd={onBeforeAdd}
          addButtonRef={addButtonRef}
          onSectionsReorder={onSectionsReorder}
        />
      </Form>
      <Button
        onClick={() => {
          setOpenEditLayout(true);
        }}
        type="link"
        disabled={!report || !report.sections.length}
        style={{ padding: '0px' }}>
        Edit Dashboard Layout
      </Button>
      <div
        style={{ margin: '16px 0px', display: 'flex', alignItems: 'self-end' }}>
        <Button onClick={onClose}>Cancel</Button>
        &nbsp;&nbsp;
        <Button type="primary" onClick={onDone} disabled={isLoading}>
          Done
        </Button>
      </div>
      {
        <Modal
          title="Edit Dashboard Layout"
          visible={openEditLayout}
          className="views-modal"
          width={'40%'}
          bodyStyle={{ padding: '0px 16px', marginTop: '-16px' }}
          onOk={() => {
            setOpenEditLayout(false);
            // save config with new layout
          }}
          onCancel={() => {
            setOpenEditLayout(false);
          }}>
          {report && (
            <ViewLayout viewId={report.view_id}>
              <View
                viewId={report.view_id}
                isEmbedded={true}
                mode={VIEW_MODES.CONFIGURE}
                isLayoutEditable={true}
                canAddNewTiles={false}
                canRemoveTiles={false}
                placeholders={viewPlaceHolders}
              />
            </ViewLayout>
          )}
        </Modal>
      }
    </div>
  );
};

export default CreateUpdateReport;
