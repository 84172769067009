import { Typography } from 'antd';

import styles from './style.less';

const HomeDeprecated = () => {
  const { Title, Text } = Typography;

  return (
    <div className={styles['home-deprecation-container']}>
      <Title level={3}>Welcome to Dragonfruit!</Title>
      <Text strong>Use the sidebar to navigate to your apps</Text>
    </div>
  );
};

export default HomeDeprecated;
