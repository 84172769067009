import React from 'react';
import { Input, Modal, Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class ShareInsight extends React.Component {
  state = { visible: false };

  onCopy() {
    this.setState({ copied: !this.state.copied });
    setTimeout(() => {
      this.setState({ copied: !this.state.copied });
    }, 3000);
  }

  toggleModal() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  render() {
    const { children, insight } = this.props;
    return (
      <>
        <Modal
          width={600}
          style={{ height: '360px' }}
          footer={null}
          wrapClassName="share-insight-modal"
          title={
            <div>
              Share Insight
              {/* <p className={styles['modal-header-subtext']}>
                Select an option below to share your report
              </p> */}
            </div>
          }
          visible={this.state.visible}
          onOk={() => this.toggleModal()}
          onCancel={() => this.toggleModal()}>
          <div>
            <div style={{ paddingBottom: '5px' }}>
              Anyone with the link can view, even without a Dragonfruit account.
            </div>
            <Input
              size="small"
              value={`${BASE_URL}/insight-report/${insight.InsightID}`}
              style={{ width: '85%' }}
            />
            <CopyToClipboard
              onCopy={() => this.onCopy()}
              text={`${BASE_URL}/insight-report/${insight.InsightID}`}
              style={{ width: '15%' }}>
              <Button
                type="link"
                style={{
                  color: '@primary-color !important',
                  fontWeight: '400 !important',
                  fontSize: '11px !important',
                }}>
                {this.state.copied ? 'Copied' : 'Copy URL'}
              </Button>
            </CopyToClipboard>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: '4px',
            }}>
            <Button onClick={() => this.toggleModal()}>Cancel</Button>
            &nbsp; &nbsp;
            <Button
              onClick={() => this.toggleModal()}
              key="done"
              type="primary">
              Done
            </Button>
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default ShareInsight;
