import * as React from 'react';

const AccountIcon = ({
  height = '16px',
  width = '16px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}>
    <path d="M8.00001 1.33331C4.32001 1.33331 1.33334 4.31998 1.33334 7.99998C1.33334 11.68 4.32001 14.6666 8.00001 14.6666C11.68 14.6666 14.6667 11.68 14.6667 7.99998C14.6667 4.31998 11.68 1.33331 8.00001 1.33331ZM8.00001 3.33331C9.10668 3.33331 10 4.22665 10 5.33331C10 6.43998 9.10668 7.33331 8.00001 7.33331C6.89334 7.33331 6.00001 6.43998 6.00001 5.33331C6.00001 4.22665 6.89334 3.33331 8.00001 3.33331ZM8.00001 12.8C6.33334 12.8 4.86001 11.9466 4.00001 10.6533C4.02001 9.32665 6.66668 8.59998 8.00001 8.59998C9.32668 8.59998 11.98 9.32665 12 10.6533C11.14 11.9466 9.66668 12.8 8.00001 12.8Z" />
  </svg>
);
export default AccountIcon;
