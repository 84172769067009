import { getCurrentCustomerID } from '@/utils/utils';
import React, { createContext, useMemo, useState } from 'react';
import { useFragment } from 'react-relay';
import { useLocation } from 'umi';
import { RecentChannelsTileFragment } from './RecentChannelsTile';
import type {
  RecentChannelsTileFragment$data,
  RecentChannelsTileFragment$key,
} from './__generated__/RecentChannelsTileFragment.graphql';

// Define the initial state of the context
interface VMSPlusContextState {
  appId: number;
  customerId: number;
  setSelectedChannels: (channels: string[]) => void;
  isSidebarHidden?: boolean;
  selectedChannels?: string[];
  toggleSidebarVisibility: () => void;
  selectedTileRef?: RecentChannelsTileFragment$key;
  setSelectedTileRef: (tileRef?: RecentChannelsTileFragment$key) => void;
  passedChannelIds?: string[];
  isTileViewingMode?: boolean;
  newChannelsSelected?: boolean;
  isTimelineViewingMode?: boolean;
  selectedTileData?: RecentChannelsTileFragment$data | null;
}

// Create the context
export const VMSPlusContext = createContext<VMSPlusContextState | undefined>(
  undefined,
);

interface VMSPlusProviderProps {
  appId: number;
  children: JSX.Element;
}

// Create a provider component
export const VMSPlusProvider = ({ appId, children }: VMSPlusProviderProps) => {
  const location = useLocation();
  const customerId = useMemo(() => getCurrentCustomerID(), []);
  const passedChannelIds = useMemo(
    () => new URLSearchParams(location.search).getAll('channelIds'),

    [location.search],
  );
  // Define the state variables and functions you want to expose in the context
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const [selectedChannels, setSelectedChannels] =
    useState<string[]>(passedChannelIds);
  const [selectedTileRef, setSelectedTileRef] =
    useState<RecentChannelsTileFragment$key>();

  const selectedTileData = useFragment(
    RecentChannelsTileFragment,
    selectedTileRef,
  );

  const newChannelsSelected =
    (selectedTileData?.channelIDs?.length ?? 0) !==
    (selectedChannels?.length ?? 0);

  const isTileViewingMode = !!selectedChannels?.length && !newChannelsSelected;
  const isTimelineViewingMode = !!selectedChannels?.length;

  const toggleSidebarVisibility = () => {
    setIsSidebarHidden((prev) => !prev);
  };

  return (
    <VMSPlusContext.Provider
      value={{
        appId,
        customerId,
        isSidebarHidden,
        toggleSidebarVisibility,
        setSelectedChannels,
        selectedChannels,
        setSelectedTileRef,
        selectedTileRef,
        passedChannelIds,
        isTileViewingMode,
        newChannelsSelected,
        isTimelineViewingMode,
        selectedTileData,
      }}>
      {children}
    </VMSPlusContext.Provider>
  );
};

export const useVMSPlusContext = () => {
  const context = React.useContext(VMSPlusContext);
  if (context === undefined) {
    throw new Error('useVMSPlusContext must be used within a VMSPlusProvider');
  }
  return context;
};
