import AccountIcon from '@/assets/account-plain';
import Logomark from '@/assets/logomark';
import { getWebsocketState, sendToSocket } from '@/services/sockets';
import Icon, { WarningOutlined } from '@ant-design/icons';
import { Layout, Tooltip } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, Link, NavLink } from 'umi';
import styles from './style.less';
const { Header } = Layout;

import InviteForm from '@/pages/account/users-permissions/components/invite-user';
import { getCurrentCustomerID } from '@/utils/utils';

// @ts-expect-error
@connect(({ user }) => ({
  currentUser: user.currentUser,
}))
class AppHeader extends React.Component {
  state = {
    websocketState: null,
  };

  componentDidMount() {
    const fn = () => {
      // these pings are 'keepalive' messages required for chrome-based
      // browsers; they're not required for safari browsers. without this,
      // if the websocket connection glitches, the browser doesn't know that
      // the connection was broken, and doesn't try to reconnect
      sendToSocket({ type: 'ping' });
      const websocketState = getWebsocketState();
      if (websocketState !== this.state.websocketState) {
        this.setState({ websocketState: getWebsocketState() });
      }
    };
    fn();
    this.pollInterval = setInterval(fn, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval);
  }

  openFreshDesk() {
    if (FreshworksWidget) {
      FreshworksWidget('open', 'ticketForm');
    }
  }

  render() {
    const { currentUser } = this.props;
    const firstName = currentUser?.FirstName;

    // Current user role
    let showAddUser = false;
    const current_customer_roles = _.get(
      currentUser,
      `Customers[${getCurrentCustomerID()}].Roles`,
      [],
    );
    if (current_customer_roles.indexOf('ADMIN_USER') !== -1) {
      showAddUser = true;
    }

    return (
      <Header className={styles.header}>
        <div className={styles.logo}>
          <Link to={'/home'}>
            <Logomark width="181px" height="28px" />
          </Link>
        </div>
        <div className={styles.actions}>
          <div className={styles.action} onClick={() => location.reload()}>
            {(!window.navigator.onLine || this.state.websocketState !== 1) && (
              <Tooltip title="Disconnected from Cloud. Reconnecting...">
                <WarningOutlined />
              </Tooltip>
            )}
          </div>

          {showAddUser ? (
            <div className={styles.action}>
              <InviteForm>Invite Team</InviteForm>
            </div>
          ) : null}
          <div className={styles.action}>
            <a id="my_custom_link" onClick={() => this.openFreshDesk()}>
              Contact Support
            </a>
          </div>
          <NavLink
            to="/account/settings"
            className={({ isActive }) =>
              `${styles.action} ${styles.account} ${
                isActive ? styles['active-account'] : ''
              }`
            }>
            <Icon component={AccountIcon} className={styles['account-icon']} />
            {firstName || 'Account'}
          </NavLink>
        </div>
      </Header>
    );
  }
}

export default AppHeader;
