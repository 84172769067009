import { Button, Checkbox, Empty, Modal, Popconfirm } from 'antd';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import AnchorDateSelector from '@/components/AnchorDateSelector/anchor-date-selection';
import DataList from '@/components/DataList';
import { dispatchWithFeedback } from '@/utils/utils';
import moment from 'moment';
import { Report, ReportFrequency, ReportFrequencyLabels } from '../constants';
import styles from '../style.less';
import CreateUpdateReport from './create-update';

const GenerateReportModal: React.FC<{
  reportID: number;
  onCancel: () => void;
}> = ({ reportID, onCancel }) => {
  const dispatch = useDispatch();

  const report: Report = useSelector(
    (state) => state.retail_insights.reports.byId[reportID],
  );
  const [endDate, setEndDate] = useState(null);
  const startDate = useMemo(() => {
    if (!endDate) return null;

    switch (report.frequency) {
      case ReportFrequency.Daily:
        return moment(endDate).startOf('day');
      case ReportFrequency.Weekly:
        // Week starts on sunday morning according to moment but anchorselector starts it on monday, so hack
        return moment(endDate).startOf('week');
      case ReportFrequency.Monthly:
        return moment(endDate).startOf('month');
      default:
        return null;
    }
  }, [endDate]);

  return (
    <Modal
      title={'Regenerate Report'}
      onCancel={onCancel}
      visible={true}
      onOk={() => {
        dispatchWithFeedback(dispatch, 'Report scheduling ', {
          type: `retail_insights/mailReport`,
          payload: {
            report_id: reportID,
            ...(startDate && endDate
              ? {
                  override: {
                    date: {
                      start: startDate.format('YYYY-MM-DD'),
                      end: endDate.format('YYYY-MM-DD'),
                    },
                  },
                }
              : {}),
          },
        });
        onCancel();
      }}
      okText={'Generate'}>
      <div>
        Calculate the report for the current or prior period, and email the
        report to configured recipients.
        <br />
      </div>
      <div className={styles['description']}>
        The emailed report might take up to an hour, depending on the complexity
        of the sections, and the duration selected.
        <br />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '8px' }}>Select duration:</span>{' '}
        <AnchorDateSelector
          frequency={report.frequency}
          onDateChange={(date) => {
            setEndDate(date);
          }}
          timezone={moment.tz.guess()}
        />
      </div>
    </Modal>
  );
};

const Reports: React.FC = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.retail_insights.reports);
  const isLoading = useSelector((state) => {
    const loadingEffects = state.loading.effects;
    return (
      loadingEffects['retail_insights/fetchReports'] ||
      loadingEffects['retail_insights/fetchReport'] ||
      loadingEffects['retail_insights/removeReport'] ||
      loadingEffects['retail_insights/updateReport'] ||
      loadingEffects['retail_insights/duplicateReport']
    );
  });
  const [showCreateUpdateView, toggleCreateUpdateView] = useState(false);
  const [currentReportId, setCurrentReportId] = useState(null);
  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
  const rows: Report[] = reports.all
    .map((reportId: number) => {
      return {
        ...reports.byId[reportId],
        key: reportId,
      };
    })
    .sort((a: Report, b: Report) => a.name.localeCompare(b.name));

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      render: (freq) => ReportFrequencyLabels[freq],
    },
    {
      title: 'Sections',
      dataIndex: 'sections',
      render: (sections, record) => {
        const numIncompleteSections =
          sections.length - record.completed_section_count;
        return (
          <span>
            {sections.length}{' '}
            <span className="df-warn-text">
              {numIncompleteSections > 0
                ? `(${numIncompleteSections} Incomplete)`
                : ''}
            </span>
          </span>
        );
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      render: (enabled, record) => {
        const onChange = (e) => {
          const payload = {
            enabled: e.target.checked,
            report_id: record.id,
          };
          dispatchWithFeedback(
            dispatch,
            `Updating Report`,
            {
              type: `retail_insights/updateReport`,
              payload,
            },
            false,
          );
        };
        return (
          <Checkbox
            defaultChecked={enabled}
            disabled={isLoading}
            onChange={onChange}
          />
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <div>
            <span
              onClick={() => {
                setCurrentReportId(record.id);
                toggleCreateUpdateView(true);
              }}
              className="df-link">
              Edit
            </span>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to duplicate this report?"
              onConfirm={() => {
                dispatchWithFeedback(dispatch, 'Duplicating report', {
                  type: `retail_insights/duplicateReport`,
                  payload: {
                    report_id: record.id,
                  },
                });
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link">Duplicate</span>
            </Popconfirm>
            &nbsp;&nbsp;
            <span
              className="df-link"
              style={
                !record.enabled
                  ? { cursor: 'not-allowed', color: 'gray' }
                  : undefined
              }
              onClick={() => {
                setCurrentReportId(record.id);
                setShowGenerateReportModal(true);
              }}>
              Generate
            </span>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to delete this report?"
              onConfirm={() => {
                dispatchWithFeedback(dispatch, 'Deleting Report', {
                  type: `retail_insights/removeReport`,
                  payload: {
                    report_id: record.id,
                  },
                });
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link df-error-text">Delete</span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      {showGenerateReportModal && (
        <GenerateReportModal
          reportID={currentReportId}
          onCancel={() => setShowGenerateReportModal(false)}
        />
      )}
      {showCreateUpdateView ? (
        <CreateUpdateReport
          reportId={currentReportId}
          onClose={() => {
            setCurrentReportId(null);
            toggleCreateUpdateView(false);
          }}
        />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              margin: '16px 0px',
            }}>
            <div>
              <Button
                type="primary"
                onClick={() => toggleCreateUpdateView(true)}>
                New Report
              </Button>
            </div>
          </div>
          <DataList
            size="small"
            columns={columns}
            dataList={rows}
            isLoading={isLoading}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No reports found"
                />
              ),
            }}
          />
        </>
      )}
    </div>
  );
};

export default Reports;
