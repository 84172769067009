import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { Form, Modal, Button } from 'antd';
import ChannelSelect2 from '@/components/ChannelSelect2';

// @ts-expect-error
@connect(({ loading }) => ({ loading }))
class SelectCameras extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = { showModal: false };
  }

  init() {
    this.setState({
      viewChannels: this.props.channelIDs,
    });
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.channelIDs, this.props.channelIDs)) {
      this.init();
    }
  }

  handleSelectCameras(e) {
    e.preventDefault();
    this.form.current
      .validateFields()
      .then((values) => {
        let newChannelIDs = _.get(values, 'channelIDs', []);

        return this.props.setChannelIDs(newChannelIDs);
      })
      .then(() => {
        this.form.current?.resetFields();
        this.toggleAddCameraModal();
        if (this.props.onDone) {
          this.props.onDone();
        }
      })
      .catch((err: any) => console.log('err', err));
  }

  toggleAddCameraModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  render() {
    const { children, loading } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <Modal
          width={500}
          height={180}
          title="Select Cameras"
          visible={showModal}
          onCancel={() => this.toggleAddCameraModal()}
          destroyOnClose={true}
          footer={[
            <Button key="cancel" onClick={() => this.toggleAddCameraModal()}>
              Cancel
            </Button>,
            <Button
              key="addcamera"
              loading={loading.effects['views/setChannelsInView']}
              onClick={(e) => this.handleSelectCameras(e)}
              type="primary">
              Save
            </Button>,
          ]}>
          <div>
            <Form
              layout="vertical"
              colon={false}
              ref={this.form}
              requiredMark={false}>
              <Form.Item
                name="channelIDs"
                initialValue={this.state.viewChannels}>
                <ChannelSelect2
                  selecttype="treeselect"
                  multiple
                  value={this.state.viewChannels}
                  onChange={(value) => this.setState({ viewChannels: value })}
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleAddCameraModal()}>{children}</span>
      </>
    );
  }
}
export default SelectCameras;
