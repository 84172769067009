import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  TimePicker,
  TreeSelect,
} from 'antd';
import moment from 'moment-timezone';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'umi';

import { dispatchWithFeedback } from '@/utils/utils';
import {
  LPRReportFrequency,
  LPRReportFrequencyLabels,
  LPRReportType,
  LPRReportTypeLabels,
} from '../constants';

const { Option } = Select;

const DurationFields = forwardRef(({ namespace, config }, ref) => {
  const appId = useSelector((state) => state[namespace].app_id);
  // const channelSettings = useSelector((state) => {
  //   return _.uniqBy(state[namespace].channel_settings.all.map((inputId) => {
  //     return state[namespace].channel_settings.byId[inputId];
  //   }), ['channel', 'id'])
  // });
  // const channelSettingsBySiteId = {};
  // channelSettings.forEach((input) => {
  //   if (!channelSettingsBySiteId[input.site.id]) {
  //     channelSettingsBySiteId[input.site.id] = [];
  //   }
  //   channelSettingsBySiteId[input.site.id].push(input);
  // });
  const sites = useSelector(
    (state) =>
      state.apps.all.filter((app) => app.AppID == appId)[0].scopes.sites,
  );
  const treeData = sites.map((site) => ({
    title: site.name,
    value: `site_${site.id}`,
    key: `site_${site.id}`,
    children: [],
    // Comment this out if you want to include channels too here
    // children: channelSettingsBySiteId[site.id] ?
    //   (channelSettingsBySiteId[site.id].map((setting) => ({
    //     title: setting.channel.name,
    //     value: `channel_${setting.channel.id}`,
    //     key: `channel_${setting.channel.id}`
    //   })))
    //   : []
  }));
  const [formData, setFormData] = useState(() => {
    if (config && config.thresholds) {
      return config.thresholds.map((item, index) => {
        return {
          key: index + 1,
          channel_site: `${item.site_id ? 'site' : 'channel'}_${
            item.site_id ?? item.channel_id
          }`,
          threshold: moment().startOf('day').add(item.duration, 'seconds'),
        };
      });
    }
    return [];
  });

  const handleAddRow = () => {
    const newRow = {
      key: formData.length + 1,
      channel_site: undefined,
      threshold: moment('00:30:00', 'HH:mm:ss'),
    };

    setFormData([...formData, newRow]);
  };

  const handleDropdownChange = (value, key, columnName) => {
    const updatedFormData = formData.map((row) => {
      if (row.key === key) {
        return { ...row, [columnName]: value };
      }
      return row;
    });

    setFormData(updatedFormData);
  };

  const handleDeleteRow = (key) => {
    const updatedFormData = formData.filter((row) => row.key !== key);
    setFormData(updatedFormData);
  };

  //Function that be accessed using ref from parent
  //This is not a recommended pattern, but suits our use case well
  useImperativeHandle(ref, () => ({
    getFormData() {
      const thresholds = formData.map(({ channel_site, threshold }) => {
        const [type, id] = channel_site.split('_');
        return {
          [type + '_id']: parseInt(id),
          duration:
            threshold.hour() * 3600 +
            threshold.minute() * 60 +
            threshold.second(),
        };
      });
      return { thresholds: thresholds };
    },
  }));

  const columns = [
    {
      title: 'Select Input',
      dataIndex: 'channel_site',
      render: (text, record) => (
        <TreeSelect
          showSearch
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="Please select a site/channel"
          value={text}
          allowClear
          treeDefaultExpandAll
          treeData={treeData}
          onChange={(value) =>
            handleDropdownChange(value, record.key, 'channel_site')
          }></TreeSelect>
      ),
      width: '50%',
      resizable: false,
      align: 'left',
    },
    {
      title: 'Choose Threshold',
      dataIndex: 'threshold',
      render: (text, record) => (
        <TimePicker
          value={text}
          onChange={(value) => {
            handleDropdownChange(value, record.key, 'threshold');
          }}
          placeholder="Select a time"
        />
      ),
      width: '40%',
      resizable: false,
      align: 'left',
    },
    {
      title: '',
      dataIndex: 'actions',
      className: 'no-padding',
      render: (_, record) => (
        <Button
          className="df-error-text"
          type="link"
          onClick={() => handleDeleteRow(record.key)}>
          <CloseCircleOutlined />
        </Button>
      ),
      width: '10%',
      resizable: false,
    },
  ];

  return (
    <Form.Item
      name="threshold"
      rules={[
        () => ({
          validator() {
            if (!formData.length) {
              return Promise.reject(
                new Error('Please add at least one site/channel'),
              );
            }
            const areEntriesValid = formData.every(
              (row) => row.channel_site && row.threshold,
            );
            if (!areEntriesValid) {
              return Promise.reject(
                new Error('One or more inputs are invalid'),
              );
            }
            return Promise.resolve();
          },
        }),
      ]}>
      <div style={{ marginBottom: '10px' }}>
        Add one or more sites/channels that you want to include in this report
      </div>
      {formData.length ? (
        <Table
          dataSource={formData}
          columns={columns}
          pagination={false}
          rowKey="key"
          locale={{
            emptyText: <div></div>,
          }}
          tableLayout="fixed"
        />
      ) : null}
      <Button
        onClick={handleAddRow}
        type="link"
        style={{ padding: '10px 0px' }}>
        <PlusCircleOutlined style={{ fontSize: '20px' }} />
      </Button>
    </Form.Item>
  );
});

type Props = {
  namespace: string;
  reportId?: number;
  onClose: Function;
};

const CreateUpdateReport: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { reportId, namespace, onClose } = props;
  const report = useSelector(
    (state) => state[namespace].reports.byId[reportId],
  );
  //React ref to a component that renders additional fields based on the type of report
  const additionalFieldsRef = useRef(null);

  const onFinish = (formData: any) => {
    let payload = {
      name: formData.name,
      frequency: formData.frequency,
      type: formData.type,
      emails: formData.emails?.split(',').map((email) => email.trim()) || [],
    };

    if (additionalFieldsRef.current) {
      payload['config'] = additionalFieldsRef.current.getFormData();
    }

    if (reportId) {
      payload.id = reportId;
    }

    dispatchWithFeedback(
      dispatch,
      `${reportId ? 'Updating' : 'Creating'} Report`,
      {
        type: `${namespace}/${reportId ? 'updateReport' : 'createReport'}`,
        payload,
      },
      false,
    ).then(() => {
      onClose();
    });
  };

  const title = `${!reportId ? 'Create New' : 'Update'} Report`;
  let initialValues = {};
  if (report) {
    initialValues = {
      name: report.name,
      frequency: report.frequency,
      type: report.type,
      emails: report.emails.join(','),
      config: report.config,
    };
  }

  return (
    <Modal title={title} visible={true} onCancel={onClose} onOk={form.submit}>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={initialValues}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please enter a name for the report',
            },
          ]}>
          <Input placeholder="Enter a name" />
        </Form.Item>
        <Form.Item
          name="frequency"
          label="Frequency"
          rules={[
            {
              required: true,
              message: 'Please select a frequency',
            },
          ]}>
          <Select placeholder="Select a frequency">
            {Object.values(LPRReportFrequency).map((frequency) => (
              <Option key={frequency} value={frequency}>
                {LPRReportFrequencyLabels[frequency]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: 'Please select a type',
            },
          ]}>
          <Select placeholder="Select a type">
            {Object.values(LPRReportType).map((type) => (
              <Option key={type} value={type}>
                {LPRReportTypeLabels[type]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="emails"
          label="Emails"
          rules={[
            () => ({
              validator(_, value) {
                const emailArray = value
                  ? value.split(',').map((email) => email.trim())
                  : [];
                const isValidEmail = emailArray.every((email) =>
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email),
                );

                if (!isValidEmail) {
                  return Promise.reject(
                    new Error('One or more emails are invalid'),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}>
          <Input placeholder="Enter emails separated by commas" />
        </Form.Item>
        <DurationFields
          namespace={namespace}
          ref={additionalFieldsRef}
          config={initialValues.config}
        />
      </Form>
    </Modal>
  );
};

export default CreateUpdateReport;
