/**
 * @generated SignedSource<<ba7ac4c00bc0a4e0496f93dd4dc78480>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type RecentChannelsTileFragment$data = {
  readonly anchorTimeStamp: string | null | undefined;
  readonly channelIDs: ReadonlyArray<string | null | undefined>;
  readonly id: string;
  readonly isFavorite: boolean | null | undefined;
  readonly name: string | null | undefined;
  readonly timestamp: string;
  readonly ' $fragmentType': 'RecentChannelsTileFragment';
};
export type RecentChannelsTileFragment$key = {
  readonly ' $data'?: RecentChannelsTileFragment$data;
  readonly ' $fragmentSpreads': FragmentRefs<'RecentChannelsTileFragment'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'RecentChannelsTileFragment',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isFavorite',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'channelIDs',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'timestamp',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'anchorTimeStamp',
      storageKey: null,
    },
  ],
  type: 'VMSPlusRecentChannels',
  abstractKey: null,
};

(node as any).hash = '8f3bf8c4da329ae616600d45ae46c9f0';

export default node;
