import { Button, Cascader, Form, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

// @ts-expect-error
@connect(({ insights, loading }) => ({
  insights,
  loadingInsightGroup: loading.effects['insights/updateInsightGroup'],
  loadingInsight: loading.effects['insights/updateInsight'],
}))
class MoveInsightGroup extends React.Component {
  state = {};

  moveInsightGroupForm = React.createRef();

  componentDidMount() {}

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  handelMoveInsightGroup(e) {
    const { dispatch, insightGroup, insight } = this.props;
    e.preventDefault();
    this.moveInsightGroupForm.current.validateFields().then(
      (values) => {
        if (insight) {
          dispatch({
            type: 'insights/updateInsight',
            insightID: insight.InsightID,
            payload: {
              insight_group_id: values.parentID[values.parentID.length - 1],
            },
          }).then(() => {
            this.toggleModal();
            if (this.moveInsightGroupForm.current) {
              this.moveInsightGroupForm.current.resetFields();
            }
          });
        } else {
          dispatch({
            type: 'insights/updateInsightGroup',
            insightGroupID: insightGroup.InsightGroupID,
            payload: { parent_id: values.parentID[values.parentID.length - 1] },
          }).then(() => {
            this.toggleModal();
            if (this.moveInsightGroupForm.current) {
              this.moveInsightGroupForm.current.resetFields();
            }
          });
        }
      },
      (err) => console.log('err', err),
    );
  }

  getInsightGroupOptions(insights) {
    if (insights.InsightGroups.length === 0) {
      return [];
    }
    let options = [];
    insights.InsightGroups.forEach((ig) => {
      if (
        _.get(this.props, 'insightGroup.InsightGroupID') !== ig.InsightGroupID
      ) {
        options.push({
          value: ig.InsightGroupID,
          label: ig.Name,
          children: this.getInsightGroupOptions(ig),
        });
      }
    });
    return options;
  }

  render() {
    const {
      children,
      loadingInsight,
      loadingInsightGroup,
      insight,
      insights,
      insightGroup,
    } = this.props;
    const { showModal } = this.state;
    let options = this.getInsightGroupOptions(insights.all);
    options.push({
      value: null,
      label: 'Insights',
    });
    return (
      <>
        <Modal
          width={500}
          height={180}
          title={`Move ${
            _.get(insightGroup, 'Name') || _.get(insight, 'Name')
          }`}
          visible={showModal}
          onCancel={() => this.toggleModal()}
          footer={[
            <Button key="cancel" onClick={() => this.toggleModal()}>
              Cancel
            </Button>,
            <Button
              key="addevent"
              loading={loadingInsightGroup || loadingInsight}
              onClick={(e) => this.handelMoveInsightGroup(e)}
              type="primary">
              Move
            </Button>,
          ]}>
          <div>
            <Form
              ref={this.moveInsightGroupForm}
              layout="vertical"
              requiredMark={false}
              onSubmit={(e) => this.handelMoveInsightGroup(e)}>
              <Form.Item
                label="Select Insight Group"
                name="parentID"
                rules={[
                  {
                    required: true,
                    message: 'Please select the insight group',
                  },
                ]}>
                <Cascader
                  changeOnSelect
                  expandTrigger="hover"
                  placeholder={'Select Insight Group'}
                  options={options}
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default MoveInsightGroup;
