import * as React from 'react';

const NotificationsIcon = ({
  height = '16px',
  width = '16px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M5.05327 2.72002L4.09994 1.76669C2.49994 2.98669 1.4466 4.86669 1.35327 7.00002H2.6866C2.7866 5.23335 3.69327 3.68669 5.05327 2.72002ZM13.3133 7.00002H14.6466C14.5466 4.86669 13.4933 2.98669 11.8999 1.76669L10.9533 2.72002C12.2999 3.68669 13.2133 5.23335 13.3133 7.00002ZM11.9999 7.33335C11.9999 5.28669 10.9066 3.57335 8.99994 3.12002V2.66669C8.99994 2.11335 8.55327 1.66669 7.99994 1.66669C7.4466 1.66669 6.99994 2.11335 6.99994 2.66669V3.12002C5.0866 3.57335 3.99994 5.28002 3.99994 7.33335V10.6667L2.6666 12V12.6667H13.3333V12L11.9999 10.6667V7.33335ZM7.99994 14.6667C8.09327 14.6667 8.17994 14.66 8.2666 14.64C8.69994 14.5467 9.05327 14.2534 9.2266 13.8534C9.29327 13.6934 9.3266 13.52 9.3266 13.3334H6.65994C6.6666 14.0667 7.25994 14.6667 7.99994 14.6667Z"
      fill="#212529"
    />
  </svg>
);
export default NotificationsIcon;
