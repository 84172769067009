import { Button, Form, Input, Modal, Upload } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'umi';

import ChannelSelect2 from '@/components/ChannelSelect2';
import ConfigureLocationMap from '@/pages/locations/location/configure-location-map';
import styles from './style.less';

import type { FormInstance } from 'antd';

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

// @ts-expect-error
@connect(({ loading, location_maps, locations }) => ({
  loading1: loading.effects['location_maps/getMapUploadUrl'],
  loading2: loading.effects['location_maps/uploadMap'],
  loading3: loading.effects['location_maps/createLocationMap'],
  installationsByID: locations.installationsByID,
  location_maps,
}))
class AddLocationMap extends Component {
  addLocationMapFormRef: React.RefObject<FormInstance>;
  configureLocationMapRef: React.RefObject<ConfigureLocationMap>;

  constructor(props) {
    super(props);
    this.addLocationMapFormRef = React.createRef();
    this.configureLocationMapRef = React.createRef();
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {}

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.addLocationMapFormRef.current !== null) {
      this.addLocationMapFormRef.current.validateFields().then(
        (values) => {
          // upload floor map
          this.props
            .dispatch({
              type: 'location_maps/getMapUploadUrl',
              payload: { fileName: values.newFloorMap[0].name },
            })
            .then((res) => {
              res.data.file = values.newFloorMap[0].originFileObj;
              this.props
                .dispatch({
                  type: 'location_maps/uploadMap',
                  payload: res.data,
                })
                .then(({ response, error }) => {
                  const mapS3Path = `${res.data.url}${res.data.fields.key}`;
                  if (response) {
                    this.props
                      .dispatch({
                        type: 'location_maps/createLocationMap',
                        locationID: this.props.locationID,
                        payload: {
                          channelIDs: values.channelIDs,
                          mapS3Path,
                        },
                      })
                      .then((resp1) => {
                        if (resp1.success) {
                          this.setState(
                            { newLocationMapID: resp1.data.LocationMapID },
                            () => {
                              this.toggleModal();
                              this.configureLocationMapRef.current.toggleModal();
                            },
                          );
                        }
                      });
                  } else {
                    console.error(error);
                  }
                });
            });
        },
        (err) => console.log('err', err),
      );
    }
  }

  render() {
    const {
      children,
      loading1,
      loading2,
      loading3,
      location_maps,
      locationID,
      installationsByID,
    } = this.props;
    const { showModal, newLocationMapID } = this.state;

    const installation = installationsByID[locationID];
    const currentLocationMaps = _.get(
      location_maps,
      `byLocID[${locationID}]`,
      [],
    );

    const locationMap = currentLocationMaps.find(
      (lM) => lM.LocationMapID === newLocationMapID,
    );
    return (
      <>
        <Modal
          width={600}
          title={
            <article>
              Add New Map
              <div className={styles['modal-header-subtext']}>
                Select cameras
              </div>
            </article>
          }
          visible={showModal}
          onCancel={() => {
            if (this.addLocationMapFormRef.current) {
              this.addLocationMapFormRef.current.resetFields();
            }
            this.toggleModal();
          }}
          footer={[
            <Button
              key="cancel"
              onClick={() => {
                if (this.addLocationMapFormRef.current) {
                  this.addLocationMapFormRef.current.resetFields();
                }
                this.toggleModal();
              }}>
              Cancel
            </Button>,
            <Button
              key="createLocationMap"
              loading={loading1 || loading2 || loading3}
              onClick={(e) => this.handleSubmit(e)}
              type="primary">
              Done
            </Button>,
          ]}>
          <Form
            layout="vertical"
            ref={this.addLocationMapFormRef}
            colon={false}
            requiredMark={false}
            onSubmit={(e) => this.handleSubmit(e)}>
            <Form.Item
              extra="Accepts PNG and GIF formats"
              name="newFloorMap"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: 'Please enter the map name',
                },
              ]}
              valuePropName="fileList">
              <Upload
                // .pdf,.svg,
                accept=".png,.jpeg,.jpg"
                className="upload-full-width"
                customRequest={(_cb) => {}}
                multiple={false}
                showUploadList={false}
                onChange={(e) => {
                  this.setState({ selectedMapFile: e.file.name });
                }}>
                <div style={{ display: 'flex', width: '100%' }}>
                  <Input
                    style={{ flex: 1 }}
                    readOnly
                    value={this.state.selectedMapFile}
                    placeholder="Choose Map..."
                  />
                  &nbsp;
                  <Button>Choose Map</Button>
                </div>
              </Upload>
            </Form.Item>
            <Form.Item
              label="Choose Cameras"
              name="channelIDs"
              rules={[
                {
                  required: true,
                  message: 'Please select the camera',
                },
              ]}>
              <ChannelSelect2
                multiple
                selecttype="treeselect"
                value={[this.state.channelSelectValue]}
                // filterLocationIDs={[+locationID]}
                onChange={(value) =>
                  this.setState({ channelSelectValue: value })
                }
              />
            </Form.Item>
          </Form>
        </Modal>
        <ConfigureLocationMap
          baseStationVersion={installation?.AppVersion}
          ref={this.configureLocationMapRef}
          locationMap={locationMap}
        />
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default AddLocationMap;
