/* eslint-disable prefer-promise-reject-errors */
import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';

import ChannelSelect2 from '@/components/ChannelSelect2';
import RangePickerDF from '@/components/RangePickerDF';
import SearchFilterSelector from '@/components/SearchFilterSelector';
import { APPS } from '@/pages/apps/app/constants/appList';
import {
  doNotificationRuleOp,
  getBaseRule,
  notificationOps,
  RUN_FREQUENCY_MIN,
} from '@/utils/notifications';
import { isEmail, isInternalUser, isPhone, isUrl } from '@/utils/utils';
import styles from '../../style.less';

import type { AppsModalState } from '@/models/apps';
import type { CH_GRP_TYPE, CH_TYPE, LOC_TYPE } from '@/types/location';
import type { FormInstance } from 'antd';
import type { FormProps } from 'antd/es/form';

const disabledDate = (current: any) =>
  current && current > moment().endOf('day');

type MyProps = {
  rule: Record<string, any>;
  duplicate?: boolean;

  loc?: LOC_TYPE;
  ch_grp?: CH_GRP_TYPE;
  ch?: CH_TYPE;
  apps?: AppsModalState;
  currentUser?: any;
  loadingDoAppOp?: boolean;
  // onChange: (value: number[]) => void;
  dispatch?: (_any: any) => Promise<any>;
};

type MyState = {
  showModal: boolean;
  title: string;
  initialValues: Record<string, any> | null;
  ruleID: any;
  enabled: boolean;
  activeDays: string[];
  activeTimezone: string;
  multiTimezoneWarning: boolean;
  deliveryType: string;
  searchFilterExtra: string;
};

// @ts-expect-error
@connect(({ locations, apps, user, loading }) => ({
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  apps,
  currentUser: user.currentUser,
  loadingDoAppOp: loading.effects['apps/doAppOp'],
}))
class NotificationsEditRule extends React.Component<MyProps, MyState> {
  static defaultProps = {
    rule: getBaseRule(),
  };

  addRuleForm: React.RefObject<FormInstance>;

  constructor(props: MyProps) {
    super(props);
    this.state = {
      showModal: false,
      timezones: moment.tz.names(),
      multiTimezoneWarning: false,
      title: '',
      initialValues: {},
      ruleID: null,
      enabled: false,
      activeDays: [],
      deliveryType: '',
      searchFilterExtra: '',
    };

    this.addRuleForm = React.createRef();
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (!_.isEqual(prevProps.rule, this.props.rule)) {
      this.setup();
    }
  }

  toggleModal() {
    const { showModal } = this.state;

    this.setState({ showModal: !showModal }, () => {
      if (!showModal && this.addRuleForm.current) {
        this.addRuleForm.current.resetFields();
      }
    });
  }

  setup() {
    const { rule, duplicate } = this.props;
    const initialValues = _.clone(rule);
    let title = 'Add Rule';
    if (duplicate) {
      title = 'Duplicate';
      initialValues['name'] = `[Duplicate] ${initialValues.name}`;
    } else if (rule && rule.id) {
      title = 'Edit Rule';
      initialValues['id'] = rule.id;
      initialValues['activeTimezone'] = _.get(
        this.props,
        'rule.timeframe.timezone',
      );
    } else {
      initialValues['activeTimezone'] = moment.tz.guess();
    }

    this.setState({
      title,
      initialValues,
      ruleID: _.get(this.props, 'rule.id'),
      enabled: _.get(this.props, 'rule.enabled', true),
      activeDays: _.get(this.props, 'rule.timeframe.days', [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
      ]),
      deliveryType: _.get(this.props, 'rule.deliveryType', 'realtime_alerts'),
      searchFilterExtra: `The rule triggers when the number of results of the specified search filter exceed the defined threshold value`,
    });
  }

  getEditDaysEl() {
    return [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ].map((d, i) => (
      <span
        onClick={(e) => {
          e.preventDefault();
          if (this.state.activeDays.indexOf(d) === -1) {
            this.setState({ activeDays: [...this.state.activeDays, d] });
          } else {
            this.setState({
              activeDays: this.state.activeDays.filter((f) => f !== d),
            });
          }
        }}
        className={
          styles[
            this.state.activeDays.indexOf(d) === -1 ? 'tf-e-off' : 'tf-e-on'
          ]
        }
        key={d}
        style={{
          borderRadius: i === 0 ? '4px 0 0 4px' : i === 6 ? '0 4px 4px 0' : '',
        }}>
        {d[0].toUpperCase()}
      </span>
    ));
  }

  getListValidator(
    getFieldValue: (name: string) => any,
    field: string,
    tester: (test: string) => boolean,
    separator: string,
  ) {
    return (_rule: any, value: string) => {
      value = value || getFieldValue(field) || '';
      let list = value.split(separator).map((el) => el.trim());
      let error;
      list.forEach((el) => {
        if (!el) {
          error = new Error('An entry is required');
        } else if (!tester(el)) {
          error = new Error(`${el} is not a valid value`);
        }
      });
      if (error) {
        return Promise.reject(error);
      }
      return Promise.resolve();
    };
  }

  renderEditRuleComponentForSearchTrigger() {
    return (
      <div style={{ marginLeft: '15px' }}>
        <Form.Item
          style={{ display: 'inline-block', width: '100%' }}
          name="channelIDs"
          label=""
          rules={[
            {
              required: true,
              message: 'Please select the camera sources',
            },
          ]}>
          <ChannelSelect2
            onChange={(channel_ids) => {
              // set timezone if all the channels have same timzone
              // or else show an warning that the selected channels are from different timezones
              const tzSet = new Set();
              channel_ids.forEach((cId) => {
                tzSet.add(this.props.ch.byId[cId].Timezone);
              });
              if (tzSet.size === 1) {
                // set timezone
                let [tz] = tzSet;
                this.addRuleForm.current?.setFieldsValue({
                  activeTimezone: tz,
                });
                this.setState({ multiTimezoneWarning: false });
              } else {
                // show warning
                this.setState({ multiTimezoneWarning: true });
              }
            }}
            selecttype="treeselect"
            multiple
            licensesRequired={{ channel_licenses: ['RAS'] }}
          />
        </Form.Item>
        <Form.Item
          label=""
          extra="Alerts are delivered when the results of the search cross the specified threshold. More advanced search filters require the Digital Investigations Suite">
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'baseline',
              width: '50%',
            }}>
            <span style={{ paddingRight: '10px' }}>Count&nbsp;of</span>
            <Form.Item
              style={{ width: '100%' }}
              rules={[
                {
                  required: true,
                  message: 'Please select a search filter',
                },
              ]}
              name={['search_filter', 'id']}>
              <SearchFilterSelector />
            </Form.Item>
          </div>
          <Form.Item
            name={['delivery', 'threshold', 'type']}
            style={{
              display: 'inline-block',
              padding: '0 10px',
              width: '30%',
            }}>
            <Select>
              {[
                ['is greater than', 'gt'],
                ['is less than', 'lt'],
              ].map((el) => (
                <Select.Option key={el[1]} value={el[1]}>
                  {el[0]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={['delivery', 'threshold', 'metric']}
            style={{
              display: 'inline-block',
              width: '20%',
            }}>
            <Input placeholder="Num" type="number" />
          </Form.Item>
        </Form.Item>
      </div>
    );
  }

  onSubmit(values: any) {
    let emailActions = values.emailEnabled
      ? _.get(values, 'emailMedium', '')
          .split(',')
          .map((to: string) => ({ type: 'email', to: to.trim() }))
      : [];
    let webhookActions = values.webhookEnabled
      ? _.get(values, 'webhookMedium', [])
          .split(' ')
          .map((to: string) => ({
            type: 'webhook',
            to: to.trim(),
          }))
      : [];
    let phoneActions = values.phoneEnabled
      ? _.get(values, 'phoneMedium', [])
          .split(',')
          .map((to: string) => ({
            type: 'phone',
            to: to.trim(),
          }))
      : [];
    let voiceActions = values.voiceEnabled
      ? _.get(values, 'voiceMedium', [])
          .split(',')
          .map((to: string) => ({
            type: 'voice',
            to: to.trim(),
          }))
      : [];

    // only internal users are allowed to set a threshold lower than 60
    let run_frequency = parseInt(
      _.get(values, 'delivery.threshold.run_frequency', RUN_FREQUENCY_MIN),
    );
    if (
      !isInternalUser(this.props.currentUser) &&
      run_frequency < RUN_FREQUENCY_MIN
    ) {
      values.delivery.threshold.run_frequency = RUN_FREQUENCY_MIN;
    }
    let rule: Record<string, any> = {
      name: values.name,
      timeframe: {
        days: this.state.activeDays,
        timezone: values.activeTimezone,
        time_range: {
          from: moment(values.activeTimes[0]).format('HH:mm:ss'),
          to: moment(values.activeTimes[1]).format('HH:mm:ss'),
        },
      },
      actions: emailActions
        .concat(webhookActions)
        .concat(phoneActions)
        .concat(voiceActions),
      priority: values.priority,
      enabled: this.state.enabled,
    };

    if (values.filterType === 'search') {
      rule = {
        ...rule,
        search_filter: _.get(values, 'search_filter', {}),
        sources: {
          channels: values.channelIDs.map((c: number) => ({ id: c })),
        },
        delivery: {
          type: 'realtime_alerts',
          threshold: values.delivery.threshold,
        },
      };
    } else if (values.filterType.startsWith('app')) {
      let appID = parseInt(values.filterType.split('-')[1]);
      rule = {
        ...rule,
        sources: {
          apps: [{ id: appID }],
        },
        delivery: {
          type: 'app_alerts',
          threshold: values.delivery.threshold,
        },
      };
    }

    if (!this.props.duplicate) {
      rule.id = this.state.ruleID;
    }

    return doNotificationRuleOp(this.props.dispatch, notificationOps.editRule, {
      rule,
    }).then(() => {
      this.toggleModal();
    });
  }

  renderSearchRules(initialValues: Record<string, any> | null) {
    const { apps, dispatch } = this.props;

    if (apps && dispatch) {
      const form_config: FormProps = {
        layout: 'vertical',
        colon: false,
        requiredMark: false,
        onFinish: (v) => this.onSubmit(v),
      };
      if (initialValues) {
        form_config['initialValues'] = initialValues;
      }
      return (
        <Form ref={this.addRuleForm} {...form_config}>
          <Form.Item
            label="Name"
            name="name"
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Please enter a name for the rule',
              },
            ]}>
            <Input placeholder="Rule Name" autoFocus />
          </Form.Item>
          <Form.Item
            label="Trigger"
            name="filterType"
            style={{ marginBottom: '10px' }}
            rules={[
              {
                required: true,
                message: 'Please select a trigger',
              },
            ]}>
            <Radio.Group>
              <Radio value="search">Search</Radio>
              {apps.all.map((app) => {
                return (
                  APPS[app.AppID]?.getEditRuleComponent && (
                    <Radio key={app.AppID} value={`app-${app.AppID}`}>
                      {app.Name}
                    </Radio>
                  )
                );
              })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues['filterType'] !== currentValues['filterType']
            }>
            {({ getFieldValue }) => {
              const trigger = getFieldValue('filterType');

              if (trigger === 'search') {
                return this.renderEditRuleComponentForSearchTrigger();
              }

              let app_instance: any = null;
              apps.all.map((app) => {
                if (trigger === `app-${app.AppID}`) {
                  app_instance = APPS[app.AppID];
                }
              });

              if (
                app_instance &&
                'getEditRuleComponent' in app_instance &&
                typeof app_instance.getEditRuleComponent === 'function'
              ) {
                return (
                  <div style={{ marginLeft: '15px' }} key={app_instance.appID}>
                    {app_instance.getEditRuleComponent(
                      { ...this.state, appID: app_instance.appID },
                      {
                        apps,
                        dispatch,
                      },
                    )}
                  </div>
                );
              }
              return null;
            }}
          </Form.Item>
          <Form.Item label="Active Timeframe" style={{ marginBottom: 0 }}>
            <div
              className={styles['tf-timeframe-row']}
              style={{ marginLeft: '15px' }}>
              <Form.Item label="" name="activeTimes" style={{ width: '33%' }}>
                <RangePickerDF
                  fromTitle="From"
                  toTitle="To"
                  disabledDate={disabledDate}
                  showDate={false}
                  format="DD MMM YYYY HH:mm:ss"
                />
              </Form.Item>
              <Form.Item
                label=""
                name="activeDays"
                style={{
                  width: '33%',
                  padding: '0 10px',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <div>Days</div>
                {this.getEditDaysEl()}
              </Form.Item>
              <div style={{ width: '33%' }}>
                Timezone
                <Form.Item label="" name="activeTimezone">
                  <Select
                    showSearch
                    dropdownMatchSelectWidth={false}
                    optionLabelProp="label">
                    {this.state.timezones.map((tz) => (
                      <Select.Option key={tz} label={tz} value={tz}>
                        {tz}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            {this.state.multiTimezoneWarning && (
              <div style={{ marginLeft: '15px' }} className="df-warn-text">
                Cameras selected are from multiple timezones. The alerts fired
                will be in the selected timezone.
              </div>
            )}
          </Form.Item>
          <Form.Item label="Notify" style={{ marginBottom: '5px' }}>
            <Form.Item
              name="emailEnabled"
              valuePropName="checked"
              style={{ marginBottom: 0, display: 'inline-block' }}>
              <Checkbox>Email</Checkbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues['emailEnabled'] !== currentValues['emailEnabled']
              }>
              {({ getFieldValue }) => {
                const checked = getFieldValue('emailEnabled');
                if (checked) {
                  return (
                    <Form.Item
                      name="emailMedium"
                      style={{ width: '350px', marginLeft: '15px' }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message:
                            'Please enter a comma-separated list of emails',
                        },
                        ({ getFieldValue: validator_getFieldValue }) => ({
                          validator: this.getListValidator(
                            validator_getFieldValue,
                            'emailMedium',
                            isEmail,
                            ',',
                          ),
                        }),
                      ]}>
                      <Input placeholder="E-mail Address" />
                    </Form.Item>
                  );
                }
                return null;
              }}
            </Form.Item>
            <Form.Item
              name="phoneEnabled"
              valuePropName="checked"
              style={{
                marginBottom: 0,
                display: 'inline-block', // !this.getValue('phoneEnabled') &&
              }}>
              <Checkbox value="phone">Text</Checkbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues['phoneEnabled'] !== currentValues['phoneEnabled']
              }>
              {({ getFieldValue }) => {
                const checked = getFieldValue('phoneEnabled');
                if (checked) {
                  return (
                    <Form.Item
                      name="phoneMedium"
                      style={{ width: '350px', marginLeft: '15px' }}
                      validateTrigger="onBlur"
                      extra="A comma-separated list of phone numbers, in the format +14085551234"
                      rules={[
                        {
                          required: true,
                          message:
                            'Please enter a comma-separated list of phone numbers',
                        },
                        ({ getFieldValue: validator_getFieldValue }) => ({
                          validator: this.getListValidator(
                            validator_getFieldValue,
                            'phoneMedium',
                            isPhone,
                            ',',
                          ),
                        }),
                      ]}>
                      <Input placeholder="Phone Number" type="tel" />
                    </Form.Item>
                  );
                }
                return null;
              }}
            </Form.Item>
            <Form.Item
              name="voiceEnabled"
              valuePropName="checked"
              style={{
                marginBottom: 0,
                display: 'inline-block', // !this.getValue('voiceEnabled') &&
              }}>
              <Checkbox value="voice">Voice</Checkbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues['voiceEnabled'] !== currentValues['voiceEnabled']
              }>
              {({ getFieldValue }) => {
                const checked = getFieldValue('voiceEnabled');
                if (checked) {
                  return (
                    <Form.Item
                      name="voiceMedium"
                      style={{ width: '350px', marginLeft: '15px' }}
                      validateTrigger="onBlur"
                      extra="A comma-separated list of voice numbers, in the format +14085551234"
                      rules={[
                        {
                          required: true,
                          message:
                            'Please enter a comma-separated list of voice numbers',
                        },
                        ({ getFieldValue: validator_getFieldValue }) => ({
                          validator: this.getListValidator(
                            validator_getFieldValue,
                            'voiceMedium',
                            isPhone,
                            ',',
                          ),
                        }),
                      ]}>
                      <Input placeholder="Voice Number" type="tel" />
                    </Form.Item>
                  );
                }
                return null;
              }}
            </Form.Item>
            <Form.Item
              name="webhookEnabled"
              valuePropName="checked"
              style={{
                marginBottom: 0,
                display: 'inline-block', // !this.getValue('webhookEnabled') &&
              }}>
              <Checkbox value="webhook">Webhook</Checkbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues['webhookEnabled'] !== currentValues['webhookEnabled']
              }>
              {({ getFieldValue }) => {
                const checked = getFieldValue('webhookEnabled');
                if (checked) {
                  return (
                    <Form.Item
                      name="webhookMedium"
                      style={{ width: '350px', marginLeft: '15px' }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message:
                            'Please enter a space-separated list of webhooks',
                        },
                        ({ getFieldValue: validator_getFieldValue }) => ({
                          validator: this.getListValidator(
                            validator_getFieldValue,
                            'webhookMedium',
                            isUrl,
                            ',',
                          ),
                        }),
                      ]}>
                      <Input placeholder="Webhook URL" />
                    </Form.Item>
                  );
                }
                return null;
              }}
            </Form.Item>
          </Form.Item>
          <Collapse ghost={true}>
            <Collapse.Panel
              className="no-horizontal-padding-collapse"
              key="duplicate-rules"
              header="Advanced Options"
              forceRender={true}>
              <Form.Item
                label="Review Queue"
                name={['delivery', 'threshold', 'review_queue']}
                extra="Show alerts triggered by this rule in the Review Queue. Notifications will only be dispatched after manual approval">
                <Select style={{ width: '150px' }}>
                  {[
                    [true, 'Enabled'],
                    [false, 'Disabled'],
                  ].map(([value, label], i) => (
                    <Select.Option key={i} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Default Priority"
                name="priority"
                extra="Priority assigned to alerts triggered by this rule">
                <Select style={{ width: '150px' }}>
                  {[1, 2, 3, 4, 5].map((n) => (
                    <Select.Option key={n} value={n}>
                      {n}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={['delivery', 'threshold', 'run_frequency']}
                label="Run Frequency"
                extra="How often should the rules be run (in seconds, minimum 20 seconds)">
                <Input type="number" style={{ width: '150px' }} />
              </Form.Item>
              <Form.Item
                name={['delivery', 'threshold', 'throttle_window']}
                label="Throttle Window"
                extra="Seconds that need to pass before the rule sends notifications again">
                <Input type="number" style={{ width: '150px' }} />
              </Form.Item>
              <Form.Item
                name={['delivery', 'threshold', 'event_time_buffer']}
                label="Event Time Buffer"
                extra="Seconds of video to share before and after a rule triggers">
                <Input type="number" style={{ width: '150px' }} />
              </Form.Item>
              <Form.Item
                name={['delivery', 'threshold', 'rolling_window']}
                label="Rolling Window"
                extra="Seconds of past data to consider when evaluating rule">
                <Input type="number" style={{ width: '150px' }} />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Form>
      );
    }

    return <></>;
  }

  render() {
    const { children, loadingDoAppOp } = this.props;
    const { showModal, title, initialValues } = this.state;

    return (
      <>
        <Modal
          centered
          // forceRender
          style={{ height: '100%', top: '5%' }}
          width="650px"
          title={title}
          visible={showModal}
          onCancel={() => this.toggleModal()}
          footer={[
            <Button key="cancel" onClick={() => this.toggleModal()}>
              Cancel
            </Button>,
            <Button
              key="addrule"
              loading={loadingDoAppOp}
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                if (this.addRuleForm.current) {
                  this.addRuleForm.current.validateFields().then((v) => {
                    this.onSubmit(v);
                  });
                }
              }}>
              Save
            </Button>,
          ]}>
          <div className={styles['search-rules']}>
            {this.renderSearchRules(initialValues)}
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default NotificationsEditRule;
