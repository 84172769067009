import { ReactComponent as EditIcon } from '@/assets/edit.svg';
import PageHeader from '@/components/PageHeader2';
import {
  renderLines,
  renderOccupancyBar,
  renderRing,
} from '@/utils/chartUtils';
import { doAppOp, ga } from '@/utils/utils';
import Icon from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Table, Tabs } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';
import BaseApp from './BaseApp';
import CorporateEditLocation from './components/corporate-edit-location';
import styles from './style.less';

const { TabPane } = Tabs;

let throttledRefreshApp;

// @ts-expect-error
@connect(({ apps, locations, loading }) => ({
  apps,
  locations,
  loading,
}))
class CorporateApp extends BaseApp {
  constructor(props) {
    super(props);
    this.state = {
      corpLocations: [],
    };
    this.configForm = React.createRef();
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setup();
    }
  }

  setup() {
    const { data } = this.props;

    let baseLocations = _.cloneDeep(data.locations);

    let info = { ...data };

    let isDemo = this.props.appObj && this.props.appObj.isDemo;
    if (isDemo) {
      info = {
        ...data,
        ...this.getTestData(),
      };
    }
    // merge data from locations and config
    info.locations = ga(info, 'locations').map((loc) => {
      let custom = _.get(info, `config.${loc.id}`);
      if (!custom) {
        return loc;
      }
      return {
        ...loc,
        ...custom,
      };
    });

    this.setState({
      info,
      corpLocations: info.locations,
      baseLocations,
    });
  }

  getConfigButton() {
    const setConfig = (locations) => {
      doAppOp(
        this.props.appID,
        this.props.dispatch,
        CorporateApp.OPS.resetLocations,
        {
          locations,
        },
      ).then(() => this.forceUpdate());
    };

    const onOk = () => {
      this.configForm.current.validateFields().then(
        (values) => {
          try {
            const config = JSON.parse(values.config);
            setConfig(config);
          } catch (e) {
            console.log('invalid config', e);
          }
          this.setState({ showModal: false });
        },
        (err: any) => console.log('err', err),
      );
    };

    const onCancel = () => this.setState({ showModal: false });

    return (
      <>
        <Modal
          width={450}
          visible={this.state.showModal}
          title="Locations"
          onCancel={onCancel}
          destroyOnClose={true}
          footer={[
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              key="save"
              loading={this.props.loading.effects['apps/fetchApp']}
              onClick={onOk}>
              Save
            </Button>,
          ]}>
          <Form
            ref={this.configForm}
            initialValues={{
              config: JSON.stringify(this.state.baseLocations, null, 2),
            }}>
            <Form.Item name="config">
              <Input.TextArea rows={20} />
            </Form.Item>
          </Form>
        </Modal>
        <Button
          size="small"
          loading={this.props.loading.effects['apps/fetchApp']}
          onClick={() => this.setState({ showModal: true })}>
          Configure
        </Button>
      </>
    );
  }

  static getEditRuleComponent = (state, { apps, dispatch }) => {
    if (!state || !apps) {
      return null;
    }

    let info = apps.byID[CorporateApp.appID];
    if (!info) {
      throttledRefreshApp(dispatch);
      return null;
    }

    let locations = _.get(info, 'Data.locations');

    return (
      <div>
        <Form.Item
          label=""
          extra="A notification will be triggered for the location if the occupancy is over the specified threshold">
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please select a location',
              },
            ]}
            style={{ display: 'inline-block', width: 'calc(55% - 20px)' }}
            name={['delivery', 'threshold', 'location_id']}>
            <Select placeholder="Select a location">
              {locations.map((location) => (
                <Select.Option key={+location.id} value={+location.id}>
                  {location.building}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={['delivery', 'threshold', 'type']}
            initialValue="gt"
            style={{
              display: 'inline-block',
              margin: '0 10px',
              width: '30%',
            }}>
            <Select>
              {[
                ['if greater than', 'gt'],
                ['if less than', 'lt'],
              ].map((el) => (
                <Select.Option key={el[1]} value={el[1]}>
                  {el[0]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={['delivery', 'threshold', 'metric']}
            initialValue="50"
            style={{
              display: 'inline-block',
              width: '15%',
              marginBottom: '0',
            }}>
            <Input placeholder="Num" />
          </Form.Item>
        </Form.Item>
      </div>
    );
  };

  render() {
    let { corpLocations } = this.state;
    let isDemo = this.props.appObj && this.props.appObj.isDemo;

    let totalLocations = corpLocations.length;
    let openLocations = corpLocations.filter((x) => x.isOpen).length;
    let openPct = totalLocations ? openLocations / totalLocations : 0;

    // update pct values
    corpLocations.forEach((loc) => {
      if (loc.pct) {
        return;
      }
      if (!loc.occupancy) {
        loc.pct = 0;
        return;
      }
      loc.pct = Math.round((100 * loc.current_occupancy) / loc.occupancy);
    });

    let sortedByOccupancy = corpLocations
      .filter((a) => a.pct)
      .sort((a, b) => a.pct < b.pct);

    return (
      <>
        <PageHeader
          title={this.props.appObj.Name}
          right={this.getConfigButton()}
        />
        <Tabs>
          <TabPane tab="Dashboard" key="dashboard">
            <div
              style={{
                marginBottom: '10px',
              }}>
              <h3>Occupancy Trends</h3>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                }}>
                <div>
                  <div className={styles['corp-summary-chart']}>
                    {renderRing(openPct, 'Open')}
                  </div>
                  <div className={styles['corp-summary-label']}>
                    Offices Open
                  </div>
                </div>
                <div style={{ width: '35%' }}>
                  <div className={styles['corp-summary-chart']}>
                    {renderOccupancyBar(sortedByOccupancy.slice(0, 3))}
                  </div>
                  <div className={styles['corp-summary-label']}>
                    Highest Occupancy (% of Normal)
                  </div>
                </div>
                <div style={{ width: '35%' }}>
                  <div className={styles['corp-summary-chart']}>
                    {renderOccupancyBar(
                      sortedByOccupancy.reverse().slice(0, 3),
                    )}
                  </div>
                  <div className={styles['corp-summary-label']}>
                    Lowest Occupancy (% of Normal)
                  </div>
                </div>
              </div>
            </div>
            {isDemo && (
              <div
                style={{
                  marginTop: '30px',
                }}>
                <h3>Alerts Status</h3>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                  }}>
                  <div>
                    <div className={styles['corp-summary-chart']}>
                      {renderRing(0.15, 'Inactive')}
                    </div>
                    <div className={styles['corp-summary-label']}>
                      Inactive Cameras
                    </div>
                  </div>
                  <div>
                    <div className={styles['corp-summary-chart']}>
                      {renderRing(0.3, 'Unreviewed')}
                    </div>
                    <div className={styles['corp-summary-label']}>
                      Unreviewed Alerts
                    </div>
                  </div>
                </div>
              </div>
            )}
          </TabPane>
          <TabPane tab="Occupancy" key="occupancy">
            <div>
              {isDemo &&
                renderLines(ga(this.state.info, 'occupancy.line_list'))}
              <h3>Details</h3>
              <Table
                className={styles['table-container']}
                dataSource={corpLocations}
                size="small"
                rowKey="id"
                pagination={false}>
                <Table.Column style={{ width: '20px' }} />
                <Table.Column
                  dataIndex="building"
                  title={'Building'}
                  sorter={(a, b) => a.building < b.building}
                  key="building"
                  render={(text, record) => (
                    <span>
                      <span>{text}</span>
                      <CorporateEditLocation location={record}>
                        <div className={styles['edit-icon']}>
                          <Icon component={EditIcon} />
                        </div>
                      </CorporateEditLocation>
                    </span>
                  )}
                />
                <Table.Column
                  dataIndex="isOpen"
                  title={'Status'}
                  align="center"
                  sorter={(a, b) => a.status < b.status}
                  key="isOpen"
                  render={(el) => (el ? 'Open' : 'Closed')}
                />
                <Table.Column
                  dataIndex="occupancy"
                  title={'Occupancy'}
                  align="center"
                  sorter={(a, b) => a.occupancy < b.occupancy}
                  key="occupancy"
                />
                <Table.Column
                  dataIndex="current_occupancy"
                  title={'Current Occupancy'}
                  align="center"
                  key="current_occupancy"
                />
                <Table.Column
                  dataIndex="pct"
                  title={'% of Normal'}
                  key="pct"
                  align="center"
                  sorter={(a, b) => a.pct < b.pct}
                  render={(text) => (text ? `${text}%` : '-')}
                />
              </Table>
            </div>
          </TabPane>
          {isDemo && (
            <TabPane tab="Alerts" key="alerts">
              <Table
                className={styles['table-container']}
                dataSource={ga(this.state.info, 'alerts.list')}
                size="small"
                pagination={false}>
                <Table.Column
                  dataIndex="priority"
                  key="priority"
                  title={<span className={styles['header']}>Pri</span>}
                />
                <Table.Column
                  dataIndex="name"
                  key="name"
                  title={<span className={styles['header']}>Alert</span>}
                />
                <Table.Column
                  dataIndex="sourceProjectName"
                  key="sourceProjectName"
                  title={<span className={styles['header']}>Location</span>}
                />
                <Table.Column
                  dataIndex="sourceChannelName"
                  key="sourceChannelName"
                  title={<span className={styles['header']}>Camera/Floor</span>}
                />
                <Table.Column
                  dataIndex="statusOwnerName"
                  key="statusOwnerName"
                  title={<span className={styles['header']}>Owner</span>}
                />
              </Table>
            </TabPane>
          )}
          {isDemo && (
            <TabPane tab="Investigations" key="investigations">
              <Table
                className={styles['table-container']}
                dataSource={ga(this.state.info, 'investigations.list')}
                size="small"
                pagination={false}>
                <Table.Column
                  dataIndex="name"
                  key="name"
                  title={
                    <span className={styles['header']}>Investigation</span>
                  }
                />
                <Table.Column
                  dataIndex="status"
                  key="status"
                  title={<span className={styles['header']}>Status</span>}
                />
                <Table.Column
                  dataIndex="sourceProjectName"
                  key="sourceProjectName"
                  title={<span className={styles['header']}>Location</span>}
                />
                <Table.Column
                  dataIndex="statusOwnerName"
                  key="statusOwnerName"
                  title={<span className={styles['header']}>Owner</span>}
                />
              </Table>
            </TabPane>
          )}
        </Tabs>
      </>
    );
  }

  getTestData() {
    return {
      locations: [
        { id: '1', building: 'Santiago', pact: 32 },
        { id: '2', building: 'Shanghai' },
        { id: '3', building: 'Szeged' },
        { id: '4', building: 'Budapest' },
        { id: '5', building: 'Bangalore', pct: 25 },
        { id: '6', building: 'Chennai', pct: 33 },
        { id: '7', building: 'Cork' },
        { id: '8', building: 'Singapore' },
        { id: '9', building: 'Dubai' },
        { id: '10', building: 'London' },
        { id: '11', building: 'Palo Alto', pct: 68 },
        { id: '12', building: 'San Francisco', pct: 48 },
        { id: '13', building: 'Santa Clara - SC1' },
        { id: '14', building: 'Santa Clara - SC2' },
        { id: '15', building: 'Atlanta', pct: 54 },
        { id: '16', building: 'New York' },
        { id: '17', building: 'Raleigh' },
        { id: '18', building: 'Austin' },
        { id: '19', building: 'Tysons' },
      ],
      occupancy: {
        list: [
          {
            location: 'Chennai',
            status: 'Open',
            occupancy: 220,
            pct: 33,
          },
          {
            location: 'San Francisco',
            status: 'Open',
            occupancy: 90,
            pct: 48,
          },
          {
            location: 'Palo Alto',
            status: 'Open',
            occupancy: 150,
            pct: 68,
          },
          {
            location: 'Atlanta',
            status: 'Open',
            occupancy: 75,
            pct: 54,
          },
          {
            location: 'Budapest',
            status: 'Closed',
            occupancy: 75,
            pct: 0,
          },
          {
            location: 'Santiago',
            status: 'Open',
            occupancy: 45,
            pct: 32,
          },
          {
            location: 'Bangalore',
            status: 'Open',
            occupancy: 280,
            pct: 25,
          },
        ],
        line_list: [
          {
            location: 'Chennai',
            status: 'Open',
            occupancy: 220,
            pct: 18,
            week: 'May 3',
          },
          {
            location: 'Chennai',
            status: 'Open',
            occupancy: 220,
            pct: 18,
            week: 'May 10',
          },
          {
            location: 'Chennai',
            status: 'Open',
            occupancy: 220,
            pct: 25,
            week: 'May 17',
          },
          {
            location: 'Chennai',
            status: 'Open',
            occupancy: 220,
            pct: 22,
            week: 'May 24',
          },
          {
            location: 'Chennai',
            status: 'Open',
            occupancy: 220,
            pct: 32,
            week: 'May 31',
          },
          {
            location: 'Chennai',
            status: 'Open',
            occupancy: 220,
            pct: 30,
            week: 'Jun 7',
          },
          {
            location: 'Chennai',
            status: 'Open',
            occupancy: 220,
            pct: 33,
          },
          {
            location: 'San Francisco',
            status: 'Open',
            occupancy: 90,
            week: 'May 3',
            pct: 15,
          },
          {
            location: 'San Francisco',
            status: 'Open',
            week: 'May 10',
            occupancy: 90,
            pct: 25,
          },
          {
            location: 'San Francisco',
            status: 'Open',
            week: 'May 17',
            occupancy: 90,
            pct: 32,
          },
          {
            location: 'San Francisco',
            status: 'Open',
            week: 'May 24',
            occupancy: 90,
            pct: 48,
          },
          {
            location: 'San Francisco',
            status: 'Open',
            occupancy: 90,
            week: 'May 31',
            pct: 38,
          },
          {
            location: 'San Francisco',
            status: 'Open',
            week: 'Jun 7',
            occupancy: 90,
            pct: 48,
          },
          {
            location: 'Palo Alto',
            status: 'Open',
            occupancy: 150,
            pct: 68,
          },
          {
            location: 'Atlanta',
            status: 'Open',
            occupancy: 75,
            pct: 54,
          },
          {
            location: 'Budapest',
            status: 'Closed',
            occupancy: 75,
            pct: 0,
          },
          {
            location: 'Santiago',
            status: 'Open',
            occupancy: 45,
            pct: 32,
          },
          {
            location: 'Bangalore',
            status: 'Open',
            occupancy: 280,
            pct: 25,
          },
        ],
      },
      alerts: {
        summary: {
          alerts_status: {
            by_category: {
              1: {
                open: 5,
                total: 9,
              },
              2: {
                open: 7,
                total: 10,
              },
              3: {
                open: 5,
                total: 25,
              },
            },
            top_high_priority_unresolved: [
              {
                location: 'Palo Alto',
                camera: 'xyz',
                type: 'Fence Crossing',
              },
              {
                location: 'Singapore',
                camera: 'abc',
                type: 'Fence Crossing',
              },
            ],
          },
        },
        list: [
          {
            name: 'Key Camera Inactive',
            id: 1,
            sourceProjectName: 'San Francisco',
            sourceChannelName: 'Entrance',
            statusOwnerName: 'John',
            priority: 1,
          },
          {
            name: 'Occupancy Over Limit',
            id: 2,
            sourceProjectName: 'Cork',
            sourceChannelName: '2nd Floor',
            statusOwnerName: 'Patrick',
            priority: 1,
          },
          {
            name: 'Rioting Outside',
            id: 3,
            sourceProjectName: 'Austin',
            sourceChannelName: 'Outside View',
            statusOwnerName: 'Brian',
            priority: 1,
          },
          {
            name: 'LPR 4VNH716',
            id: 4,
            sourceProjectName: 'New York',
            sourceChannelName: 'Parking Lot',
            statusOwnerName: 'Ashley',
            priority: 1,
          },
          {
            name: 'Activity During Night',
            id: 5,
            sourceProjectName: 'Chennai',
            sourceChannelName: 'Warehouse',
            statusOwnerName: 'Clara',
            priority: 2,
          },
        ],
      },
      investigations: {
        list: [
          {
            case: '173-24',
            name: 'Missing Laptop',
            id: 1,
            status: 'Open',
            sourceProjectName: 'San Francisco',
            sourceChannelName: 'Entrance',
            statusOwnerName: 'John',
            priority: 1,
          },
          {
            name: 'Harassment Complaint',
            id: 2,
            status: 'Open',
            sourceProjectName: 'Cork',
            sourceChannelName: '2nd Floor',
            statusOwnerName: 'Patrick',
            priority: 1,
          },
          {
            name: 'Cleaning Crew Monitoring',
            id: 3,
            status: 'Open',
            sourceProjectName: 'Austin',
            sourceChannelName: 'Outside View',
            statusOwnerName: 'Brian',
            priority: 1,
          },
          {
            name: 'Vandalism',
            id: 4,
            status: 'Open',
            sourceProjectName: 'New York',
            sourceChannelName: 'Parking Lot',
            statusOwnerName: 'Ashley',
            priority: 1,
          },
          {
            name: 'Scuffles in the parking lot',
            id: 5,
            status: 'Open',
            sourceProjectName: 'Chennai',
            sourceChannelName: 'Warehouse',
            statusOwnerName: 'Clara',
            priority: 1,
          },
        ],
      },
    };
  }

  static appID = 45;

  static OPS = {
    editLocation: {
      name: 'edit_location',
      requiredParams: ['location_id', 'config'],
    },
    resetLocations: {
      name: 'reset_locations',
      requiredParams: ['locations'],
    },
  };
}

const refreshApp = (dispatch) => {
  dispatch({
    type: 'apps/fetchApp',
    appID: CorporateApp.appID,
  });
};
throttledRefreshApp = _.throttle(refreshApp, 10000, { leading: true });

export default CorporateApp;
