import CameraSearchForm from '@/components/SearchForm2/CameraSearchForm';
import CreateSearchFilter from '@/components/SearchForm2/CreateSearchFilter';
import { locationFilters } from '@/utils/filterModules';
import React, { Component } from 'react';
import { connect } from 'umi';

import { Modal } from 'antd';
import styles from './style.less';

@connect(({ search2, loading }) => ({
  search2,
  loadingCreateSearchFilter:
    loading.effects['search_filters/createSearchFilter'],
}))
class FilterCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      okDisabled: true,
    };
    this.formRef = React.createRef();
    this.searchRef = React.createRef();
    this.filterCtn = React.createRef();
    this.createSearchFilterRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ okDisabled: true });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.search2, this.props.search2)) {
      this.forceUpdate();
    }
  }

  show() {
    this.setState({ showModal: true });
  }

  reset() {
    this.setState({ showModal: false, okDisabled: true });
  }

  searchFilterUpdated() {
    this.forceUpdate();
    this.filterCtn.current?.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    let appliedFilter = _.get(
      this.createSearchFilterRef,
      'current.state.appliedFilter',
    );

    let buttons = this.createSearchFilterRef.current?.getFilterMenu()?.buttons;

    return (
      <>
        <Modal
          title="Manage Filters"
          style={{ top: '5%' }}
          bodyStyle={{
            height: '80vh',
            padding: '0 16px 0 8px',
          }}
          width="800px"
          destroyOnClose={true}
          visible={this.state.showModal}
          footer={null}
          onOk={() => this.onOK()}
          okButtonProps={{
            disabled: this.state.okDisabled,
            loading: this.props.loadingCreateSearchFilter,
          }}
          onCancel={() => this.reset()}>
          <div className={styles.ctn}>
            <div
              style={{
                flexBasis: '40%',
                height: '100%',
                overflow: 'scroll',
              }}>
              <div className={styles.listing}>
                <CreateSearchFilter
                  filterManagerView={true}
                  updateCallback={() => this.searchFilterUpdated()}
                  ref={this.createSearchFilterRef}
                  searchRef={this.searchRef}
                />
              </div>
            </div>
            <div
              style={{
                height: '100%',
                width: '100%',
                flexBasis: '60%',
                overflow: 'hidden scroll',
              }}>
              <div className={styles.detail} ref={this.filterCtn}>
                {!appliedFilter && (
                  <div style={{ marginTop: '16px' }}>
                    <p>
                      Filters are saved searches you can use for filtering
                      results, setting up alerts, or charting insights.
                    </p>
                    <p>
                      Select an existing filter from the list to the left, or
                      create a brand new one.
                    </p>
                  </div>
                )}
                <div
                  className={styles.title}
                  style={{ display: appliedFilter ? 'flex' : 'none' }}>
                  <div className={styles.name}>{appliedFilter?.Name}</div>
                  <div className={styles.buttons}> {buttons}</div>
                </div>
                <div style={{ display: appliedFilter ? 'initial' : 'none' }}>
                  <CameraSearchForm
                    ref={this.searchRef}
                    formStyle={{ width: '100%' }}
                    doNotAffix={true}
                    assumeLicenses={true}
                    hideDate={true}
                    hideSubmit={true}
                    showFilters={locationFilters}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <span onClick={() => this.show()}>{this.props.children}</span>
      </>
    );
  }
}

export default FilterCreator;
