import ChannelTile from '@/pages/locations/components/channel-tile-2';
import {
  DeleteFilled,
  EditFilled,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { Popconfirm, Typography } from 'antd';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useSelector } from 'umi';
import styles from './styles.less';
import { getRecentChannelsTileName } from './utils';
import {
  useRecentChannelsTileMarkingFavoriteActions,
  useRecentChannelsTileRemoveActions,
  useRecentChannelsTileUpdateActions,
} from './VMSPlusMutations';
import type {
  RecentChannelsTileFragment$data,
  RecentChannelsTileFragment$key,
} from './__generated__/RecentChannelsTileFragment.graphql';

const { Text } = Typography;

interface RecentChannelsTileProps {
  appId: number;
  recentChannelsTileKey: RecentChannelsTileFragment$key;
  onSelect: (
    recentTileRef: RecentChannelsTileFragment$key,
    recentTileData: RecentChannelsTileFragment$data,
  ) => void;
  recentTilesConnectionData: {
    id: string;
    key: string;
  };
}

export const RecentChannelsTileFragment = graphql`
  fragment RecentChannelsTileFragment on VMSPlusRecentChannels {
    id
    name
    isFavorite
    channelIDs
    timestamp
    anchorTimeStamp
  }
`;

const RecentChannelsTile = ({
  appId,
  recentChannelsTileKey,
  onSelect,
  recentTilesConnectionData,
}: RecentChannelsTileProps) => {
  const recentTile = useFragment(
    RecentChannelsTileFragment,
    recentChannelsTileKey,
  );

  const { markAsFavorite, unMarkAsFavorite } =
    useRecentChannelsTileMarkingFavoriteActions({
      recentTile,
      recentTilesConnectionData,
      appId,
    });

  const { removeChannelsTile } = useRecentChannelsTileRemoveActions({
    recentTile,
    recentTilesConnectionData,
    appId,
  });

  const { updateTileName } = useRecentChannelsTileUpdateActions({
    recentTile,
    appId,
  });

  const { ch } = useSelector((state) => ({
    // @ts-expect-error
    ch: state.locations.ch,
  }));

  const tileName = useMemo(
    () =>
      recentTile.name ??
      getRecentChannelsTileName(recentTile?.channelIDs as string[], ch),
    [recentTile, ch],
  );

  const handleTileClick = () => {
    onSelect(recentChannelsTileKey, recentTile);
  };

  const handleStarClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    markAsFavorite();
  };

  const handleUnStarClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    unMarkAsFavorite();
  };

  const handleTileTitleChage = (text: string) => {
    if (text !== tileName) {
      updateTileName(text);
    }
  };

  return (
    <>
      <div key={recentTile?.timestamp} className="df-tile-container">
        <div className={styles['df-tile-star-container']}>
          {recentTile.isFavorite ? (
            <StarFilled
              onClick={handleUnStarClick}
              style={{ color: '#ff9301' }}
            />
          ) : (
            <StarOutlined onClick={handleStarClick} />
          )}
        </div>
        <div className="df-tile-content">
          <div className="df-tile-body" onClick={handleTileClick}>
            {recentTile?.channelIDs.map((channelID) => (
              <div
                key={channelID}
                className="df-tile-overlaid"
                style={{ pointerEvents: 'none' }}>
                <ChannelTile
                  key={channelID}
                  channelID={Number(channelID)}
                  showTime={false}
                  showImagePreview={false}
                />
              </div>
            ))}
          </div>
          <div className={styles['tile-footer-container']}>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                gap: '6px',
              }}>
              <Text
                className={styles['tile-title']}
                editable={{
                  onChange: handleTileTitleChage,
                  icon: <EditFilled size={14} />,
                }}
                onClick={(e) => e?.stopPropagation()}>
                {tileName}
              </Text>
              {recentTile?.timestamp && (
                <div className={styles['last-updated']}>
                  Last updated{' '}
                  {moment(Number(recentTile?.timestamp) * 1000).fromNow()}
                </div>
              )}
            </div>
            {recentTile.isFavorite ? (
              <Popconfirm
                title="Are you sure you want to delete the scene?"
                onConfirm={(e) => {
                  e?.stopPropagation();
                  removeChannelsTile();
                }}
                okText="Delete"
                cancelText="Cancel"
                okType="danger"
                okButtonProps={{
                  type: 'primary',
                }}>
                <div className={styles['delete-icon']}>
                  <DeleteFilled />
                </div>
              </Popconfirm>
            ) : (
              <div
                className={styles['delete-icon']}
                onClick={(e) => {
                  e.stopPropagation();
                  removeChannelsTile();
                }}>
                <DeleteFilled />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { RecentChannelsTile };
