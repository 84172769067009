import React from 'react';
import { connect, Link, Outlet } from 'umi';

import AppHeader from '@/components/AppHeader';
import { isInternalUser } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import { Layout, Menu } from 'antd';
import styles from './AccountLayout.less';

const { Content, Sider } = Layout;

// @ts-expect-error
@connect(({ user }) => ({ currentUser: user.currentUser }))
class AccountLayout extends React.Component {
  state = {};

  componentDidMount() {
    this.props.dispatch({
      type: 'user/fetchCurrent',
    });
    this.props.dispatch({
      type: 'sites/fetchSites',
    });
    this.props.dispatch({
      type: 'labels/fetchLabels',
    });
    this.props.dispatch({
      type: 'locations/fetchLocations',
    });
    const pathname = this.props.location.pathname;
    let key = '1';
    if (pathname === '/account/settings') {
      key = '1';
    } else if (pathname === '/account/users') {
      key = '2';
    } else if (pathname === '/account/sites') {
      key = '3';
    } else if (pathname === '/account/app-roles') {
      key = '4';
    } else if (pathname === '/account/licenses') {
      key = '5';
    } else if (pathname === '/account/billing') {
      key = '6';
    } else {
      key = '7';
    }
    this.internalUser = false;
    this.setupMetadata(key);
  }

  onMenuClick(args) {
    this.setupMetadata(args.key);
  }

  setupMetadata(key) {
    this.internalUser = isInternalUser(this.props.currentUser);
    const selectedKey = key;

    this.setState({
      selectedKey,
    });
  }

  render() {
    const { props } = this;

    // this is defined in config/theme.ts
    const siderWidth = '232px';

    return (
      <Layout>
        <AppHeader />
        <Layout>
          <Sider
            breakpoint="md"
            collapsedWidth="0"
            collapsible={true}
            zeroWidthTriggerStyle={{
              top: '-49px',
              left: 0,
              backgroundColor: 'transparent',
              borderRadius: '0 8px 8px 0',
            }}
            width={siderWidth}
            className={styles.sider}>
            <Menu
              className={styles['menu-container']}
              mode="inline"
              selectable
              onClick={(args) => this.onMenuClick(args)}
              selectedKeys={[this.state.selectedKey]}>
              <Menu.Item key="1">
                <Link className={styles['menu-item']} to="/account/settings">
                  Account
                </Link>
              </Menu.Item>
              {this.internalUser ? (
                <Menu.Item key="2">
                  <Link className={styles['menu-item']} to="/account/users">
                    Users
                  </Link>
                </Menu.Item>
              ) : null}
              <Menu.Item key="3">
                <Link className={styles['menu-item']} to="/account/sites">
                  Sites
                </Link>
              </Menu.Item>
              {this.internalUser ? (
                <Menu.Item key="5">
                  <Link to="/account/licenses" className={styles['menu-item']}>
                    Licenses
                  </Link>
                </Menu.Item>
              ) : null}
              <Menu.Item key="7">
                <div
                  style={{ color: 'red' }}
                  className={styles['menu-item']}
                  onClick={() =>
                    props.dispatch({
                      type: 'user/logout',
                    })
                  }>
                  Logout
                </div>
              </Menu.Item>
            </Menu>
          </Sider>
          <Content>
            <div className={styles['page-wrapper']}>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
export default withRouter(AccountLayout);
