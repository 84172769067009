import ChannelSelect from '@/components/ChannelSelect2';
import DataList from '@/components/DataList';
import DaySelector from '@/components/DaySelector';
import RangePickerDF from '@/components/RangePickerDF';
import {
  groupFiltersByChannel,
  groupFiltersByType,
} from '@/components/SearchForm2/SpatialFilters/utils';
import SpatialFiltersModal from '@/components/SearchForm2/SpatialFiltersModal';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import type { Values } from '@/types/utils';
import { isEmail } from '@/utils/utils';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useDispatch } from 'umi';
import CodeList from '../codes-list';
import {
  AlarmTriggerObjectFilters,
  AlarmTriggerObjectFiltersLabels,
} from './constants';
import { getObjectLabelFromFilters } from './utils';

const disabledDate = () => false;

const getCreatePayload = ({ formData, alarm, namespace }) => {
  let payload = {
    step: 'last',
    site_id: formData[0].site_id,
    data: {
      name: formData[0].alarmName,
      timeframe: {
        days: formData[0].timeframe.days,
        timezone: formData[0].timeframe.timezone,
        time_range: {
          from: formData[0].timeframe.time_range[0].format('HH:mm:ss'),
          to: formData[0].timeframe.time_range[1].format('HH:mm:ss'),
        },
      },
      actions: formData[2].actions,
      autoEscalate: formData[2].autoEscalate,
      autoEscalateAfterInMins: formData[2].autoEscalateAfterInMins,
      escalations: formData[2].escalations,
      codes: formData[3].codes,
      priority: formData[3].priority,
      // enabled: true,
      search_filter: {
        id: alarm?.data?.search_filter?.id || null,
        filter_dsl:
          namespace === 'burglar_alarm'
            ? {
                ...groupFiltersByType(formData[1]).filter_dsl,
                Objects:
                  AlarmTriggerObjectFilters[
                    formData[3].objects as Values<
                      typeof AlarmTriggerObjectFiltersLabels
                    >
                  ],
              }
            : groupFiltersByType(formData[1]).filter_dsl,
      },
      sources: {
        channels: formData[0].cameras.map((c) => {
          return {
            id: c,
          };
        }),
      },
      delivery: {
        type: 'realtime_alerts',
        threshold: {
          review_queue: formData[2].reviewQueue,
          run_frequency: 30,
          throttle_window: 600,
          event_time_buffer: 5,
          rolling_window: 120,
          type: 'gt',
          metric: 0,
          auto_snooze_seconds: formData[2].autoSnoozeSeconds,
          ..._.get(formData[3], 'delivery.threshold', {}),
        },
      },
    },
  };
  if (alarm) {
    payload.alarm_id = alarm.alarm_id;
  }
  return payload;
};

const getDefaultFormData = ({ namespace }) => {
  let formData = [{}, {}, {}, {}];
  formData[2] = {
    autoSnoozeSeconds: 0,
  };
  formData[3] = {
    priority: 3,
    delivery: {
      threshold: {
        run_frequency: 30,
        throttle_window: 600,
        event_time_buffer: 5,
        rolling_window: 120,
      },
    },
  };
  if (namespace === 'burglar_alarm') {
    formData[3].objects = AlarmTriggerObjectFiltersLabels.people; // Default to people
  }
  return formData;
};

const getFormData = ({ alarm, namespace }) => {
  let formData = [{}, {}, {}, {}];
  formData[0] = {
    site_id: alarm.site_id,
    cameras: alarm.data.sources.channels.map((c) => c.id),
    alarmName: alarm.data.name,
    timeframe: {
      time_range: [
        moment(
          moment().format('YYYY-MM-DD') +
            ' ' +
            alarm.data.timeframe.time_range.from,
        ),
        moment(
          moment().format('YYYY-MM-DD') +
            ' ' +
            alarm.data.timeframe.time_range.to,
        ),
      ],
      timezone: alarm.data.timeframe.timezone,
      days: alarm.data.timeframe.days,
    },
  };
  formData[1] = groupFiltersByChannel(
    _.get(alarm, 'data.search_filter.filter_dsl', {}),
  );
  formData[2] = {
    actions: _.get(alarm, 'data.actions'),
    escalations: _.get(alarm, 'data.escalations'),
    reviewQueue: _.get(alarm, 'data.delivery.threshold.review_queue', false),
    autoEscalate: _.get(alarm, 'data.autoEscalate'),
    autoEscalateAfterInMins: _.get(alarm, 'data.autoEscalateAfterInMins'),
    autoSnoozeSeconds: _.get(
      alarm,
      'data.delivery.threshold.auto_snooze_seconds',
      0,
    ),
  };
  formData[3] = {
    codes: alarm.data.codes,
    priority: alarm.data.priority,
    delivery: alarm.data.delivery,
  };
  if (namespace === 'burglar_alarm') {
    formData[3].objects = getObjectLabelFromFilters(
      alarm.data.search_filter.filter_dsl?.Objects || [],
    );
  }
  return formData;
};

const SetupAlarm = ({
  filteredSites,
  loadingLocation,
  creatingAlarm,
  alarm,
  sites,
  locations,
  onSubmit,
  onCancel,
  namespace,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedSite, setSelectedSite] = useState();
  const [filterLocationIDs, setFilterLocationIDs] = useState([]);
  let formRef = useRef<FormInstance>();
  const timezones = moment.tz.names();

  const onSiteChange = (val) => {
    setSelectedSite(val);
    let locIDs = sites.all
      .find((s) => s.SiteID === val)
      .Projects.map((p) => p.ProjectID);
    setFilterLocationIDs(locIDs);
    let locationNode = locations.all.find(
      (l) => locIDs.indexOf(l.ProjectID) !== -1,
    );
    if (locationNode && locationNode.Timezone) {
      formRef.current?.setFieldValue(
        ['timeframe', 'timezone'],
        locationNode.Timezone,
      );
    }
  };

  const [formData, setFormData] = useState(() => {
    const data = alarm
      ? getFormData({ alarm, namespace })
      : getDefaultFormData({ namespace });
    if (data[0]?.site_id) {
      onSiteChange(data[0].site_id);
    }
    return data;
  });

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const dispatch = useDispatch();

  const validateDestination = (
    rule: any,
    value: string,
    callback: Function,
  ) => {
    const typeField = rule.field.replace('to', 'type');
    const typeValue = formRef.current?.getFieldValue(typeField.split('.'));

    if (typeValue === 'email') {
      if (!value || !isEmail(value)) {
        callback('Please enter a valid email address');
        return;
      }
    } else if (!value) {
      callback('Please enter a value');
      return;
    }
    callback();
  };

  return (
    <>
      <div style={{ padding: '10px 0px', minWidth: '500px' }}>
        <h4>
          {
            [
              'Step 1 of 4: Name and Time',
              'Step 2 of 4: Cameras and Areas',
              'Step 3 of 4: Notifications and Escalations',
              'Step 4 of 4: Overrides and Advanced Settings',
            ][currentStep]
          }
        </h4>
      </div>
      <div
        className="steps-content"
        style={{
          minHeight: '500px',
          display: 'flex',
          marginBottom: '10px',
          // justifyContent: 'center',
        }}>
        {currentStep === 0 && (
          <Form
            ref={formRef}
            requiredMark={false}
            layout="vertical"
            style={{ minWidth: '500px' }}
            initialValues={formData[currentStep]}
            onFinish={(values) => {
              console.log('values', values);
              setFormData((f) => {
                f[currentStep] = values;
                return f;
              });
              let projectIDs = [];
              values.cameras.forEach((c) => {
                let channelNode = locations.ch.byId[c];
                // Fetch Location if there is no media for this channel
                if (channelNode.LatestMedia.length === 0) {
                  projectIDs.push(channelNode.ProjectID);
                }
              });
              let promises = [];
              new Set(projectIDs).forEach((id) => {
                promises.push(
                  dispatch({
                    type: 'locations/fetchLocation',
                    payload: { locationID: id },
                  }),
                );
              });
              Promise.all(promises).then(() => next());
            }}>
            <Form.Item
              name="site_id"
              label="Select Site"
              rules={[
                {
                  required: true,
                  message: 'Please select site',
                },
              ]}>
              <Select onChange={onSiteChange}>
                {filteredSites.map((site) => (
                  <Select.Option key={site.SiteID} value={site.SiteID}>
                    {site.Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {selectedSite && filterLocationIDs.length === 0 && (
              <div className="df-warn-text" style={{ marginBottom: '10px' }}>
                No Cameras in this Site, Please choose another site.
              </div>
            )}
            <Form.Item
              name="cameras"
              label="Select Cameras"
              rules={[
                {
                  required: true,
                  message: 'Please select cameras',
                },
              ]}>
              <ChannelSelect
                disabled={selectedSite && filterLocationIDs.length === 0}
                multiple
                filterLocationIDs={filterLocationIDs}
              />
            </Form.Item>
            <Form.Item
              name="alarmName"
              label="Alarm Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter alarm name',
                },
              ]}>
              <Input />
            </Form.Item>
            <Row gutter={8}>
              <Col span={16}>
                <Form.Item
                  name={['timeframe', 'time_range']}
                  label=""
                  rules={[
                    {
                      required: true,
                      message: 'Please enter from and to time',
                    },
                  ]}>
                  <RangePickerDF
                    fromTitle="From"
                    toTitle="To"
                    disabledDate={disabledDate}
                    canWrapTime={true}
                    showDate={false}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                Timezone
                <Form.Item
                  label=""
                  name={['timeframe', 'timezone']}
                  rules={[
                    {
                      required: true,
                      message: 'Please select the timezone',
                    },
                  ]}>
                  <Select
                    defaultValue={'UTC'}
                    disabled
                    showSearch
                    dropdownMatchSelectWidth={false}
                    optionLabelProp="label">
                    {timezones.map((tz) => (
                      <Select.Option key={tz} label={tz} value={tz}>
                        {tz}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name={['timeframe', 'days']}
              label=""
              rules={[
                {
                  required: true,
                  message: 'Please select the days',
                },
              ]}>
              <DaySelector />
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'start' }}>
                {currentStep > 0 && (
                  <Button style={{ marginRight: '8px' }} onClick={prev}>
                    Previous
                  </Button>
                )}
                {currentStep < 3 && (
                  <>
                    <Button
                      style={{ marginRight: '8px' }}
                      onClick={() => onCancel()}>
                      Cancel
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={loadingLocation}
                      loading={loadingLocation}>
                      Next
                    </Button>
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        )}
        {currentStep === 1 && (
          <Form
            layout="vertical"
            style={{ minWidth: '500px', width: '100%' }}
            initialValues={formData[currentStep]}
            onFinish={(values) => {
              console.log('values', values);
              setFormData((f) => {
                f[currentStep] = values;
                return f;
              });
              next();
            }}>
            <Form.Item>
              <DataList
                columns={[
                  {
                    title: 'Site',
                    key: 'site',
                    render: (_, record) => {
                      return record.locationNode.Name;
                    },
                  },
                  {
                    title: 'Channel',
                    key: 'channelNode.Name',
                    render: (_, record) => {
                      return record.channelNode.Name;
                    },
                  },
                  {
                    title: 'Actions',
                    key: 'actions',
                    render: (_, record) => (
                      <Form.Item
                        noStyle
                        name={[record.channelNode.ChannelID, 'spatialFilters']}>
                        <SpatialFiltersModal
                          channelID={record.channelNode.ChannelID}>
                          <Button>Configure</Button>
                        </SpatialFiltersModal>
                      </Form.Item>
                    ),
                  },
                ]}
                cardMap={{
                  content: (record) => {
                    return (
                      <div
                        style={{
                          border: '1px solid #efefef',
                          padding: '16px 0px 0px 16px',
                          borderRadius: '4px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}>
                        <ChannelTile
                          noLink
                          key={record.channelNode.ChannelID}
                          channelID={record.channelNode.ChannelID}
                          showTime={false}
                        />
                        <div style={{ paddingBottom: '16px' }}>
                          <Form.Item
                            noStyle
                            name={[
                              record.channelNode.ChannelID,
                              'spatialFilters',
                            ]}>
                            <SpatialFiltersModal
                              channelID={record.channelNode.ChannelID}>
                              <Button size="small">Configure</Button>
                            </SpatialFiltersModal>
                          </Form.Item>
                        </div>
                      </div>
                    );
                  },
                }}
                dataList={formData[0].cameras.map((c) => {
                  let channelNode = locations.ch.byId[c];
                  return {
                    key: c,
                    channelNode,
                    locationNode: locations.loc.byId[channelNode.ProjectID],
                  };
                })}
                pagination={{
                  p_size: formData[0].cameras.length,
                }}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'start' }}>
                {currentStep > 0 && (
                  <Button style={{ marginRight: '8px' }} onClick={prev}>
                    Previous
                  </Button>
                )}
                {currentStep < 3 && (
                  <Button type="primary" htmlType="submit">
                    Next
                  </Button>
                )}
              </div>
            </Form.Item>
          </Form>
        )}
        {currentStep === 2 && (
          <Form
            ref={formRef}
            requiredMark={false}
            layout="vertical"
            style={{ minWidth: '500px' }}
            initialValues={formData[currentStep]}
            onFinish={(values) => {
              console.log('values', values);
              setFormData((f) => {
                f[currentStep] = values;
                return f;
              });
              next();
            }}>
            <div style={{ fontWeight: 500, marginBottom: '5px' }}>
              Screener+
            </div>
            <div className={'df-description'}>
              Subscribe to Dragonfruit's Screener+ service to have our Security
              Operations Center staff review the alarm before sending to your
              attention.
              <br />
              This reduces false alarms as our staff will verify that the
              incident is valid before notifying you.
            </div>
            <Form.Item name="reviewQueue" valuePropName="checked">
              <Checkbox>Enable Screener+ subscription for this alarm</Checkbox>
            </Form.Item>
            <div style={{ fontWeight: 500, margin: '10px 0px' }}>
              Incident Notifications
            </div>
            <Form.List name="actions">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <Row gutter={8}>
                          <Col span={8}>
                            <Form.Item
                              {...field}
                              label={index === 0 ? 'Name' : ''}
                              name={[field.name, 'name']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter name',
                                },
                              ]}>
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...field}
                              label={index === 0 ? 'Type' : ''}
                              name={[field.name, 'type']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select type',
                                },
                              ]}>
                              <Select>
                                <Select.Option value="email">
                                  Email
                                </Select.Option>
                                <Select.Option value="text">Text</Select.Option>
                                <Select.Option value="voice">
                                  Voice
                                </Select.Option>
                                <Select.Option value="webhook">
                                  Webhook
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              {...field}
                              label={index === 0 ? 'Destination' : ''}
                              name={[field.name, 'to']}
                              rules={[
                                {
                                  validator: validateDestination,
                                },
                              ]}>
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Form.Item label={index === 0 ? ' ' : ''}>
                              <Button
                                onClick={() => remove(field.name)}
                                icon={<MinusCircleOutlined />}></Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <Form.Item>
                      <Button onClick={() => add()} icon={<PlusOutlined />}>
                        Add Notifications
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
            <Form.Item
              name="autoSnoozeSeconds"
              label="Auto Snooze"
              extra="Seconds to wait before sending the notification for an incident">
              <InputNumber style={{ width: '150px' }} />
            </Form.Item>
            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Item name="autoEscalate" valuePropName="checked">
                <Checkbox>Auto Escalate after</Checkbox>
              </Form.Item>
              <Form.Item name="autoEscalateAfterInMins">
                <Input style={{ width: '50px' }} defaultValue={15}></Input>
              </Form.Item>
              <Form.Item>&nbsp;&nbsp;mins</Form.Item>
            </div>
            <div style={{ fontWeight: 500, margin: '10px 0px' }}>
              Escalations
            </div>
            <Form.List name="escalations">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <Row gutter={8}>
                          <Col span={8}>
                            <Form.Item
                              {...field}
                              label={index === 0 ? 'Name' : ''}
                              name={[field.name, 'name']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter name',
                                },
                              ]}>
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...field}
                              label={index === 0 ? 'Type' : ''}
                              name={[field.name, 'type']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select type',
                                },
                              ]}>
                              <Select>
                                <Select.Option value="email">
                                  Email
                                </Select.Option>
                                <Select.Option value="text">Text</Select.Option>
                                <Select.Option value="voice">
                                  Voice
                                </Select.Option>
                                <Select.Option value="webhool">
                                  Webhook
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              {...field}
                              label={index === 0 ? 'Destination' : ''}
                              name={[field.name, 'destination']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter destination',
                                },
                              ]}>
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Form.Item label={index === 0 ? ' ' : ''}>
                              <Button
                                onClick={() => remove(field.name)}
                                icon={<MinusCircleOutlined />}></Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <Button
                      style={{ marginBottom: '10px' }}
                      onClick={() => add()}
                      icon={<PlusOutlined />}>
                      Add Escalations
                    </Button>
                  </>
                );
              }}
            </Form.List> */}
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              {currentStep > 0 && (
                <Button style={{ marginRight: '8px' }} onClick={prev}>
                  Previous
                </Button>
              )}
              {currentStep < 3 && (
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              )}
            </div>
          </Form>
        )}
        {currentStep === 3 && (
          <Form
            requiredMark={false}
            layout="vertical"
            style={{ minWidth: '500px', width: '100%' }}
            initialValues={formData[currentStep]}
            onFinish={(values) => {
              console.log('values', values);
              setFormData((f) => {
                f[currentStep] = values;
                return f;
              });
              // get create payload from formData
              const payload = getCreatePayload({ formData, alarm, namespace });
              if (alarm) {
                payload.alarm_id = alarm.alarm_id;
              }
              dispatch({ type: namespace + '/setupAlarm', payload }).then(
                () => {
                  onSubmit();
                },
              );
            }}>
            <div>
              <Form.Item noStyle name="codes">
                <CodeList />
              </Form.Item>
            </div>
            <Form.Item>
              <Collapse ghost={true}>
                <Collapse.Panel
                  className="no-horizontal-padding-collapse"
                  key="duplicate-rules"
                  header="Advanced Options"
                  forceRender={true}>
                  {namespace === 'burglar_alarm' && (
                    <Form.Item
                      label="Trigger on"
                      name="objects"
                      extra="Types of objects to trigger on">
                      <Select
                        style={{ width: 'min-content', minWidth: '150px' }}>
                        {[
                          {
                            label: 'People',
                            value: AlarmTriggerObjectFiltersLabels.people,
                          },
                          {
                            label: 'Vehicles',
                            value: AlarmTriggerObjectFiltersLabels.vehicle,
                          },
                          {
                            label: 'Both people and vehicles',
                            value: AlarmTriggerObjectFiltersLabels.both,
                          },
                        ].map(({ label, value }) => (
                          <Select.Option key={value} value={value}>
                            {label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    label="Default Priority"
                    name="priority"
                    extra="Priority assigned to alerts triggered by this rule">
                    <Select style={{ width: '150px' }}>
                      {[1, 2, 3, 4, 5].map((n) => (
                        <Select.Option key={n} value={n}>
                          {n}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['delivery', 'threshold', 'run_frequency']}
                    label="Run Frequency"
                    extra="How often should the rules be run (in seconds, minimum 20 seconds)">
                    <InputNumber style={{ width: '150px' }} />
                  </Form.Item>
                  <Form.Item
                    name={['delivery', 'threshold', 'throttle_window']}
                    label="Throttle Window"
                    extra="Seconds that need to pass before the rule sends notifications again">
                    <InputNumber type="number" style={{ width: '150px' }} />
                  </Form.Item>
                  <Form.Item
                    name={['delivery', 'threshold', 'event_time_buffer']}
                    label="Event Time Buffer"
                    extra="Seconds of video to share before and after a rule triggers">
                    <InputNumber type="number" style={{ width: '150px' }} />
                  </Form.Item>
                  <Form.Item
                    name={['delivery', 'threshold', 'rolling_window']}
                    label="Rolling Window"
                    extra="Seconds of past data to consider when evaluating rule">
                    <InputNumber type="number" style={{ width: '150px' }} />
                  </Form.Item>
                </Collapse.Panel>
              </Collapse>
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              {currentStep > 0 && (
                <Button style={{ marginRight: '8px' }} onClick={prev}>
                  Previous
                </Button>
              )}
              {currentStep === 3 && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={creatingAlarm}>
                  Done
                </Button>
              )}
            </div>
          </Form>
        )}
      </div>
    </>
  );
};

export default SetupAlarm;
