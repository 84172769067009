import { ACCESS_CONTROL_APPS, APPS } from '@/pages/apps/app/constants/appList';
import { METRIC_GROUPS } from '@/utils/insight';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Collapse, Form, Input, Popover, Radio, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import FacetContainer from '../facet-container';
import styles from './style.less';

const INITIAL_VALUE = {
  metric: 'occupancy',
};

@connect(({ apps }) => ({ apps }))
class MetricsFacet extends React.PureComponent {
  constructor(props) {
    super(props);
    const facet = _.get(props, 'facet', {});
    this.formRef = React.createRef({});
    this.state = {
      editing: true,
      facet,
      accessControlEntries: [],
    };

    this.loadAccessControlData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.facet !== this.props.facet) {
      const { facet } = this.props;
      this.setState({ facet });
      this.formRef.current?.setFieldsValue(this.props.facet);
    }
    if (!_.isEqual(prevProps.apps, this.props.apps)) {
      this.loadAccessControlData();
    }
  }

  loadAccessControlData() {
    const { apps, dispatch } = this.props;

    apps.all.forEach((app) => {
      if (ACCESS_CONTROL_APPS.includes(APPS[app.AppID])) {
        APPS[app.AppID].getEventSources(dispatch).then((entries) => {
          const new_accessControlEntries = entries.filter(
            (entry) => _.get(entry, ['channelIDs', 'length'], 0) > 0,
          );
          this.setState({
            accessControlEntries: [
              ...this.state.accessControlEntries,
              ...new_accessControlEntries,
            ],
          });
        });
      }
    });
  }

  toggleEdit() {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  }

  onFormValueChange() {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        this.setState({ facet: values }, () => {
          this.props.onFacetChange(values);
        });
      })
      .catch((err) => console.log('err', err));
  }

  renderCustomMetricForm() {
    return (
      <div id="custom-form-item" style={{ paddingLeft: '25px' }}>
        <Form.Item noStyle>
          <Form.Item
            style={{ marginBottom: '8px' }}
            label={<span style={{ fontWeight: 400 }}>Name</span>}
            name={['custom', 'name']}
            rules={[{ required: true, message: 'Name is required' }]}>
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={
              <span style={{ fontWeight: 400 }}>
                Formula&nbsp;
                <Popover
                  trigger="click"
                  arrowPointAtCenter
                  content={
                    <span>
                      Define a formula that uses a listed metric in it.
                      <br />
                      For example:
                      <br />
                      occupancy * 0.8 + 2
                    </span>
                  }>
                  <QuestionCircleOutlined />
                </Popover>
              </span>
            }
            name={['custom', 'expression']}
            rules={[
              {
                required: true,
                message: 'Expression is required',
              },
            ]}>
            <Input></Input>
          </Form.Item>
        </Form.Item>
      </div>
    );
  }

  renderAccessAllForm() {
    let { accessControlEntries } = this.state;

    if (_.isEmpty(accessControlEntries)) {
      return (
        <div id="custom-form-item" style={{ paddingLeft: '25px' }}>
          No entries configured
        </div>
      );
    }

    return (
      <div id="custom-form-item" style={{ paddingLeft: '25px' }}>
        <Form.Item
          name={['access', 'ids']}
          rules={[
            {
              min: 1,
              type: 'array',
              message: 'An entry is required',
            },
          ]}>
          <Select
            mode="multiple"
            placeholder="Select Entries..."
            options={accessControlEntries.map(({ source }) => ({
              label: source.name,
              key: source.id,
              value: source.id,
            }))}></Select>
        </Form.Item>
      </div>
    );
  }

  render() {
    const radioStyle = {
      display: 'block',
      height: '30px',
    };
    const { editing, facet } = this.state;
    const facet_value = { ...INITIAL_VALUE, ...facet };

    let openGroup = _.find(METRIC_GROUPS, (group) =>
      _.find(group.metrics, (info) => facet.metric === info.value),
    )?.group.value;

    return (
      <FacetContainer
        title="Metrics"
        editing={editing}
        disabled={_.get(this.props, 'disableFacet', false)}
        toggleEdit={() => this.toggleEdit()}>
        <div style={{ padding: '10px' }}>
          {editing === true && (
            <Form
              initialValues={facet_value}
              layout="vertical"
              colon={false}
              requiredMark={false}
              ref={this.formRef}
              size="small"
              name="metrics_facet"
              onValuesChange={(changedValues, allValues) => {
                this.setState({ facet: allValues });
                setTimeout(() => {
                  this.onFormValueChange();
                }, 0);
              }}
              autoComplete="off">
              <Form.Item
                noStyle
                name="metric"
                rules={[
                  {
                    required: true,
                    message: 'Please choose one',
                  },
                ]}>
                <Radio.Group style={{ fontSize: '12px', width: '100%' }}>
                  <Collapse ghost={true} defaultActiveKey={openGroup}>
                    {METRIC_GROUPS.map(({ group, metrics }) => (
                      <Collapse.Panel
                        key={group.value}
                        className={styles['mheader-panel']}
                        header={
                          <div className={styles['mheader']}>{group.name}</div>
                        }
                        forceRender={true}>
                        {metrics.map((info) => (
                          <div key={info.value}>
                            <Radio style={radioStyle} value={info.value}>
                              {info.name}
                              {info.description && (
                                <Popover
                                  trigger="click"
                                  arrowPointAtCenter
                                  content={
                                    <div style={{ maxWidth: 300 }}>
                                      {info.description}
                                    </div>
                                  }>
                                  &nbsp;
                                  <QuestionCircleOutlined />
                                </Popover>
                              )}
                            </Radio>
                            {facet.metric === info.value && (
                              <>
                                {group.value === 'advanced' &&
                                  info.value === 'custom' &&
                                  this.renderCustomMetricForm()}
                                {group.value === 'access' &&
                                  this.renderAccessAllForm()}
                              </>
                            )}
                          </div>
                        ))}
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </Radio.Group>
              </Form.Item>
            </Form>
          )}
          {editing === false && <>{JSON.stringify(facet_value)}</>}
        </div>
      </FacetContainer>
    );
  }
}

export default MetricsFacet;
