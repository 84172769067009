import Settings from '@/components/Settings';
import { Button, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'umi';
import InsightDelete from './InsightDelete';
import InsightEmailsTab from './InsightEmailsTab';
import InsightNameTab from './InsightNameTab';
import type { InsightFormValueType } from './types';

interface Props {
  insightID: string;
  children: React.ReactNode;
  dispatch?: (_any: any) => Promise<any>;
}

interface State {
  showModal: boolean;
}

// @ts-expect-error
@connect(({ insights }) => ({
  insights,
}))
class InsightSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  render() {
    const { insightID, children } = this.props;

    if (!insightID) {
      return null;
    }

    const updateInsightData = (values: InsightFormValueType) => {
      const { dispatch } = this.props;
      return (
        dispatch &&
        dispatch({
          type: 'insights/updateInsight',
          insightID,
          payload: values,
        })
      );
    };

    const insightSettings = [
      {
        name: 'Insight Name',
        content: (
          <InsightNameTab
            insightID={insightID}
            updateInsightData={updateInsightData}
          />
        ),
      },
      {
        name: 'Emails',
        content: (
          <InsightEmailsTab
            insightID={insightID}
            updateInsightData={updateInsightData}
          />
        ),
      },
      {
        name: 'Delete Insight',
        content: <InsightDelete insightID={insightID} />,
      },
    ];

    return (
      <>
        <Modal
          title="Insight Settings"
          visible={this.state.showModal}
          onCancel={() => this.toggleModal()}
          width={800}
          zIndex={1030}
          footer={[
            <Button key="back" onClick={() => this.toggleModal()}>
              Close
            </Button>,
          ]}>
          <Settings
            settingsConfig={insightSettings}
            startTabName={insightSettings[0].name}
          />
        </Modal>
        <div
          style={{ display: 'inline-flex', alignItems: 'center' }}
          onClick={() => this.toggleModal()}>
          {children}
        </div>
      </>
    );
  }
}
export default InsightSettings;
