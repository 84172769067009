import { LocationMap } from '@/types/types';
import {
  DualViewportMap,
  PixiMap,
  PixiMapsContainer,
} from '@/utils/pixi-lib/components';
import { DfViewport } from '@/utils/pixi-lib/display';
import {
  ptsClamp,
  ptsConvertAbsToAbs,
  ptsConvertRelToAbs,
} from '@/utils/pixi-lib/math';
import centroid from '@turf/centroid';
import { polygon } from '@turf/helpers';
import * as PIXI from 'pixi.js';
import React from 'react';
import { connect } from 'umi';

const textStyle = new PIXI.TextStyle({
  strokeThickness: 1,
  fill: '#fff',
  fontFamily: 'Inter',
  fontWeight: '300',
  fontSize: 18,
  align: 'center',
});

const colors = [
  0xfadb14, // yellow-6
  0x4096ff, // blue-5
  0xff4d4f, // red-5
  0x73d13d, // green-5
  0x9254de, // purple-5
  0xffa940, // orange-5
  0x36cfc9, // cyan-5
  0xf759ab, // magenta-5
  0xffc53d, // gold-5
  0x597ef7, // geek-blue-5
  0xbae637, // lime-5
  0xff7a45, // volcano-5
];

type CoverageData = {
  coverage: [number, number][];
};

type MapZoneData = {
  Name: string;
  Config: {
    polygon: [number, number][];
  };
};

type Props = {
  locationMap: LocationMap;
  locationID: any;
  floorMapPixi: PIXI.Application;
  // injected by @connect
  dispatch: (action: any) => any;
  loading: any;
};

type State = {
  coverage?: { [channelId: string]: CoverageData };
};

// typescript declaration merging
// - https://www.typescriptlang.org/docs/handbook/declaration-merging.html
interface MapCoverage {
  floorMapRef: React.RefObject<HTMLDivElement>;
  pixiMapVP: DfViewport;
}

@connect(({ loading }) => ({ loading }), null, null, { forwardRef: true })
class MapCoverage extends DualViewportMap<Props, State> {
  constructor(props) {
    super(props);
    this._loadCoverageData();
  }

  private _loadCoverageData() {
    const { locationMap, locationID } = this.props;
    if (locationMap) {
      this.props
        .dispatch({
          type: 'location_maps/getCoverageV2',
          locationID: locationID,
          locationMapID: locationMap.LocationMapID,
        })
        .then((response) => {
          this.setState(
            {
              ...this.state,
              coverage: response.data,
            },
            () => {
              this.initAfterPixiLoaded();
            },
          );
        });
    }
  }

  initAfterPixiLoaded() {
    const { coverage } = this.state;
    const { locationMap } = this.props;

    if (coverage === undefined || !this.pixiMapVP) {
      return;
    }

    Object.entries(coverage).forEach(([channelId, coverageData], index) => {
      this.addPixiRegion(channelId, coverageData, index);
    });

    const mapRegions = locationMap?.MapRegions || [];
    mapRegions.forEach((mapZoneData) => {
      this.addPixiZone(mapZoneData);
    });
  }

  createPoint(x: number, y: number, _index: number): PIXI.Graphics {
    const point = new PIXI.Graphics();

    point.beginFill(0xffffff);
    point.lineStyle(2.5, 0x000000, 0.3, 1); // can't set css dropshadow on top of a sprite
    point.position.set(x, y);
    point.zIndex = 10;
    point.endFill();

    this.pixiMapVP.pixi.addChild(point);
    return point;
  }

  addPixiZone(mapZoneData: MapZoneData) {
    const points = mapZoneData.Config.polygon;

    // NOTE: regions are still stored in absolute map coordinates. This is okay for now
    //       because it is never expected to change.
    const transformedRegionPolygon = ptsConvertAbsToAbs(
      ptsClamp(
        points,
        this.pixiMapVP.origSpriteWidth,
        this.pixiMapVP.origSpriteHeight,
      ),
      this.pixiMapVP.origSpriteWidth,
      this.pixiMapVP.origSpriteHeight,
      this.pixiMapVP.worldWidth,
      this.pixiMapVP.worldHeight,
    );

    const regionPoints = transformedRegionPolygon.map((pt, index) =>
      this.createPoint(pt[0], pt[1], index),
    );
    const regionPoly = regionPoints.map((pt) => [pt.position.x, pt.position.y]);

    const regionGraphic = new PIXI.Graphics();
    regionGraphic.clear();
    regionGraphic.beginFill(0x000000, 0.2);
    regionGraphic.zIndex = 3;
    regionGraphic.lineStyle(2, 0x888888, 1);

    regionGraphic.drawPolygon(..._.flatten(regionPoly));
    regionGraphic.endFill();

    const text = new PIXI.Text(mapZoneData.Name, textStyle);
    const turfPolygon = polygon([[...regionPoly, regionPoly[0]]]);
    const turfCentroid = centroid(turfPolygon);
    const [turfX, turfY] = turfCentroid.geometry.coordinates;

    text.position.set(turfX, turfY);
    text.anchor.set(0.5, 0.5); // make (0, 0) as the center of the text sprite
    text.zIndex = 5;
    regionGraphic.addChild(text);

    this.pixiMapVP.pixi.addChild(regionGraphic);
  }

  addPixiRegion(channelId: string, coverageData: CoverageData, index: number) {
    // NOTE: coverage is still stored in relative map coordinates. This makes it
    //       easier to display information. Map coords should be updated to
    //       correspond to this.
    const transformedRegionPolygon = ptsConvertRelToAbs(
      ptsClamp(coverageData.coverage, 1, 1),
      this.pixiMapVP.worldWidth,
      this.pixiMapVP.worldHeight,
    );

    const poly = transformedRegionPolygon.map(([x, y], i) => {
      const pt = this.createPoint(x, y, i);
      return [pt.position.x, pt.position.y];
    });

    const regionGraphic = new PIXI.Graphics();
    regionGraphic.beginFill(colors[index % colors.length], 0.4);
    regionGraphic.drawPolygon(..._.flatten(poly));
    regionGraphic.endFill();
    regionGraphic.zIndex = 2;

    this.pixiMapVP.pixi.addChild(regionGraphic);
  }

  render() {
    return (
      <PixiMapsContainer>
        <PixiMap
          pixiRef={this.floorMapRef}
          dfViewport={this.pixiMapVP}
          single></PixiMap>
      </PixiMapsContainer>
    );
  }
}

export default MapCoverage;
