import { VIZS } from '@/utils/insight';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
} from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

const radioStyle = {
  display: 'block',
  height: '30px',
  margin: '0px',
};

// @ts-expect-error
@connect(({ insights }) => ({ insights }))
class EditInsightView extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = { showModal: false };
  }

  init() {
    this.setState({ vizSpec: this.props.vizSpec });
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.vizSpec, this.props.vizSpec)) {
      this.init();
    }
  }

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  handleSave(e) {
    const { onSave } = this.props;

    e.preventDefault();

    this.form.current.validateFields().then((values) => {
      // if we're default, nullify
      let isDefault = values.isDefault;
      delete values.isDefault;
      values = isDefault ? null : values;
      onSave(values);
      this.toggleModal();
    });
  }

  renderInsightOptions() {
    const { isEmbed, insights, insightID } = this.props;
    let { vizSpec } = this.state;
    let insight = _.get(insights, `byID[${insightID}]`, undefined);
    let isDefault = _.has(vizSpec, 'isDefault')
      ? vizSpec.isDefault
      : _.isEmpty(vizSpec);
    vizSpec = {
      ...vizSpec,
      isDefault,
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Form
          ref={this.form}
          initialValues={vizSpec}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}>
          <Form.Item name="isDefault" value={isDefault}>
            <Radio.Group
              onChange={(e) =>
                this.setState({
                  vizSpec: { ...vizSpec, isDefault: e.target.value },
                })
              }>
              <Radio style={radioStyle} value={true}>
                Default
              </Radio>
              <Radio style={radioStyle} value={false}>
                Override
              </Radio>
            </Radio.Group>
          </Form.Item>
          {!isDefault && (
            <>
              {isEmbed && (
                <Form.Item
                  label="Title"
                  name="label"
                  initialValue={_.get(insight, 'Name')}>
                  <Input placeholder="Enter Title..." />
                </Form.Item>
              )}
              <Form.Item
                label="Goal"
                name="goalValue"
                wrapperCol={{ span: 10 }}>
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Enter Value..."
                />
              </Form.Item>
              <Form.Item label="Show as" name="viz" wrapperCol={{ span: 10 }}>
                <Select placeholder="Select...">
                  {VIZS.map((v) => (
                    <Select.Option key={v.key} value={v.value}>
                      {v.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Legend"
                valuePropName="checked"
                name="showLegend">
                <Checkbox>Enabled</Checkbox>
              </Form.Item>
              <Form.Item
                label="Average"
                valuePropName="checked"
                name="showMean">
                <Checkbox>Enabled</Checkbox>
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    );
  }

  render() {
    const { children, insights, insightID } = this.props;
    let insight = _.get(insights, `byID[${insightID}]`, undefined);
    const { showModal } = this.state;
    return (
      <>
        <Modal
          width={500}
          height={180}
          title={`Edit Insight View ${insight ? `- ${insight.Name}` : ''}`}
          visible={showModal}
          onCancel={() => this.toggleModal()}
          destroyOnClose={true}
          footer={[
            <Button key="cancel" onClick={() => this.toggleModal()}>
              Cancel
            </Button>,
            <Button
              key="save"
              onClick={(e) => this.handleSave(e)}
              type="primary">
              Save
            </Button>,
          ]}>
          <div>{this.renderInsightOptions()}</div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default EditInsightView;
