import React from 'react';
import _ from 'lodash';
import { connect, history, Link } from 'umi';
import { notification, Image, Modal, Checkbox, Button, Form, Tabs } from 'antd';
import { Line } from '@ant-design/charts';
import LineDivider from '@/components/LineDivider';
import InfoSidebar from '@/components/InfoSidebar';
import styles from './style.less';
import { gd, ga, doAppOp, dispatchWithFeedback } from '@/utils/utils';
import { getSidebarActions } from '@/utils/datatable';
import NotificationsTriggeredTable from '@/pages/apps/app/components/notifications-triggered-table';
import NotificationsEditRule from './components/notifications-edit-rule-2';
import RetailDemoImage from '@/assets/retail-demo.jpeg';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import TimelinePlayer from '@/components/TimelinePlayer';
import { transformTriggered2, getBaseRule } from '@/utils/notifications';
import BaseApp from './BaseApp';
import JSONConfigLoader from '@/components/JSONConfigLoader';

const { TabPane } = Tabs;

// @ts-expect-error
@connect(({ apps, locations, user, loading }) => ({
  apps,
  loc: locations.loc,
  ch: locations.ch,
  currentUser: user.currentUser,
  loadingFetchApp: loading.effects['apps/fetchApp'],
}))
class RetailApp extends BaseApp {
  static OPS = {
    saveConfig: {
      name: 'save_config',
      requiredParams: ['config'],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      monitoredChannels: [],
    };
    this.playerRef = React.createRef();
    this.alertDetailsRef = React.createRef();
    this.infoSidebarRef = React.createRef();

    this.setConfig = this.setConfig.bind(this);
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) ||
      JSON.stringify(prevProps.queryParams) !==
        JSON.stringify(this.props.queryParams)
    ) {
      this.setup();
    }
  }

  setup() {
    const data = this.props.data.config || this.getTestData();
    this.setState({ data });
    const locationID = parseInt(
      _.get(this.props, 'queryParams.locationID', '0'),
    );
    const alertID = parseInt(_.get(this.props, 'queryParams.alertID', '0'));

    this.setSelectedLocationFromParams(data, locationID);
    this.setSelectedAlertFromParams(data, alertID);
  }

  setSelectedLocationFromParams(data, locationID) {
    if (locationID === 0) {
      return null;
    }
    const selectedLocation = data.locations.find(
      (x) => x.locationID === locationID,
    );
    if (selectedLocation) {
      this.setLocation(selectedLocation);
    }
    return selectedLocation;
  }

  setSelectedAlertFromParams(data, alertID) {
    if (alertID === 0) {
      return null;
    }
    const selectedAlert = this.getSelectedAlertFromID(data, alertID);
    if (selectedAlert) {
      this.setSelectedAlert(selectedAlert);
    }
    return selectedAlert;
  }

  setLocation(loc) {
    // this will reset the loc in state and params as well
    this.setState({ selectedLocation: loc }, () => {
      this.updateURLParams();
    });

    if (!loc) {
      return;
    }

    // loc can be null
    const { locationID } = loc;
    this.fetchLocation(locationID).then(() => {
      const location = this.props.loc.byId[locationID];
      if (location) {
        const allChannels: number[] = this.props.ch.loc_ch_map[+locationID];
        const channelIDs = ga(this.state, 'selectedLocation.channelIDs');
        const channels = channelIDs
          .filter((id) => id in allChannels && +id in this.props.ch.byId)
          .map((id) => this.props.ch.byId[+id]);

        this.setState({ monitoredChannels: channels });
      }
    });
  }

  setSelectedAlert(record) {
    this.setState({ selectedAlert: record }, () => {
      this.updateURLParams();
    });
  }

  fetchLocation(locationID) {
    return dispatchWithFeedback(
      this.props.dispatch,
      'Fetching location',
      {
        type: 'locations/fetchLocation',
        payload: { locationID },
      },
      true,
    );
  }

  getSelectedAlertFromID(data, alertID) {
    const paths = [
      'loss_prevention.realtime.alerts',
      'loss_prevention.forensic.reports',
      'operations.realtime.alerts',
    ];
    let alert;
    paths.forEach((path) => {
      if (alert) {
        return;
      }
      Object.values(gd(data, `${path}.triggered`)).forEach((info) => {
        if (alert) {
          return;
        }
        const found = ga(info, 'list').find((x) => x.id === alertID);
        if (found) {
          alert = found;
        }
      });
    });
    if (!alert) {
      return null;
    }
    const list = transformTriggered2(
      {
        loc: this.props.loc,
        ch: this.props.ch,
      },
      [alert],
    );
    return list[0];
  }

  updateURLParams() {
    const location = this.state.selectedLocation;
    const alert = this.state.selectedAlert;
    const params = {};
    if (location) {
      params.locationID = location.locationID;
    }
    if (alert) {
      params.alertID = alert.id;
    }
    history.push({
      search: `?${new URLSearchParams(params).toString()}`,
    });
  }

  getTriggered(path, type) {
    const list = ga(this.state, `${path}.triggered[${type}].list`);
    return transformTriggered2(
      {
        loc: this.props.loc,
        ch: this.props.ch,
      },
      list,
    );
  }

  doRecordSelected(record) {
    this.setSelectedAlert(record);
  }

  setModal(key, value) {
    const dict = {};
    dict[key] = value;
    this.setState(dict);
  }

  getNotificationSet(spec) {
    let notificationsCount = 0;
    Object.values(gd(this.state, `${spec.path}.types`)).forEach((typeInfo) => {
      typeInfo.list.forEach((type) => {
        if (type.enabled) {
          notificationsCount += 1;
        }
      });
    });

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <div className={styles['pane-sec-header']}>{spec.title}</div>
          <div style={{ marginRight: '20px', float: 'right', width: '100%' }}>
            <Modal
              width={450}
              visible={this.state[spec.modalKey]}
              title="Settings"
              footer={null}
              onCancel={() => this.setModal(spec.modalKey, false)}>
              <Form requiredMark={false} colon={false}>
                {Object.entries(gd(this.state, `${spec.path}.types`)).map(
                  ([k, v]) => {
                    return (
                      <div key={k}>
                        <h4>{v.name}</h4>
                        <div className={styles['config-alerts-form']}>
                          {v.list.map((t) => (
                            <Form.Item style={{ width: '200px' }} key={t.type}>
                              <Checkbox
                                checked={t.enabled}
                                onChange={(e) => {
                                  t.enabled = e.target.checked;
                                  this.setState({ data: this.state.data });
                                }}>
                                {t.name}
                              </Checkbox>
                            </Form.Item>
                          ))}
                        </div>
                      </div>
                    );
                  },
                )}
              </Form>
            </Modal>
            <div
              className={styles.link}
              style={{ float: 'right' }}
              onClick={() => this.setModal(spec.modalKey, true)}>
              Configure
            </div>
          </div>
        </div>
        {notificationsCount === 0 ? (
          <span>
            <i>None configured.</i>
          </span>
        ) : (
          <span />
        )}
        {Object.values(gd(this.state, `${spec.path}.types`)).map((v) =>
          v.list.map(
            (t) =>
              t.enabled && (
                <div className={styles['info-module-ctn']} key={t.type}>
                  <div className={styles['info-module-header']}>
                    <span style={{ marginRight: '20px' }}>{t.name}</span>
                    <NotificationsEditRule rule={getBaseRule()}>
                      <span style={{ float: 'right' }} className={styles.link}>
                        Add
                      </span>
                    </NotificationsEditRule>
                  </div>
                  <NotificationsTriggeredTable
                    dataSource={this.getTriggered(spec.path, t.type)}
                    infoSidebarRef={this.infoSidebarRef}
                    dispatch={this.props.dispatch}
                    selected={this.state.selectedAlert}
                    doSelected={(r) => this.doRecordSelected(r)}
                    hideLocation={true}
                    hideName={true}
                    hidePriority={true}
                    isDemo={true}
                  />
                </div>
              ),
          ),
        )}
      </>
    );
  }

  setConfig(config) {
    doAppOp(this.props.appID, this.props.dispatch, RetailApp.OPS.saveConfig, {
      config,
    });
  }

  startDemoAlertTimer(spec) {
    const alert = this.getSelectedAlertFromID(this.state.data, spec.alertID);
    if (!alert) {
      return;
    }
    let current = _.clone(gd(this.state, 'timerOn'));
    current[spec.id] = true;
    this.setState({ timerOn: current });

    setTimeout(() => {
      notification.info({
        key: spec.id,
        message: `Alert: ${spec.name}`,
        description: (
          <>
            <div style={{ cursor: 'pointer' }}>
              <Button style={{ paddingLeft: 0 }} type="link">
                View Alert Details
              </Button>
              <div>
                Camera: <b>{alert.sourceChannelName}</b>
              </div>
            </div>
          </>
        ),
        duration: 0,
        onClick: () => {
          history.push(
            `/apps/47?alertID=${spec.alertID}&locationID=${spec.locationID}`,
          );
          // this.setSelectedAlert(alert);
          notification.close(spec.id);
        },
      });
      current = _.clone(gd(this.state, 'timerOn'));
      current[spec.id] = false;
      this.setState({ timerOn: current });
    }, spec.delay);
  }

  startDemo(spec) {
    this.setState({ demoSpec: spec });
  }

  renderDemo() {
    const demoConfig = ga(this.state, 'data.demos');

    const demoTabs = _.get(this.state, 'data.featureConfig', {
      Dashboards: {
        name: 'Dashboards',
        description:
          "Single pane of glass to manage what's important for your organization.",
        sublist: [
          {
            name: 'Occupancy Dashboard',
            description:
              'Understand occupancy across locations as people return back to office',
            link: '/apps/45',
          },
          {
            name: 'Retail Dashboard',
            description:
              'Manage both loss prevention/asset protection and store operations from one place',
            link: '/apps/47?locationID=641',
          },
        ],
      },
      'Video Summaries': {
        name: 'Video Summaries',
        description:
          'Squeeze days of footage into minutes using AI Fast-Forward',
        sublist: [
          {
            name: 'Osaka',
            description: 'A busy scene from an intersection in Osaka, Japan',
            link: '/investigations/1532/events/5332/summary',
          },
        ],
      },
    });

    return (
      <Tabs className={styles['top-tab']}>
        <TabPane tab="Features" key="Features">
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {Object.values(demoTabs).map((feature) => (
              <div className={styles['demo-module']}>
                <h3>{feature.name}</h3>
                <div>{feature.description}</div>

                {feature.sublist.map((el) => (
                  <div style={{ marginTop: '5px' }}>
                    &bull;{' '}
                    {el.link.startsWith('http') ? (
                      <a href={el.link} target="_blank" rel="noreferrer">
                        <b>{el.name}</b>
                      </a>
                    ) : (
                      <Link to={el.link}>
                        <b>{el.name}</b>
                      </Link>
                    )}
                    {el.description && <span> - {el.description}</span>}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </TabPane>

        <TabPane tab="Real-Time Alerts">
          <div
            style={{ position: 'relative', display: 'flex', flexWrap: 'wrap' }}>
            {demoConfig.map((spec) => (
              <div key={spec.id} style={{ width: '450px' }}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <h4>{spec.name}</h4>
                  <div>
                    <span style={{ marginLeft: '5px' }}>&bull;</span>
                    <Button
                      size="small"
                      type="link"
                      onClick={() => this.startDemo(spec)}>
                      Start
                    </Button>
                    <Button
                      size="small"
                      type="link"
                      disabled={gd(this.state, 'timerOn')[spec.id]}
                      onClick={() => this.startDemoAlertTimer(spec)}>
                      Enable
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <div>
              <Button
                style={{ position: 'absolute', right: 0, top: 0 }}
                size="small"
                onClick={() => this.setState({ demoSpec: null })}>
                Reset
              </Button>
            </div>
          </div>
          <LineDivider />
          {this.state.demoSpec && (
            <div style={{ height: '80vh' }}>
              <TimelinePlayer
                showShare={true}
                shareContext={{
                  channelIDs: [
                    {
                      channelID: this.state.demoSpec.channelID,
                      timezone: this.state.demoSpec.channelTZ,
                    },
                  ],
                }}
                startTime={this.state.demoSpec.startTime}
              />
            </div>
          )}
        </TabPane>
      </Tabs>
    );
  }

  renderRetailDashboard() {
    return (
      <Tabs className={styles['top-tab']}>
        <TabPane tab="Loss Prevention" key="lossPrevention">
          <Tabs className={styles['lower-tab']}>
            <TabPane tab="Real-Time" key="realtime">
              <div className={styles['pane-section']}>
                <div>
                  <div className={styles['pane-sec-header']}>Monitoring</div>
                  {this.state.monitoredChannels.map((channel) => (
                    <ChannelTile
                      key={channel.ChannelID}
                      channelID={channel.ID}
                      showTime={false}
                    />
                  ))}
                </div>
              </div>
              <div className={styles['pane-section']}>
                {this.getNotificationSet({
                  title: 'Alerts',
                  path: 'data.loss_prevention.realtime.alerts',
                  modalKey: 'lp-alerts',
                })}
              </div>
            </TabPane>
            <TabPane tab="Forensic" key="forensic">
              <div className={styles['pane-section']}>
                {this.getNotificationSet({
                  title: 'Reports',
                  path: 'data.loss_prevention.forensic.reports',
                  modalKey: 'lp-reports',
                })}
              </div>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Operations" key="operations">
          <Tabs className={styles['lower-tab']}>
            <TabPane tab="Real-Time" key="realtime">
              <div className={styles['pane-section']}>
                {this.getNotificationSet({
                  title: 'Alerts',
                  path: 'data.operations.realtime.alerts',
                  modalKey: 'ops-reports',
                })}
              </div>
              <div className={styles['pane-section']}>
                <div>
                  <div className={styles['pane-sec-header']}>Dashboard</div>
                  <div className={styles['info-module-ctn']}>
                    <div className={styles['info-module-header']}>
                      Floor Plan
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Image
                        preview={false}
                        src={RetailDemoImage}
                        style={{ width: '600px' }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles['stat-display']}>
                  <div className={styles['info-module-ctn']}>
                    <div className={styles['info-module-header']}>Counters</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className={styles.stat} style={{ minWidth: '85px' }}>
                        <div>
                          <span className={styles['stat-number']}>2</span>
                          <span>/3</span>
                        </div>
                        <div className={styles['stat-label']}>open</div>
                      </div>
                      <div className={styles.stat} style={{ minWidth: '85px' }}>
                        <div>
                          <span className={styles['stat-number']}>6</span>
                        </div>
                        <div className={styles['stat-label']}>in queue</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles['info-module-ctn']}>
                    <div className={styles['info-module-header']}>
                      Drive Thru
                    </div>
                    <div className={styles.stat}>
                      <div>
                        <span className={styles['stat-number']}>2</span>
                      </div>
                      <div className={styles['stat-label']}>waiting</div>
                    </div>
                  </div>
                  <div className={styles['info-module-ctn']}>
                    <div className={styles['info-module-header']}>Staff</div>
                    <div className={styles.stat}>
                      <div>
                        <span className={styles['stat-number']}>5</span>
                      </div>
                      <div className={styles['stat-label']}>crew</div>
                    </div>
                  </div>
                </div>
                <div className={styles['stat-display']}>
                  <div
                    className={styles['info-module-ctn']}
                    style={{ width: '100%' }}>
                    <div className={styles['info-module-header']}>
                      Occupancy
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}>
                      <div className={styles.stat}>
                        <div>
                          <span className={styles['stat-number']}>7</span>
                        </div>
                        <div className={styles['stat-label']}>parking lot</div>
                      </div>
                      <div className={styles.stat}>
                        <div>
                          <span className={styles['stat-number']}>6</span>
                        </div>
                        <div className={styles['stat-label']}>in queue</div>
                      </div>
                      <div className={styles.stat}>
                        <div>
                          <span className={styles['stat-number']}>25</span>
                        </div>
                        <div className={styles['stat-label']}>dining</div>
                      </div>
                      <div className={styles.stat}>
                        <div>
                          <span className={styles['stat-number']}>4</span>
                        </div>
                        <div className={styles['stat-label']}>playhouse</div>
                      </div>
                      <div className={styles.stat}>
                        <div>
                          <span className={styles['stat-number']}>8</span>
                        </div>
                        <div className={styles['stat-label']}>
                          party/meeting
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Reports" key="reports">
              <div className={styles['pane-section']}>
                <div className={styles['info-module-ctn']}>
                  <div className={styles['info-module-header']}>
                    Occupancy (% to Normal)
                  </div>
                  {this.renderLines(
                    _.get(this.state.data, 'operations.reports.occupancy', []),
                    'pct',
                  )}
                </div>
                <div className={styles['info-module-ctn']}>
                  <div className={styles['info-module-header']}>
                    Counter Wait Times
                  </div>
                  {this.renderLines(
                    _.get(
                      this.state.data,
                      'operations.reports.counter_wait_times',
                      [],
                    ),
                    'wait_time',
                  )}
                </div>
                <div className={styles['info-module-ctn']}>
                  <div className={styles['info-module-header']}>
                    Drive Thru Wait Times
                  </div>
                  {this.renderLines(
                    _.get(
                      this.state.data,
                      'operations.reports.drivethru_wait_times',
                      [],
                    ),
                    'wait_time',
                  )}
                </div>
              </div>
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
    );
  }

  getAlert(ruleName, channel, start, end) {
    return {
      name: '',
      id: Math.round(Math.random() * 10000),
      source: {
        camera: {
          id: channel,
        },
      },
      rule: {
        name: ruleName,
      },
      timeframe: {
        start,
        end,
      },
    };
  }

  getTestData() {
    return {
      name: 'Retail Dashboard',
      demos: [
        {
          id: 1,
          name: 'Slip and Fall',
          startTime: '2021-07-01 10:00:52',
          channelID: 14498,
          channelTZ: 'America/Los_Angeles',
          alertID: 6077,
          delay: 30000,
        },
      ],
      locations: [
        {
          name: 'Clifton Hill',
          locationID: 2596,
          channelIDs: [8763, 8766, 8769, 8760],
        },
        {
          name: 'The Corner',
          locationID: 124,
        },
        {
          name: 'George St',
          locationID: 125,
        },
        {
          name: 'The Strand',
          locationID: 126,
        },
        {
          name: 'Sydney Airport',
          locationID: 127,
        },
      ],
      loss_prevention: {
        forensic: {
          reports: {
            types: {
              compliance: {
                name: 'Compliance',
                list: [
                  {
                    type: 'mask_usage',
                    name: 'Mask Usage',
                    enabled: true,
                  },
                  {
                    type: 'uniform_wearing',
                    name: 'Uniforms',
                    enabled: false,
                  },
                  {
                    type: 'safe_activity',
                    name: 'Safe Access',
                    enabled: false,
                  },
                  {
                    type: 'corral_activity',
                    name: 'Corral Usage',
                    enabled: false,
                  },
                ],
              },
              safety: {
                name: 'Safety Review',
                list: [
                  {
                    type: 'overnight',
                    name: 'Overnight Report',
                    enabled: true,
                  },
                  {
                    type: 'lpr_report',
                    name: 'License Plate Report',
                    enabled: false,
                  },
                ],
              },
            },
            triggered: {
              mask_usage: {
                list: [
                  this.getAlert('Mask Usage', 8507, 1624726800, 1624726810),
                ],
              },
            },
          },
        },
        realtime: {
          monitoring: [],
          alerts: {
            types: {
              behavior: {
                name: 'Behavior',
                list: [
                  {
                    type: 'slip_and_fall',
                    name: 'Slip and Fall',
                    enabled: true,
                  },
                  {
                    type: 'violence',
                    name: 'Violent Activity',
                    enabled: false,
                  },
                  {
                    type: 'crowds',
                    name: 'Crowd Formation',
                    enabled: false,
                  },
                  {
                    type: 'loitering',
                    name: 'Loitering',
                    enabled: false,
                  },
                ],
              },
              retail: {
                name: 'Custom Retail',
                list: [
                  {
                    type: 'spills',
                    name: 'Liquid Spills',
                    enabled: true,
                  },
                ],
              },
              camera: {
                name: 'Camera Status',
                list: [
                  {
                    type: 'camera_down',
                    name: 'Camera Down',
                    enabled: false,
                  },
                  {
                    type: 'camera_degraded',
                    name: 'Camera Degraded',
                    enabled: false,
                  },
                ],
              },
              watchlist: {
                name: 'Watchlists',
                list: [
                  {
                    type: 'lpr_list',
                    name: 'Watchlist Plate Found',
                    enabled: false,
                  },
                  {
                    type: 'person_list',
                    name: 'Watchlist Person Found',
                    enabled: false,
                  },
                ],
              },
            },
            triggered: {
              spills: {
                list: [
                  this.getAlert('Liquid Spill', 8817, 1625158821, 1625158825),
                ],
              },
              slip_and_fall: {
                list: [
                  this.getAlert('Slip and Fall', 8762, 1625158878, 1625158887),
                  this.getAlert('Slip and Fall', 8767, 1625158817, 1625158820),
                ],
              },
              loitering: {
                list: [
                  this.getAlert('Loitering', 8792, 1625158818, 1625158838),
                ],
              },
              violence: {
                list: [
                  this.getAlert(
                    'Violent Activity',
                    8771,
                    1625158856,
                    1625158865,
                  ),
                  this.getAlert(
                    'Violent Activity',
                    8772,
                    1625158854,
                    1625158864,
                  ),
                  this.getAlert(
                    'Violent Activity',
                    8761,
                    1625158820,
                    1625158840,
                  ),
                ],
              },
              crowds: {
                list: [
                  this.getAlert(
                    'Crowd Formation',
                    8760,
                    1625158826,
                    1625158832,
                  ),
                ],
              },
              camera_degraded: {
                list: [
                  this.getAlert(
                    'Camera Degraded',
                    8773,
                    1625158824,
                    1625158827,
                  ),
                ],
              },
            },
          },
        },
      },
      operations: {
        realtime: {
          alerts: {
            types: {
              behavior: {
                name: 'Staffing',
                list: [
                  {
                    type: 'service_backlog',
                    name: 'Service Staffing Level',
                    enabled: false,
                  },
                  {
                    type: 'drivethru_backlog',
                    name: 'Drive Thru Staffing Level',
                    enabled: true,
                  },
                ],
              },
            },
            triggered: {
              service_backlog: {
                list: [
                  this.getAlert(
                    'Service Staffing Level',
                    8765,
                    1625158887,
                    1625158897,
                  ),
                ],
              },
            },
          },
        },
        reports: {
          counter_wait_times: [
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 18,
              week: 'May 3',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 11,
              week: 'May 10',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 15,
              week: 'May 17',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 4,
              week: 'May 24',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 9,
              week: 'May 31',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 11,
              week: 'Jun 7',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 15,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 3',
              wait_time: 7,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 10',
              wait_time: 4,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 17',
              wait_time: 3,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 24',
              wait_time: 6,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 31',
              wait_time: 8,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'Jun 7',
              wait_time: 4,
            },
            {
              location: 'George St',
              status: 'Open',
              wait_time: 6,
            },
            {
              location: 'The Strand',
              status: 'Open',
              wait_time: 4,
            },
            {
              location: 'Sydney Airport',
              status: 'Closed',
              wait_time: 8,
            },
          ],
          drivethru_wait_times: [
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 3,
              week: 'May 3',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 4,
              week: 'May 10',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 7,
              week: 'May 17',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 5,
              week: 'May 24',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 8,
              week: 'May 31',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 4,
              week: 'Jun 7',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              wait_time: 3,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 3',
              wait_time: 7,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 10',
              wait_time: 8,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 17',
              wait_time: 11,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 24',
              wait_time: 4,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 31',
              wait_time: 9,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'Jun 7',
              wait_time: 7,
            },
            {
              location: 'George St',
              status: 'Open',
              wait_time: 12,
            },
            {
              location: 'The Strand',
              status: 'Open',
              wait_time: 3,
            },
            {
              location: 'Sydney Airport',
              status: 'Closed',
              wait_time: 5,
            },
          ],
          occupancy: [
            {
              location: 'Clifton Hill',
              status: 'Open',
              occupancy: 220,
              pct: 18,
              week: 'May 3',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              occupancy: 220,
              pct: 18,
              week: 'May 10',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              occupancy: 220,
              pct: 25,
              week: 'May 17',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              occupancy: 220,
              pct: 22,
              week: 'May 24',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              occupancy: 220,
              pct: 32,
              week: 'May 31',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              occupancy: 220,
              pct: 30,
              week: 'Jun 7',
            },
            {
              location: 'Clifton Hill',
              status: 'Open',
              occupancy: 220,
              pct: 33,
            },
            {
              location: 'The Corner',
              status: 'Open',
              occupancy: 90,
              week: 'May 3',
              pct: 15,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 10',
              occupancy: 90,
              pct: 25,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 17',
              occupancy: 90,
              pct: 32,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'May 24',
              occupancy: 90,
              pct: 48,
            },
            {
              location: 'The Corner',
              status: 'Open',
              occupancy: 90,
              week: 'May 31',
              pct: 38,
            },
            {
              location: 'The Corner',
              status: 'Open',
              week: 'Jun 7',
              occupancy: 90,
              pct: 48,
            },
            {
              location: 'George St',
              status: 'Open',
              occupancy: 150,
              pct: 68,
            },
            {
              location: 'The Strand',
              status: 'Open',
              occupancy: 75,
              pct: 54,
            },
            {
              location: 'Sydney Airport',
              status: 'Closed',
              occupancy: 75,
              pct: 0,
            },
          ],
        },
      },
    };
  }

  renderLines(data, yField) {
    const config = {
      data,
      xField: 'week',
      yField,
      seriesField: 'location',
      width: 700,
      color: ['#1979C9', '#D62A0D', '#FAA219'],
    };
    return <Line {...config} />;
  }

  render() {
    return (
      <div>
        {this.state.selectedAlert && (
          <InfoSidebar
            clip={this.state.selectedAlert}
            ref={this.infoSidebarRef}
            getExtraActions={() =>
              getSidebarActions(this, this.state.selectedAlert)
            }
          />
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span className={styles['top-header']}>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => this.setLocation(null)}>
              Demos &gt; {_.get(this.state.data, 'name')}
            </span>
          </span>
          <JSONConfigLoader
            setConfig={this.setConfig}
            initialValues={{
              config: JSON.stringify(this.state.data, null, 2),
            }}
          />
        </div>
        <LineDivider margin="0 16px 0 0" />
        {this.state.selectedLocation
          ? this.renderRetailDashboard()
          : this.renderDemo()}
      </div>
    );
  }
}
export default RetailApp;
