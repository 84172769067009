import React from 'react';
import { connect, history } from 'umi';

import { Modal } from 'antd';

// @ts-expect-error
@connect(({ views, loading }) => ({ views, loading }))
class DeleteView extends React.Component {
  state = {
    showModal: false,
  };

  componentDidMount() {}

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  deleteView(e) {
    const { dispatch, view } = this.props;
    e.preventDefault();
    dispatch({
      type: 'views/deleteView',
      viewID: view.ViewID,
    }).then(() => {
      this.toggleModal();
      history.push('/views');
    });
  }

  render() {
    const { children, loading, view } = this.props;
    return (
      <>
        <Modal
          width={400}
          title="Delete view"
          visible={this.state.showModal}
          onOk={(e) => this.deleteView(e)}
          confirmLoading={loading.effects['views/deleteView']}
          onCancel={() => this.toggleModal()}>
          Are you sure you want to delete the view <b>{view?.Name}</b>?
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default DeleteView;
