/* eslint-disable prefer-promise-reject-errors */
import ChannelSelect2 from '@/components/ChannelSelect2';
import { Button, Form, Input, Modal } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'umi';

import InvestigationSelector from '@/components/InvestigationSelector';
import RangePickerDF from '@/components/RangePickerDF';
import {
  dispatchWithFeedback,
  getClipEndTime,
  getClipStartTime,
} from '@/utils/utils';

// Can not select days after today
const disabledDate = (current) => current && current > moment().endOf('day');

// @ts-expect-error
@connect(
  ({ accounts, investigations, loading }) => ({
    accounts,
    investigations,
    loading,
  }),
  null,
  null,
  { forwardRef: true },
)
class CreateEvent extends Component {
  constructor(props) {
    super(props);
    this.createEventFrom = React.createRef();
    this.state = {
      showModal: false,
      dateRange: null,
      loadingChannels: false,
      locations: {},
    };
  }

  setup() {
    this.createEventFrom.current?.setFieldsValue({
      dateRange: this.props.dateRange,
      channelID: this.props.channelID,
    });
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.dateRange !== this.props.dateRange ||
      prevProps.channelID !== this.props.channelID
    ) {
      this.setup();
    }
  }

  toggleInvestigationEventModal = () => {
    this.setState({ showModal: !this.state.showModal }, () => {
      if (this.state.showModal) {
        const { clip, startTime, endTime, channelID } = this.props;

        let stTime = startTime;
        let edTime = endTime;
        let chID = channelID;

        if (clip) {
          stTime = getClipStartTime(clip);
          edTime = getClipEndTime(clip);
          chID =
            parseInt(
              _.get(clip, 'MetadataDF.ChannelID') || _.get(clip, 'ChannelID'),
            ) || null;
        }

        const dateRange = [stTime, edTime];
        this.createEventFrom.current?.setFieldsValue({
          channelID: [chID],
          dateRange,
        });
      }
    });
  };

  handleCreateEvent(e) {
    e.preventDefault();
    this.createEventFrom.current.validateFields().then(
      (values) => {
        if (!values) {
          return;
        }
        let payload = {
          investigationID: values.investigationID,
          name: values.name,
          startTime: values.dateRange[0],
          endTime: values.dateRange[1],
          channelID: values.channelID[0],
        };

        dispatchWithFeedback(
          this.props.dispatch,
          'Adding event to investigation',
          {
            type: 'investigations/addEventToInvestigation',
            payload,
          },
        ).then(() => {
          this.toggleInvestigationEventModal();
          if (this.createEventFrom.current) {
            this.createEventFrom.current.resetFields();
          }
        });
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const { children, loading, investigations, event } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <Modal
          forceRender
          width={600}
          height={180}
          wrapClassName="df-add-investigation-modal"
          title="Add to Investigation"
          visible={showModal}
          onCancel={() => this.toggleInvestigationEventModal()}
          footer={[
            <Button
              key="cancel"
              onClick={() => this.toggleInvestigationEventModal()}>
              Cancel
            </Button>,
            <Button
              key="createEvent"
              loading={
                loading.effects['investigations/addEventToInvestigation']
              }
              onClick={(e) => this.handleCreateEvent(e)}
              type="primary">
              Done
            </Button>,
          ]}>
          <div>
            <Form
              ref={this.createEventFrom}
              layout="vertical"
              colon={false}
              requiredMark={false}
              onSubmit={(e) => this.handleCreateEvent(e)}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the name of the event',
                  },
                ]}>
                <Input placeholder="Event Name" autoFocus />
              </Form.Item>
              <Form.Item
                label="Investigation"
                name="investigationID"
                initialValue={
                  !event
                    ? investigations.all.length &&
                      investigations.all[0].InvestigationID
                    : event.InvestigationID
                }
                rules={[
                  {
                    required: true,
                    message: 'Please choose investigation',
                  },
                ]}>
                <InvestigationSelector />
              </Form.Item>
              <Form.Item
                name="channelID"
                label="Camera"
                style={{ display: 'inline-block', width: '100%' }}>
                <ChannelSelect2
                  licensesRequired={{ location_licenses: ['DIS'] }}
                />
              </Form.Item>
              <Form.Item
                name="dateRange"
                style={{ display: 'inline-block', width: '450px' }}
                initialValue={this.props.dateRange}
                rules={[
                  { required: true, message: 'Please select date source' },
                ]}>
                <RangePickerDF
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleInvestigationEventModal()}>
          {children}
        </span>
      </>
    );
  }
}
export default CreateEvent;
