import ErrorBoundary from '@/components/ErrorBoundary';
import LoadingSpinner from '@/components/LoadingSpinner';
import { relayGraphQlFetchFunction } from '@/services/graphql';
import _ from 'lodash';
import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { connect, Outlet } from 'umi';

const createEnvironment = () => {
  const network = Network.create(relayGraphQlFetchFunction);
  const store = new Store(new RecordSource());
  return new Environment({ store, network });
};

const environment = createEnvironment();

// @ts-expect-error
@connect(({ user }) => ({
  currentUser: user.currentUser,
}))
class AuthLayout extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'user/fetchCurrent',
    });
  }

  render() {
    const { currentUser } = this.props;
    if (!_.get(currentUser, 'Email', false)) {
      return <LoadingSpinner />;
    }
    return (
      <ErrorBoundary>
        <RelayEnvironmentProvider environment={environment}>
          <Outlet />
        </RelayEnvironmentProvider>
      </ErrorBoundary>
    );
  }
}

export default AuthLayout;
