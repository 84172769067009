import * as React from 'react';

const AppIcon = ({
  height = '16px',
  width = '16px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}>
    <path d="M2.66669 5.33335H5.33335V2.66669H2.66669V5.33335ZM6.66669 13.3334H9.33335V10.6667H6.66669V13.3334ZM2.66669 13.3334H5.33335V10.6667H2.66669V13.3334ZM2.66669 9.33335H5.33335V6.66669H2.66669V9.33335ZM6.66669 9.33335H9.33335V6.66669H6.66669V9.33335ZM10.6667 2.66669V5.33335H13.3334V2.66669H10.6667ZM6.66669 5.33335H9.33335V2.66669H6.66669V5.33335ZM10.6667 9.33335H13.3334V6.66669H10.6667V9.33335ZM10.6667 13.3334H13.3334V10.6667H10.6667V13.3334Z" />
  </svg>
);
export default AppIcon;
