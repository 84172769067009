import { CloudUploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  message,
  Modal,
  Progress,
  Radio,
  Row,
  Typography,
  Upload,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import type { RefObject } from 'react';
import React from 'react';
import { connect } from 'umi';

import RadioChecked from '@/assets/radio-checked-plain';
import RadioUnChecked from '@/assets/radio-unchecked-plain';
import ChannelSettings from '@/components/ChannelSettings';
import DatepickerDF from '@/components/DatepickerDF';
import { entityHasLicenseOfType } from '@/utils/licenses';
import { UPLOADING_STATUS } from '@/utils/location';
import {
  dispatchWithFeedback,
  getModalWidth,
  getUnixBasedOnTimeZone,
} from '@/utils/utils';
import styles from './style.less';
//import LoadingSpinner from '@/components/LoadingSpinner';

import type { AccountsModelState } from '@/models/accounts';
import type { LocationsModalState } from '@/models/location';
import type { ChannelNode } from '@/types/location';
import type { FormInstance } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';

type MyProps = {
  channelID: number;
  locations?: LocationsModalState;
  accounts?: AccountsModelState;
  urlFetchloading?: boolean;
  uploadMediaLoading?: boolean;
  children?: React.ReactElement;
  truckIn?: boolean;
  dispatch?: (_obj: any) => Promise<any>;
  onComplete?: () => void;
  onUpload?: () => void;
  isEmbedded?: boolean;
};

type MyState = {
  showModal: boolean;
  uploadTypeRadio: UPLOAD_VIDEO | undefined;
  startCaptureVideo: boolean;

  recordedBlobs: (Blob | File)[];
  recordingStartTime: Date[];
  progressBlobsFileUpload: number[];

  retryUpload: boolean;
};

enum UPLOAD_VIDEO {
  CAPTURE,
  ADD,
}

const getSupportedMimeTypes = () => {
  const possibleTypes = [
    'video/webm;codecs=vp9,opus',
    'video/webm;codecs=vp8,opus',
    'video/webm;codecs=h264,opus',
    'video/mp4;codecs=h264,aac',
  ];
  return possibleTypes.filter((mimeType) => {
    return MediaRecorder.isTypeSupported(mimeType);
  });
};

// @ts-expect-error
@connect(({ locations, accounts, loading }) => ({
  locations,
  accounts,
  urlFetchloading: loading.effects['channels/getChannelVideoUploadURL'],
  markingUploadComplete:
    loading.effects['channels/markChannelVideoDFfileUploadComplete'],
  uploadMediaLoading: loading.effects['channels/uploadMedia'],
}))
class UploadVideoToChannel extends React.Component<MyProps, MyState> {
  captureVideoRef: RefObject<HTMLVideoElement>;
  fileListFormRef: RefObject<FormInstance>;
  recordingTimeRef: RefObject<HTMLDivElement>;
  streamObj: MediaStream | null;
  mediaRecorder: MediaRecorder | null;
  countTrySetStreamObj: number;

  static defaultProps = {
    isEmbedded: false,
  };

  constructor(props: MyProps) {
    super(props);
    this.state = {
      showModal: false,
      uploadTypeRadio: UPLOAD_VIDEO.CAPTURE,
      startCaptureVideo: false,

      recordedBlobs: [],
      recordingStartTime: [],
      progressBlobsFileUpload: [],

      retryUpload: false,
    };
    this.captureVideoRef = React.createRef<HTMLVideoElement>();
    this.fileListFormRef = React.createRef<FormInstance>();
    this.recordingTimeRef = React.createRef<HTMLDivElement>();
    this.streamObj = null;
    this.mediaRecorder = null;
    this.countTrySetStreamObj = 0;
  }

  componentDidMount() {
    this.trySetStreamObj();
  }

  componentDidUpdate(prevProps: MyProps, prevState: MyState) {
    if (prevState.showModal !== this.state.showModal && this.state.showModal) {
      this._init_();
      this.trySetStreamObj();
    }
    if (prevState.uploadTypeRadio !== this.state.uploadTypeRadio) {
      this._init_();
      if (this.state.uploadTypeRadio === UPLOAD_VIDEO.CAPTURE) {
        this.trySetStreamObj();
      } else if (this.state.uploadTypeRadio === UPLOAD_VIDEO.ADD) {
        this.unsetStreamObj();
      }
    }
  }

  componentWillUnmount() {
    this.stopRecording();
    this.unsetStreamObj();
  }

  _init_() {
    this.setState({
      startCaptureVideo: false,

      recordedBlobs: [],
      recordingStartTime: [],
      progressBlobsFileUpload: [],
    });
  }

  trySetStreamObj() {
    this.countTrySetStreamObj = 0;
    this.setStreamObj();
  }

  setStreamObj() {
    if ('mediaDevices' in navigator && this.captureVideoRef.current) {
      if (
        this.streamObj &&
        this.streamObj instanceof MediaStream &&
        this.streamObj.active
      ) {
        this.captureVideoRef.current.srcObject = this.streamObj;
      } else {
        const constraints = {
          audio: {
            echoCancellation: true,
          },
          video: {
            facingMode: 'environment',
          },
        };
        navigator.mediaDevices.getUserMedia(constraints).then(
          (stream) => {
            this.streamObj = stream;
            if (this.captureVideoRef.current) {
              this.captureVideoRef.current.srcObject = stream;
            }
          },
          (err) => message.error(`Error getting Media Device. ${err}`, 3),
        );
      }
    } else {
      if (this.countTrySetStreamObj < 10) {
        this.countTrySetStreamObj += 1;
        setTimeout(() => {
          this.setStreamObj();
        }, 200);
      }
    }
  }

  unsetStreamObj() {
    const src_obj = this.captureVideoRef.current?.srcObject || this.streamObj;
    if (src_obj && src_obj instanceof MediaStream) {
      const src_obj_tracks = src_obj.getTracks();
      src_obj_tracks.forEach((track) => {
        track.stop();
      });
    }
  }

  getUploadUrl(
    file: File | Blob,
    file_name: string,
    channelID: number,
    projectID: number,
  ) {
    const { dispatch } = this.props;
    let file_extention = '.blob';
    if (file instanceof File) {
      const fileName = _.get(file, 'name', '');
      const fileExtensionIndex = fileName.lastIndexOf('.');
      if (fileExtensionIndex >= 0) {
        file_extention = fileName.slice(fileExtensionIndex, fileName.length);
      } else {
        file_extention = '';
      }
    }
    return dispatchWithFeedback(
      dispatch,
      'Getting channel video and df file upload URL',
      {
        type: 'channels/getChannelVideoUploadURL',
        payload: {
          channelID,
          projectID,
          req_data: {
            video_name: `${file_name}${file_extention}`,
            dfmeta_name: `${file_name}.df`,
          },
        },
      },
      true,
    );
  }

  updateProgressFn(
    upload_status: UPLOADING_STATUS,
    progress: number,
    index: number,
  ) {
    const { progressBlobsFileUpload } = this.state;

    if (index >= progressBlobsFileUpload.length) return;

    let upload_progress_val = 0;
    if (upload_status === UPLOADING_STATUS.IN_PROGRESS) {
      upload_progress_val = progress;
    } else if (upload_status === UPLOADING_STATUS.UPLOAD_DONE) {
      upload_progress_val = 100;
    } else if (upload_status === UPLOADING_STATUS.UPLOAD_ERROR) {
      upload_progress_val = -1;
    } else {
      upload_progress_val = 0;
    }

    progressBlobsFileUpload[index] = upload_progress_val;
    this.setState({ progressBlobsFileUpload: [...progressBlobsFileUpload] });
  }

  completeUploadVideoDFfile(
    file: File | Blob,
    video_url_obj: any,
    start_time_moment: moment.Moment,
  ) {
    const { channelID, locations, dispatch, truckIn, onUpload } = this.props;
    const ch: ChannelNode = _.get(locations, `ch.byId[${channelID}]`, null);
    const video_url_path_arr = video_url_obj.fields.key.split('/');
    let payload = {
      upload_id: video_url_path_arr[1],
      client_dir_name: 'WEB_UPLOAD',
      client_timezone: moment.tz.guess(),
      client_file_name: video_url_path_arr[video_url_path_arr.length - 1],
      client_file_size: file.size,
      s3_bucket: video_url_obj.url.split('.')[0].split(`//`)[1],
      s3_filepath: video_url_obj.fields.key,
      video_start_time: start_time_moment,
      truck_in: truckIn,
    };

    if (onUpload) {
      onUpload(payload);
    } else if (ch && ch.ID && ch.ProjectID && dispatch) {
      payload['video_start_time'] = `${start_time_moment.format(
        'YYYY-MM-DDTHH:mm:ss.000000Z',
      )}`;
      dispatch({
        type: 'channels/markChannelVideoDFfileUploadComplete',
        payload,
        channelID,
        locationID: ch.ProjectID,
      });
    }
  }

  uploadVideoDFmetaFile(
    file: File | Blob,
    url_data: any,
    start_time_moment: moment.Moment,
    index: number,
  ) {
    const { channelID, dispatch } = this.props;

    const promiseArr: Promise<any>[] = [];

    if ('video_file' in url_data && dispatch) {
      const video_url_obj = url_data.video_file;
      video_url_obj.file = file;
      promiseArr.push(
        dispatch({
          type: 'channels/uploadMedia',
          payload: video_url_obj,
          updateVideoProgressFn: (
            upload_status: UPLOADING_STATUS,
            progress: number,
          ) => {
            this.updateProgressFn(upload_status, progress, index);
          },
        }),
      );
    }
    if ('df_meta_file' in url_data && dispatch) {
      const dfFile = new File(
        [
          `{"VideoStartTime":"${start_time_moment.format(
            'YYYY-MM-DD HH:mm:ss',
          )}.000000","ChannelID":${channelID}}`,
        ],
        `${start_time_moment.format('YYYY-MM-DD-HH-mm-ss')}.df`,
        {
          type: 'text/plain',
        },
      );
      const dfFile_url_obj = url_data.df_meta_file;
      dfFile_url_obj.file = dfFile;
      promiseArr.push(
        dispatch({
          type: 'channels/uploadMedia',
          payload: dfFile_url_obj,
        }),
      );
    }

    if (promiseArr.length == 2 && 'video_file' in url_data) {
      Promise.all(promiseArr).then(
        (_res) => {
          let upload_successful = true;
          _res.forEach((res) => {
            if ('error' in res) {
              upload_successful = false;
            }
          });
          if (upload_successful) {
            const video_url_obj = url_data.video_file;
            this.completeUploadVideoDFfile(
              file,
              video_url_obj,
              start_time_moment,
            );
            this.setState({ retryUpload: false });
          } else {
            this.setState({ retryUpload: true });
          }
        },
        (err) => console.log('Upload Media error', err),
      );
    }
  }

  uploadVideotoS3() {
    if (this.fileListFormRef.current) {
      const { recordedBlobs } = this.state;
      const fileListFormFields = this.fileListFormRef.current.getFieldsValue();

      const { channelID, locations } = this.props;
      const ch: ChannelNode = _.get(locations, `ch.byId[${channelID}]`, null);

      if (recordedBlobs.length > 0 && ch && ch.ID && ch.ProjectID) {
        const project_id: number = ch.ProjectID;
        recordedBlobs.forEach((file: Blob | File, idx: number) => {
          const start_time_moment = _.get(
            fileListFormFields,
            `video_list-${idx}.video_start_time`,
          );
          this.getUploadUrl(
            file,
            start_time_moment.format('YYYY-MM-DD-HH-mm-ss'),
            ch.ID,
            project_id,
          ).then(
            (res: any) => {
              if (res && 'video_file' in res && 'df_meta_file' in res) {
                this.uploadVideoDFmetaFile(file, res, start_time_moment, idx);
              }
            },
            (err: any) => console.error('Fetching upload URL failed.', err),
          );
        });
      }
    }
  }

  startRecording() {
    const { startCaptureVideo } = this.state;
    const mimeTypes = getSupportedMimeTypes();
    if (
      this.streamObj &&
      this.streamObj.active &&
      startCaptureVideo &&
      mimeTypes.length > 0
    ) {
      const options = {
        mimeType: mimeTypes[0],
      };
      try {
        this.mediaRecorder = new MediaRecorder(this.streamObj, options);
      } catch (e) {
        console.error('Exception while creating MediaRecorder:', e);
        return;
      }

      const recordedBlobs: Blob[] = [];

      let recordingStartTime: Date;
      let timeCounterTextInterval: NodeJS.Timer;
      this.mediaRecorder.onstart = () => {
        recordingStartTime = new Date(Date.now());
        timeCounterTextInterval = setInterval(() => {
          const nowTime: Date = new Date(Date.now());
          const diffTime = new Date(
            nowTime.getTime() - recordingStartTime.getTime(),
          );

          const hr = diffTime.getUTCHours();
          const mm = diffTime.getUTCMinutes();
          const ss = diffTime.getUTCSeconds();

          const timeText = `${hr > 9 ? hr : `0${hr}`}:${
            mm > 9 ? mm : `0${mm}`
          }:${ss > 9 ? ss : `0${ss}`}`;
          if (this.recordingTimeRef.current) {
            this.recordingTimeRef.current.innerText = timeText;
          }
        }, 1000);
      };
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          recordedBlobs.push(event.data);
        }
      };
      this.mediaRecorder.onstop = () => {
        const recBlob = new Blob(recordedBlobs, { type: mimeTypes[0] });
        this.setState({
          recordedBlobs: [recBlob],
          recordingStartTime: [recordingStartTime],
          progressBlobsFileUpload: [0],
        });
        this.mediaRecorder = null;
        clearInterval(timeCounterTextInterval);
        this.unsetStreamObj();
      };

      this.mediaRecorder.start();
    }
  }

  stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }
  }

  renderCaptureVideo() {
    const { startCaptureVideo } = this.state;
    const { truckIn } = this.props;
    return (
      <div
        className={
          truckIn != undefined
            ? `${styles['upload-capture-video-ctn']} ${styles['upload-capture-video-scanner']}`
            : styles['upload-capture-video-ctn']
        }>
        <video
          className={styles['capture-video-element']}
          ref={this.captureVideoRef}
          muted={true}
          autoPlay={true}
        />
        {startCaptureVideo ? (
          <Button
            shape="circle"
            className={styles['capture-start-stop-video-btn']}
            icon={
              <RadioUnChecked
                style={{ transform: 'scale(1.5)', fill: 'red' }}
              />
            }
            size="large"
            onClick={() => {
              this.setState({ startCaptureVideo: false }, () => {
                this.stopRecording();
              });
            }}
          />
        ) : (
          <Button
            shape="circle"
            className={styles['capture-start-stop-video-btn']}
            icon={
              <RadioChecked
                style={{ transform: 'scale(1.5)', fill: 'green' }}
              />
            }
            size="large"
            onClick={() => {
              this.setState({ startCaptureVideo: true }, () => {
                this.startRecording();
              });
            }}
          />
        )}
        {startCaptureVideo ? (
          <div ref={this.recordingTimeRef} className={styles['recording-time']}>
            00:00:00
          </div>
        ) : null}
      </div>
    );
  }

  renderAddVideo() {
    return (
      <div className={styles['upload-add-video-ctn']}>
        <Upload.Dragger
          multiple={false}
          showUploadList={false}
          customRequest={({ file, onSuccess }: UploadRequestOption) => {
            if (file && file instanceof File) {
              const { channelID, locations } = this.props;
              let ch_Timezone = _.get(
                locations,
                `ch.byId[${channelID}].Timezone`,
                null,
              );
              if (!ch_Timezone) {
                ch_Timezone = moment.tz.guess();
              }
              const nowTime = moment().tz(ch_Timezone).toDate();
              this.setState({
                recordedBlobs: [file],
                recordingStartTime: [nowTime],
                progressBlobsFileUpload: [0],
              });
              if (onSuccess) {
                onSuccess('ok');
              }
            }
          }}>
          <div className={styles['upload-container']}>
            {/* <div style={{ height: '50px' }}>
                <LoadingSpinner fontSize="20px" />
              ) : (
                <CloudUploadOutlined style={{ fontSize: '45px' }} />
              )}
            </div> */}
            <CloudUploadOutlined style={{ fontSize: '45px' }} />
            <Typography.Text>
              Drop files here or Click to select files
            </Typography.Text>
          </div>
        </Upload.Dragger>
      </div>
    );
  }

  renderListedFile(file: File | Blob, index: number) {
    const { recordingStartTime, progressBlobsFileUpload, recordedBlobs } =
      this.state;
    const progressVal = _.get(progressBlobsFileUpload, [index], -1);
    const progressProps: Record<string, any> = { type: 'line' };
    if (progressVal >= 0) {
      progressProps.percent = progressVal;
    } else {
      progressProps.percent = 0;
      progressProps.status = 'exception';
    }

    const { channelID, locations } = this.props;
    let ch_Timezone = _.get(locations, `ch.byId[${channelID}].Timezone`, null);
    if (!ch_Timezone) {
      ch_Timezone = moment.tz.guess();
    }
    const disabling_time = moment(
      getUnixBasedOnTimeZone(moment(), ch_Timezone),
    );
    const displayTime = moment(recordingStartTime[index]).tz(ch_Timezone);

    return (
      <React.Fragment key={`video-${index}`}>
        {recordedBlobs.length > 1 && (
          <Divider orientation="left">{index}</Divider>
        )}
        <Row gutter={[0, 12]}>
          <Col xs={24} sm={24} md={11}>
            <Row>{file instanceof File ? file.name : `video-${index}`}</Row>
            {/* <Row>
              <video
                style={{ width: '100%' }}
                src={URL.createObjectURL(file)}
                controls={true}
              />
            </Row> */}
          </Col>
          <Col xs={24} sm={24} md={{ span: 11, offset: 2 }}>
            <Form.Item
              label={
                <strong>
                  Video Start Time : <span>{ch_Timezone}</span>
                </strong>
              }
              name={[`video_list-${index}`, `video_start_time`]}
              initialValue={displayTime}
              rules={[
                { required: true, message: 'Missing Video Start Time.' },
                {
                  validator: (_rule, value) => {
                    if (!value || value.unix() > disabling_time.unix()) {
                      return Promise.reject(
                        new Error('You are trying to set time in Future.'),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <DatepickerDF />
            </Form.Item>
          </Col>
          <Col style={{ width: '100%' }}>
            <Progress {...progressProps} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderUploadVideoComp() {
    const {
      uploadTypeRadio,
      recordedBlobs,
      progressBlobsFileUpload,
      retryUpload,
    } = this.state;
    const {
      urlFetchloading,
      uploadMediaLoading,
      onComplete,
      markingUploadComplete,
    } = this.props;
    const uploadingInProgress = !(
      recordedBlobs.length > 0 &&
      progressBlobsFileUpload.reduce((acc, curr) => {
        return acc && (curr === 0 || curr === -1);
      }, true)
    );
    return (
      <div className={styles['upload-video-ctn']}>
        {recordedBlobs.length === 0 ? (
          <div className={styles['capture-add-video-radio-ctn']}>
            <Radio.Group
              value={uploadTypeRadio}
              onChange={(e) => {
                const newValue = e.target.value;
                this.setState({ uploadTypeRadio: newValue });
              }}>
              <Radio value={UPLOAD_VIDEO.CAPTURE}>Capture Video</Radio>
              <Radio value={UPLOAD_VIDEO.ADD}>Add Video</Radio>
            </Radio.Group>
          </div>
        ) : null}
        {recordedBlobs.length === 0 ? (
          <div className={styles['capture-add-video-content-ctn']}>
            {uploadTypeRadio === UPLOAD_VIDEO.CAPTURE
              ? this.renderCaptureVideo()
              : null}
            {uploadTypeRadio === UPLOAD_VIDEO.ADD
              ? this.renderAddVideo()
              : null}
          </div>
        ) : (
          <div className={styles['capture-add-video-fileList-ctn']}>
            <Form
              className={styles['file-list-form']}
              style={{ marginBottom: '16px' }}
              ref={this.fileListFormRef}
              requiredMark={false}
              layout="vertical">
              {recordedBlobs.map((file: Blob | File, idx: number) => {
                return this.renderListedFile(file, idx);
              })}
            </Form>
            <div className={styles['upload-video-btn']}>
              {recordedBlobs.length > 0 &&
              progressBlobsFileUpload.reduce((acc, curr) => {
                return acc && curr === 100;
              }, true) &&
              markingUploadComplete === false ? (
                <Button
                  type="primary"
                  onClick={() => {
                    if (!this.props.isEmbedded) this.closeUploadModal();
                    onComplete?.();
                  }}>
                  Done
                </Button>
              ) : (
                <>
                  <Button
                    style={{ marginRight: '5px' }}
                    onClick={() => {
                      this.closeUploadModal();
                    }}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    loading={
                      urlFetchloading ||
                      uploadMediaLoading ||
                      markingUploadComplete ||
                      uploadingInProgress
                    }
                    disabled={uploadingInProgress}
                    onClick={() => {
                      this.uploadVideotoS3();
                    }}>
                    {retryUpload ? 'Retry Upload Video' : 'Upload Video'}
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderFeatureNeeded() {
    const { channelID, locations } = this.props;
    const ch: ChannelNode | null = _.get(
      locations,
      `ch.byId[${channelID}]`,
      null,
    );
    if (!(channelID && ch && ch.ProjectID)) {
      return <></>;
    }
    return (
      <>
        <p>
          To use Upload Video capabilities, Cloud Storage for the camera to be
          turn on.
        </p>
        <br />
        <ChannelSettings
          startTabName="Cloud Storage"
          channelID={channelID}
          afterClose={() => {
            this.forceUpdate();
          }}>
          <span
            className="df-link"
            onClick={() => {
              setTimeout(() => {
                this.closeUploadModal();
              }, 200);
            }}>
            Turn On Cloud Storage
          </span>
        </ChannelSettings>
      </>
    );
  }

  showUploadModal() {
    this.setState({ showModal: true });
  }

  closeUploadModal() {
    this.unsetStreamObj();
    this.setState({ showModal: false });
    if (this.props.isEmbedded) {
      this._init_();
      this.trySetStreamObj();
    }
  }

  render() {
    const { showModal } = this.state;
    const { channelID, accounts, children, className, isEmbedded } = this.props;
    let shouldRenderReIndexModal = false;
    if (
      channelID &&
      // @ts-expect-error
      entityHasLicenseOfType(accounts, 'STR', channelID, null)
    ) {
      shouldRenderReIndexModal = true;
    }
    const mainComp = shouldRenderReIndexModal
      ? this.renderUploadVideoComp()
      : this.renderFeatureNeeded();
    if (isEmbedded) {
      return mainComp;
    }
    return (
      <>
        <Modal
          title={'Upload Video'}
          visible={showModal}
          onCancel={() => {
            this.closeUploadModal();
          }}
          className={
            shouldRenderReIndexModal
              ? styles['channel-upload-video-modal-ctn']
              : ''
          }
          width={
            shouldRenderReIndexModal ? getModalWidth(640) : getModalWidth(480)
          }
          footer={null}
          destroyOnClose>
          {mainComp}
        </Modal>
        <span className={className} onClick={() => this.showUploadModal()}>
          {children}
        </span>
      </>
    );
  }
}
export default UploadVideoToChannel;
