import React from 'react';
import { Modal } from 'antd';

type State = any;
type Props = any;

class ReportItemModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  render() {
    const { children, modalContent } = this.props;
    return (
      <>
        <Modal
          centered
          width={900}
          title={this.props.title || 'Player'}
          footer={null}
          visible={this.state.showModal}
          onCancel={() => this.toggleModal()}>
          {modalContent}
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default ReportItemModal;
