import { ArrowDownOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import { graphql, usePaginationFragment } from 'react-relay';
import { RecentChannelsTile } from './RecentChannelsTile';
import styles from './styles.less';
import { useVMSPlusContext } from './VMSPlusContext';
import type {
  RecentChannelsTileFragment$data,
  RecentChannelsTileFragment$key,
} from './__generated__/RecentChannelsTileFragment.graphql';
import type { ScenesDataFragment$key } from './__generated__/ScenesDataFragment.graphql';

interface ScenesDataProps {
  customerID: number;
  scenesDataKey: ScenesDataFragment$key;
  scenesDataConnectionKey: string;
}

const ITEMS_PER_PAGE = 10;

const ScenesDataFragment = graphql`
  fragment ScenesDataFragment on VMSPlusScenesData
  @argumentDefinitions(
    only_favorites: { type: "Boolean", defaultValue: false }
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String" }
    app_id: { type: "Int" }
    customer_id: { type: "Int" }
  )
  @refetchable(queryName: "ScenesDataPaginationQuery") {
    scenes(
      first: $count
      after: $cursor
      filters: { onlyFavorites: $only_favorites }
      appId: $app_id
      customerId: $customer_id
    ) @connection(key: "VMSPlusConfig_scenes") {
      edges {
        node {
          id
          ...RecentChannelsTileFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const ScenesData = ({
  customerID,
  scenesDataKey,
  scenesDataConnectionKey,
}: ScenesDataProps) => {
  const {
    appId,
    isSidebarHidden,
    toggleSidebarVisibility,
    setSelectedTileRef,
    setSelectedChannels,
  } = useVMSPlusContext();
  const [showOnlyFavorite, setShowOnlyFavorite] = useState(false);
  const {
    data: fragmentData,
    refetch,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(ScenesDataFragment, scenesDataKey);
  const recents = fragmentData.scenes?.edges;

  const handleTileSelect = (
    recentTileRef: RecentChannelsTileFragment$key,
    recentTileData: RecentChannelsTileFragment$data,
  ) => {
    setSelectedTileRef(recentTileRef);
    setSelectedChannels(recentTileData.channelIDs as string[]);
  };

  const handleLoadMore = () => {
    loadNext(ITEMS_PER_PAGE, {
      UNSTABLE_extraVariables: {
        app_id: appId,
        customer_id: customerID,
        only_favorites: showOnlyFavorite,
      },
    });
  };

  const handleShowOnlyFavoriteToggled = () => {
    setShowOnlyFavorite((prev) => !prev);
    refetch({
      count: ITEMS_PER_PAGE,
      only_favorites: !showOnlyFavorite,
      app_id: appId,
      customer_id: customerID,
    });
  };

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles['live-header']}>Recent Cameras</div>
        <div className={styles['right-buttons']}>
          <Button size="small" onClick={handleShowOnlyFavoriteToggled}>
            {showOnlyFavorite ? 'Show All' : 'Show Starred'}
          </Button>
          <Button size="small" onClick={toggleSidebarVisibility}>
            {isSidebarHidden ? 'Show camera list' : 'Hide camera list'}
          </Button>
        </div>
      </div>
      <div className={styles.recents}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {recents?.length ? (
            <>
              <div>
                {recents.map((recentTile) => (
                  <RecentChannelsTile
                    key={recentTile?.node?.id}
                    recentChannelsTileKey={
                      recentTile?.node as RecentChannelsTileFragment$key
                    }
                    appId={appId}
                    onSelect={handleTileSelect}
                    recentTilesConnectionData={{
                      id: scenesDataConnectionKey,
                      key: 'VMSPlusConfig_scenes',
                    }}
                  />
                ))}
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  paddingBottom: '40px',
                }}>
                {hasNext && (
                  <Button
                    type="link"
                    icon={<ArrowDownOutlined />}
                    onClick={handleLoadMore}
                    loading={isLoadingNext}>
                    Load more
                  </Button>
                )}
              </div>
            </>
          ) : (
            <div
              className="df-center"
              style={{ height: 'initial', paddingTop: 32 }}>
              <div style={{ textAlign: 'center' }}>
                {showOnlyFavorite ? (
                  <p>Nothing starred yet</p>
                ) : (
                  <p>No recent activity.</p>
                )}
                <p>
                  Select a camera to start &nbsp;
                  <ArrowRightOutlined />
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { ScenesData };
