import PageHeader from '@/components/PageHeader2';
import { Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'umi';
import IncidentsList from './components/incident-list';
import SelfCheckoutIncidentAudit from './components/self-checkout-incident-audit';
import type {
  IncidentKey,
  SelfCheckoutLossDetectionModelState,
} from './constants';

const SelfCheckoutLossDetectionApp: React.FC = () => {
  const dispatch = useDispatch();

  const selectedIncident = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) => state.self_checkout_loss_detection.selectedIncident,
  );

  const setSelectedIncident = (incidentId: IncidentKey | null) => {
    dispatch({
      type: 'self_checkout_loss_detection/setSelectedIncident',
      payload: incidentId,
    });
  };

  const modalCloseHandler = () => {
    setSelectedIncident(null);
  };

  return (
    <div>
      <PageHeader title="Self Checkout Loss Detection Internal Review" />
      <IncidentsList onIncidentSelect={setSelectedIncident} />
      <Modal
        width={1100}
        destroyOnClose={true}
        visible={selectedIncident !== null}
        footer={null}
        onCancel={modalCloseHandler}>
        {/* this conditional rendering is for type safety,
        otherwise we will have to allow null in the audit component */}
        {selectedIncident != null ? (
          <SelfCheckoutIncidentAudit incidentId={selectedIncident} />
        ) : null}
      </Modal>
    </div>
  );
};

export default SelfCheckoutLossDetectionApp;
