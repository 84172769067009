import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { connect } from 'umi';

// @ts-expect-error
@connect(({ loading }) => ({ loading }))
class RemoveReportItem extends Component {
  state = { visible: false };

  showModal() {
    this.setState({
      visible: true,
    });
  }

  handleModal() {
    this.setState({
      visible: false,
    });
  }

  removeItem() {
    const { dispatch, reportItem } = this.props;
    dispatch({
      type: 'investigations/deleteReportItem',
      reportID: reportItem.InvestigationReportID,
      reportItemID: reportItem.ReportItemID,
    }).then(() => {
      this.handleModal();
    });
  }

  render() {
    const { children, loading } = this.props;
    return (
      <>
        <Modal
          width={400}
          title="Delete Item"
          visible={this.state.visible}
          onOk={() => this.removeItem()}
          onCancel={() => this.handleModal()}
          footer={[
            <Button key="cancel" onClick={() => this.handleModal()}>
              Cancel
            </Button>,
            <Button
              loading={loading.effects['investigations/deleteReportItem']}
              key="delete"
              type="primary"
              onClick={() => this.removeItem()}>
              Delete
            </Button>,
          ]}>
          Are you sure you want to delete.
        </Modal>
        <span onClick={() => this.showModal()}>{children}</span>
      </>
    );
  }
}
export default RemoveReportItem;
