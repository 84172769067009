import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { connect, history } from 'umi';

// @ts-expect-error
@connect(({ loading }) => ({ loading }))
class ArchiveReport extends Component {
  state = { visible: false };

  showArchiveModal() {
    this.setState({
      visible: true,
    });
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  handleArchive() {
    const { dispatch, investigationID, report } = this.props;
    dispatch({
      type: 'investigations/archiveReport',
      investigationID,
      reportID: report.InvestigationReportID,
    }).then(() => {
      this.handleCancel();
      history.push(`/investigations/${investigationID}`);
    });
  }

  render() {
    const { children, report, loading } = this.props;
    return (
      <>
        <Modal
          width={600}
          height={180}
          title="Archive Report"
          visible={this.state.visible}
          onOk={() => this.handleArchive()}
          onCancel={() => this.handleCancel()}
          footer={[
            <Button key="cancel" onClick={() => this.handleCancel()}>
              Cancel
            </Button>,
            <Button
              loading={loading.effects['investigations/archiveReport']}
              key="archive"
              type="primary"
              onClick={() => this.handleArchive()}>
              Archive
            </Button>,
          ]}>
          Are you sure you want to archive report {report.Name}? It will be
          removed from the Investigations section, however you can restore it
          later by contacting customer support.
        </Modal>
        <span onClick={() => this.showArchiveModal()}>{children}</span>
      </>
    );
  }
}
export default ArchiveReport;
