import { ReactComponent as DfLogo } from '@/assets/logo.svg';
import Icon, { ArrowLeftOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
import classNames from 'classnames';
import { Component } from 'react';
import { Link } from 'umi';
import styles from './style.less';

class ForgotPasswordForm extends Component {
  static defaultProps = {
    className: '',
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleSubmit = (values) => {
    this.props.onSubmit('', values);
  };

  renderMessage = (content) => (
    <Alert
      style={{ marginBottom: 24 }}
      message={content}
      type="error"
      showIcon
    />
  );

  renderMessageSuccess = (content) => (
    <Alert
      style={{ marginBottom: 24 }}
      message={content}
      type="success"
      showIcon
    />
  );

  render() {
    return (
      <div className={classNames(styles.container)}>
        <div className={styles['logo-container']}>
          <Icon component={DfLogo} className={styles.logo} />
        </div>
        <div className={classNames(styles.login)}>
          <div className={classNames(styles.header)}>Forgot Password?</div>
          <div className={classNames(styles['header-subtext'])}>
            Enter the email address associated with your account, and we’ll
            email you a link to reset your password.
          </div>
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            colon={false}
            requiredMark={false}
            className={styles.form}>
            {this.props.response.success
              ? this.renderMessageSuccess('Email Sent')
              : null}
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please enter your email',
                },
              ]}>
              <Input size="default" autoFocus className={styles.inputButton} />
            </Form.Item>
            <Form.Item>
              <Button
                block
                loading={this.props.loading}
                size="default"
                className={styles.submit}
                type="primary"
                htmlType="submit">
                Send Reset Link
              </Button>
            </Form.Item>
          </Form>
          <div className={classNames(styles['footer-form'])}>
            <Link to="/login" className={styles['new-user']}>
              <ArrowLeftOutlined /> Back to login
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default ForgotPasswordForm;
