import LocationsIcon from '@/assets/location-plain';
import LoadingSpinner from '@/components/LoadingSpinner';
import { SiteSelectionCascader } from '@/components/SiteSelectionCascader';
import type { Site } from '@/components/SiteSelectionCascader/types';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import Icon, {
  ArrowRightOutlined,
  CheckCircleTwoTone,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Input } from 'antd';
import _ from 'lodash';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import type {
  PreloadedQuery,
  UseQueryLoaderLoadQueryOptions,
} from 'react-relay';
import {
  useLazyLoadQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import styles from './styles.less';
import { useVMSPlusContext } from './VMSPlusContext';
import {
  AllSitesAndLabelsQuery,
  ChannelsByIdsQuery,
  SitesWithChannelsQuery,
} from './VMSPlusQueries';
import type { VMSPlusQueries_AllSitesAndLabels_Query } from './__generated__/VMSPlusQueries_AllSitesAndLabels_Query.graphql';
import type { VMSPlusQueries_ChannelsByIds_Query } from './__generated__/VMSPlusQueries_ChannelsByIds_Query.graphql';
import type {
  VMSPlusQueries_SitesWithChannels_Query,
  VMSPlusQueries_SitesWithChannels_Query$variables,
} from './__generated__/VMSPlusQueries_SitesWithChannels_Query.graphql';

const getSelectedSitesLocalStorageKey = (customerId: number) =>
  `${customerId}.vms_plus.camera_selection.filtered_sites`; // we are appending the customer id to make the localstorage key unique to avoid clashes when a user logs into multiple customer accounts on same browser

const TileObserver = ({
  obj,
}: {
  obj: {
    ID: number;
  };
}) => {
  const { ref, inView } = useInView({ threshold: 0 });

  return (
    <div ref={ref}>
      {inView && (
        <ChannelTile
          key={obj.ID}
          channelID={obj.ID}
          showTime={false}
          showImagePreview={true}
        />
      )}
    </div>
  );
};

interface ChannelsListProps {
  queryReference: PreloadedQuery<VMSPlusQueries_SitesWithChannels_Query>;
  sidebarSearch: string;
  showInactiveChannels: boolean;
  showOnlySelectedChannels: boolean;
  selectedChannels?: string[];
  addChannel: (channelID: string | string[]) => void;
  removeChannel: (channelID: string | string[]) => void;
}

const ChannelsList = ({
  queryReference,
  sidebarSearch,
  showInactiveChannels,
  showOnlySelectedChannels,
  selectedChannels,
  addChannel,
  removeChannel,
}: ChannelsListProps) => {
  const queryData = usePreloadedQuery<VMSPlusQueries_SitesWithChannels_Query>(
    SitesWithChannelsQuery,
    queryReference,
  );

  const handleSiteCheckboxToggle = (
    channelIds: string[],
    isChecked: boolean,
  ) => {
    if (isChecked) {
      addChannel(channelIds);
    } else {
      removeChannel(channelIds);
    }
  };

  return (
    <div className={styles['sidebar-list']}>
      {queryData.sites?.edges.map((site) => {
        const siteNode = site?.node;
        const siteName = siteNode?.Name;
        const siteId = siteNode?.SiteID;
        const channelEdges = siteNode?.Channels?.edges;

        const channelsToShow = channelEdges
          ?.filter((channel) => {
            const channelNode = channel?.node;

            if (
              sidebarSearch &&
              !_.includes(channelNode?.Name?.toLowerCase(), sidebarSearch)
            ) {
              return false;
            }

            if (
              !showInactiveChannels &&
              channelNode?.MonitorStatus === 'inactive'
            ) {
              return false;
            }

            if (
              showOnlySelectedChannels &&
              !selectedChannels?.includes(channelNode?.ChannelID as string)
            ) {
              return false;
            }

            return true;
          })
          .map((channel) => channel?.node);

        const allChannelsSelected = channelsToShow?.every((channel) => {
          return selectedChannels?.includes(channel?.ChannelID as string);
        });

        const showAllSitesCheckbox =
          !showOnlySelectedChannels && !sidebarSearch;

        return (
          <div className={styles['bare-tile-ctn']} key={siteId}>
            <div
              style={{
                margin: '8px 0',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}>
              {showAllSitesCheckbox && (
                <Checkbox
                  checked={allChannelsSelected}
                  onChange={(e) => {
                    const channelIds = channelsToShow?.map(
                      (channel) => channel?.ChannelID,
                    );

                    handleSiteCheckboxToggle(
                      channelIds as string[],
                      e.target.checked,
                    );
                  }}
                />
              )}
              <Icon component={LocationsIcon} style={{ fontSize: 16 }} />
              <span style={{ fontWeight: 500 }}>{siteName}</span>
            </div>
            {channelsToShow?.length === 0 && (
              <div style={{ color: '#8E8E95' }}>
                No {sidebarSearch ? 'Matching' : ''} Cameras
              </div>
            )}
            <div className={styles['live-sidebar-tiles-list']}>
              {channelsToShow?.map((channel) => {
                const isChecked = _.includes(
                  selectedChannels,
                  channel?.ChannelID,
                );
                const className = [
                  styles['live-sidebar-tile-ctn'],
                  isChecked ? styles.checked : '',
                ].join(' ');

                return (
                  <div
                    key={channel?.ChannelID}
                    className={className}
                    onClick={() => {
                      if (
                        selectedChannels?.includes(channel?.ChannelID as string)
                      ) {
                        removeChannel(channel?.ChannelID as string);
                      } else {
                        addChannel(channel?.ChannelID as string);
                      }
                    }}>
                    {isChecked && (
                      <div className={styles['live-channel-checked']}>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      </div>
                    )}
                    {channel?.ChannelID && (
                      <TileObserver
                        obj={{
                          ID: Number(channel?.ChannelID),
                        }}
                      />
                    )}

                    <div className={styles['live-sidebar-info']}>
                      {channel?.Name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface SiteSelectorProps {
  selectedSiteIds: string[];
  setSelectedSiteIds: React.Dispatch<React.SetStateAction<string[]>>;
  loadSitesWithChannelsQuery: (
    variables: VMSPlusQueries_SitesWithChannels_Query$variables,
    options?: UseQueryLoaderLoadQueryOptions,
  ) => void;
  setSidebarSearch: React.Dispatch<React.SetStateAction<string>>;
  existingSiteIds?: string[];
}

const SiteSelector = ({
  selectedSiteIds,
  setSelectedSiteIds,
  loadSitesWithChannelsQuery,
  setSidebarSearch,
  existingSiteIds,
}: SiteSelectorProps) => {
  const { appId, customerId } = useVMSPlusContext();
  const allSitesData = useLazyLoadQuery<VMSPlusQueries_AllSitesAndLabels_Query>(
    AllSitesAndLabelsQuery,
    {
      app_id: appId,
      customer_id: customerId,
    },
  );
  const handleSiteSelectionChange = (value: string[]) => {
    setSelectedSiteIds(value);
  };

  const handleSiteSelectionGoClick = () => {
    loadSitesWithChannelsQuery({
      app_id: appId,
      customer_id: customerId,
      filter_site_ids: selectedSiteIds,
    });
    localStorage.setItem(
      getSelectedSitesLocalStorageKey(customerId),
      selectedSiteIds.join(','),
    );
  };

  const handleSidebarSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setSidebarSearch(value?.toLowerCase() ?? '');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        gap: '8px',
      }}>
      <div className={styles['live-header']} style={{ marginBottom: '16px' }}>
        Select&nbsp;Cameras&nbsp;&nbsp;
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'flex-end',
        }}>
        <SiteSelectionCascader
          sites={allSitesData.sites?.edges.map((site) => site?.node) as Site[]}
          siteGroups={
            allSitesData.labels?.edges.map((label) => ({
              LabelID: label?.node?.LabelID,
              Name: label?.node?.Name,
              Color: label?.node?.Color,
              LabelType: label?.node?.LabelType,
              Sites: label?.node?.Sites?.edges.map(
                (site) => site?.node,
              ) as Site[],
            })) as []
          }
          existingSelectedSites={existingSiteIds}
          onChange={(options) => {
            const siteIds = options.map(
              (option) => option[option.length - 1],
            ) as string[];
            handleSiteSelectionChange(siteIds);
          }}
          placeholder="Select sites"
          maxTagCount={2}
        />
        <Button
          onClick={handleSiteSelectionGoClick}
          type="primary"
          icon={<ArrowRightOutlined />}
          disabled={selectedSiteIds.length === 0}
        />
      </div>
      <Input
        style={{ marginTop: 8 }}
        placeholder="Filter by name"
        suffix={<SearchOutlined />}
        onChange={handleSidebarSearchChange}
      />
    </div>
  );
};

const ChannelsSelectionContent = () => {
  const {
    appId,
    customerId,
    selectedChannels,
    setSelectedChannels,
    passedChannelIds,
    isTileViewingMode,
    selectedTileData,
  } = useVMSPlusContext();
  const areChannelsPassed = !!passedChannelIds && passedChannelIds?.length > 0;
  const [sidebarSearch, setSidebarSearch] = useState('');
  const [showOnlySelectedChannels, setShowOnlySelectedChannels] =
    useState(areChannelsPassed);
  const [showInactiveChannels, setShowInactiveChannels] = useState(false);

  const passedOrSelectedChannelsData =
    useLazyLoadQuery<VMSPlusQueries_ChannelsByIds_Query>(ChannelsByIdsQuery, {
      app_id: appId,
      customer_id: customerId,
      filter_channel_ids:
        (areChannelsPassed
          ? passedChannelIds
          : isTileViewingMode
          ? selectedChannels
          : []) ?? [],
    });
  const passedOrSelectedSites =
    passedOrSelectedChannelsData.channels?.edges.map(
      (channel) => channel?.node?.Project?.Site?.SiteID,
    ) as string[];

  const selectedSitesFromLocalStorage = useMemo(
    () => localStorage.getItem(getSelectedSitesLocalStorageKey(customerId)),
    [customerId],
  );

  const existingSelectedSites = useMemo(
    () => selectedSitesFromLocalStorage?.split(',') ?? [],
    [selectedSitesFromLocalStorage],
  );

  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([]);

  const [sitesWithChannelsQueryRef, loadSitesWithChannelsQuery] =
    useQueryLoader<VMSPlusQueries_SitesWithChannels_Query>(
      SitesWithChannelsQuery,
    );

  useEffect(() => {
    if (passedOrSelectedSites?.length > 0) {
      setSelectedSiteIds(passedOrSelectedSites);
      loadSitesWithChannelsQuery({
        app_id: appId,
        customer_id: customerId,
        filter_site_ids: passedOrSelectedSites,
      });
      localStorage.setItem(
        getSelectedSitesLocalStorageKey(customerId),
        passedOrSelectedSites.join(','),
      );
      return;
    }
  }, [JSON.stringify(passedOrSelectedSites)]);

  useEffect(() => {
    if (existingSelectedSites?.length > 0 && !passedOrSelectedSites?.length) {
      setSelectedSiteIds(existingSelectedSites);
      loadSitesWithChannelsQuery({
        app_id: appId,
        customer_id: customerId,
        filter_site_ids: existingSelectedSites,
      });
      return;
    }
  }, [JSON.stringify(existingSelectedSites)]);

  const addChannel = (channelID: string | string[]) => {
    const newChannelIDs = _.cloneDeep(selectedChannels || []);

    if (channelID instanceof Array) {
      channelID.forEach((id) => {
        newChannelIDs.push(id);
      });
    } else {
      newChannelIDs.push(channelID);
    }

    setSelectedChannels(newChannelIDs);
  };

  const removeChannel = (channelID: string | string[]) => {
    const newChannelIDs = _.cloneDeep(selectedChannels || []);

    if (channelID instanceof Array) {
      channelID.forEach((id) => {
        _.pull(newChannelIDs, id);
      });
    } else {
      _.pull(newChannelIDs, channelID);
    }

    setSelectedChannels(newChannelIDs);
  };

  const handleOnlySelectedChannelsToggled = () => {
    setShowOnlySelectedChannels((prev) => !prev);
  };

  const handleIncludeInactiveToggled = () => {
    setShowInactiveChannels((prev) => !prev);
  };

  const existingSiteIds = useMemo(() => {
    if (areChannelsPassed || isTileViewingMode) {
      return passedOrSelectedSites;
    }

    if (existingSelectedSites.length > 0) {
      return existingSelectedSites;
    }
  }, [
    areChannelsPassed,
    existingSelectedSites,
    isTileViewingMode,
    passedOrSelectedSites,
  ]);

  return (
    <>
      <div className={styles['toolbar-sidebar']}>
        <SiteSelector
          key={isTileViewingMode ? selectedTileData?.id : undefined}
          selectedSiteIds={selectedSiteIds}
          setSelectedSiteIds={setSelectedSiteIds}
          loadSitesWithChannelsQuery={loadSitesWithChannelsQuery}
          setSidebarSearch={setSidebarSearch}
          existingSiteIds={existingSiteIds}
        />
        <div className={styles['search-filters']}>
          <Button onClick={handleOnlySelectedChannelsToggled} size="small">
            {showOnlySelectedChannels ? 'Show All' : 'Show Selected'}
          </Button>
          <Button onClick={handleIncludeInactiveToggled} size="small">
            {showInactiveChannels ? 'Exclude Inactive' : 'Include Inactive'}
          </Button>
        </div>
      </div>
      {sitesWithChannelsQueryRef ? (
        <Suspense fallback={<LoadingSpinner />}>
          <ChannelsList
            queryReference={sitesWithChannelsQueryRef}
            sidebarSearch={sidebarSearch}
            selectedChannels={selectedChannels}
            showInactiveChannels={showInactiveChannels}
            showOnlySelectedChannels={showOnlySelectedChannels}
            addChannel={addChannel}
            removeChannel={removeChannel}
          />
        </Suspense>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            textAlign: 'center',
          }}>
          <p>Please select sites from the selector above to proceed</p>
        </div>
      )}
    </>
  );
};

const ChannelsSelection = () => {
  return (
    <div className={styles['channel-cols']}>
      <Suspense fallback={<LoadingSpinner />}>
        <ChannelsSelectionContent />
      </Suspense>
    </div>
  );
};

export { ChannelsSelection };
