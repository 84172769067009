// react component that offers a selector, using ant design
import { default as Icon } from '@ant-design/icons';
import { Select, Typography } from 'antd';
import React from 'react';

import { ReactComponent as SvgEquirectIntr } from '@/assets/icons/camera_type/camera_type_equirect_alt.svg';
import { ReactComponent as SvgEquirect } from '@/assets/icons/camera_type/camera_type_equirect_alt_dot.svg';
import { ReactComponent as SvgEquirectIntrVirt } from '@/assets/icons/camera_type/camera_type_equirect_alt_virtual.svg';

import { ReactComponent as SvgCylinderIntr } from '@/assets/icons/camera_type/camera_type_cylinder.svg';
import { ReactComponent as SvgCylinder } from '@/assets/icons/camera_type/camera_type_cylinder_dot.svg';
import { ReactComponent as SvgCylinderIntrVirt } from '@/assets/icons/camera_type/camera_type_cylinder_virtual.svg';

import { ReactComponent as SvgLegacy } from '@/assets/icons/camera_type/camera_type_legacy.svg';
import { ReactComponent as SvgLegacyVirt } from '@/assets/icons/camera_type/camera_type_legacy_virtual.svg';

import { ReactComponent as SvgNormalIntr } from '@/assets/icons/camera_type/camera_type_normal.svg';
import { ReactComponent as SvgNormal } from '@/assets/icons/camera_type/camera_type_normal_dot.svg';
import { ReactComponent as SvgNormalIntrVirt } from '@/assets/icons/camera_type/camera_type_normal_virtual.svg';

import { ReactComponent as SvgFisheyeIntr } from '@/assets/icons/camera_type/camera_type_fisheye.svg';
import { ReactComponent as SvgFisheye } from '@/assets/icons/camera_type/camera_type_fisheye_dot.svg';
import { ReactComponent as SvgFisheyeIntrVirt } from '@/assets/icons/camera_type/camera_type_fisheye_virtual.svg';

import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import RadarChartOutlined from '@ant-design/icons/RadarChartOutlined';

import { dispatchWithFeedback, isCustomerProfileEnabled } from '@/utils/utils';
import { connect } from 'umi';
import theme from '../../../config/theme';
import styles from './style.less';

const { Text } = Typography;

type CameraConfigState =
  | 'normal_params'
  | 'virtual_params'
  | 'virtual_manual'
  | 'virtual_invalid';

type CameraConfigStateInfo = {
  name: string;
};

const CAMERA_CONFIG_STATE_INFO: {
  [key in CameraConfigState]: CameraConfigStateInfo;
} = {
  normal_params: { name: 'standard camera' },
  virtual_params: { name: 'virtual camera' },
  virtual_manual: { name: 'configured virtual camera' },
  virtual_invalid: { name: 'unconfigured virtual camera' },
};

// these types should be syncronized with the backend
// - ideally the backend would even send us the names and data for these
type CameraTypeFE =
  | 'legacy_camera'
  | 'legacy_camera_virtual' // there is no `legacy_intrinsic_virtual`
  | 'normal_camera'
  | 'normal_camera_intrinsic'
  | 'normal_camera_intrinsic_virtual'
  | 'equirect_camera'
  | 'equirect_camera_intrinsic'
  | 'equirect_camera_intrinsic_virtual'
  | 'cylindrical_camera'
  | 'cylindrical_camera_intrinsic'
  | 'cylindrical_camera_intrinsic_virtual'
  | 'fisheye_camera'
  | 'fisheye_camera_intrinsic'
  | 'fisheye_camera_intrinsic_virtual';

type CameraTypeFeInfo = {
  name: string;
  desc: string;
  iconType: any;
};

const CAMERA_TYPE_FE_INFO: { [key in CameraTypeFE]: CameraTypeFeInfo } = {
  // no distortion correction
  legacy_camera: {
    name: 'Advanced',
    desc: 'No distortion correction.',
    iconType: (props) => <Icon {...props} component={SvgLegacy} />,
  },
  legacy_camera_virtual: {
    name: 'Advanced',
    desc: 'No distortion correction, over a virtual camera.',
    iconType: (props) => <Icon {...props} component={SvgLegacyVirt} />,
  },

  // radial distortion correction
  normal_camera: {
    name: 'Default', // DEPRECATED
    desc: 'Radial distortion correction, originating from the center.',
    iconType: (props) => <Icon {...props} component={SvgNormal} />,
  },
  normal_camera_intrinsic: {
    name: 'Default',
    desc: 'Radial distortion correction.',
    iconType: (props) => <Icon {...props} component={SvgNormalIntr} />,
  },
  normal_camera_intrinsic_virtual: {
    name: 'Default',
    desc: 'Radial distortion correction, over a virtual camera.',
    iconType: (props) => <Icon {...props} component={SvgNormalIntrVirt} />,
  },

  // fisheye distortion correction
  fisheye_camera: {
    name: 'Fisheye', // DEPRECATED
    desc: 'Fisheye distortion correction, originating from the center.',
    iconType: (props) => <Icon {...props} component={SvgFisheye} />,
  },
  fisheye_camera_intrinsic: {
    name: 'Fisheye',
    desc: 'Fisheye distortion correction.',
    iconType: (props) => <Icon {...props} component={SvgFisheyeIntr} />,
  },
  fisheye_camera_intrinsic_virtual: {
    name: 'Fisheye',
    desc: 'Fisheye distortion correction, over a virtual camera.',
    iconType: (props) => <Icon {...props} component={SvgFisheyeIntrVirt} />,
  },

  // equirectangular distortion correction / 360 deg camera
  equirect_camera: {
    name: '360°', // DEPRECATED
    desc: '360° Equirectangular distortion correction, originating from the center.',
    iconType: (props) => <Icon {...props} component={SvgEquirect} />,
  },
  equirect_camera_intrinsic: {
    name: '360°',
    desc: '360° Equirectangular distortion correction.',
    iconType: (props) => <Icon {...props} component={SvgEquirectIntr} />,
  },
  equirect_camera_intrinsic_virtual: {
    name: '360°',
    desc: '360° Equirectangular distortion correction, over a virtual camera.',
    iconType: (props) => <Icon {...props} component={SvgEquirectIntrVirt} />,
  },

  // cylindrical distortion correction / 360 deg camera
  cylindrical_camera: {
    name: 'Pano', // DEPRECATED
    desc: 'Panoramic cylindrical distortion correction, originating from the center.',
    iconType: (props) => <Icon {...props} component={SvgCylinder} />,
  },
  cylindrical_camera_intrinsic: {
    name: 'Pano',
    desc: 'Panoramic cylindrical distortion correction.',
    iconType: (props) => <Icon {...props} component={SvgCylinderIntr} />,
  },
  cylindrical_camera_intrinsic_virtual: {
    name: 'Pano',
    desc: 'Panoramic cylindrical distortion correction, over a virtual camera.',
    iconType: (props) => <Icon {...props} component={SvgCylinderIntrVirt} />,
  },
};

interface State {
  cameraType: CameraTypeFE | null;
  // backend values
  allowedCameraTypes?: CameraTypeFE[];
  currentCameraType?: CameraTypeFE | null;
  defaultCameraType?: CameraTypeFE;
  // backend state
  cameraConfigState?: CameraConfigState;
  // computed here, not from backend
  initialCameraType?: CameraTypeFE;
}

interface Props {
  locationID: any;
  locationMapID: any;
  channelID: any;
  onChange: (key: CameraTypeFE, initialCameraType: CameraTypeFE) => void;
  disabled?: boolean;
}

@connect(
  ({ loading, user }) => ({ loading, currentUser: user.currentUser }),
  null,
  null,
  { forwardRef: true },
)
export class MappingCameraTypeSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cameraType: null,
    };

    // we can't call this in componentDidMount because we need this to happen
    // before the render function is called
    this.getAllowedCameraTypes();
  }

  private getAllowedCameraTypes() {
    const { dispatch, locationID, locationMapID, channelID } = this.props;

    return dispatchWithFeedback(
      dispatch,
      'Fetching allowed camera types',
      {
        type: 'location_maps/getMapCameraTypesV2',
        locationID,
        locationMapID,
        channelID,
      },
      true,
    ).then((response) => {
      const allowedCameraTypes = response['allowed_camera_types'];
      const currentCameraType = response['current_camera_type'];
      const defaultCameraType = response['default_camera_type'];
      const cameraConfigState = response['camera_config_state'];

      if (
        allowedCameraTypes === undefined ||
        currentCameraType === undefined ||
        defaultCameraType === undefined
      ) {
        throw new Error('Missing data');
      }

      const initialCameraType = (currentCameraType ||
        defaultCameraType) as CameraTypeFE;

      this.setState(
        {
          allowedCameraTypes,
          currentCameraType,
          defaultCameraType,
          initialCameraType,
          cameraConfigState,
          cameraType: null,
        },
        () => {
          this._updateCameraType(initialCameraType);
        },
      );
    });
  }

  private get loading() {
    return (
      this.props.loading.effects['location_maps/getMapCameraTypesV2'] ||
      !this.state.cameraType ||
      !this.state.allowedCameraTypes
    );
  }

  private _updateCameraType(cameraType: CameraTypeFE) {
    this.setState({ cameraType }, () => {
      this.props.onChange(
        this.state.cameraType as CameraTypeFE,
        this.state.initialCameraType as CameraTypeFE,
      );
    });
  }

  private _getItem(key: CameraTypeFE) {
    const { defaultCameraType, currentCameraType, cameraConfigState } =
      this.state;

    let cameraTypeFeInfo: CameraTypeFeInfo = _.get(
      CAMERA_TYPE_FE_INFO,
      key,
      null,
    );

    if (!cameraTypeFeInfo) {
      cameraTypeFeInfo = {
        name: `${key}`,
        desc: `Unknown distortion correction ${key}.`,
        iconType: RadarChartOutlined,
      };
    }

    let cameraConfigStateInfo: CameraConfigStateInfo = _.get(
      CAMERA_CONFIG_STATE_INFO,
      cameraConfigState,
      null,
    );

    if (!cameraConfigStateInfo) {
      cameraConfigStateInfo = {
        name: `${cameraConfigState}`,
      };
    }

    // adjust tooltip
    let tooltip = cameraTypeFeInfo.desc;
    if (key === defaultCameraType) {
      tooltip = `${tooltip} (default)`;
    }
    tooltip = `${tooltip} [${cameraConfigStateInfo.name}]`;

    const iconColor =
      key !== currentCameraType ? theme['df-light-purple'] : theme['df-blue'];

    return {
      name: cameraTypeFeInfo.name,
      tooltip,
      IconType: cameraTypeFeInfo.iconType,
      iconColor,
    };
  }

  render() {
    const { cameraType, allowedCameraTypes } = this.state;
    const { disabled, currentUser } = this.props;

    // TODO: this should be validated on backend.
    const selectorDisabled = !isCustomerProfileEnabled(
      currentUser,
      'fe_mapping_v2_selector',
    );

    const placeholder = this.loading ? (
      <>
        <LoadingOutlined className={styles.camera_type_option_icon} />
        <Text type="secondary" className={styles.camera_type_option_text}>
          Loading...
        </Text>
      </>
    ) : undefined;

    return (
      <>
        <Select
          disabled={disabled || selectorDisabled || this.loading}
          size="small"
          value={cameraType}
          placeholder={placeholder}
          onChange={(value) => this._updateCameraType(value)}
          className={styles.camera_type_selector}
          dropdownMatchSelectWidth={false}>
          {allowedCameraTypes &&
            allowedCameraTypes.map((key) => {
              const { IconType, iconColor, name, tooltip } = this._getItem(key);
              const title =
                tooltip +
                (selectorDisabled
                  ? ' [selection is not enabled in config]'
                  : '');
              return (
                <Select.Option key={key} value={key} title={title}>
                  <Text className={styles.camera_type_option}>
                    <IconType
                      style={{
                        color: selectorDisabled
                          ? theme['df-text-gray']
                          : iconColor,
                      }}
                      className={styles.camera_type_option_icon}
                    />
                    <Text
                      type="secondary"
                      className={styles.camera_type_option_text}>
                      {name}
                    </Text>
                  </Text>
                </Select.Option>
              );
            })}
        </Select>
      </>
    );
  }
}

export default MappingCameraTypeSelector;
