import Logomark from '@/assets/logomark';
import { Outlet } from 'umi';
import styles from './LoginLayout.less';

const LoginLayout = () => {
  return (
    <div className={styles.container}>
      <div className={styles['left-pane']}>
        <div className={styles['logo-container']}>
          <Logomark height="37px" width="250px" />
        </div>
      </div>
      <div className={styles['right-pane']}></div>
      <Outlet />
    </div>
  );
};

export default LoginLayout;
