import ChannelSelect from '@/components/ChannelSelect2';
import { dispatchWithFeedback } from '@/utils/utils';
import { Checkbox, Form, Input, Modal, Select } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import { EventFieldType, EventType, EventTypeLabels } from '../constants';

type Props = {
  namespace: string;
  channelSettingId?: number;
  onClose: Function;
};

const CreateUpdateSettings: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { channelSettingId, namespace, onClose } = props;
  const channel_settings = useSelector(
    (state) => state[namespace].channel_settings,
  );
  const event_fields = useSelector((state) => state[namespace].event_fields);
  const appId = useSelector((state) => state[namespace].app_id);
  const sites = useSelector(
    (state) =>
      state.apps.all.filter((app) => app.AppID == appId)[0].scopes.sites,
  );
  const master_sites = useSelector((state) => state.sites);

  const [selectedSite, setSelectedSite] = useState();
  const [filterLocationIDs, setFilterLocationIDs] = useState([]);

  const onFinish = (formData: any) => {
    const { event_type, channel_id } = formData;
    let payload = {
      channel_id: channel_id[0],
    };
    let other_data = {};
    event_fields.all.forEach((id) => {
      const { name, type } = event_fields.byId[id];
      if (type === EventFieldType.Text) {
        other_data[name] = formData[name] || '';
      } else if (type === EventFieldType.Select) {
        other_data[name] = formData[name]?.length ? [formData[name]] : [];
      } else {
        other_data[name] = formData[name] || [];
      }
    });
    other_data['event_type'] = event_type;
    payload['field_defaults'] = other_data;

    if (channelSettingId) {
      payload.id = channelSettingId;
    }

    dispatchWithFeedback(
      dispatch,
      `${channelSettingId ? 'Updating' : 'Creating'} Input`,
      {
        type: `${namespace}/${
          channelSettingId ? 'updateChannelSetting' : 'createChannelSetting'
        }`,
        payload,
      },
      false,
    ).then(() => {
      onClose();
    });
  };

  let initialValues = {};
  if (channelSettingId) {
    const channel_setting = channel_settings.byId[channelSettingId];
    initialValues = {
      site_id: channel_setting.site.id,
      channel_id: [channel_setting.channel.id],
      event_type: channel_setting.type_default,
    };
    event_fields.all.forEach((id) => {
      const { name, type } = event_fields.byId[id];
      const field_default = channel_setting.field_defaults[name];
      if (type === EventFieldType.Text) {
        initialValues[name] = field_default;
      } else if (type === EventFieldType.Select) {
        initialValues[name] = field_default?.length ? field_default[0] : null;
      } else {
        initialValues[name] = field_default || [];
      }
    });
  }

  const title = `${!channelSettingId ? 'Create New' : 'Update'} Input`;
  return (
    <Modal title={title} visible={true} onCancel={onClose} onOk={form.submit}>
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        form={form}>
        <Form.Item
          name="site_id"
          label="Select Site"
          rules={[
            {
              required: true,
              message: 'Please select site',
            },
          ]}>
          <Select
            onChange={(val) => {
              setSelectedSite(val);
              let locIDs = master_sites.all
                .find((s) => s.SiteID === val)
                .Projects.map((p) => p.ProjectID);
              setFilterLocationIDs(locIDs);
            }}>
            {sites.map((site) => (
              <Select.Option key={site.id} value={site.id}>
                {site.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {selectedSite && filterLocationIDs.length === 0 && (
          <div className="df-warn-text" style={{ marginBottom: '10px' }}>
            No Cameras in this Site, Please choose another site.
          </div>
        )}
        <Form.Item
          name="channel_id"
          label="Select Camera"
          rules={[
            {
              required: true,
              message: 'Please select a camera',
            },
          ]}>
          <ChannelSelect
            value={initialValues.channel_id}
            disabled={selectedSite && filterLocationIDs.length === 0}
            filterLocationIDs={filterLocationIDs}
          />
        </Form.Item>
        <Form.Item
          name="event_type"
          label="Select Type"
          rules={[
            {
              required: true,
              message: 'Please select a type',
            },
          ]}>
          <Select>
            {Object.values(EventType).map((type) => (
              <Select.Option key={type} value={type}>
                {EventTypeLabels[type]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {event_fields.all.map((id) => {
          const { type, name, options = [] } = event_fields.byId[id];
          if (type === EventFieldType.Text) {
            return (
              <Form.Item label={`Enter a value for ${name}`} name={name}>
                <Input placeholder="Enter" />
              </Form.Item>
            );
          } else if (type === EventFieldType.Select) {
            return (
              <Form.Item name={name} label={`Select a value for ${name}`}>
                <Select>
                  {options.map((option) => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          } else if (type === EventFieldType.Checkbox) {
            return (
              <Form.Item name={name} label={`Select relevant boxes`}>
                <Checkbox.Group>
                  {options.map((option) => (
                    <Checkbox value={option}>{option}</Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            );
          }
        })}
      </Form>
    </Modal>
  );
};

export default CreateUpdateSettings;
