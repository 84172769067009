import { dispatchWithFeedback } from '@/utils/utils';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Popconfirm, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import NewScheduleForm from './new_schedule';

interface CRONTableColumns {
  key: number;
  ID: number;
  ChannelID: number;
  CVATProjectID: number;
  Confidence: number;
  SearchObjects: string[];
  Duration: number;
  Enabled: boolean;
}

const CRONSchedules = () => {
  const dispatch = useDispatch();
  const { cronSchedules, helpers } = useSelector((state) => state.app_kaizen);
  const { items } = cronSchedules;
  const { cvatProjects } = helpers;
  const [isCreateScheduleFormVisible, setIsCreateScheduleFormVisible] =
    useState(false);

  const openCreateScheduleForm = () => {
    setIsCreateScheduleFormVisible(true);
  };

  const closeCreateScheduleForm = () => {
    setIsCreateScheduleFormVisible(false);
  };

  const TABLE_COLUMNS: ColumnsType<CRONTableColumns> = [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
    },
    {
      title: 'Channel ID',
      dataIndex: 'ChannelID',
      key: 'channel_id',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Channel ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
          />
        </div>
      ),
      onFilter: (value, record) =>
        record.ChannelID.toString().toLowerCase().includes(value.toLowerCase()),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    },
    {
      title: 'CVAT Project',
      dataIndex: 'CVATProjectID',
      key: 'cvat_project_id',
      render: (cvatProjectID) => {
        const cvatProject = cvatProjects.find(
          (project) => project.project_id === cvatProjectID,
        );
        return cvatProject
          ? `${cvatProject.name} (${cvatProjectID})`
          : cvatProjectID;
      },
    },
    {
      title: 'Confidence',
      dataIndex: 'Confidence',
      key: 'confidence',
    },
    {
      title: 'Search Objects',
      dataIndex: 'SearchObjects',
      key: 'search_objects',
    },
    {
      title: 'Duration (Sec)',
      dataIndex: 'DurationSec',
      key: 'duration',
    },
    {
      title: 'Data Collection',
      dataIndex: 'DataCollectionEnabled',
      key: 'enabled',
      render: (enabled: boolean, record: CRONTableColumns) => (
        <Popconfirm
          title={`Are you sure you want to ${
            enabled ? 'Disable' : 'Enable'
          } Data Collection for this schedule:`}
          okText="Yes"
          onConfirm={() =>
            dispatch({
              type: 'app_kaizen/updateKaizenSchedule',
              payload: { id: record.ID, op: 'toggle_data_collection_enable' },
            })
          }>
          <Checkbox checked={enabled} />
        </Popconfirm>
      ),
    },
    {
      title: 'ML Monitoring',
      dataIndex: 'MonitoringEnabled',
      key: 'monitoring_enabled',
      render: (enabled: boolean, record: CRONTableColumns) => (
        <Popconfirm
          title={`Are you sure you want to ${
            enabled ? 'Disable' : 'Enable'
          } ML Monitoring for this schedule:`}
          okText="Yes"
          onConfirm={() =>
            dispatch({
              type: 'app_kaizen/updateKaizenSchedule',
              payload: { id: record.ID, op: 'toggle_monitoring_enable' },
            })
          }>
          <Checkbox checked={enabled} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() =>
          dispatchWithFeedback(
            dispatch,
            'Getting Schedules',
            {
              type: 'app_kaizen/fetchKaizenSchedule',
            },
            true,
          )
        }
        style={{
          marginBottom: 16,
          marginTop: 16,
          marginLeft: 8,
          marginRight: 8,
          float: 'right',
        }}>
        Refresh
      </Button>
      <Button
        type="primary"
        onClick={openCreateScheduleForm}
        style={{
          marginBottom: 16,
          marginTop: 16,
          marginRight: 8,
          float: 'right',
        }}>
        Create New CronJob +
      </Button>
      <NewScheduleForm
        visible={isCreateScheduleFormVisible}
        onCancel={closeCreateScheduleForm}
      />

      <Table
        columns={TABLE_COLUMNS}
        dataSource={items}
        rowKey={(record) => record.ID}
      />
    </div>
  );
};

export default CRONSchedules;
