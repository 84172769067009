/**
 * @generated SignedSource<<9beffb8d37700beeaeb2a08d01c12284>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type ScenesDataFragment$data = {
  readonly id: string;
  readonly scenes:
    | {
        readonly edges: ReadonlyArray<
          | {
              readonly node:
                | {
                    readonly id: string;
                    readonly ' $fragmentSpreads': FragmentRefs<'RecentChannelsTileFragment'>;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
        readonly pageInfo: {
          readonly endCursor: string | null | undefined;
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
          readonly startCursor: string | null | undefined;
        };
      }
    | null
    | undefined;
  readonly ' $fragmentType': 'ScenesDataFragment';
};
export type ScenesDataFragment$key = {
  readonly ' $data'?: ScenesDataFragment$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ScenesDataFragment'>;
};

const node: ReaderFragment = (function () {
  var v0 = ['scenes'],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'app_id',
      },
      {
        defaultValue: 10,
        kind: 'LocalArgument',
        name: 'count',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cursor',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customer_id',
      },
      {
        defaultValue: false,
        kind: 'LocalArgument',
        name: 'only_favorites',
      },
    ],
    kind: 'Fragment',
    metadata: {
      connection: [
        {
          count: 'count',
          cursor: 'cursor',
          direction: 'forward',
          path: v0 /*: any*/,
        },
      ],
      refetch: {
        connection: {
          forward: {
            count: 'count',
            cursor: 'cursor',
          },
          backward: null,
          path: v0 /*: any*/,
        },
        fragmentPathInResult: ['node'],
        operation: require('./ScenesDataPaginationQuery.graphql'),
        identifierInfo: {
          identifierField: 'id',
          identifierQueryVariableName: 'id',
        },
      },
    },
    name: 'ScenesDataFragment',
    selections: [
      {
        alias: 'scenes',
        args: [
          {
            kind: 'Variable',
            name: 'appId',
            variableName: 'app_id',
          },
          {
            kind: 'Variable',
            name: 'customerId',
            variableName: 'customer_id',
          },
          {
            fields: [
              {
                kind: 'Variable',
                name: 'onlyFavorites',
                variableName: 'only_favorites',
              },
            ],
            kind: 'ObjectValue',
            name: 'filters',
          },
        ],
        concreteType: 'VMSPlusRecentChannelsConnection',
        kind: 'LinkedField',
        name: '__VMSPlusConfig_scenes_connection',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'VMSPlusRecentChannelsEdge',
            kind: 'LinkedField',
            name: 'edges',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'VMSPlusRecentChannels',
                kind: 'LinkedField',
                name: 'node',
                plural: false,
                selections: [
                  v1 /*: any*/,
                  {
                    args: null,
                    kind: 'FragmentSpread',
                    name: 'RecentChannelsTileFragment',
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: '__typename',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'cursor',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PageInfo',
            kind: 'LinkedField',
            name: 'pageInfo',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'startCursor',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'endCursor',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'hasNextPage',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'hasPreviousPage',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      v1 /*: any*/,
    ],
    type: 'VMSPlusScenesData',
    abstractKey: null,
  };
})();

(node as any).hash = '1a4d0c6d597a5bde6ca61eca01be30ea';

export default node;
