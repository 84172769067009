import React, { RefObject } from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import moment from 'moment';
import { Button, Col, Divider, Form, FormInstance, Modal, Row } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import styles from './style.less';
import {
  dispatchWithFeedback,
  getModalWidth,
  mergeIntervals,
  TodaysStartEndMoment,
} from '@/utils/utils';
import ChannelSelect2 from '@/components/ChannelSelect2';
import RangePickerDF from '@/components/RangePickerDF';
import ChannelSettings from '@/components/ChannelSettings';
import LocationSettings from '@/pages/locations/location/location-settings';
import { entityHasLicenseOfType } from '@/utils/licenses';

import type { LocationsModalState } from '@/models/location';
import type { AccountsModelState } from '@/models/accounts';
import type {
  FormListFieldData,
  FormListOperation,
} from 'antd/lib/form/FormList';
import type { ChannelNode } from '@/types/location';

type ReindexVideoObj = {
  channel_id: number;
  start_end_time: { start_time: moment.Moment; end_time: moment.Moment }[];
};

type ReindexvideoForm = {
  reindex_list: {
    channel_id: number[];
    start_end_time_list: {
      start_end_time: moment.Moment[];
    }[];
  }[];
};

type MyProps = {
  channelID?: number;
  reindexTimeInterval?: moment.Moment[];
  locations?: LocationsModalState;
  accounts?: AccountsModelState;
  sendingRequestToReindex?: boolean;
  children?: React.ReactElement;
  dispatch?: (_obj: any) => Promise<any>;
};

type MyState = {
  showModal: boolean;
  reindexVideolist: ReindexVideoObj[];
};

// @ts-expect-error
@connect(({ accounts, loading }) => ({
  accounts,
  sendingRequestToReindex: loading.effects['locations/reindexVideo'],
}))
class ReindexVideo extends React.Component<MyProps, MyState> {
  reindexVideoListFormRef: RefObject<FormInstance>;

  constructor(props: MyProps) {
    super(props);
    this.state = {
      showModal: false,
      reindexVideolist: [],
    };
    this.reindexVideoListFormRef = React.createRef<FormInstance>();
  }

  componentDidMount() {
    this._init_();
  }

  componentDidUpdate(prevProps: MyProps, prevState: MyState) {
    if (
      prevProps.channelID !== this.props.channelID ||
      (prevState.showModal !== this.state.showModal && this.state.showModal)
    ) {
      this._init_();
    }
  }

  _init_() {
    const { channelID, reindexTimeInterval } = this.props;
    if (channelID) {
      let obj_StartEnd_moment = TodaysStartEndMoment();
      if (
        reindexTimeInterval &&
        _.get(reindexTimeInterval, 'length', 0) === 2
      ) {
        obj_StartEnd_moment = {
          start_time: moment(reindexTimeInterval[0]),
          end_time: moment(reindexTimeInterval[1]),
        };
      }
      const reindex_video_obj: ReindexVideoObj = {
        channel_id: channelID,
        start_end_time: [
          {
            start_time: obj_StartEnd_moment.start_time,
            end_time: obj_StartEnd_moment.end_time,
          },
        ],
      };
      this.setState({ reindexVideolist: [reindex_video_obj] });
    } else {
      this.setState({ reindexVideolist: [] });
    }
  }

  sendReindexVideoRequest() {
    const { dispatch } = this.props;
    if (this.reindexVideoListFormRef.current && dispatch) {
      this.reindexVideoListFormRef.current
        .validateFields()
        .then((form_fields: ReindexvideoForm) => {
          const payload: {
            channel_id: number;
            time_intervals: { start_time: string; end_time: string }[];
          }[] = [];
          form_fields.reindex_list.forEach((item) => {
            let time_intervals = item.start_end_time_list.map(
              (t) => t.start_end_time,
            );
            time_intervals = mergeIntervals(time_intervals);
            if (time_intervals.length > 0) {
              payload.push({
                channel_id: item.channel_id[0],
                time_intervals: time_intervals.map((i_val) => {
                  return {
                    start_time: i_val[0].format(`YYYY-MM-DDTHH:mm:ss.000000`),
                    end_time: i_val[1].format(`YYYY-MM-DDTHH:mm:ss.000000`),
                  };
                }),
              });
            }
          });
          console.log(payload);
          dispatchWithFeedback(dispatch, 'Sending request for Reindexing.', {
            type: 'locations/reindexVideo',
            payload,
          }).then((data: any) => {
            console.log('Reindex done.', data);
            this.closeModal();
          });
        });
    }
  }

  renderStartEndTimeFormList(
    fields: FormListFieldData[],
    { add, remove }: FormListOperation,
  ): React.ReactElement {
    const { reindexTimeInterval } = this.props;
    let showAddTimeIntervalBtn = true;
    if (reindexTimeInterval && _.get(reindexTimeInterval, 'length', 0) === 2) {
      showAddTimeIntervalBtn = false;
    }
    return (
      <>
        {fields.map((field: FormListFieldData, idx: number) => {
          const today_StartEnd_moment = TodaysStartEndMoment();
          return (
            <Row key={field.key} align="middle">
              <Col xs={22} sm={22} md={23}>
                <Form.Item
                  name={[field.name, 'start_end_time']}
                  fieldKey={[field.fieldKey, 'start_end_time']}
                  rules={[
                    { required: true, message: 'Missing Start/End Time' },
                  ]}
                  style={{ flex: '1' }}
                  initialValue={[
                    today_StartEnd_moment.start_time,
                    today_StartEnd_moment.end_time,
                  ]}>
                  <RangePickerDF disabled={!showAddTimeIntervalBtn} />
                </Form.Item>
              </Col>
              <Col
                xs={2}
                sm={2}
                md={1}
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {idx !== 0 ? (
                  <MinusCircleOutlined
                    style={{ marginLeft: '10px' }}
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Col>
            </Row>
          );
        })}
        {showAddTimeIntervalBtn ? (
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}>
              Add Time Interval
            </Button>
          </Form.Item>
        ) : null}
      </>
    );
  }

  renderReindexFormList(
    fields: FormListFieldData[],
    { add, remove }: FormListOperation,
  ): React.ReactElement {
    const { channelID } = this.props;
    const { reindexVideolist } = this.state;
    return (
      <>
        {fields.map((field: FormListFieldData, idx: number) => {
          const today_StartEnd_moment = TodaysStartEndMoment();
          const initialValue: {
            channel_id: number | undefined;
            start_end_time_list: { start_end_time: moment.Moment[] }[];
          } = {
            channel_id: undefined,
            start_end_time_list: [
              {
                start_end_time: [
                  today_StartEnd_moment.start_time,
                  today_StartEnd_moment.end_time,
                ],
              },
            ],
          };
          const reindexVideolist_idx = reindexVideolist[idx];
          if (reindexVideolist_idx) {
            initialValue.channel_id = reindexVideolist_idx.channel_id;
            initialValue.start_end_time_list = [
              ...reindexVideolist_idx.start_end_time.map((v) => {
                return {
                  start_end_time: [v.start_time, v.end_time],
                };
              }),
            ];
          }
          return (
            <React.Fragment key={field.key}>
              <Row justify="center">
                <Col xs={22} sm={22} md={23}>
                  <Form.Item
                    name={[field.name, 'channel_id']}
                    fieldKey={[field.fieldKey, 'channel_id']}
                    rules={[{ required: true, message: 'Missing Camera' }]}>
                    <ChannelSelect2 disabled={!!channelID} />
                  </Form.Item>
                  <Form.List
                    name={[field.name, 'start_end_time_list']}
                    initialValue={initialValue.start_end_time_list}>
                    {(start_end_time_fields, start_end_time_operation) =>
                      this.renderStartEndTimeFormList(
                        start_end_time_fields,
                        start_end_time_operation,
                      )
                    }
                  </Form.List>
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={1}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {idx !== 0 ? (
                    <MinusCircleOutlined
                      style={{ marginTop: '5px' }}
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Col>
              </Row>
              <Divider />
            </React.Fragment>
          );
        })}
        {channelID ? null : (
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}>
              Add Channel
            </Button>
          </Form.Item>
        )}
      </>
    );
  }

  renderReindexVideoComp() {
    const { reindexVideolist } = this.state;
    const initialValue = reindexVideolist.map(
      (val: ReindexVideoObj, _idx: number) => {
        return {
          channel_id: [val.channel_id],
          start_end_time_list: [
            ...val.start_end_time.map((v) => ({
              start_end_time: [v.start_time, v.end_time],
            })),
          ],
        };
      },
    );
    return (
      <div className={styles['reindex-video-ctn']}>
        <div
          className={`${styles['reindex-list-form-ctn']} scroll-bar-slim-style`}>
          <Form
            className={styles['reindex-list-form']}
            ref={this.reindexVideoListFormRef}
            requiredMark={false}
            layout="vertical">
            <Form.List name="reindex_list" initialValue={initialValue}>
              {(fields, operation) =>
                this.renderReindexFormList(fields, operation)
              }
            </Form.List>
          </Form>
        </div>
        <div className={styles['reindex-video-btn']}>
          {true ? (
            <>
              <Button
                style={{ marginRight: '5px' }}
                onClick={() => {
                  this.closeModal();
                }}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={this.props.sendingRequestToReindex}
                onClick={() => {
                  this.sendReindexVideoRequest();
                }}>
                Reindex Video
              </Button>
            </>
          ) : null}
        </div>
      </div>
    );
  }

  renderFeatureNeeded() {
    const { channelID, locations, accounts } = this.props;
    const ch: ChannelNode | null = _.get(
      locations,
      `ch.byId[${channelID}]`,
      null,
    );
    if (!(channelID && ch && ch.ProjectID)) {
      return <></>;
    }
    return (
      <>
        <p>
          To use Re-Indexing capabilities, Cloud Storage for the camera should
          be turned on and Investigations Suite for the location should be
          enabled.
        </p>
        {
          // @ts-expect-error
          entityHasLicenseOfType(accounts, 'STR', channelID, null) ? null : (
            <>
              <br />
              <ChannelSettings
                startTabName="Cloud Storage"
                channelID={channelID}
                afterClose={() => {
                  this.forceUpdate();
                }}>
                <span
                  className="df-link"
                  onClick={() => {
                    setTimeout(() => {
                      this.closeModal();
                    }, 200);
                  }}>
                  Turn On Cloud Storage
                </span>
              </ChannelSettings>
              <br />
            </>
          )
        }
        {
          // @ts-expect-error
          entityHasLicenseOfType(accounts, 'DIS', null, ch.ProjectID) ? null : (
            <>
              <br />
              <LocationSettings
                // @ts-expect-error
                startTabName="Licenses"
                locationID={ch.ProjectID}
                afterClose={() => {
                  this.forceUpdate();
                }}>
                <span
                  className="df-link"
                  onClick={() => {
                    setTimeout(() => {
                      this.closeModal();
                    }, 200);
                  }}>
                  Turn On Investigations
                </span>
              </LocationSettings>
            </>
          )
        }
      </>
    );
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { showModal } = this.state;
    const { children } = this.props;
    return (
      <>
        <Modal
          className={styles['channel-reindex-video-modal-ctn']}
          title={'Reindex Video'}
          visible={showModal}
          destroyOnClose
          onCancel={() => {
            this.closeModal();
          }}
          width={getModalWidth(640)}
          footer={null}>
          {this.renderReindexVideoComp()}
        </Modal>
        <span
          onClick={() => {
            this.showModal();
          }}>
          {children}
        </span>
      </>
    );
  }
}
export default ReindexVideo;
