import LoadingSpinner from '@/components/LoadingSpinner';
import { Layout, Tabs } from 'antd';
import { Suspense } from 'react';
import { ChannelsSelection } from './ChannelsSelection';
import { Scenes } from './Scenes';
import styles from './styles.less';
import { useVMSPlusContext, VMSPlusProvider } from './VMSPlusContext';

const { Content, Sider } = Layout;

interface VMSPlusProps {
  appID: number;
  data: any;
}

const VMSPlus = () => {
  const { isSidebarHidden } = useVMSPlusContext();
  const liveTabContent = (
    <div className={styles['live-body']}>
      <Layout className={styles['live-content']}>
        <Content>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              marginRight: 16,
            }}>
            <Scenes />
          </div>
        </Content>
        <Sider
          trigger={null}
          breakpoint="md"
          collapsedWidth="0"
          collapsible={true}
          collapsed={isSidebarHidden}
          width={282}
          style={{ background: 'white' }}>
          <ChannelsSelection />
        </Sider>
      </Layout>
    </div>
  );

  return (
    <div className={styles['vms-ctn']}>
      <Tabs
        tabBarStyle={{ marginRight: 16 }}
        className="df-full-height-tabs"
        style={{ height: '100%' }}>
        <Tabs.TabPane tab="Scenes" key="live">
          {liveTabContent}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

const VMSPlusWithSuspense = (props: VMSPlusProps) => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <VMSPlusProvider appId={props.appID}>
        <VMSPlus />
      </VMSPlusProvider>
    </Suspense>
  );
};

VMSPlusWithSuspense.CAPABILITIES = ['scenes'];
VMSPlusWithSuspense.CAPABILITIES_LABEL_MAP = { scenes: 'Scenes' };

export default VMSPlusWithSuspense;
