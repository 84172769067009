import DFErrorCard from '@/components/Cards/DFErrorCard';
import DFInfoCard from '@/components/Cards/DFInfoCard';
import CustomerSuccess from '@/components/CustomerSuccess';
import DateRangeText from '@/components/DateRangeText';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import SearchForm from '@/components/SearchForm2';
import SearchResults from '@/components/SearchResults';
import TimelinePlayer from '@/components/TimelinePlayer';
import LPRReportApp from '@/pages/apps/app/LPRReportAppLegacy';
import { filterModulesInfo, investigationFilters } from '@/utils/filterModules';
import { entityHasLicenseOfType } from '@/utils/licenses';
import { displayTZ, resetSearchForm2 } from '@/utils/utils';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect, Link } from 'umi';
import AddEvent from '../../components/add-event';
import AddEventToReport from '../../components/add-event-to-report';
import DeleteInvestigationEvent from '../../components/delete-event';
import RedactEvent from '../../components/redact-event';
import UpdateInvestigationEvent from '../../components/update-event';

import ButtonContactUs from '@/components/ButtonContactUs';
import DFWarningCard from '@/components/Cards/DFWarningCard';
import ReindexVideo from '@/components/ReindexVideo';
import {
  eventProcessingGetError,
  eventProcessingIsDone,
} from '@/utils/investigation';
import withRouter from '@/utils/withRouter';
import styles from './style.less';

// @ts-expect-error
@connect(
  ({ accounts, investigations, investigation_events, search2, user }) => ({
    accounts,
    investigations,
    investigation_events,
    search2,
    user,
  }),
)
class InvestigationEvent extends Component {
  constructor(props) {
    super(props);
    this.searchFormRef = React.createRef();
    this.eventHeaderRef = React.createRef();
    this.state = {
      currentMedia: null,
      toggleLine: false,
      toggleRegion: false,
      togglePath: false,
    };
  }

  componentDidMount() {
    resetSearchForm2(this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    const prevEventID = +prevProps.match.params.eventID;
    let { eventID } = this.props.match.params;
    eventID = +eventID;
    if (prevEventID !== eventID) {
      resetSearchForm2(this.props.dispatch);
    }
  }

  getEndMoment(media) {
    media = media || this.state.currentMedia;
    if (!media) {
      return null;
    }
    return moment(
      media.VideoEndTime ||
        moment(media.VideoStartTime).add(
          _.get(media, 'VideoDurationMsec'),
          'ms',
        ),
    );
  }

  getEvent() {
    let { investigationID, eventID } = this.props.match.params;
    investigationID = +investigationID;
    eventID = +eventID;
    const { investigation_events, investigations } = this.props;
    return {
      investigation: investigations.byID[investigationID],
      investigation_event: investigation_events.byID[eventID],
    };
  }

  showLPRReportButton() {
    const { investigation_event } = this.getEvent();
    return entityHasLicenseOfType(
      this.props.accounts,
      'LPR',
      +investigation_event.ChannelID,
    );
  }

  saveSearchFiltersForSummary() {
    const { search2 } = this.props;
    const filter: Record<string, any> = {
      person_colors: _.get(search2, 'person_colors'),
      vehicle_colors: _.get(search2, 'vehicle_colors'),
      lineFilters: _.get(search2, 'lineFilters'),
      regionFilters: _.get(search2, 'regionFilters'),
      pathFilters: _.get(search2, 'pathFilters'),
    };
    Object.entries(filterModulesInfo).forEach(([_mKey, info]) => {
      filter[info.key] = _.get(search2, info.key);
    });

    if ('localStorage' in window) {
      window.localStorage.setItem('summary_filter', JSON.stringify(filter));
    }
  }

  getSummaryButton() {
    const { investigation_event } = this.getEvent();
    let { investigationID, eventID } = this.props.match.params;
    investigationID = +investigationID;
    eventID = +eventID;
    switch (investigation_event.SummaryIndexStatus) {
      default:
        return eventProcessingIsDone(investigation_event) ? (
          <Button
            type="default"
            size="small"
            onClick={() => {
              this.saveSearchFiltersForSummary();
              this.props.history.push(
                `/investigations/${investigationID}/events/${eventID}/summary`,
              );
            }}>
            Summarize
          </Button>
        ) : (
          <Tooltip
            arrowPointAtCenter
            placement="rightTop"
            title="This event is still being processed, and a summary can't be created yet. Please check again later">
            <Button type="default" size="small" disabled>
              Summarize
            </Button>
          </Tooltip>
        );
    }
  }

  onEyeLineToggle() {
    const { toggleLine } = this.state;
    this.setState({
      toggleLine: !toggleLine,
    });
  }

  onEyeRegionToggle() {
    const { toggleRegion } = this.state;
    this.setState({
      toggleRegion: !toggleRegion,
    });
  }

  onEyePathToggle() {
    const { togglePath } = this.state;
    this.setState({
      togglePath: !togglePath,
    });
  }

  render() {
    let { eventID } = this.props.match.params;
    eventID = +eventID;
    const { investigation, investigation_event } = this.getEvent();
    const { demoConfig } = this.state;
    const timezone = _.get(investigation_event, 'Channel.Timezone');

    if (!investigation_event) {
      return <LoadingSpinner />;
    }

    // TODO: should this be shown on the event card?
    if (!investigation_event.Media.length) {
      return (
        <div className={styles.container}>
          <DFWarningCard
            title="No Event Video"
            message="No video found for this event."
          />
          ;
        </div>
      );
    }

    // TODO: is a try/catch needed here, if eventProcessing is not done?
    const startMoment = moment.utc(investigation_event.EventStart);
    const endMoment = moment.utc(investigation_event.EventEnd);

    if (!eventProcessingIsDone(investigation_event)) {
      const eventError = eventProcessingGetError(investigation_event);

      return (
        <div className={styles.container}>
          {!eventError ? (
            <DFInfoCard
              title="Processing Video"
              message="Your video is being processed. Please give us a moment.">
              <LoadingSpinner />
            </DFInfoCard>
          ) : (
            <DFErrorCard
              title="Processing Error"
              message={eventError.description}>
              <Space style={{ marginTop: '8px' }}>
                <ButtonContactUs size="small" />
                {eventError.tryReindexing && (
                  <ReindexVideo
                    channelID={+investigation_event.ChannelID}
                    reindexTimeInterval={[startMoment, endMoment]}>
                    <Button size="small" type="primary">
                      Reindex
                    </Button>
                  </ReindexVideo>
                )}
              </Space>
            </DFErrorCard>
          )}
        </div>
      );
    }

    if (this.state.showLPRResults) {
      return (
        <>
          <PageHeader
            title="LPR Report"
            left={
              <>
                <Link onClick={() => this.setState({ showLPRResults: false })}>
                  <ArrowLeftOutlined />
                </Link>
                &nbsp;&nbsp;
              </>
            }
            right={
              <>
                <Button onClick={() => window.print()} size="small">
                  Print
                </Button>
              </>
            }
          />
          <LPRReportApp
            eventID={eventID}
            startTime={startMoment}
            endTime={endMoment}
          />
        </>
      );
    }

    return (
      <>
        {investigation_event.InvestigationEventID ===
        _.get(demoConfig, 'investigation_event_id') ? (
          <CustomerSuccess
            page="demo_investigation_event"
            demoConfig={demoConfig}
          />
        ) : (
          <CustomerSuccess page="investigation_event" />
        )}
        <div ref={this.eventHeaderRef} id="loc-ch_grp-ch-title">
          <PageHeader
            title={investigation_event.Name}
            subtitle={
              <span>
                <DateRangeText
                  start={investigation_event.EventStart}
                  end={investigation_event.EventEnd}
                  long
                />
                <span style={{ marginLeft: '5px' }}>{displayTZ(timezone)}</span>
              </span>
            }
            right={
              <div>
                {this.getSummaryButton()}
                &nbsp;
                <ReindexVideo
                  channelID={+investigation_event.ChannelID}
                  reindexTimeInterval={[startMoment, endMoment]}>
                  <Button size="small" type="default">
                    Reindex
                  </Button>
                </ReindexVideo>
                &nbsp;
                <RedactEvent
                  eventID={eventID}
                  startTime={startMoment}
                  endTime={endMoment}>
                  <Button size="small">Redact</Button>
                </RedactEvent>
                &nbsp;
                {this.showLPRReportButton() && (
                  <>
                    <Button
                      size="small"
                      onClick={() => this.setState({ showLPRResults: true })}>
                      LPR Report
                    </Button>
                    &nbsp;
                  </>
                )}
                <UpdateInvestigationEvent
                  investigation={investigation}
                  event={investigation_event}>
                  <Button size="small">Rename</Button>
                </UpdateInvestigationEvent>
                &nbsp;
                <AddEvent
                  investigation={investigation}
                  event={investigation_event}>
                  <Button size="small">Duplicate</Button>
                </AddEvent>
                &nbsp;
                <AddEventToReport
                  investigation={investigation}
                  event={investigation_event}>
                  <Button size="small">Add To Report</Button>
                </AddEventToReport>
                &nbsp;
                <DeleteInvestigationEvent
                  investigation={investigation}
                  event={investigation_event}>
                  <Button size="small" className={styles['delete-btn']}>
                    Delete
                  </Button>
                </DeleteInvestigationEvent>
              </div>
            }
          />
          <div className={styles['top-ctn']}>
            <div className={styles['main-ctn']}>
              {this.props.search2.showSearchResults === true ? (
                <SearchResults
                  searchForm={this.searchFormRef}
                  event={investigation_event}
                  toggleLine={this.state.toggleLine}
                  toggleRegion={this.state.toggleRegion}
                  togglePath={this.state.togglePath}
                />
              ) : (
                <TimelinePlayer
                  showShare={true}
                  eventsOnly={true}
                  events={investigation_event ? [investigation_event] : []}
                  searchForm={this.searchFormRef}
                />
              )}
            </div>
            <SearchForm
              ref={this.searchFormRef}
              showFilters={investigationFilters}
              channelID={_.get(investigation_event, 'ChannelID', null)}
              eventID={eventID}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(InvestigationEvent);
