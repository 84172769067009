/**
 * @generated SignedSource<<fef3765f94ce06f7bfd8cb9426ccc734>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type VMSPlusMutations_recentChannelsTileRemove_Mutation$variables = {
  app_id: number;
  connections: ReadonlyArray<string>;
  customer_id: number;
  id: string;
};
export type VMSPlusMutations_recentChannelsTileRemove_Mutation$data = {
  readonly deleteVmsPlusRecentChannels:
    | {
        readonly deleted:
          | {
              readonly id: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};
export type VMSPlusMutations_recentChannelsTileRemove_Mutation = {
  response: VMSPlusMutations_recentChannelsTileRemove_Mutation$data;
  variables: VMSPlusMutations_recentChannelsTileRemove_Mutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'app_id',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'connections',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'customer_id',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v4 = [
      {
        fields: [
          {
            kind: 'Variable',
            name: 'appId',
            variableName: 'app_id',
          },
          {
            kind: 'Variable',
            name: 'customerId',
            variableName: 'customer_id',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        kind: 'ObjectValue',
        name: 'input',
      },
    ],
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'VMSPlusMutations_recentChannelsTileRemove_Mutation',
      selections: [
        {
          alias: null,
          args: v4 /*: any*/,
          concreteType: 'DeleteVMSPlusRecentChannelsPayload',
          kind: 'LinkedField',
          name: 'deleteVmsPlusRecentChannels',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'VMSPlusRecentChannels',
              kind: 'LinkedField',
              name: 'deleted',
              plural: false,
              selections: [v5 /*: any*/],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v1 /*: any*/,
      ],
      kind: 'Operation',
      name: 'VMSPlusMutations_recentChannelsTileRemove_Mutation',
      selections: [
        {
          alias: null,
          args: v4 /*: any*/,
          concreteType: 'DeleteVMSPlusRecentChannelsPayload',
          kind: 'LinkedField',
          name: 'deleteVmsPlusRecentChannels',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'VMSPlusRecentChannels',
              kind: 'LinkedField',
              name: 'deleted',
              plural: false,
              selections: [
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  filters: null,
                  handle: 'deleteEdge',
                  key: '',
                  kind: 'ScalarHandle',
                  name: 'id',
                  handleArgs: [
                    {
                      kind: 'Variable',
                      name: 'connections',
                      variableName: 'connections',
                    },
                  ],
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '6168107c263da909312b7e2e82c22e6e',
      id: null,
      metadata: {},
      name: 'VMSPlusMutations_recentChannelsTileRemove_Mutation',
      operationKind: 'mutation',
      text: 'mutation VMSPlusMutations_recentChannelsTileRemove_Mutation(\n  $app_id: Int!\n  $customer_id: Int!\n  $id: String!\n) {\n  deleteVmsPlusRecentChannels(input: {appId: $app_id, customerId: $customer_id, id: $id}) {\n    deleted {\n      id\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '3d0c5ef33a3764b2be6f6af395356653';

export default node;
