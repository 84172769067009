import UploadVideoToChannel from '@/components/UploadVideoToChannel';
import { dispatchWithFeedback, getCurrentCustomerID } from '@/utils/utils';
import { Form, Modal, Select } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import { EventType, EventTypeLabels } from '../constants';
import CreateUpdateEvent from './create-update';
import styles from './style.less';

type Props = {
  namespace: string;
  onClose?: () => void;
  showImageInCreateUpdate?: boolean;
  onAfterPageChange?: (newPageNo: number) => void;
};

const customerID = getCurrentCustomerID();

export default function CaptureVideo(props: Props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    namespace,
    onClose = () => {},
    onAfterPageChange = () => {},
    showImageInCreateUpdate = false,
  } = props;
  const appId = useSelector((state) => state[namespace].app_id);
  const app = useSelector((state) => state.apps.byID[appId]);
  const sites = useSelector(
    (state) =>
      state.apps.all.filter((_app) => _app.AppID == appId)[0].scopes.sites,
  );
  const sitesWithUploadSupported = _.get(app, ['Data', 'site_channel_map'], []);
  const filteredSites = _.filter(sites, (masterSite) =>
    _.some(sitesWithUploadSupported, (site) => masterSite.id === site.site_id),
  );
  const storedSiteId = localStorage.getItem(`${customerID}-selectedSiteId`)
    ? parseInt(localStorage.getItem(`${customerID}-selectedSiteId`), 10)
    : null;
  const [siteId, setSiteId] = useState(storedSiteId);
  const eventType = Form.useWatch('type', form);
  const [eventId, setEventId] = useState();
  //This is a 2 step process and this indicates what page we're currently in
  const [currentPage, setCurrentPage] = useState(1);
  const getDefaultChannelForUpload = () => {
    return sitesWithUploadSupported.find(({ site_id }) => {
      return site_id == siteId;
    })?.channel_id;
  };

  const captureEvent = (payload: any) => {
    dispatchWithFeedback(
      dispatch,
      'Capturing Event',
      {
        type: `${namespace}/captureEvent`,
        payload: {
          ...payload,
          event_type: eventType,
          video_start_time: Math.floor(
            payload['video_start_time'].valueOf() / 1000,
          ),
          site_id: siteId,
        },
      },
      true,
    ).then((data: any) => {
      if (data?.Data.event) {
        setEventId(data.Data.event.ID);
        setCurrentPage(2);
        onAfterPageChange(2);
        form.resetFields();
      } else {
        onClose();
      }
    });
  };

  const onSelect = (val: any) => {
    setSiteId(val);
    localStorage.setItem(`${customerID}-selectedSiteId`, val.toString());
  };

  const formJSX = (
    <>
      <Form layout="vertical" form={form} className={styles.form}>
        <Form.Item
          name="site_id"
          label="Site"
          rules={[
            {
              required: true,
              message: 'Please select a site',
            },
          ]}>
          <Select onChange={onSelect} defaultValue={siteId}>
            {filteredSites.map((site) => (
              <Select.Option key={site.id} value={site.id}>
                {site.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: 'Please select a type',
            },
          ]}>
          <Select>
            {Object.values(EventType).map((type) => (
              <Select.Option key={type} value={type}>
                {EventTypeLabels[type]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {eventType && siteId ? (
        <div className={styles.form}>
          <UploadVideoToChannel
            channelID={getDefaultChannelForUpload(siteId)}
            truckIn={eventType === EventType.VehicleIn}
            onUpload={captureEvent}
            onComplete={onClose}
            isEmbedded={true}
          />
        </div>
      ) : null}
    </>
  );

  return (
    <>
      {currentPage === 1 ? (
        <>
          {filteredSites.length ? (
            formJSX
          ) : (
            <div className="df-error-text">
              {
                'You need to have a channel with licenses for storage and license plate recognition enabled to continue'
              }
            </div>
          )}
        </>
      ) : null}
      {currentPage === 2 ? (
        <CreateUpdateEvent
          namespace={namespace}
          eventId={eventId}
          onClose={() => {
            setCurrentPage(1);
            onClose();
          }}
          showImage={showImageInCreateUpdate}
        />
      ) : null}
    </>
  );
}

const CaptureVideoModal: React.FC<Props> = (props: Props) => {
  const { onClose } = props;

  const [pageTitle, setPageTitle] = useState('Capture Video');
  return (
    <Modal title={pageTitle} visible={true} footer={null} onCancel={onClose}>
      <CaptureVideo
        {...props}
        onAfterPageChange={(pageNo) => {
          if (pageNo === 2) setPageTitle('Update Event');
        }}
      />
    </Modal>
  );
};
export { CaptureVideoModal };
