import TimelinePlayer from '@/components/TimelinePlayer';
import { Modal } from 'antd';
import _ from 'lodash';
import React from 'react';

type State = any;

class PlayerModal extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  render() {
    const {
      children,
      startTime,
      endTime,
      events,
      fitEventsOnAxis,
      showLive,
      autoPlay,
    } = this.props;

    // if we have search events, add those channelIDs in as well as the ones passed in
    const channelIDs = _.uniq([
      ...(this.props.channelIDs || []).map((e) => +e),
      ...(events || []).map((e) => +e.ChannelID),
    ]);

    return (
      <>
        <Modal
          centered
          width={900}
          title={this.props.title || 'Player'}
          footer={null}
          visible={this.state.showModal}
          onCancel={() => this.toggleModal()}
          destroyOnClose={true}>
          <div style={{ height: 'calc(90vh - 54px - 32px)' }}>
            <TimelinePlayer
              autoPlay={autoPlay}
              fitEventsOnAxis={fitEventsOnAxis}
              showLive={showLive}
              events={events}
              startTime={startTime}
              endTime={endTime}
              channelIDs={channelIDs}
            />
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default PlayerModal;
