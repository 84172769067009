import { LocationMap } from '@/types/types';
import {
  DualViewportMap,
  MapSidebarSectionButton,
  MapSidebarSectionTooltipIcon,
  PixiMap,
  PixiMapsContainer,
} from '@/utils/pixi-lib/components';
import { DfViewport, MapRegion } from '@/utils/pixi-lib/display';
import { ptsConvertAbsToAbs } from '@/utils/pixi-lib/math';
import { dispatchWithFeedback } from '@/utils/utils';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusSquareOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import React, { RefObject } from 'react';
import { connect } from 'umi';
import theme from '../../../../../../config/theme';

const _SAVE_INTERVAL = 10000;

const _UPDATE_STATES = {
  dirty: {
    tooltip:
      'Regions have been updated, but are not yet saved. Saving happens automatically every 10 seconds.',
    iconType: SaveOutlined,
    color: theme['df-orange'],
  },
  loading: {
    tooltip: 'Updating regions...',
    iconType: LoadingOutlined,
    color: theme['df-text-grey'],
  },
  success: undefined,
};

type State = {
  showNameModal: boolean;
  isDirty: boolean;
  isSelected: boolean;
  coverageData: any;
};

type Props = {
  locationMap: LocationMap;
  floorMapPixi: PIXI.Application;
};

// typescript declaration merging
// - https://www.typescriptlang.org/docs/handbook/declaration-merging.html
interface MapRegions {
  floorMapRef: React.RefObject<HTMLDivElement>;
  pixiMapVP: DfViewport;
}

// @ts-expect-error
// @connect(({ loading }) => ({ loading }), null, null, { forwardRef: true })
class MapRegions extends DualViewportMap<Props, State> {
  saveIntervalRef: number;
  addRegionRef: RefObject<any>;
  mapRegions: MapRegion[];

  constructor(props) {
    super(props);
    this.state = {
      showNameModal: false,
      isDirty: false,
      isSelected: false,
      coverageData: {},
    };
    this.mapRegions = [];
    this.addRegionRef = React.createRef();
  }

  componentWillUnmount() {
    if (this.saveIntervalRef) {
      clearInterval(this.saveIntervalRef);
    }
    super.componentWillUnmount();
  }

  componentDidMount() {
    super.componentDidMount();
    this.saveIntervalRef = setInterval(() => {
      this.getPayload();
    }, _SAVE_INTERVAL);
  }

  // ~=~=~=~=~ initialize ~=~=~=~=~ //

  initAfterPixiLoaded() {
    // make sure we reset after clicking on the map
    this.pixiMapVP.pixi.on('clicked', () => this.clearRegionSelections());

    // init data
    const { locationMap } = this.props;
    const regions = _.get(locationMap, 'MapRegions', []);

    // create regions and add to viewport
    this.mapRegions = regions.map((region) => {
      const mRegion = this.initMapRegionFromPayload(region);
      mRegion.addToParent(this.pixiMapVP);
      return mRegion;
    });
  }

  /**
   * Load the region data from a server response. Make sure to reset the region
   * if it is invalid or out of bounds.
   */
  protected getRegionDataFromResponse(data: {
    Config: { polygon };
    MapRegionID;
    Name;
    Version;
  }) {
    let poly = _.get(data, 'Config.polygon', []);
    // - rescale polygon from original image dimensions
    poly = ptsConvertAbsToAbs(
      poly,
      this.pixiMapVP.origSpriteWidth,
      this.pixiMapVP.origSpriteHeight,
      this.pixiMapVP.worldWidth,
      this.pixiMapVP.worldHeight,
    );
    // - reset the region if needed
    if (
      !poly ||
      poly.length < 3 ||
      poly.every(([x, y]) => {
        return !this.pixiMapVP.dfWorldContainsPos({ x, y });
      })
    ) {
      console.log(
        'region, id:',
        data.MapRegionID,
        'name:',
        data.Name,
        'poly is out of bounds or invalid, resetting...',
        poly,
      );
      poly = this.getVpCenteredPoly();
    }
    // - return region data
    return {
      id: data.MapRegionID,
      name: data.Name,
      version: data.Version,
      polygon: poly,
    };
  }

  protected initMapRegionFromPayload(payload: {
    Config: { polygon };
    MapRegionID;
    Name;
    Version;
  }) {
    this.getVpCenteredPoly();
    const region = this.getRegionDataFromResponse(payload);
    // - callbacks
    const onRegionChange = (_r, kind) => {
      this.setState({ isDirty: true });
      if (kind === 'corner_added') {
        this.pixiMapVP.dfForceCurrentZoom();
      }
    };
    const onRegionSelect = (r) => {
      this.selectRegion(r);
    };
    // - create region
    const mRegion = new MapRegion(
      region.id,
      region.name,
      region.polygon,
      onRegionChange,
      onRegionSelect,
    );
    return mRegion;
  }

  protected getVpCenteredPoly() {
    // create region at the center of the current visible viewport
    const crect = this.pixiMapVP.visibleRect;
    const center = this.pixiMapVP.visibleCenter;
    const x0 = center.x - crect.width / 4;
    const y0 = center.y - crect.height / 4;
    const x1 = center.x + crect.width / 4;
    const y1 = center.y + crect.height / 4;
    const poly = [
      [x0, y0],
      [x1, y0],
      [x1, y1],
      [x0, y1],
    ];
    return poly;
  }

  // ~=~=~=~=~ payload saving ~=~=~=~=~ //

  /**
   * Return the payload for `dispatch('location_maps/updateChannelOnMapV2', ...)`
   * - The payload is generated by the parent `configure-location-map` component
   *   and passed to the `saveMapChannel` method from the `saveProgress` method
   *   in the `configure-location-map` parent component. `saveProgress` is
   *   called when the user clicks the next or previous button.
   */
  public getPayload = () => {
    if (!this.state.isDirty) {
      return { payload: {}, changed: false };
    }

    this.mapRegions.forEach((mapRegion) => {
      this.dispatchUpdateMapRegion(mapRegion);
    });

    this.setState({ isDirty: false });

    return { payload: {}, changed: false };
  };

  // ~=~=~=~=~ region helper ~=~=~=~=~ //

  private clearRegionSelections(options: { skipRegion?: MapRegion } = {}) {
    this.mapRegions.forEach((p) => {
      if (p !== options.skipRegion) {
        p.isSelected = false;
      }
    });
    // TODO: logic is wrong with skipping, but seems ok for now
    this.setState({ isSelected: false });
  }

  protected selectRegion(region: MapRegion) {
    this.clearRegionSelections({ skipRegion: region });
    region.isSelected = true; // internally checked to prevent recursion
    this.setState({ isSelected: true });
  }

  private getSelectedRegion(): MapRegion | undefined {
    return this.mapRegions.find((p) => p.isSelected);
  }

  // ~=~=~=~=~ region dispatch ~=~=~=~=~ //

  private dispatchCreateMapRegion(name: string, polygon: number[][]) {
    // create region
    return dispatchWithFeedback(
      this.props.dispatch,
      'Adding region',
      {
        type: 'location_maps/addMapRegion',
        payload: {
          name,
          config: {
            polygon: ptsConvertAbsToAbs(
              polygon,
              this.pixiMapVP.worldWidth,
              this.pixiMapVP.worldHeight,
              this.pixiMapVP.origSpriteWidth,
              this.pixiMapVP.origSpriteHeight,
            ),
          },
        },
        locationID: this.props.locationMap.ProjectID,
        locationMapID: this.props.locationMap.LocationMapID,
      },
      true,
    ).then((response) => {
      if (this.addRegionRef.current) {
        this.addRegionRef.current.resetFields();
      }

      // create!
      const mRegion = this.initMapRegionFromPayload(response);
      mRegion.addToParent(this.pixiMapVP);
      this.mapRegions = [...this.mapRegions, mRegion];

      // select
      this.selectRegion(mRegion);
    });
  }

  private dispatchDeleteMapRegion(region: MapRegion) {
    return dispatchWithFeedback(
      this.props.dispatch,
      'Deleting region',
      {
        type: 'location_maps/deleteMapRegion',
        locationID: this.props.locationMap.ProjectID,
        locationMapID: this.props.locationMap.LocationMapID,
        mapRegionID: region.id,
      },
      true,
    ).then(() => {
      this.mapRegions.splice(this.mapRegions.indexOf(region), 1);
      region.removeFromParent();
      this.clearRegionSelections();
    });
  }

  private dispatchUpdateMapRegion(region: MapRegion, newName?: string) {
    const oldName = region.name;
    const oldPoly = region.getPoly();

    if (newName) {
      region.name = newName;
    }
    if (region.isSameAsLastSave()) {
      console.log('region not updated, skipping update.');
      return Promise.resolve();
    }

    // dispatch
    return dispatchWithFeedback(
      this.props.dispatch,
      'Updating region',
      {
        type: 'location_maps/updateMapRegion',
        payload: {
          name: region.name,
          config: {
            polygon: ptsConvertAbsToAbs(
              oldPoly,
              this.pixiMapVP.worldWidth,
              this.pixiMapVP.worldHeight,
              this.pixiMapVP.origSpriteWidth,
              this.pixiMapVP.origSpriteHeight,
            ),
          },
        },
        locationID: this.props.locationMap.ProjectID,
        locationMapID: this.props.locationMap.LocationMapID,
        mapRegionID: region.id,
      },
      true,
    ).then((data) => {
      // update the regions state based on the response...
      const r = this.getRegionDataFromResponse(data);
      region.setLastSaveState(r.polygon, r.name);
      region.name = r.name;

      // check if the region is still consistent with the response
      // this is probably a bug if it happens
      if (!region.isSameAsLastSave()) {
        console.log(
          'displayed region is inconsistent after saving...',
          region.id,
          'old:',
          oldName,
          oldPoly,
          'response:',
          r.name,
          r.polygon,
        );
      }
    });
  }

  // ~=~=~=~=~ sidebar buttons ~=~=~=~=~ //

  private onButtonEditName() {
    const selectedRegion = this.getSelectedRegion();
    if (!selectedRegion) {
      return;
    }
    this.setState({ showNameModal: true }, () => {
      this.addRegionRef.current.setFieldsValue({
        name: selectedRegion.name,
      });
    });
  }

  private onButtonAddNewMapRegion() {
    this.setState({ showNameModal: true });
  }

  private onButtonRemoveMapRegion() {
    const selectedRegion = this.getSelectedRegion();
    if (!selectedRegion) {
      return;
    }
    this.dispatchDeleteMapRegion(selectedRegion);
  }

  private onButtonRegionDuplicate() {
    const selectedRegion = this.getSelectedRegion();
    if (!selectedRegion) {
      return;
    }

    // 1. get the name, but strip off the copy number: `${name} copy-(\d+)` if it exists at the end
    let name = selectedRegion.name.replace(/ copy-(\d+)$/, '');
    // | get a new name for the polygon, check all the regions for the highest copy number
    // | which is the current region name `${name} copy-(\d+)`. Keep track of the max
    // | copy number, if it is the max, then add 1 to it.
    let num = 0;
    this.mapRegions.forEach((r) => {
      const match = r.name.match(/copy-(\d+)$/);
      if (match) {
        try {
          const n = parseInt(match[1]);
          if (n > num) {
            num = n;
          }
        } catch (e) {
          // do nothing
        }
      }
    });

    // 2. create a new polygon with the same points, but shifted. Make sure we are within the map bounds.
    const [x0, y0, x1, y1] = selectedRegion.getTurfBbox();
    // - get the maximum shift amount in each direction, based on the space between the poly bbox and the map edge
    const maxOffset = 15;
    const dx0 = Math.min(maxOffset, Math.max(0, x0));
    const dx1 = Math.min(
      maxOffset,
      Math.max(0, this.pixiMapVP.worldWidth - x1),
    );
    const dy0 = Math.min(maxOffset, Math.max(0, y0));
    const dy1 = Math.min(
      maxOffset,
      Math.max(0, this.pixiMapVP.worldHeight - y1),
    );
    // - get the offset amounts
    const offsetX = dx1 > dx0 ? dx1 : -dx0;
    const offsetY = dy1 > dy0 ? dy1 : -dy0;
    // - shift the polygon
    const poly = selectedRegion
      .getPoly()
      .map((p) => [p[0] + offsetX, p[1] + offsetY]);

    // 3. create the new region
    this.dispatchCreateMapRegion(`${name} copy-${num + 1}`, poly);
  }

  // ~=~=~=~=~ render ~=~=~=~=~ //

  render() {
    const { locationMap } = this.props;
    // const isSelected = !!this.getSelectedRegion();
    const { isDirty, isSelected } = this.state;
    const { loading } = this.props;
    const isDispatching =
      loading.effects['location_maps/addMapRegion'] ||
      loading.effects['location_maps/deleteMapRegion'] ||
      loading.effects['location_maps/updateMapRegion'];
    const updateCfg = isDispatching
      ? _UPDATE_STATES.loading
      : isDirty
      ? _UPDATE_STATES.dirty
      : _UPDATE_STATES.success;

    return (
      <PixiMapsContainer>
        <PixiMap
          pixiRef={this.floorMapRef}
          dfViewport={this.pixiMapVP}
          heading={locationMap.Name}
          single>
          {updateCfg && (
            <MapSidebarSectionTooltipIcon
              tooltip={updateCfg.tooltip}
              color={updateCfg.color}
              iconType={updateCfg.iconType}
            />
          )}
          {this.renderMapRegionsSidebar(isDispatching, isSelected)}
        </PixiMap>
        {/* MODAL */}
        {this.renderModal()}
      </PixiMapsContainer>
    );
  }

  // ~=~=~=~=~ modal ~=~=~=~=~ //

  handleMapRegionForm(_e) {
    this.addRegionRef.current.validateFields().then(
      (values) => {
        const selectedRegion = this.getSelectedRegion();
        let promise;
        if (selectedRegion) {
          promise = this.dispatchUpdateMapRegion(selectedRegion, values.name);
        } else {
          promise = this.dispatchCreateMapRegion(
            values.name,
            this.getVpCenteredPoly(),
          );
        }
        promise.finally(() => {
          this.setState({ showNameModal: false });
        });
      },
      (err) => console.log('err', err),
    );
  }
  protected renderMapRegionsSidebar(isDispatching, isSelected) {
    return (
      <>
        <MapSidebarSectionButton
          onClick={() => this.onButtonEditName()}
          iconType={EditOutlined}
          disabled={isDispatching || !isSelected}
          title="Edit Region Name"
        />
        <MapSidebarSectionButton
          onClick={() =>
            isSelected
              ? this.onButtonRegionDuplicate()
              : this.onButtonAddNewMapRegion()
          }
          iconType={isSelected ? CopyOutlined : PlusSquareOutlined}
          disabled={isDispatching}
          title={isSelected ? 'Duplicate Region' : 'New Region'}
        />
        <MapSidebarSectionButton
          onClick={() => this.onButtonRemoveMapRegion()}
          iconType={DeleteOutlined}
          disabled={isDispatching || !isSelected}
          title="Delete Region"
        />
      </>
    );
  }
  protected renderModal() {
    const { loading } = this.props;

    return (
      <Modal
        width={400}
        title="Region View"
        visible={this.state.showNameModal}
        confirmLoading={
          loading.effects['location_maps/updateMapRegion'] ||
          loading.effects['location_maps/addMapRegion']
        }
        onOk={(e) => {
          this.handleMapRegionForm(e);
        }}
        onCancel={() => {
          this.setState({ showNameModal: false }, () => {
            if (this.addRegionRef.current) {
              this.addRegionRef.current.resetFields();
            }
          });
        }}>
        <Form
          ref={this.addRegionRef}
          layout="vertical"
          requiredMark={false}
          onFinish={(e) => this.handleMapRegionForm(e)}>
          <Form.Item
            label="Region Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name of the region',
              },
            ]}>
            <Input autoFocus />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
export { MapRegions, _UPDATE_STATES };

const mapStateToProps = (state) => ({ loading: state.loading });
const withReduxConnect = connect(mapStateToProps, null, null, {
  forwardRef: true,
});
const MapRegionsWithRedux = withReduxConnect(MapRegions);
export default MapRegionsWithRedux;
