/* eslint-disable no-bitwise */

import CamDefault from '@/assets/cam-image-default.png';
import Icon, {
  InfoCircleOutlined,
  Loading3QuartersOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Empty,
  Form,
  Image,
  Input,
  Spin,
  Tooltip,
} from 'antd';
import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { connect, Link } from 'umi';

import InfoIcon from '@/assets/info-plain';
import ChannelImageStream from '@/components/channel-image-stream';
import PageHeader from '@/components/PageHeader2';
import BulkDiscoverCameras from '@/pages/frontier/component/BulkDiscoverCameras';
import DiscoverManually from '@/pages/frontier/component/DiscoverManually';
import LocationSettings from '@/pages/locations/location/location-settings';

import {
  getChannelList,
  getChannelONVIFDetails,
  ONVIFCamera,
  ONVIFCameraLabel,
} from '@/utils/onvif';
import {
  dispatchWithFeedback,
  META_VMS_PLUGIN_ID,
  ONVIF_VMS_PLUGIN_ID,
  STREAM_TYPES,
} from '@/utils/utils';
import styles from './style.less';

import CameraOffIcon from '@/assets/camera-off-plain';
import LoadingSpinner from '@/components/LoadingSpinner';
import { DiscoveredDeviceNode } from '@/types/location';
import withRouter from '@/utils/withRouter';
import type { FormInstance } from 'antd';

enum PAGE_STATE {
  DISCOVER_CAMERA,
  CONFIGURE_CAMERA,
}

type Props = any;
type State = {
  isDiscovering: boolean;
  page_state: PAGE_STATE;
  channel_list: ONVIFCamera[];
  activechannel: ONVIFCamera | null;
  activechannel_idx: number | null;
};

// @ts-expect-error
@connect(({ user, locations, loading }) => ({
  user,
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  base_stn: locations.base_stn,
  discovered_ch: locations.discovered_ch,
  // loading,
  loadingDiscoveredDevices: loading.effects['locations/fetchDiscoveredDevices'],
  loadingChannelServer: loading.effects['locations/addChannelServer'],
  loadingChannelDetails: loading.effects['locations/updateChannelDetails'],
}))
class FrontierLocation extends React.Component<Props, State> {
  ONVIFChannnelFormRef: React.RefObject<FormInstance>;
  ONVIFConfigFormRef: React.RefObject<FormInstance>;
  discoverManuallyRef: React.RefObject<DiscoverManually>;
  bulkDiscoverCamerasRef: React.RefObject<BulkDiscoverCameras>;
  isRefreshing: any;
  possible_no_clm: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDiscovering: false,
      page_state: PAGE_STATE.DISCOVER_CAMERA,
      channel_list: [],
      activechannel: null,
      activechannel_idx: null,
    };
    this.ONVIFChannnelFormRef = React.createRef();
    this.ONVIFConfigFormRef = React.createRef();
    this.discoverManuallyRef = React.createRef();
    this.bulkDiscoverCamerasRef = React.createRef();
    this.isRefreshing = {};
    this.possible_no_clm = 2;
  }

  componentDidMount() {
    this.setChannels();
    this.fetchDiscoveredDevices();
  }

  componentWillUnmount() {
    let channel_ids = this.getLocationChannels() || [];
    _.forEach(channel_ids, (ch) => {
      this.isRefreshing[ch] = true;

      this.props.dispatch({
        type: 'user/imageStreamOp',
        op: 'unrequest',
        channelID: ch,
        streamType: STREAM_TYPES.CIF_1,
      });
    });
  }

  componentDidUpdate(prevProps: Props) {
    const location = this.getMyLocation();
    const { loc } = prevProps;
    let { locationID } = prevProps.match.params;
    locationID = +locationID;
    const prevLocation = loc.byId[locationID];
    if (prevLocation !== location) {
      this.setChannels();
    }
  }

  getMyLocation() {
    const { loc } = this.props;
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const location = loc.byId[locationID];
    return location;
  }

  setChannels() {
    const { ch_grp, ch } = this.props;
    const location = this.getMyLocation();
    const newchannel_list = getChannelList(location, { ch_grp, ch });
    this.addChannelToChannelList(newchannel_list, {
      labels: [ONVIFCameraLabel.ALREADY_ADDED],
    });
  }

  fetchDiscoveredDevices(force: boolean = false) {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const { base_stn, discovered_ch } = this.props;

    if (base_stn.map_loc_baseStn.has(locationID)) {
      let currentList = discovered_ch.allIds.filter(
        (ch) => ch.ProjectID === locationID,
      );

      dispatchWithFeedback(
        this.props.dispatch,
        'Discovering devices',
        {
          type: 'locations/fetchDiscoveredDevices',
          locationID,
        },
        true,
      ).then((res) => {
        let list = _.get(res, 'DiscoveredDevices') || [];

        if (!force && list.length) {
          this.setDiscoveredDevices();
          return;
        }

        if (force && !_.isEqual(currentList, list)) {
          this.setDiscoveredDevices();
          return;
        }
        // if we got the same list as before, and we're in a force
        // situation, clearly we're not happy with the old list...
        // so try another refresh.

        // this call sets in motion a fetch call to frontier.
        // so if we don't get anything, wait for some time and try
        // again.

        if (this.state.isDiscovering) {
          return;
        }

        this.setState({ isDiscovering: true });
        setTimeout(() => {
          dispatchWithFeedback(
            this.props.dispatch,
            'Discovering devices',
            {
              type: 'locations/fetchDiscoveredDevices',
              locationID,
            },
            true,
          ).then(() => {
            this.setState({ isDiscovering: false });
            this.setDiscoveredDevices();
          });
        }, 15000);
      });
    }
  }

  setDiscoveredDevices() {
    const { discovered_ch } = this.props;
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const dis_ch = discovered_ch.allIds
      .map((key: string, idx: number) => {
        const ch: DiscoveredDeviceNode = discovered_ch.byId[key];
        if (ch.ProjectID !== locationID) return null;

        let model = _.get(ch, 'Model');
        let name = `Discovered Cam ${idx + 1}`;
        if (model) {
          name = `${model} (discovered ${idx + 1})`;
        }

        const channelServerCred = {
          Name: name,
          ChannelDetails: {
            RTSPPort: ch.Port,
            HTTPPort: '80',
            IPAddress: ch.IP,
            ServerURL: _.get(ch, 'ServerURL'),
            Username: _.get(ch, 'Username'),
            Password: _.get(ch, 'Password'),
            Manufacturer: _.get(ch, 'Manufacturer'),
            Model: _.get(ch, 'Model'),
            Hardware: _.get(ch, 'Hardware'),
          },
          MonitorStatus: 'stream',
          DiscoveredDevice: true,
        };
        return channelServerCred;
      })
      .filter((item: any) => {
        return item !== null;
      });

    this.addChannelToChannelList(dis_ch, {
      labels: [ONVIFCameraLabel.AUTO_DISCOVER],
    });
  }

  mergeNewOldChannelList(
    oldchannel_list: ONVIFCamera[],
    newchannel_list: ONVIFCamera[],
  ) {
    const withChannelID_key_ch_list_map = new Map();
    const withoutChannelID_key_ch_list_map = new Map();

    oldchannel_list.forEach((ch: ONVIFCamera) => {
      const u_key = ch.getUniqueKey();
      if (ch.isAddedToLocation()) {
        withChannelID_key_ch_list_map.set(ch.ChannelID, ch);
      } else if (u_key) {
        withoutChannelID_key_ch_list_map.set(u_key, ch);
      }
    });
    newchannel_list.forEach((ch: ONVIFCamera) => {
      const u_key = ch.getUniqueKey();
      if (ch.isAddedToLocation()) {
        withChannelID_key_ch_list_map.set(ch.ChannelID, ch);
      } else if (u_key) {
        withoutChannelID_key_ch_list_map.set(u_key, ch);
      }
    });

    const channel_list: ONVIFCamera[] = [];
    withChannelID_key_ch_list_map.forEach((ch) => channel_list.push(ch));
    withoutChannelID_key_ch_list_map.forEach((ch) => channel_list.push(ch));

    return channel_list;
  }

  openDiscoverManuallyModal() {
    if (this.discoverManuallyRef.current) {
      this.discoverManuallyRef.current.openModal();
    }
  }

  openBulkDiscoverCamerasModal() {
    if (this.bulkDiscoverCamerasRef.current) {
      this.bulkDiscoverCamerasRef.current.openModal();
    }
  }

  addChannelToChannelList(
    ch_list: any[],
    {
      labels = [],
    }: {
      labels?: ONVIFCameraLabel[];
    } = {},
  ) {
    // if we try to add manually before we wait for discoverd devices
    this.setState({ isDiscovering: false });
    const new_ch_map = new Map();

    const updated_channel: ONVIFCamera[] = [];

    const channels: ONVIFCamera[] = ch_list.map((ch) => {
      const onvif_new_ch = ch instanceof ONVIFCamera ? ch : new ONVIFCamera(ch);

      onvif_new_ch.addLabels(labels);

      // update channels that correspond to IP addresses we already know about.
      const ch_from_ch_list = _.find(
        this.state.channel_list,
        (c) => c.IPAddress === onvif_new_ch.IPAddress,
      );
      if (
        ch_from_ch_list &&
        (_.get(ch, 'DiscoveredDevice', false) ||
          ch_from_ch_list.getUniqueKey() === onvif_new_ch.getUniqueKey())
      ) {
        ch_from_ch_list.update(onvif_new_ch);
        onvif_new_ch.addLabels([ONVIFCameraLabel.ALREADY_ADDED]);
        updated_channel.push(ch_from_ch_list);
      }
      new_ch_map.set(onvif_new_ch.getUniqueKey(), true);
      return onvif_new_ch;
    });
    const newChannelList = this.mergeNewOldChannelList(
      this.state.channel_list,
      [...updated_channel, ...channels],
    );
    this.setState({ channel_list: newChannelList }, () => {
      const form_field: Record<string, any> = {};
      newChannelList.forEach((ch, idx) => {
        if (ch && !ch.ChannelID && new_ch_map.has(ch.getUniqueKey())) {
          form_field[`check-box-${idx}`] = false;
        }
      });
      if (this.ONVIFChannnelFormRef.current) {
        this.ONVIFChannnelFormRef.current.setFieldsValue(form_field);
      }
    });
  }

  refreshLocation() {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    if (this.refreshingLocation) {
      return Promise.resolve();
    }
    this.refreshingLocation = true;
    return dispatchWithFeedback(
      this.props.dispatch,
      'Fetching location',
      {
        type: 'locations/fetchLocationsNoLoader',
        payload: { locationID },
      },
      true,
    ).then(() => {
      this.refreshingLocation = false;
      this.forceUpdate();
    });
  }

  addChannelServer(ch_list: any) {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const promises: any[] = [];
    ch_list.forEach((ch: any) => {
      const channelServerCred = {
        name: ch['Name'],
        username: ch['Username'],
        password: ch['Password'],
        ip_address: ch['IPAddress'],
        http_port: ch['HTTPPort'],
        rtsp_port: ch['RTSPPort'],
        server_url: ch['ServerURL'],
        monitor_status: ch['MonitorStatus'] || 'stream',
        more_info: {
          Model: _.get(ch, 'Model', null),
          Manufacturer: _.get(ch, 'Manufacturer', null),
          Hardware: _.get(ch, 'Hardware', null),
        },
      };

      const _promise = this.props.dispatch({
        type: 'locations/addChannelServer',
        locationID,
        payload: channelServerCred,
      });

      promises.push(_promise);
    });

    return Promise.all(promises).then(() => {
      this.setState({ page_state: PAGE_STATE.CONFIGURE_CAMERA });
    });
  }

  updateChannelDetails() {
    if (this.ONVIFConfigFormRef.current) {
      const form_fields = this.ONVIFConfigFormRef.current.getFieldsValue();
      const { channel_list, activechannel, activechannel_idx } = this.state;
      if (
        !(activechannel instanceof ONVIFCamera) ||
        activechannel_idx === undefined
      ) {
        return;
      }
      const _activechannel_fields: Record<string, any> = {
        camera_name: _.get(activechannel, 'Name', null),
        username: _.get(activechannel, 'Username', null),
        password: _.get(activechannel, 'Password', null),
        ip_address: _.get(activechannel, 'IPAddress', null),
        rtsp_port: _.get(activechannel, 'RTSPPort', null),
        server_url: _.get(activechannel, 'ServerURL', null),
      };

      const channelServerCred: Record<string, any> = {};
      Object.keys(form_fields).forEach((_k) => {
        if (form_fields[_k] !== _activechannel_fields[_k]) {
          channelServerCred[_k] = form_fields[_k];
          if (_k === 'monitor_status') {
            channelServerCred[_k] = form_fields[_k] ? 'stream' : 'inactive';
          }
        }
      });
      if (Object.keys(channelServerCred).length > 0) {
        if (activechannel.isAddedToLocation()) {
          let { locationID } = this.props.match.params;
          locationID = +locationID;
          this.props
            .dispatch({
              type: 'locations/updateChannelDetails',
              locationID,
              channelID: activechannel.ChannelID,
              payload: channelServerCred,
            })
            .then(() => {
              this.requestImageStream([activechannel.ChannelID]);
              // return this.refreshLocation();
            });
        }
        activechannel.updateFromONVIFConfigFormField(channelServerCred);
        channel_list[activechannel_idx] = activechannel;
        const newchannel_list = [...channel_list];
        this.setState({
          channel_list: newchannel_list,
          activechannel,
        });
      }
    }
  }

  addActiveChannel(channel: ONVIFCamera, idx: number) {
    // the form only shows up in the second step
    if (this.state.page_state === PAGE_STATE.DISCOVER_CAMERA) {
      return;
    }

    this.setState(
      {
        activechannel: channel,
        activechannel_idx: idx,
      },
      () => {
        const form_fields = {
          camera_name: _.get(channel, 'Name', null),
          username: _.get(channel, 'Username', null),
          password: _.get(channel, 'Password', ''),
          ip_address: _.get(channel, 'IPAddress', null),
          rtsp_port: _.get(channel, 'RTSPPort', null),
          server_url: _.get(channel, 'ServerURL', null),
          monitor_status: _.isEqual(
            _.get(channel, 'MonitorStatus', null),
            'stream',
          ),
        };
        if (this.ONVIFConfigFormRef.current) {
          this.ONVIFConfigFormRef.current.setFieldsValue(form_fields);
        }
      },
    );
  }

  onAddToLocation() {
    if (this.ONVIFChannnelFormRef.current) {
      const fields = this.ONVIFChannnelFormRef.current.getFieldsValue();
      const idx_vals = Object.keys(fields)
        .filter((k) => fields[k])
        .map((k) => k.replace('check-box-', ''));
      const ch_list: ONVIFCamera[] = [];
      const { channel_list } = this.state;
      idx_vals.forEach((k: any) => ch_list.push(channel_list[k]));
      return this.addChannelServer(ch_list);
    }
  }

  baseStationLoadingDeviceRender() {
    return (
      <div className={styles['base-station-detail-ctn']}>
        <div className={styles['plugin-info-box']}>
          <div className={styles['icon-ctn']}>
            <Spin
              indicator={
                <Loading3QuartersOutlined
                  style={{ fontSize: 30, color: '#fff' }}
                  spin
                />
              }
            />
          </div>
          <div className={styles['text-ctn']}>
            Searching for cameras on the local network. Once discovered, cameras
            will be displayed here for configuration.
          </div>
          <div className={styles['action-btn-ctn']}>
            <Button
              size="middle"
              type="default"
              onClick={() => this.skipToConfigure()}>
              Skip Discovery
            </Button>
            <Button
              size="middle"
              type="default"
              style={{ marginLeft: '16px' }}
              onClick={() => this.openDiscoverManuallyModal()}>
              Discover Manually
            </Button>
          </div>
        </div>
      </div>
    );
  }

  baseStationNoDeviceFoundRender() {
    const location = this.getMyLocation();
    return (
      <div className={styles['base-station-detail-ctn']}>
        <div className={styles['plugin-info-box']}>
          <div className={styles['icon-ctn']}>
            <Icon
              component={CameraOffIcon}
              style={{
                color: '#fff',
                transform: 'scale(2.3)',
              }}
            />
          </div>
          {location.ProjectStatus ? (
            <div className={styles['text-ctn']}>No cameras discovered.</div>
          ) : (
            <div className={styles['text-ctn']}>
              Base Station hasn't connected yet.
            </div>
          )}
          <div className={styles['action-btn-ctn']}>
            <Button
              size="middle"
              type="default"
              onClick={() => this.fetchDiscoveredDevices(true)}>
              Try Again
            </Button>
            <Button
              size="middle"
              type="default"
              style={{ marginLeft: '16px' }}
              onClick={() => this.openDiscoverManuallyModal()}>
              Discover Manually
            </Button>
          </div>
          {this.getLocationChannels().length ? (
            <div className={styles['action-btn-ctn']}>
              <Button
                size="middle"
                type="default"
                onClick={() => this.skipToConfigure()}>
                Skip Discovery
              </Button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }

  baseStationCheckRender() {
    if (this.state.page_state === PAGE_STATE.CONFIGURE_CAMERA) {
      // we've skipped discovery
      return null;
    }

    const { loadingDiscoveredDevices } = this.props;
    if (loadingDiscoveredDevices || this.state.isDiscovering) {
      return this.baseStationLoadingDeviceRender();
    }
    const { discovered_ch } = this.props;
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const dis_ch = discovered_ch.allIds.filter((key: string) => {
      const ch: DiscoveredDeviceNode = discovered_ch.byId[key];
      return ch.ProjectID === locationID;
    });
    if (dis_ch.length === 0 && this.state.channel_list.length === 0) {
      return this.baseStationNoDeviceFoundRender();
    }
    return null;
  }

  getLocationName() {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const location = this.props.loc.byId[locationID];
    return location.Name;
  }

  requestImageStream(channel_ids) {
    _.forEach(channel_ids, (channelID) => {
      this.isRefreshing[channelID] = true;
      this.props.dispatch({
        type: 'user/imageStreamOp',
        op: 'request',
        channelID,
        streamType: STREAM_TYPES.CIF_1,
      });
    });

    setTimeout(() => {
      _.forEach(channel_ids, (ch) => {
        this.isRefreshing[ch] = false;
      });
      this.forceUpdate();
    }, 15000);
  }

  getLocationChannels() {
    return this.state.channel_list.map((ch) => ch.ChannelID).filter((ch) => ch);
  }

  skipToConfigure() {
    let locationChannels = this.getLocationChannels();

    this.setState(
      {
        activechannel: undefined,
        activechannel_idx: undefined,
        page_state: PAGE_STATE.CONFIGURE_CAMERA,
      },
      () => {
        this.forceUpdate();
        this.requestImageStream(locationChannels);
      },
    );
  }

  hasCheckedCards() {
    let fieldValues = this.ONVIFChannnelFormRef.current?.getFieldsValue() || {};
    return Object.values(fieldValues).filter((x) => x).length;
  }

  renderCard(channel, idx, page_state) {
    const { activechannel } = this.state;
    const isAddedToLocation = channel.isAddedToLocation();
    if (page_state === PAGE_STATE.DISCOVER_CAMERA && isAddedToLocation) {
      return null;
    }
    if (page_state === PAGE_STATE.CONFIGURE_CAMERA && !isAddedToLocation) {
      return null;
    }
    // if (isAddedToLocation && !map_new_ch_uniquekey.has(channel.getUniqueKey()))
    //  return null;
    const channel_onvif_details = getChannelONVIFDetails(channel);
    const tile_attribute: Record<string, any> = {};

    if (
      activechannel &&
      ((activechannel.ChannelID &&
        channel.ChannelID &&
        channel.ChannelID === activechannel.ChannelID) ||
        (!activechannel.ChannelID &&
          channel.getUniqueKey() === activechannel.getUniqueKey()))
    ) {
      tile_attribute['active-channel'] = 'true';
    }
    if (
      page_state === PAGE_STATE.DISCOVER_CAMERA &&
      this.ONVIFChannnelFormRef.current?.getFieldValue(`check-box-${idx}`)
    ) {
      tile_attribute['checked-channel'] = 'true';
    } else {
      tile_attribute['checked-channel'] = 'false';
    }
    let _class_name_ = 'df-tile-container';
    let inactive = false;
    if (channel_onvif_details.monitorStatus !== 'stream') {
      inactive = true;
      _class_name_ += ' df-tile-container-disabled';
    }

    let manufacturer = _.get(channel, 'Manufacturer') || '';
    let model = _.get(channel, 'Model') || _.get(channel, 'Hardware') || '';
    let makeModelStr = ` ${manufacturer} `.trim();
    let newCamStr = model || 'Discovered Camera';

    // see if we have info on this model
    let modelInfo = DiscoveredDeviceNode.getModelInfo(model);

    let src = CamDefault;
    if (modelInfo && modelInfo.imageUrl) {
      src = `https://df-camera-image.s3.amazonaws.com/${modelInfo.imageUrl}`;
    }

    return (
      <div
        key={`camera-tile-${idx}`}
        className={_class_name_}
        {...tile_attribute}
        onClick={() => {
          this.addActiveChannel(channel, idx);
        }}>
        <div className="df-tile-content">
          {inactive && (
            <div className={`${styles['inactive-label']} df-tile-badge`}>
              <WarningOutlined />
              &nbsp;&nbsp;Inactive
            </div>
          )}
          {channel.labels.has(ONVIFCameraLabel.MANUAL) &&
            channel.labels.has(ONVIFCameraLabel.ALREADY_ADDED) && (
              <div className={`${styles['already-added-label']} df-tile-badge`}>
                <InfoCircleOutlined />
                &nbsp;&nbsp;Already Added
              </div>
            )}
          {!channel.isAddedToLocation() && channel.isCheckable() ? (
            <div className={styles['checkbox-ctn']}>
              <Form.Item
                name={`check-box-${idx}`}
                onChange={() => this.forceUpdate()}
                valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </div>
          ) : null}
          <div className="df-tile-body">
            <Image src={src} preview={false} />
            <div className="df-tile-image-stream">
              <ChannelImageStream channelID={channel.ChannelID} />
            </div>
            <div className={styles['cam-status']}>
              {channel_onvif_details.channelID !== 0 &&
              channel_onvif_details.channelStatus != null &&
              channel_onvif_details.monitorStatus === 'stream' ? (
                <div className={styles['row-element']}>
                  <div
                    className={classnames(
                      styles['status'],
                      channel_onvif_details.channelStatus === 0
                        ? styles['status-connected']
                        : styles['status-disconnected'],
                      styles['clickable'],
                    )}>
                    {channel_onvif_details.channelStatus === 0
                      ? 'Connected'
                      : 'Failed'}
                    &nbsp;
                    <Tooltip
                      arrowPointAtCenter
                      placement="top"
                      title={
                        <div onClick={() => console.log(channel_onvif_details)}>
                          <div>
                            Last updated:{' '}
                            {channel_onvif_details.lastStatusUpdateTime}
                          </div>
                          {channel_onvif_details.channelStatusMsg && (
                            <div>
                              Last message:{' '}
                              {channel_onvif_details.channelStatusMsg}
                            </div>
                          )}
                        </div>
                      }>
                      <Icon component={InfoIcon} />
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div className={classnames(styles['row-element'])}>
                  {channel.ChannelID ? 'Not Connected' : newCamStr}
                </div>
              )}
              <div className={styles['cam-refresher']}>
                {channel.ChannelID && (
                  <>
                    {this.isRefreshing[channel.ChannelID] ? (
                      <span>Refreshing...</span>
                    ) : (
                      <span
                        className="df-link"
                        onClick={() =>
                          this.requestImageStream([channel.ChannelID])
                        }>
                        Refresh
                      </span>
                    )}
                    <span> | </span>
                    <a
                      href={`http://${channel.IPAddress}`}
                      target="_blank"
                      rel="noreferrer">
                      Admin
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="df-tile-footer">
            <div className={styles['name-ctn']}>
              {channel.ChannelID ? this.getLocationName() : makeModelStr}
            </div>
            <div className={styles['cam-ip-ctn']}>
              {channel.ChannelID
                ? channel.Name
                : channel_onvif_details.ipAddress}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDiscovered(channel_list) {
    let elements = channel_list
      .map((channel: ONVIFCamera, idx: number) =>
        this.renderCard(channel, idx, this.state.page_state),
      )
      .filter((item) => {
        return item !== null;
      })
      .map((ele: React.ReactElement, idx: number) => {
        if ((idx + 1) % this.possible_no_clm === 0) {
          const new_props = {
            className:
              ele.props.className + ` ${styles['camera-tile-0-right-margin']}`,
          };
          const new_ele = React.cloneElement(ele, new_props);
          return new_ele;
        }
        return ele;
      });

    return (
      <div className={styles.ctn}>
        {elements}
        {!elements.length && (
          <div style={{ width: '100%' }}>
            <Empty
              description={
                <div>
                  <div>No Cameras Discovered</div>
                  <div>
                    <span
                      className="df-link"
                      onClick={() => this.fetchDiscoveredDevices(true)}>
                      {' '}
                      Refresh List
                    </span>
                  </div>
                </div>
              }
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { page_state, channel_list, activechannel } = this.state;

    const { loadingChannelServer, loadingChannelDetails } = this.props;

    const baseStationRender = this.baseStationCheckRender();

    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const location = this.getMyLocation();

    if (!location) return <LoadingSpinner />;

    // ONVIF FORM CTN WIDTH :- sidebar 232px, right steps panel min 282px + container padding 16px = 530
    this.possible_no_clm = Math.floor((window.innerWidth - 530) / 298);

    let locationHasChannels = this.getLocationChannels().length !== 0;

    return (
      <>
        <PageHeader
          title={this.getLocationName()}
          loc_node={location}
          right={
            <span>
              <Link to={`/locations/${locationID}`}>
                <Tooltip
                  placement="left"
                  title={!locationHasChannels && 'Add a camera to continue'}>
                  <Button
                    size="small"
                    type="default"
                    disabled={!locationHasChannels}>
                    Finish Configuration
                  </Button>
                </Tooltip>
              </Link>
              &nbsp;
              <LocationSettings locationID={locationID}>
                <Button size="small" type="default">
                  Settings
                </Button>
              </LocationSettings>
            </span>
          }
        />
        {baseStationRender || (
          <div
            style={{
              width: '100%',
              height: 'calc(100% - 65px)',
              overflowX: 'hidden',
              overflowY: 'hidden',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}>
            <div
              className={`${
                styles['onvif-channel-form-ctn']
              } ${'scroll-bar-slim-style2'}`}
              style={{
                width: `${
                  282 * this.possible_no_clm +
                  16 * (this.possible_no_clm - 1) +
                  11
                }px`,
              }}>
              <Form
                className={styles['onvif-channel-form']}
                ref={this.ONVIFChannnelFormRef}>
                {this.renderDiscovered(channel_list)}
              </Form>
            </div>
            <div
              className={`${
                styles['onvif-action-pannels-ctn']
              } ${'scroll-bar-slim-style2'}`}>
              {page_state === PAGE_STATE.DISCOVER_CAMERA ? (
                <div className={styles['location-action-pannel']}>
                  <span className={styles['configure-step']}>Step 1 of 2</span>
                  <h3>Add New Cameras to Location</h3>
                  <p>
                    Select the discovered cameras you would like to add to this
                    location. Missing cameras? Click ‘Enter Manually’ below to
                    enter them using RTSP credentials.
                  </p>
                  {this.getLocationChannels().length ? (
                    <p>
                      You can
                      <span
                        className="df-link"
                        onClick={() => this.skipToConfigure()}>
                        &nbsp;skip this discovery step&nbsp;
                      </span>
                      to configuration.
                    </p>
                  ) : (
                    <p>Add at least one camera to continue.</p>
                  )}
                  <div>
                    <Button
                      size="middle"
                      type="default"
                      onClick={() => this.openDiscoverManuallyModal()}>
                      Enter Manually
                    </Button>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <Button
                      disabled={this.hasCheckedCards() === 0}
                      size="middle"
                      type="primary"
                      loading={loadingChannelServer || this.refreshingLocation}
                      style={{ marginRight: '8px' }}
                      onClick={() => {
                        this.onAddToLocation();
                      }}>
                      Add Camera{this.hasCheckedCards() === 1 ? '' : 's'} to
                      Location
                    </Button>
                  </div>
                </div>
              ) : null}
              {page_state === PAGE_STATE.CONFIGURE_CAMERA && !activechannel && (
                <div className={styles['onvif-channel-action-pannel']}>
                  <span className={styles['configure-step']}>Step 2 of 2</span>
                  <h3>Configure Cameras</h3>
                  <p>Select a Camera to modify its configuration.</p>
                  <br />
                </div>
              )}
              {page_state === PAGE_STATE.CONFIGURE_CAMERA && activechannel ? (
                <div className={styles['onvif-channel-action-pannel']}>
                  <span className={styles['configure-step']}>Step 2 of 2</span>
                  <h3>Configure Camera</h3>
                  <p>
                    Change the configuration for the selected camera. You can
                    also update this later in Camera settings.
                  </p>
                  <br />
                  <div className={styles['onvif-config-form-ctn']}>
                    <Form
                      ref={this.ONVIFConfigFormRef}
                      layout="vertical"
                      requiredMark={false}>
                      <Form.Item
                        name="camera_name"
                        label={
                          <span className={styles['form-item-label']}>
                            Camera Name
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'Please select a value',
                          },
                        ]}>
                        <Input className={styles['form-input']} />
                      </Form.Item>
                      {/* Show username, port etc. only for VMSPluginID 8 and 10 */}
                      {[ONVIF_VMS_PLUGIN_ID, META_VMS_PLUGIN_ID].includes(
                        _.get(location, 'VMSPluginID', 0),
                      ) ? (
                        <>
                          <Form.Item
                            name="username"
                            label={
                              <span className={styles['form-item-label']}>
                                Username
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'Please select a value',
                              },
                              {
                                pattern: /\b([^:?\/%]){1,1000}$/g,
                                message: 'Not Valid',
                              },
                            ]}>
                            <Input className={styles['form-input']} />
                          </Form.Item>
                          <Form.Item
                            name="password"
                            label={
                              <span className={styles['form-item-label']}>
                                Password
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'Please select a value',
                              },
                              {
                                pattern: /\b([^:?\/%]){1,1000}$/g,
                                message: 'Not Valid',
                              },
                            ]}>
                            <Input
                              className={styles['form-input']}
                              placeholder="New password"
                            />
                          </Form.Item>
                          <Form.Item
                            name="ip_address"
                            label={
                              <span className={styles['form-item-label']}>
                                IP Address
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'Please select a value',
                              },
                              {
                                pattern: /^\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}$/g,
                                message: 'Not Valid',
                              },
                            ]}>
                            <Input className={styles['form-input']} />
                          </Form.Item>
                          <Form.Item
                            name="rtsp_port"
                            label={
                              <span className={styles['form-item-label']}>
                                Port Number
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'Please select a value',
                              },
                              {
                                pattern: /^\d{1,5}$/g,
                                message: 'Not Valid',
                              },
                            ]}>
                            <Input className={styles['form-input']} />
                          </Form.Item>
                          <Form.Item
                            name="server_url"
                            label={
                              <span className={styles['form-item-label']}>
                                Server URL
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'Please select a value',
                              },
                            ]}>
                            <Input className={styles['form-input']} />
                          </Form.Item>
                        </>
                      ) : null}
                      <Form.Item
                        name={`monitor_status`}
                        valuePropName="checked"
                        label={
                          <span
                            className={styles['form-item-label']}
                            style={{
                              height: '32px',
                              marginTop: 'calc( 50% - 14px )',
                              marginRight: '4px',
                            }}>
                            Enabled:
                          </span>
                        }
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                        // labelCol={{ offset: 12 }}
                        rules={[
                          {
                            required: true,
                            message: 'Please select a value',
                          },
                        ]}>
                        <Checkbox />
                      </Form.Item>
                      <div className={styles['form-row']}>
                        <Button
                          loading={loadingChannelDetails}
                          size="middle"
                          type="primary"
                          onClick={() => {
                            this.updateChannelDetails();
                          }}>
                          Update
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
        <DiscoverManually
          ref={this.discoverManuallyRef}
          addChannelToChannelList={(ch_list: any[]) => {
            this.addChannelToChannelList(ch_list, {
              labels: [ONVIFCameraLabel.MANUAL],
            });
          }}
          openBulkDiscoverCamerasModal={() =>
            this.openBulkDiscoverCamerasModal()
          }
        />
        <BulkDiscoverCameras
          existingChannelCount={channel_list.length}
          ref={this.bulkDiscoverCamerasRef}
          addChannelToChannelList={(ch_list: any[]) => {
            this.addChannelToChannelList(ch_list, {
              labels: [ONVIFCameraLabel.MANUAL],
            });
          }}
          openDiscoverManuallyModal={() => this.openDiscoverManuallyModal()}
        />
      </>
    );
  }
}
export default withRouter(FrontierLocation);
